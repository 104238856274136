import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderStatusFilter } from '../../redux/filterSlice';
import { useTranslation } from "react-i18next";
import axios from "axios";

import './OrderStatus.css';
import moment from "moment";

import { Button, Select, Form, Spin, notification, DatePicker } from 'antd';
import { OrderForStatus, Pagination } from '../../components';
import { createParasutInvoice, listOrders, fetchCreateEnglishInvoice, setOrders, updateReadyBulkOrders } from '../../redux/orderSlice';
import { fetchTntCargo, fetchAllCompanies } from '../../redux/cargoCompanySlice';
import LocaleProvider from 'antd/lib/locale-provider';
import { selectAllOrders, removeAllOrders } from '../../redux/bulkInvoiceSlice';
import BulkInvoicePopup from './components/BulkInvoicePopup';
import { fetchAllExchangeRates } from '../../redux/exchangeRatesSlice';

import 'moment/locale/tr';
import { ORDER_STATUS_STATUSES } from '../../constants';

const { RangePicker } = DatePicker;

function OrderStatus() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orderStatusFilter: filter } = useSelector((state) => state.filter);
  //const { orders } = useSelector((state) => state.order);
  const [orderstatus, setOrderStatus] = useState([]);
  const [orderStatusCount, setOrderStatusCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const bulkOrders = useSelector((state) => state.bulkInvoice.bulkOrders);
  const [InvoiceLoading, setInvoiceLoading] = useState(false);

  const [cargoCompanies, setCargoCompanies] = useState([]);
  const [bulkCargoCompanies, setBulkCargoCompanies] = useState([]);

  const [totalInfo, setTotalInfo] = useState({desi : 0, weight : 0, package : 0, quantity: 0});
  const [realInfo, setRealInfo] = useState({desi : 0, weight : 0, package : 0, quantity: 0});
  const [estimatedInfo, setEstimatedInfo] = useState({desi : 0, weight : 0, package : 0, quantity: 0});

  const [isBulkInvoicePopupOpen, setIsBulkInvoicePopupOpen ] = useState(false);
  const [shipmentDate, setShipmentDate] = useState(null);
  const [billOfLading, setBillOfLading] = useState("");
  const [bulkCargoCompany, setBulkCargoCompany] = useState("");

  const [shipmentCost, setShipmentCost] = useState(0);
  const [shipmentCostLocal, setShipmentCostLocal] = useState(0);
  const [currencies, setCurrencies] = useState([]);
  const [currency, setCurrency] = useState({});
  const [invoice, setInvoice] = useState(null);

  const [selectedOrderStatuses, setSelectedOrderStatuses] = useState([]);

  const allOrderStatusWithTranslations = ORDER_STATUS_STATUSES.map(order_status => {
    return {
      label: t(order_status.label),
      value: order_status.value
    }
  })

  function calculateRealTotals(bulkOrders, key) {
    return bulkOrders.reduce((accumulator, current) => {
      if (current?.packages_length > 0) {
        return accumulator + (current?.[key] || 0);
      } else {
        return accumulator;
      }
    }, 0);
  }

  function calculateEstimatedTotals(bulkOrders, key) {
    return bulkOrders.reduce((accumulator, current) => {
      if (current?.packages_length < 1) {
        return accumulator + (current?.[key] || 0);
      } else {
        return accumulator;
      }
    }, 0);
  }

  useEffect(() => {
    setRealInfo({
      ...realInfo, 
      desi: calculateRealTotals(bulkOrders, "calculated_desi").toFixed(2),
      weight: calculateRealTotals(bulkOrders, "calculated_weight").toFixed(2),
      package: calculateRealTotals(bulkOrders, "packages_length"),
      quantity: calculateRealTotals(bulkOrders, "total_quantity"),
    })
    setEstimatedInfo({
      ...estimatedInfo, 
      desi: calculateEstimatedTotals(bulkOrders, "total_desi").toFixed(2),
      weight: calculateRealTotals(bulkOrders, "calculated_desi") ? (calculateEstimatedTotals(bulkOrders, "total_desi") * calculateRealTotals(bulkOrders, "calculated_weight") / calculateRealTotals(bulkOrders, "calculated_desi")).toFixed(2): 0,
      quantity: calculateEstimatedTotals(bulkOrders, "total_quantity"),
    })
  },[bulkOrders])

  useEffect(() => {
    setTotalInfo({
      desi: (parseFloat(realInfo?.desi) + parseFloat(estimatedInfo?.desi)) || 0,
      weight: (parseFloat(realInfo?.weight) + parseFloat(estimatedInfo?.weight)) || 0,
      package: (realInfo?.package + estimatedInfo?.package) || 0,
      quantity: (realInfo?.quantity + estimatedInfo?.quantity) || 0,
    })
  }, [realInfo, estimatedInfo])

  const [selectAll, setSelectAll] = useState(false);


  const { Option } = Select;
  const [form] = Form.useForm();

  const setFilter = useCallback((value) => {
    dispatch(setOrderStatusFilter(value));
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);

    const source = axios.CancelToken.source();

    listOrders({
      filter,
      mode: "order_status",
      cancelToken: source.token
    })
      .then((res) => {
        setLoading(false)
        setOrderStatus(res?.data?.data)
        setOrderStatusCount(res?.data?.totalCount?.[0]?.count)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      });

      return () => {
        source.cancel('Operation canceled by the user.');
      }
      
  }, [filter])

  const handleBulkOperations = async (mode) => {
    let hasNonEuropeanOrder = false;

    bulkOrders.forEach(element => {
      if (element.region !== 'Europe') {
        notification['error']({
          message: t(`errors.Non-European order selected`),
        });
        hasNonEuropeanOrder = true;
        return;
      }
    })

    if (hasNonEuropeanOrder) return;

    let orderIds = bulkOrders.map(order => order._id);

    if (mode === 'bulkInvoice') {

      setInvoiceLoading(true);
      try {
        await fetchCreateEnglishInvoice({
          orders: orderIds,
          mode: "bulk"
        });
        notification['success']({
          message: t('errors.English Invoice created successfully'),
        });

        await createParasutInvoice({
          orders: orderIds,
          mode: "bulk"
        });
        notification['success']({
          message: t('errors.Parasut Invoice created successfully'),
        });

        setInvoiceLoading(false);

      } catch (error) {
        setInvoiceLoading(false);
        notification['error']({
          message: t(`errors.Invoice couldn't created successfully`),
        });
      }
    } else if (mode === 'tnt') {
      fetchTntCargo({
        orders: orderIds,
        mode: "bulk"
      })
        .then((res) => {
          notification['success']({
            message: t('errors.Tnt Shipment successfully'),
          });
        })
        .catch((err) => {
          console.log(err)
          notification.error({
            message: err?.response?.data?.error || t('errors.Tnt Shipment failed'),
          });
        });
    } else if(mode === "bulkShipment") {
      setInvoiceLoading(true);
      updateReadyBulkOrders({
        orderIds: orderIds,
        shipmentDate: shipmentDate,
        billOfLading: billOfLading,
        bulkCargoCompany: bulkCargoCompany,
        shipmentCost: shipmentCost,
        shipmentCostLocal: shipmentCostLocal,
        shipmentDesi: realInfo?.desi,
        currency: currency,
        invoice: invoice
      }).then((response) => {
        notification['success']({
          message: t("fields.order_status.Order Statuses Updated Successfully"),
        });
        setInvoiceLoading(false);
        window.location.reload();
      }).catch((err) => {
        notification['error']({
          message: t("fields.order_status.Bulk Shipment Failed"),
        });
        setInvoiceLoading(false);
      });
    }
  }

  useEffect(() => {
    fetchAllCompanies()
      .then(res => {
        setCargoCompanies(res)
        setBulkCargoCompanies(res?.filter(cargoCompany => cargoCompany?.name === "Ekol"));
      });
  }, [])

  useEffect(() => {
		fetchAllExchangeRates()
		  .then(res => {
			  setCurrencies(res?.data);
        setCurrency(res?.data.find(c => c?.abbreviation === "EUR"));
		  })
	}, [])

  const onOrderStatusFilterChange = (checkedValues) => {
    if(checkedValues?.includes("all")) {
        const allOrderStatuses = allOrderStatusWithTranslations?.map(option => option?.value);
        setSelectedOrderStatuses(allOrderStatuses);
        setFilter({
            ...filter,
            filter: {
              ...filter?.filter,
              status: allOrderStatuses?.filter(os => os !== "all")
            }
        });
    } else if(selectedOrderStatuses?.includes("all")) {
        setSelectedOrderStatuses([]);
        setFilter({
            ...filter,
            filter: {
              ...filter?.filter,
              status: []
            }
        });
    } else {
        setSelectedOrderStatuses(checkedValues);
        setFilter({
            ...filter,
            filter: {
              ...filter?.filter,
              status: checkedValues
            }
        });
    }
}

  return (
    <div className="list-order-status-container">
      {isBulkInvoicePopupOpen && 
        <BulkInvoicePopup 
          handleBulkOperations={handleBulkOperations} 
          setIsBulkInvoicePopupOpen={setIsBulkInvoicePopupOpen}
          shipmentDate={shipmentDate}
          setShipmentDate={setShipmentDate}
          billOfLading={billOfLading}
          setBillOfLading={setBillOfLading}
          bulkCargoCompanies={bulkCargoCompanies}
          bulkCargoCompany={bulkCargoCompany}
          setBulkCargoCompany={setBulkCargoCompany}
          numBulkOrders={bulkOrders?.length}
          shipmentCost={shipmentCost}
          setShipmentCost={setShipmentCost}
          shipmentCostLocal={shipmentCostLocal}
          setShipmentCostLocal={setShipmentCostLocal}
          currencies={currencies}
          currency={currency}
          setCurrency={setCurrency}
          invoice={invoice}
          setInvoice={setInvoice}
          realInfo={realInfo}
        />
      }
      <div className="list-order-status-nav">
        <h2>{t('status.Order Status')}</h2>
        <div className='list-order-status-nav-right'>

          <Button type="text">{t(`fields.order_status.Export`)}</Button>
          <Button type="text">{t(`fields.order_status.Import`)}</Button>
          <Button type="text">{t(`fields.order_status.Add New Order`)}</Button>

        </div>
      </div>

      <div className='list-order-status-number'>
        <span style={{fontStyle: 'italic'}}>Number of orders:</span>
        {' ' + orderStatusCount}
      </div>

      <div className="list-order-status-filter">
        <Form
          className="order-status-filter-form"
          layout="inline"
          form={form}
          initialValues={{
            status: filter?.filter?.status || 'all',
            custom: filter?.filter?.orderStatus || 'all',
            cargo_company: filter?.filter?.cargo_company || 'all',
            sort: JSON.stringify(filter?.sort),
          }}
        >
          <Form.Item name="region">
            <Select defaultValue={'All'}
              onChange={(e) => {
                if (e === "All") {
                  let removedFilter = { ...filter.filter };
                  delete removedFilter.region;

                  setFilter({ ...filter, filter: { ...removedFilter } });
                } else {
                  setFilter({ ...filter, filter: { ...filter.filter, region: e } });

                }
              }}
            >
              <Option value="All">{t(`fields.regions.All Regions`)}</Option>
              <Option value="Europe">{t(`fields.regions.Europe`)}</Option>
              <Option value="Other Europe">{t(`fields.regions.Other Europe`)}</Option>
              <Option value="Turkey">{t(`fields.regions.Turkey`)}</Option>
              <Option value="Other Turkey">{t(`fields.regions.Other Turkey`)}</Option>
              <Option value="Neighbors">{t(`fields.regions.Neighbors`)}</Option>
              <Option value="Others">{t(`fields.regions.Others`)}</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="cargo_company">
            <Select
              onChange={(e) => {
                if (e === "all") {
                  let removedFilter = { ...filter.filter };
                  delete removedFilter.cargo_company;
                  setFilter({ ...filter, filter: {...removedFilter } });
                } else {
                  setFilter({ ...filter, filter: { ...filter.filter,  cargo_company: e } });
                }
              }}
            >
              <Option value="all">All Cargos</Option>
              {cargoCompanies?.map((company) => (
                <Option key={company._id} value={company._id}>
                  {company.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <div className="filter-container"><LocaleProvider >
            <RangePicker
              showTime
              value={[moment(filter?.dateFilter?.start || new Date()), moment(filter?.dateFilter?.end || new Date())]}
              onChange={(dates, dateStrings) => {
                setFilter({
                  ...filter,
                  dateFilter: {
                    start: dates[0].toDate() ? dates[0].toDate() : null, 
                    end: dates[1].toDate() ? dates[1].toDate() : null
                  }
                });
              }}
              
              style={{ width: "240px" }}
            />
          </LocaleProvider></div>
          <Form.Item name="custom">
            <Select
              onChange={(e) => {
                if (e === "all") {
                  setFilter({ ...filter, orderStatus: 'all' });
                } else if (e === "some") {
                  setFilter({ ...filter, orderStatus: 'invoiced' });
                } else if (e === "packaged") {
                  setFilter({ ...filter, orderStatus: 'packaged' });
                } else if (e === "packaged_no_invoice") {
                  setFilter({ ...filter, orderStatus: 'packaged_no_invoice' });
                } else if (e === "none") {
                  setFilter({ ...filter, orderStatus: 'none' });
                } 
              }}
              style={{ width: "240px" }}
            >
              <Option value="all">{t(`home.All Orders`)}</Option>
              <Option value="some">{t(`home.Has Invoice`)}</Option>
              <Option value="packaged">{t('home.Packaged Orders')}</Option>
              <Option value="packaged_no_invoice">{t('home.packaged_no_invoice')}</Option>
              <Option value="none">{t('home.No_invoice_packaged')}</Option>
            </Select>
          </Form.Item>
          <Form.Item name="status">
            <></>
              <Select
                  mode="multiple"
                  allowClear
                  placeholder="Select Order Status"
                  value={selectedOrderStatuses}
                  onChange={onOrderStatusFilterChange}
                  options={allOrderStatusWithTranslations}
                  maxTagCount="responsive"
                  showArrow
              />
          </Form.Item>
          <Form.Item name="sort">
            <Select
              onChange={(e) => {
                const newValue = JSON.parse(e);
                setFilter({ ...filter, sort: newValue });
              }}
              style={{ width: "240px" }}
            >
              <Option value='{"checkout_date":-1}'>{t('fields.order_status.Checkout')}: {t('sort.New to Old')}</Option>
              <Option value='{"checkout_date":1}'>{t('fields.order_status.Checkout')}: {t('sort.Old to New')}</Option>
              <Option value='{"payment_at":-1}'>{t('fields.order_status.Payment')}: {t('sort.New to Old')}</Option>
              <Option value='{"payment_at":1}'>{t('fields.order_status.Payment')}: {t('sort.Old to New')}</Option>
              <Option value='{"shipment_date":-1}'>{t('fields.order_status.Shipment')}: {t('sort.New to Old')}</Option>
              <Option value='{"shipment_date":1}'>{t('fields.order_status.Shipment')}: {t('sort.Old to New')}</Option>
              <Option value='{"delivery_date":-1}'>{t('fields.order_status.Delivery')}: {t('sort.New to Old')}</Option>
              <Option value='{"delivery_date":1}'>{t('fields.order_status.Delivery')}: {t('sort.Old to New')}</Option>
            </Select>
          </Form.Item>

          
        </Form>
      </div>

      <div className="list-order-status-totals">
        <div className='list-order-status-nav-left'>
          <Button className='bulk-invoice-button'
            onClick={() => handleBulkOperations('tnt')}
            loading={InvoiceLoading}>
            {t(`fields.order_status.create_tnt_shipment`)} ({`${bulkOrders.length}`})
          </Button>

          <Button className='bulk-invoice-button' 
            onClick={() => handleBulkOperations("bulkInvoice")}
            loading={InvoiceLoading}>
            {t(`fields.order_status.Create Bulk Invoice`)} ({`${bulkOrders.length}`})
          </Button>

          <Button className='bulk-invoice-button' 
            onClick={() => setIsBulkInvoicePopupOpen(true)}
            loading={InvoiceLoading}>
            {t(`fields.order_status.Create Bulk Shipment`)} ({`${bulkOrders.length}`})
          </Button>
        </div>

        <table className="table order-info-table">
          <thead>
            <tr>
              <th></th>
              <th>Desi</th>
              <th>Weight (kg)</th>
              <th>Package</th>
              <th>Item Count</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Real</td>
              <td>{realInfo?.desi}</td>
              <td>{realInfo?.weight}kg</td>
              <td>{realInfo?.package}</td>
              <td>{realInfo?.quantity}</td>
            </tr>
            <tr>
              <td>Estimated</td>
              <td>{estimatedInfo?.desi}</td>
              <td>{estimatedInfo?.weight}kg</td>
              <td>{estimatedInfo?.package}</td>
              <td>{estimatedInfo?.quantity}</td>
            </tr>
            <tr>
              <td>Total</td>
              <td>{totalInfo?.desi}</td>
              <td>{totalInfo?.weight}kg</td>
              <td>{totalInfo?.package}</td>
              <td>{totalInfo?.quantity}</td>
            </tr>
          </tbody>
        </table>
      </div>

      
      
      <div className="list-order-status-table-head">
        <div className="list-orders-table-checked">
          <label>
            <input
              value={selectAll}
              onChange={() =>  {
                const { checked } = event.target;
                if (checked) {
                  dispatch(selectAllOrders(orderstatus));
                } else {
                  dispatch(removeAllOrders());
                }
                setSelectAll((prev) => !prev)}
              }
              type="checkbox"
            />  
          </label>
        </div>
        <div className="list-orders-table-fname">{t(`fields.order_status.First Name`)}</div>
        <div className="list-orders-table-lname">{t(`fields.order_status.Last Name`)}</div>
        <div className="list-orders-table-status">{t(`fields.order_status.Status`)}</div>
        <div className="list-orders-table-created">{t(`fields.order_status.Payment Date`)}</div>
        <div className="list-orders-table-received-items">{t(`status.Received`)}</div>
        <div className="list-orders-table-stockOut-items">{t(`status.StockIn`)}</div>
        <div className="list-orders-table-confirmed-items">{t(`status.Confirmed`)}</div>
        <div className="list-orders-table-returned-items">{t(`status.Wrong / Missing Items`)}</div>
        <div className="list-orders-table-cargo-company">{t(`fields.order_status.Cargo Company`)}</div>
        <div className="list-orders-table-customer-document">{t(`fields.order_status.Customs Document`)}</div>
        <div className="list-orders-table-packaged">{t('fields.order_status.Packaged')}</div>
        <div className="list-orders-table-invoice">{t(`fields.order_status.Invoice`)}</div>
      </div>
      {loading && <Spin style={{ "marginTop": "20px" }} tip={"Loading Orders..."} />}
      {orderstatus?.map((item) => {
        return (
          <OrderForStatus key={item._id} item={item} isAllSelected={selectAll}/>
        )
      })}
      <div>
        <Pagination filter={filter} setFilter={setFilter} />
      </div>
    </div>
  );
}

export default OrderStatus;
