import axios from 'axios';
import store from './store';
import { getUrlFromState } from './userSlice';

export const fetchCreateCountryGroup = async (cargoCompanyId, regionNumber) => {
  const requestBody = {
    cargoCompanyId: cargoCompanyId,
    regionNumber: regionNumber
  };

  const url = getUrlFromState(store)
  try {
    const { data } = await axios.post(
      `${url}/country_group/by-cargo-company/create`,
      requestBody
    );
    return data;
  } catch (error) {
    // Handle errors here
    console.error('Error:', error);
    throw error;
  }
};

export const fetchCountryGroupsByCargoCompany = async (cargoCompanyId) => {
  const url = getUrlFromState(store)
  const { data } = await axios.post(
    `${url}/country_group/by-cargo-company/${cargoCompanyId}`
  );
  return data;
};

export const fetchBulkCountryGroupUpdate = (bulkUpdate) => {
  const url = getUrlFromState(store)
  return axios.patch(`${url}/country_group/by-cargo-company/update`, {
  bulkUpdate
})
};


