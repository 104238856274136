import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

import "./Filters.scss";

import { Button } from "antd";
import { Filter, SearchBar } from "../../components";

function Filters() {
  const navigate = useNavigate()

  const { filterTypes } = useSelector((state) => state.filterType);
  const [searchText, setSearchText] = useState("");

  return (
    <div className="list-filters-container">
      <div className="list-filters-nav">
        <h2>Filters</h2>
        <div>
          <Button type="text">Export</Button>
          <Button type="text">Import</Button>
          <Button type="text" onClick={() => {navigate("/filter/new")}}>Add New Filter</Button>
        </div>
      </div>
      <div className="list-filters-filter">
        <SearchBar type="filter"/>
      </div>
      <div className="list-filters-table-head">
        <div className="list-filters-table-check">*</div>
        <div className="list-filters-table-name">Name</div>
        <div className="list-filters-table-type">Type</div>
        <div className="list-filters-table-handle">Url Extension</div>
        <div className="list-filters-table-status">Status</div>
        <div className="list-filters-table-url-order">Url Extension Order</div>
        <div className="list-filters-table-ui-order">FilterBar UI Order</div>
        <div className="list-filters-table-naming">Category Naming</div>
      </div>
      {filterTypes
        ?.filter((filterType) => {
          return (
            filterType?.type?.toLowerCase().includes(searchText.toLowerCase())
          );
        })
        ?.map((item) => (
          <Filter key={item?._id} item={item} />
        ))}
    </div>
  );
}

export default Filters;
