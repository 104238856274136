import React, { useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Form, Input, Button, notification, Select, Modal } from 'antd';
import { ResponsiveImage } from "../../components";
import { fetchCreate, fetchUpdate } from "../../redux/cargoCompanySlice";
import { fetchCountryGroupsByCargoCompany, fetchBulkCountryGroupUpdate, fetchCreateCountryGroup } from "../../redux/countryGroupSlice";
import { fetchUploadUrl } from "../../redux/productSlice";
import { fetchAllExchangeRates } from "../../redux/exchangeRatesSlice";
import { UploadOutlined } from '@ant-design/icons';

import "./EditCargoCompany.css";
import { useEffect } from "react";
import { fetchCountries } from "../../redux/countrySlice";
import { findIndex } from "underscore";

const EditCargoCompany = () => {
    const { Option } = Select;
    const { name } = useParams();
    const isNew = name === "new";
    const { state } = useLocation();
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const [updatedCargoCompany, setupdatedCargoCompany] = useState(state ? state : {});
    const [countryGroups, setCountryGroups] = useState([]);
    const [updateCountryGroups, setUpdateCountryGroups] = useState([]);
    const [showDecreaseButton, setShowDecreaseButton] = useState(false);
    const [showAddCountryButton, setShowAddCountryButton] = useState(false);
    const [countries, setCountries] = useState([]);
    const [files, setFiles] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [countryGroupSaveLoading, setCountryGroupSaveLoading] = useState(false);
    const [createCountryGroupLoading, setCreateCountryGroupLoading] = useState(false);
    const [currencies, setCurrencies] = useState([]);

    useEffect(() => {
        form.setFieldsValue({
            name: updatedCargoCompany?.name,
            own_cargo: updatedCargoCompany?.own_cargo,
            express: updatedCargoCompany?.express,
            fuel_rate: updatedCargoCompany?.fuel_rate,
            delivery: updatedCargoCompany?.delivery,
            desi_multiplier: updatedCargoCompany?.desi_multiplier,
            max_delivery: updatedCargoCompany?.max_delivery,
            min_delivery: updatedCargoCompany?.min_delivery,
            tracking_link:  updatedCargoCompany?.tracking_link,
            currency:  updatedCargoCompany?.currency,
            address_address: updatedCargoCompany?.address_array?.[0]?.address,
            address_city: updatedCargoCompany?.address_array?.[0]?.city,
            address_country: updatedCargoCompany?.address_array?.[0]?.country,
            address_phone_number: updatedCargoCompany?.address_array?.[0]?.phone_number,
            address_postal_code: updatedCargoCompany?.address_array?.[0]?.postal_code,
            billing_address: updatedCargoCompany?.billing_address_array?.[0]?.address,
            billing_billing_name: updatedCargoCompany?.billing_address_array?.[0]?.billing_name,
            billing_city: updatedCargoCompany?.billing_address_array?.[0]?.city,
            billing_corporate_identity_number: updatedCargoCompany?.billing_address_array?.[0]?.corporate_identity_number,
            billing_country: updatedCargoCompany?.billing_address_array?.[0]?.country,
            billing_postal_code: updatedCargoCompany?.billing_address_array?.[0]?.postal_code,
            billing_tax_office: updatedCargoCompany?.billing_address_array?.[0]?.tax_office,
        })
    }, [form, updatedCargoCompany])

    useEffect(async () => {
        const countries = await fetchCountries();
        setCountries(countries);
    }, [])

    useEffect(async () => {
        if (isNew) return;
        if (createCountryGroupLoading) return;

        const countryGroups = await fetchCountryGroupsByCargoCompany(state?._id);
        setCountryGroups(countryGroups);
    }, [state?._id, createCountryGroupLoading]);

    useEffect(() => {
		fetchAllExchangeRates()
		  .then(res => {
			setCurrencies(res?.data);
		  })
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const CountryList = ({ items, filter, index1, handleFunction }) => {
        return (
            <>
                {items.filter(filter).map((country, index2) => {
                    return (
                        <div className={showAddCountryButton ? "country-group-country" : "country-group-country-reversed"}>
                            <div key={index2} className="country-group-country">
                                {country.name}
                            </div>
                            {
                                <button
                                    type="button"
                                    className="country-group-buttons"
                                    onClick={() => handleFunction(country, index1)}
                                >
                                    {showAddCountryButton ? '+' : ''}
                                    {showDecreaseButton ? '-' : ''}
                                </button>
                            }
                        </div>
                    );
                })}
            </>
        );
    };


    const uploadButton = (name) => (
        <label className="custom-file-upload">
            <input type="file" onChange={(e) => {
                setFiles(files => [...files, { file: e.target.files[0], type: name }]);

                let updatedCargoCompanyCopy = { ...updatedCargoCompany };
                updatedCargoCompanyCopy[name.toLocaleLowerCase()] = URL.createObjectURL(e.target.files[0]);
                setupdatedCargoCompany(updatedCargoCompanyCopy)
            }} />
            <UploadOutlined />
            {'  '}{(`Upload ${name}`)}
        </label>
    )

    const handleSubmit = async () => {
        try {
            if (files.length > 0) {
                await Promise.all(files.map(async file => {
                    await fetchUploadUrl(`website/cargo_companies/${updatedCargoCompany?.name?.replace(" ", "_")}/${file?.type?.toLocaleLowerCase()}/${new Date().getTime()}.webp`, "image/webp")
                        .then(async response => {
                            await fetch(response.data, {
                                method: "PUT",
                                body: file?.file,
                            })

                            const imageUrl = response.data.split('?')[0];
                            notification['success']({
                                message: `${file.type} image uploaded successfully`
                            });

                            updatedCargoCompany[file?.type?.toLocaleLowerCase()] = imageUrl;
                        })
                        .catch((err) => {
                            notification['error']({
                                message: `Images couldn't uploaded successfully`
                            });
                        })
                }));
            }

            if (isNew) {
                await fetchCreate(updatedCargoCompany);
                notification['success']({
                    message: "Cargo Company created successfully"
                });
                navigate("/cargo-companies");
            } else {
                await fetchUpdate(state._id, updatedCargoCompany);
                notification['success']({
                    message: "Cargo Company modified successfully"
                });
            }
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error) {
                notification['error']({
                    message: err.response.data.error
                });
            } else {
                notification['error']({
                    message: isNew
                        ? "Cargo Company couldn't be created successfully"
                        : "Cargo Company couldn't be modified successfully"
                });
            }
        }
    };


    const handleRemoveCountry = (country, index) => {
        let updatedCountryGroups = [...countryGroups];
        const updatedCountryGroupCountries = countryGroups[index].countries.filter((countryId) => countryId !== country._id);
        const updatedCountryGroupCountryDatas = countryGroups[index].countryDatas.filter((countryData) => countryData._id !== country._id);
        updatedCountryGroups[index].countries = updatedCountryGroupCountries;
        updatedCountryGroups[index].countryDatas = updatedCountryGroupCountryDatas;

        let updatedCountryGroupsDiff = [...updateCountryGroups]
        updatedCountryGroupsDiff.push(updatedCountryGroups[index])
        const countryGroupIndex = findIndex(updateCountryGroups, (countryGroup) => {
            return countryGroup._id === updatedCountryGroups[index]._id
        })

        if (countryGroupIndex !== -1) {
            updatedCountryGroupsDiff.splice(countryGroupIndex, 1)
        }

        setCountryGroups(updatedCountryGroups)
        setUpdateCountryGroups(updatedCountryGroupsDiff);
    }

    const handleAddCountry = (country, index) => {
        let updatedCountryGroups = [...countryGroups];
        updatedCountryGroups[index].countries.push(country._id);
        updatedCountryGroups[index].countryDatas.push(country);

        let updatedCountryGroupsDiff = [...updateCountryGroups]
        updatedCountryGroupsDiff.push(updatedCountryGroups[index])
        const countryGroupIndex = findIndex(updateCountryGroups, (countryGroup) => {
            return countryGroup._id === updatedCountryGroups[index]._id
        })

        if (countryGroupIndex !== -1) {
            updatedCountryGroupsDiff.splice(countryGroupIndex, 1)
        }

        setCountryGroups(updatedCountryGroups);
        setUpdateCountryGroups(updatedCountryGroupsDiff);
    }

    const handleShowCountryButton = (countryButton) => {
        if (countryButton === "add") {
            if (showAddCountryButton) {
                setSearchText("");
            }
            setShowAddCountryButton((prevShowAddCountryButton) => !prevShowAddCountryButton)
        } else if (countryButton === "decrease") {
            if (showDecreaseButton) {
                setSearchText("");
            }
            setShowDecreaseButton((prevShowDecreaseButton) => !prevShowDecreaseButton)
        }
    }

    const handleCreateCountryGroup = async () => {
        try {
            setCreateCountryGroupLoading(true);
            await fetchCreateCountryGroup(state._id, countryGroups.length + 1);
            notification['success']({
                message: "Country group created successfully"
            });
            setCreateCountryGroupLoading(false);
        } catch (err) {
            setCreateCountryGroupLoading(false);
            if (err.response && err.response.data && err.response.data.error) {
                notification['error']({
                    message: err.response.data.error
                });
            } else {
                notification['error']({
                    message: "Country group couldn't be created successfully"
                });
            }
        }
    }
    const handleCountryGroupSave = async () => {
        try {
            setCountryGroupSaveLoading(true);
            await fetchBulkCountryGroupUpdate(updateCountryGroups);
            notification['success']({
                message: "Country groups modified successfully"
            });
            setCountryGroupSaveLoading(false);
        } catch (err) {
            setCountryGroupSaveLoading(false);
            if (err.response && err.response.data && err.response.data.error) {
                notification['error']({
                    message: err.response.data.error
                });
            } else {
                notification['error']({
                    message: "Country groups couldn't be modified successfully"
                });
            }
        }
    }



    return (
        <div className='container'>

            <div className='product-details'>
                <Form
                    form={form}
                    className="cargo_company-form"
                    onFinish={handleSubmit}
                    layout="vertical"
                >
                    <div className="header-save">
                        <h2 className="product-header">{``}</h2>
                        <div>
                            <Button type="primary" htmlType="submit" style={{ marginRight: "10px" }}>
                                {(`Save`)}
                            </Button>
                            <Button type="secondary" onClick={() => { navigate("/cargo-companies") }}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                    <div className="form-top-cargo_company">
                        <div className="inline-three-inputs-cargo_company">
                            <Form.Item label={(`Name`)} name="name">
                                <Input
                                    required
                                    value={updatedCargoCompany?.name}
                                    onChange={(e) => {
                                        setupdatedCargoCompany({ ...updatedCargoCompany, name: e.target.value })
                                    }}>
                                </Input>
                            </Form.Item>
                            <Form.Item label={(`Own Cargo`)} name="own_cargo">
                                <Select
                                    required
                                    value={updatedCargoCompany?.own_cargo}
                                    onChange={(e) => {
                                        setupdatedCargoCompany({ ...updatedCargoCompany, own_cargo: e });
                                    }}>
                                    <Option value={true}>True</Option>
                                    <Option value={false}>False</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item label={(`Express`)} name="express">
                                <Select
                                    required
                                    value={updatedCargoCompany?.express}
                                    onChange={(e) => {
                                        setupdatedCargoCompany({ ...updatedCargoCompany, express: e });
                                    }}>
                                    <Option value={true}>True</Option>
                                    <Option value={false}>False</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item label={(`Fuel Rate`)} name="fuel_rate">
                                <Input
                                    type="number"
                                    value={updatedCargoCompany?.fuel_rate}
                                    onChange={(e) => {
                                        setupdatedCargoCompany({ ...updatedCargoCompany, fuel_rate: e.target.value });
                                    }}>
                                </Input>
                            </Form.Item>

                            <Form.Item label={(`Delivery`)} name="delivery">
                                <Input
                                    value={updatedCargoCompany?.delivery}
                                    onChange={(e) => {
                                        setupdatedCargoCompany({ ...updatedCargoCompany, delivery: e.target.value });
                                    }}>
                                </Input>
                            </Form.Item>

                            <Form.Item label={(`Desi Multiplier`)} name="desi_multiplier">
                                <Input
                                    type="number"
                                    defaultValue={1}
                                    required
                                    value={updatedCargoCompany?.desi_multiplier}
                                    onChange={(e) => {
                                        setupdatedCargoCompany({ ...updatedCargoCompany, desi_multiplier: e.target.value });
                                    }}>
                                </Input>
                            </Form.Item>
                            <Form.Item label={(`Max Delivery`)} name="max_delivery">
                                <Input
                                    type="number"
                                    value={updatedCargoCompany?.max_delivery}
                                    onChange={(e) => {
                                        setupdatedCargoCompany({ ...updatedCargoCompany, max_delivery: e.target.value });
                                    }}>
                                </Input>
                            </Form.Item>
                            <Form.Item label={(`Min Delivery`)} name="min_delivery">
                                <Input
                                    type="number"
                                    value={updatedCargoCompany?.min_delivery}
                                    onChange={(e) => {
                                        setupdatedCargoCompany({ ...updatedCargoCompany, min_delivery: e.target.value });
                                    }}>
                                </Input>
                            </Form.Item>
                            <Form.Item label={(`Tracking Link`)} name="tracking_link">
                                <Input
                                    value={updatedCargoCompany?.tracking_link}
                                    onChange={(e) => {
                                        setupdatedCargoCompany({ ...updatedCargoCompany, tracking_link: e.target.value });
                                    }}>
                                </Input>
                            </Form.Item>
                            <Form.Item label={(`Currency`)} name="currency">
                                <Select
                                    value={updatedCargoCompany?.currency}
                                    onChange={(e) => {
                                        setupdatedCargoCompany({ ...updatedCargoCompany, currency: e });
                                    }}>
                                    {currencies?.map((currency) => 
                                    <Option value={currency?.abbreviation}>{currency?.abbreviation}</Option>)}
                                </Select>
                            </Form.Item>

                        </div>

                        <div className="inline-four-inputs-plus-cargo_company cargo_company-border">
                            <h2 className="sub-title">Address Information</h2>
                            <Form.Item label={(`Address`)} name="address_address">
                                <Input
                                    value={updatedCargoCompany?.address_array?.[0]?.address}
                                    onChange={(e) => {
                                        setupdatedCargoCompany({
                                            ...updatedCargoCompany, address_array: [
                                                {
                                                    ...updatedCargoCompany?.address_array?.[0],
                                                    address: e.target.value
                                                }
                                            ],
                                        });
                                    }}>
                                </Input>
                            </Form.Item>

                            <Form.Item label={(`City`)} name="address_city">
                                <Input
                                    value={updatedCargoCompany?.address_array?.[0]?.city}
                                    onChange={(e) => {
                                        setupdatedCargoCompany({
                                            ...updatedCargoCompany, address_array: [
                                                {
                                                    ...updatedCargoCompany?.address_array?.[0],
                                                    city: e.target.value,
                                                },
                                            ],
                                        });
                                    }}>
                                </Input>
                            </Form.Item>

                            <Form.Item label={(`Country`)} name="address_country">

                                <Select
                                    required
                                    showSearch
                                    value={updatedCargoCompany?.address_array?.[0]?.country}
                                    optionFilterProp="children"
                                    onChange={(e) => {
                                        setupdatedCargoCompany({
                                            ...updatedCargoCompany, address_array: [
                                                {
                                                    ...updatedCargoCompany?.address_array?.[0],
                                                    country: e,
                                                },
                                            ],
                                        });
                                    }}>
                                    {countries.map((country) =>
                                        <Option value={country.code}>{country.name}</Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item label={(`Phone Number`)} name="address_phone_number">
                                <Input
                                    value={updatedCargoCompany?.address_array?.[0]?.phone_number}
                                    onChange={(e) => {
                                        setupdatedCargoCompany({
                                            ...updatedCargoCompany, address_array: [
                                                {
                                                    ...updatedCargoCompany?.address_array?.[0],
                                                    phone_number: e.target.value,
                                                },
                                            ],
                                        });
                                    }}>
                                </Input>
                            </Form.Item>

                        </div>

                        <div className="inline-two-inputs-cargo_company-title cargo_company-border">
                            <h2 className="sub-title">Billing Address Information</h2>
                            <Form.Item label={(`Address`)} name="billing_address">
                                <Input
                                    value={updatedCargoCompany?.billing_address_array?.[0]?.address}
                                    onChange={(e) => {
                                        setupdatedCargoCompany({
                                            ...updatedCargoCompany, billing_address_array: [
                                                {
                                                    ...updatedCargoCompany?.billing_address_array?.[0],
                                                    address: e.target.value,
                                                },
                                            ],
                                        });
                                    }}>
                                </Input>
                            </Form.Item>
                            <Form.Item label={(`Billing Name`)} name="billing_billing_name">
                                <Input
                                    value={updatedCargoCompany?.billing_address_array?.[0]?.billing_name}
                                    onChange={(e) => {
                                        setupdatedCargoCompany({
                                            ...updatedCargoCompany, billing_address_array: [
                                                {
                                                    ...updatedCargoCompany?.billing_address_array?.[0],
                                                    billing_name: e.target.value,
                                                },
                                            ],
                                        });
                                    }}>
                                </Input>
                            </Form.Item>
                            <Form.Item label={(`City`)} name="billing_city">
                                <Input
                                    value={updatedCargoCompany?.billing_address_array?.[0]?.city}
                                    onChange={(e) => {
                                        setupdatedCargoCompany({
                                            ...updatedCargoCompany, billing_address_array: [
                                                {
                                                    ...updatedCargoCompany?.billing_address_array?.[0],
                                                    city: e.target.value,
                                                },
                                            ],
                                        });
                                    }}>
                                </Input>
                            </Form.Item>
                            <Form.Item label={(`Corporate Identity Number`)} name="billing_corporate_identity_number">
                                <Input
                                    value={updatedCargoCompany?.billing_address_array?.[0]?.corporate_identity_number}
                                    onChange={(e) => {
                                        setupdatedCargoCompany({
                                            ...updatedCargoCompany, billing_address_array: [
                                                {
                                                    ...updatedCargoCompany?.billing_address_array?.[0],
                                                    corporate_identity_number: e.target.value,
                                                },
                                            ],
                                        });
                                    }}>
                                </Input>
                            </Form.Item>

                            <Form.Item label={(`Country`)} name="billing_country">
                                <Select
                                    required
                                    showSearch
                                    value={updatedCargoCompany?.billing_address_array?.[0]?.country}
                                    optionFilterProp="children"
                                    onChange={(e) => {
                                        setupdatedCargoCompany({
                                            ...updatedCargoCompany, billing_address_array: [
                                                {
                                                    ...updatedCargoCompany?.billing_address_array?.[0],
                                                    country: e,
                                                },
                                            ],
                                        });
                                    }}>
                                    {countries.map((country) =>
                                        <Option value={country.code}>{country.name}</Option>)}
                                </Select>
                            </Form.Item>

                            <Form.Item label={(`Postal Code`)} name="billing_postal_code">
                                <Input
                                    value={updatedCargoCompany?.billing_address_array?.[0]?.postal_code}
                                    onChange={(e) => {
                                        setupdatedCargoCompany({
                                            ...updatedCargoCompany, billing_address_array: [
                                                {
                                                    ...updatedCargoCompany?.billing_address_array?.[0],
                                                    postal_code: e.target.value,
                                                },
                                            ],
                                        });
                                    }}>
                                </Input>
                            </Form.Item>
                            <Form.Item label={(`Tax Office`)} name="billing_tax_office">
                                <Input
                                    value={updatedCargoCompany?.billing_address_array?.[0]?.tax_office}
                                    onChange={(e) => {
                                        setupdatedCargoCompany({
                                            ...updatedCargoCompany, billing_address_array: [
                                                {
                                                    ...updatedCargoCompany?.billing_address_array?.[0],
                                                    tax_office: e.target.value,
                                                },
                                            ],
                                        });
                                    }}>
                                </Input>
                            </Form.Item>


                        </div>
                        {
                            !isNew &&
                            <div className="cargo_company-border">
                                <div className="country-groups-header">
                                    <div><h2 className="sub-title">Country Groups</h2></div>
                                    <div><Button loading={createCountryGroupLoading} onClick={() => handleCreateCountryGroup()}>Add Region</Button></div>
                                    <div><Button loading={countryGroupSaveLoading} onClick={() => handleCountryGroupSave()}>Save</Button></div>
                                </div>
                                <div className="country-groups">
                                    {countryGroups.sort((a, b) => parseInt(a.region) - parseInt(b.region)).map((countryGroup, index1) => {
                                        return (
                                            <div key={index1} className="country-group">
                                                <Input
                                                    placeholder="Ülke ara"
                                                    value={searchText}
                                                    onChange={handleSearch}
                                                />
                                                <div className="country-group-header">
                                                    {!showAddCountryButton && <button type="button" className="country-group-buttons" onClick={() => handleShowCountryButton('decrease')}>-</button>}
                                                    <div className="country-group-title">Region {countryGroup?.region}</div>
                                                    {!showDecreaseButton && <button type="button" className="country-group-buttons" onClick={() => handleShowCountryButton('add')}>+</button>}
                                                </div>

                                                <div className="country-group-countries">
                                                    {<CountryList
                                                        items={showAddCountryButton ? countries : countryGroup.countryDatas}
                                                        filter={showAddCountryButton ? (country) => {
                                                            const countryName = country.name.toLowerCase();
                                                            return (
                                                                !countryGroup.countries.includes(country._id) &&
                                                                (countryName.includes(searchText.toLowerCase()) || searchText === "")
                                                            )
                                                        } : (country) => {
                                                            const countryName = country.name.toLowerCase();
                                                            return (
                                                                countryName.includes(searchText.toLowerCase()) || searchText === ""
                                                            );
                                                        }}
                                                        index1={index1}
                                                        handleFunction={showAddCountryButton ? handleAddCountry : handleRemoveCountry}

                                                    />}

                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        }

                        <div className="image-container">
                            <div className="image-div">
                                {uploadButton("Logo")}
                                <div className="image">
                                    {
                                        updatedCargoCompany?.logo?.includes("aws") ?
                                            <ResponsiveImage width={312} alt="logo" src={updatedCargoCompany?.logo} className="image" /> :
                                            <img width={312} alt="logo" src={updatedCargoCompany?.logo} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div >
        </div >
    )
};

export default EditCargoCompany;