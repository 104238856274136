import React from 'react';
import { Form, InputNumber } from 'antd';
import StockBoxEditorSubSeries from '../StockBoxEditor/StockBoxEditorSubSeries';

const StockInformationSubSeries = ({ product, setProduct, setIsDirty, user, changedStockBox, setChangedStockBox, isAllowed, isDraftProduct}) => {

    const handleStockChange = (newValue, index, key) => {
        const updatedSubSeries = [...product?.sub_series];
        updatedSubSeries[index] = {
            ...updatedSubSeries[index],
            [key]: newValue
        };

        setProduct({ ...product, sub_series: updatedSubSeries }); 
        setIsDirty(true); 
    };

    return (
        <div className="stock-box">
            <Form.Item label='Stock Information' >
            
                {product?.sub_series?.map((subSeries, index) => {
                return (
                    <div>
                        <Form.Item label={subSeries?.series?.name} >
                            <div className="inline-inputs">
                                <Form.Item label="Stock">
                                    <InputNumber
                                    value={subSeries?.stock}
                                    min={0}
                                    onChange={(value) => handleStockChange(parseInt(value), index, 'stock')}
                                    className="input-width"
                                    />
                                </Form.Item>
                                {isAllowed && <Form.Item label="Supplier Stock">
                                    <InputNumber
                                    value={subSeries?.supplier_stock}
                                    min={0}
                                    onChange={(value) => handleStockChange(parseInt(value), index, 'supplier_stock')}
                                    className="input-width"
                                    />
                                </Form.Item>}
                            </div>
                            {!isDraftProduct && <div className="inline-inputs">
                                <Form.Item label="In Transit Stock">
                                    <InputNumber
                                    value={subSeries?.in_transit_stock}
                                    min={0}
                                    onChange={(value) => handleStockChange(parseInt(value), index, 'in_transit_stock')}
                                    className="input-width"
                                    />
                                </Form.Item>
                                <Form.Item label="Reserved Stock">
                                    <InputNumber
                                    value={subSeries?.reserved_stock}
                                    min={0}
                                    onChange={(value) => handleStockChange(parseInt(value), index, 'reserved_stock')}
                                    className="input-width"
                                    />
                                </Form.Item>
                            </div>}
                        </Form.Item>

                        {!isDraftProduct && <StockBoxEditorSubSeries product={product} setIsDirty={setIsDirty} user={user}
                            setProduct={setProduct} changedStockBox={changedStockBox} setChangedStockBox={setChangedStockBox} subSeriesIndex={index}/>}
                    </div>
                )})}
            </Form.Item>
            
        </div>
        
    );
};

export default StockInformationSubSeries;
