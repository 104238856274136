import React from 'react';
import { Image } from 'antd';

import './FileViewer.css';

const FileViewer = ({ fileList }) => {
  return (
    <div>
      {(!fileList || fileList.length === 0) ? <div>No file provided</div> : (
          <div className="file-row">
            {fileList?.map((fileUrl, index) => (
              <div key={index} className="file-item">
                {!fileUrl ? (
                  <div>No file provided</div>
                ) : fileUrl?.type.includes('pdf') ? (
                  <embed
                    style={{"width" :"100%", "height": "100%"}}
                    src={fileUrl?.url}
                    type={fileUrl?.type}
                  />
                ) : (
                  <Image
                  style={{"width" :"90%", "height": "100%"}}
                  alt="example"
                  src={fileUrl?.url}
                />
                )}
              </div>
            ))}
          </div>        
      )}
    </div>
  );
};

export default FileViewer;
