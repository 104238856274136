import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Form, Button, Spin, Input, notification, Modal, Collapse } from "antd";
import TextArea from "antd/lib/input/TextArea";

import { fetchStaticContent, addStaticContent, removeStaticContent, updateStaticContent } from "../../../redux/staticContentSlice";

import "../EditStaticContent.scss";

const EditGoogleReviews = () => {
    const { Panel } = Collapse;

    const type = useParams()?.type;

    const [contents, setContents] = useState([]);
    const [initialContents, setInitialContents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [removeLoading, setRemoveLoading] = useState(false);
    const [additionLoading, setAdditionoading] = useState(false);
    const [updatedContent, setUpdatedContent] = useState({});
    const [refresh, setRefresh] = useState(false);
    const [removeVendorModal, setRemoveVendorModal] = useState(false);
    const [removeContentId, setRemoveContentId] = useState();
    const [showNewForm, setShowNewForm] = useState(false);
    const [newItem, setNewItem] = useState({});

    useEffect(() => {
        setLoading(true);

        fetchStaticContent(type)
            .then(data => {
                setContents(data);
                setInitialContents(data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            })
    }, [refresh]);

    const handleFieldChange = (contentId, field, value) => {
        setUpdatedContent(updated => {
            return {
                ...updated,
                [contentId]: { 
                    ...updated?.[contentId],
                    [field]: value
                }
            }
        });
    }

    const handleNewItemFieldChange = (field, value) => {
        setNewItem(item => ({ ...item, [field]: value }));
    }

    const handleSave = async () => {
        setSaveLoading(true);

        const copyUpdatedObject = { ...updatedContent };

        updateStaticContent(type, copyUpdatedObject)
            .then(() => {
                notification['success']({
                    message: `Updated succesfully`
                });
                setUpdatedContent({});
                setRefresh(r => !r);
                setSaveLoading(false);
            })
            .catch(() => {
                notification['error']({
                    message: `Update failed!`
                });
                setSaveLoading(false);
            });
    };


    const handleRemove = () => {
        setRemoveLoading(true);

        removeStaticContent(type, removeContentId)
            .then(() => {
                notification['success']({
                    message: `Removed succesfully`
                });
                setUpdatedContent({});
                setRefresh(r => !r);
                setRemoveLoading(false);
                setRemoveVendorModal(false);
            })
            .catch(() => {
                notification['error']({
                    message: `Remove failed!`
                });
                setRemoveLoading(false);
                setRemoveVendorModal(false);
            });
    };

    const handleNewItemSave = async () => {
        setAdditionoading(true);

        const missingFields = [];

        if (!newItem?.reviewer) missingFields.push("Reviewer");
        if (!newItem?.rating) missingFields.push("Rating");
        if (!newItem?.time) missingFields.push("Time Text");
        if (!newItem?.text) missingFields.push("Review Text");

        if (missingFields?.length > 0) {
            notification['error']({
                message: `${missingFields?.join(", ")} cannot be empty!`
            });
            setAdditionoading(false);
            return;
        }

        addStaticContent(type, newItem)
            .then(() => {
                notification['success']({
                    message: `Created succesfully`
                });
                setNewItem({});
                setShowNewForm(false);
                setRefresh(r => !r);
                setAdditionoading(false);
            })
            .catch(() => {
                notification['error']({
                    message: `Creation failed!`
                });
                setAdditionoading(false);
            });
    }

    return (
        <div className="edit-static-content-container">
            <Modal
                title="Remove Vendor"
                visible={removeVendorModal}
                onCancel={() => setRemoveVendorModal(false)}
                onOk={() => handleRemove()}
            >
                <p>Do you want to remove this review from Google Reviews?</p>
            </Modal>

            <div className="edit-static-content-header">
                <h2>Edit Static Content - { type?.split('-')?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1))?.join(' ') } { loading && <Spin /> }</h2>
                <div className="edit-static-content-header-buttons">
                    <Button 
                        onClick={() => setShowNewForm(true)} 
                        disabled={loading}
                    >
                        Add New
                    </Button>
                    <Button 
                        type="primary"
                        loading={saveLoading} 
                        onClick={handleSave} 
                        disabled={Object.keys(updatedContent)?.length === 0}
                    >
                        Save
                    </Button>
                </div>
            </div>

            {
                (type === "google-reviews" && contents?.length > 0) &&
                <div className="homepage-top-vendors">

                    { showNewForm &&
                        <Form layout="vertical">
                            <div className="homepage-top-vendor-item">
                                <div className="homepage-top-vendor-item-header">
                                    <h2>{ newItem?.vendor?.name || "" }</h2>
                                    <div className="homepage-top-vendor-item-header-buttons">
                                        <Button 
                                            type="primary" 
                                            onClick={() => setShowNewForm(false)}
                                        >
                                            Cancel
                                        </Button>
                                        <Button 
                                            type="primary" 
                                            onClick={handleNewItemSave}
                                            loading={additionLoading}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </div>

                                <div className="homepage-top-vendor-item-fields">
                                    <div className="right-part">
                                        <h3>Parameters</h3>

                                        <Form.Item label="Reviewer">
                                            <Input 
                                                className="sort-order-input"
                                                value={newItem?.reviewer}
                                                onChange={(e) => {
                                                    handleNewItemFieldChange("reviewer", e.target.value)
                                                }}
                                            />
                                        </Form.Item>

                                        <Form.Item label="Rating">
                                            <Input 
                                                className="sort-order-input"
                                                value={newItem?.rating}
                                                onChange={(e) => {
                                                    handleNewItemFieldChange("rating", !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : "")
                                                }}
                                            />
                                        </Form.Item>

                                        <Form.Item label="Google Review Link">
                                            <Input 
                                                className="sort-order-input"
                                                value={newItem?.link}
                                                onChange={(e) => {
                                                    handleNewItemFieldChange("link", e.target.value)
                                                }}
                                            />
                                        </Form.Item>

                                        <Form.Item label="Sort Order (Lower values appears at beginning)">
                                            <Input 
                                                className="sort-order-input"
                                                value={newItem?.sort_order}
                                                onChange={(e) => {
                                                    handleNewItemFieldChange("sort_order", !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : "")
                                                }}
                                            />
                                        </Form.Item>

                                        <div className="descriptions">
                                            <Form.Item 
                                                className=""
                                                label="Time Text (English)"
                                            >
                                                <TextArea 
                                                    value={newItem?.time || ""}
                                                    autoSize
                                                    className={"sort-order-input"}
                                                    onChange={(e) => {
                                                        handleNewItemFieldChange("time", e.target.value)
                                                    }}
                                                />
                                            </Form.Item>
                                        </div>

                                        <div className="descriptions">
                                            <Form.Item 
                                                className=""
                                                label="Review Text (English)"
                                            >
                                                <TextArea 
                                                    value={newItem?.text || ""}
                                                    autoSize
                                                    className={"sort-order-input"}
                                                    onChange={(e) => {
                                                        handleNewItemFieldChange("text", e.target.value)
                                                    }}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form> 
                    }
                        

                    {
                        contents?.map(content => {
                            const updateObject = updatedContent?.[content?._id?.toString()] || {};
                            const timeLanguages = Object.keys(content?.time_translations || {});
                            const textLanguages = Object.keys(content?.text_translations || {});

                            return (
                                <Form layout="vertical">
                                    <div className="homepage-top-vendor-item">
                                        <div className="homepage-top-vendor-item-header">
                                            <h2>{ content?.reviewer }</h2>
                                            <div className="homepage-top-vendor-item-header-buttons">
                                                <Button 
                                                    type="primary" 
                                                    disabled={Object.keys(updateObject)?.length === 0}
                                                    onClick={() => {
                                                        let updateCopy = { ...updatedContent };
                                                        delete updateCopy?.[content?._id?.toString()];
                                                        setUpdatedContent(updateCopy);
                                                    }}
                                                >
                                                    Discard Changes
                                                </Button>
                                                <Button 
                                                    type="primary" 
                                                    className="homepage-top-vendor-item-remove"
                                                    loading={removeLoading}
                                                    onClick={() => {
                                                        setRemoveContentId(content?._id);
                                                        setRemoveVendorModal(true)
                                                    }}
                                                >
                                                    Remove
                                                </Button>
                                            </div>
                                        </div>

                                        <div className="homepage-top-vendor-item-fields">
                                            <div className="right-part">
                                                
                                                <h3>Parameters</h3>
                                                <Form.Item label="Reviewer">
                                                    <Input 
                                                        className={Object.keys(updateObject)?.includes("reviewer") ? "sort-order-input updated-description" : "sort-order-input"}
                                                        value={(typeof updateObject?.reviewer !== "undefined" && !loading) ? updateObject?.reviewer : content?.reviewer}
                                                        onChange={(e) => {
                                                            handleFieldChange(
                                                                content?._id?.toString(), 
                                                                "reviewer", 
                                                                e.target.value
                                                            )
                                                        }}
                                                    />
                                                </Form.Item>

                                                <Form.Item label="Rating">
                                                    <Input 
                                                        className={Object.keys(updateObject)?.includes("rating") ? "sort-order-input updated-description" : "sort-order-input"}
                                                        value={(typeof updateObject?.rating !== "undefined" && !loading) ? updateObject?.rating : content?.rating}
                                                        onChange={(e) => {
                                                            handleFieldChange(
                                                                content?._id?.toString(), 
                                                                "rating", 
                                                                !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : ""
                                                            )
                                                        }}
                                                    />
                                                </Form.Item>

                                                <Form.Item label="Google Review Link">
                                                    <Input 
                                                        className={Object.keys(updateObject)?.includes("link") ? "sort-order-input updated-description" : "sort-order-input"}
                                                        value={(typeof updateObject?.link !== "undefined" && !loading) ? updateObject?.link : content?.link}
                                                        onChange={(e) => {
                                                            handleFieldChange(
                                                                content?._id?.toString(), 
                                                                "link", 
                                                                e.target.value
                                                            )
                                                        }}
                                                    />
                                                </Form.Item>

                                                <Form.Item label="Sort Order (Lower values appears at beginning)">
                                                    <Input 
                                                        className={Object.keys(updateObject)?.includes("sort_order") ? "sort-order-input updated-description" : "sort-order-input"}
                                                        value={(typeof updateObject?.sort_order !== "undefined" && !loading) ? updateObject?.sort_order : content?.sort_order}
                                                        onChange={(e) => {
                                                            handleFieldChange(
                                                                content?._id?.toString(), 
                                                                "sort_order", 
                                                                !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : ""
                                                            )
                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </div>

                                        <div style={{ padding: "0.5rem 0" }}>
                                            <Collapse>
                                                <Panel header="Time Text Translations" key="1">
                                                    <div className="descriptions">
                                                        { timeLanguages?.map((language, index) => (
                                                            <Form.Item 
                                                                className="description"
                                                                label={`Time (${language})`} 
                                                                key={index}
                                                            >
                                                                <TextArea 
                                                                    value={(typeof updateObject?.[`time_translations.${language}`] !== "undefined" && !loading) ? updateObject?.[`time_translations.${language}`] : content?.time_translations?.[language]}
                                                                    autoSize
                                                                    className={Object.keys(updateObject)?.includes(`time_translations.${language}`) ? "updated-description" : ""}
                                                                    onChange={(e) => {
                                                                        handleFieldChange(content?._id?.toString(), `time_translations.${language}`, e.target.value)
                                                                    }}
                                                                />
                                                            </Form.Item>
                                                        )) }
                                                    </div>
                                                </Panel>

                                                <Panel header="Review Text Translations" key="2">
                                                    <div className="descriptions">
                                                        { textLanguages?.map((language, index) => (
                                                            <Form.Item 
                                                                className="description"
                                                                label={`Description (${language})`} 
                                                                key={index}
                                                            >
                                                                <TextArea 
                                                                    value={(typeof updateObject?.[`text_translations.${language}`] !== "undefined" && !loading) ? updateObject?.[`text_translations.${language}`] : content?.text_translations?.[language]}
                                                                    autoSize
                                                                    className={Object.keys(updateObject)?.includes(`text_translations.${language}`) ? "updated-description" : ""}
                                                                    onChange={(e) => {
                                                                        handleFieldChange(content?._id?.toString(), `text_translations.${language}`, e.target.value)
                                                                    }}
                                                                />
                                                            </Form.Item>
                                                        )) }
                                                    </div>
                                                </Panel>
                                            </Collapse>
                                        </div>
                                    </div>
                                </Form>
                            )
                        })
                    }
                </div>
            }
        </div>
    );
};

export default EditGoogleReviews;