import React, { useState, useEffect } from 'react';
import { Form, Select } from 'antd';
import { useSelector } from 'react-redux';


const ProductTypeSelector = ({ product, setProduct, setIsDirty, isAllowed }) => {

    const { allProductTypes: productTypesMain } = useSelector((state) => state.productTypes);

    const [productTypes, setProductTypes] = useState(productTypesMain);

    useEffect(() => {
        setProductTypes(productTypesMain);
    }, [productTypesMain]);

    useEffect(() => {
        if (product) {
          if (product.product_type) {
            const product_type = typeof product.product_type === "object" ? product?.product_type?._id : product.product_type;
            const selectedType = productTypes.find((type) => type?._id === product_type);
            setProductTypes(types => [
              selectedType,
              ...types.filter((type) => type?._id !== selectedType?._id)
            ]);
          }
        }
      }, [product?.product_type]);

    const handleProductTypeChange = (e) => {
        setProduct({
        ...product,
        product_type: productTypes.find((type) => type?._id === e),
        });
        setIsDirty(true);
    };

    return (
        <Form.Item name="productType" label="Product Type">
        <Select
            showSearch
            optionFilterProp="children"
            onChange={handleProductTypeChange}
            disabled={!isAllowed}
        >
            {productTypes
            ?.slice()
            .sort((a, b) => a?.names?.en.localeCompare(b?.names?.en))
            .map((type, index) => (
                <Select.Option key={'type-select' + index} value={type?._id}>
                {type?.names?.en}
                </Select.Option>
            ))}
        </Select>
        </Form.Item>
    );
};

export default ProductTypeSelector;
