import axios from 'axios';

import { getUrlFromState } from './userSlice';
import store from './store';

export const fetchStaticContent = async (type) => {
    try {
        const url = getUrlFromState(store);
        const { data } = await axios.get(`${url}/static-content`, { params: { type } });
        return data;
    } catch (err) {
        return null;
    }
}

export const addStaticContent = (type, data) => {
    const url = getUrlFromState(store);
    return axios.post(
        `${url}/static-content`, 
        { type, data }
    );
}

export const updateStaticContent = (type, update) => {
    const url = getUrlFromState(store);
    return axios.patch(
        `${url}/static-content`, 
        { type, update }
    );
}

export const removeStaticContent = (type, contentId) => {
    const url = getUrlFromState(store);
    return axios.delete(
        `${url}/static-content`, 
        { data: { type, contentId } }
    );
}