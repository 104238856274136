import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import store from './store'
import { getUrlFromState } from './userSlice';


export const fetchCoupon = createAsyncThunk('order/fetchCoupon', async (filter) => {
  const url = getUrlFromState(store)

  const { data } = await axios.get(`${url}/coupon`, { params: filter });
  return data;
});

export const fetchActiveCoupon = createAsyncThunk('order/fetchActiveCoupon', async () => {
  const url = getUrlFromState(store)

  const { data } = await axios.get(`${url}/coupon`, { params: { status: 'Active' } });
  return data;
});

export const fetchCreate = (coupon) => {
  const url = getUrlFromState(store)

  return axios.post(`${url}/coupon/new`, { coupon })
};

export const fetchCreateForRefund = (coupon, orderId) => {
  const url = getUrlFromState(store)

  return axios.post(`${url}/coupon/new-for-refund`, { coupon, orderId })
};


export const fetchCouponByCode = (code) =>{
  const url = getUrlFromState(store)
  return axios.get(`${url}/coupon/one/${code}`)
};

export const fetchDelete = (id) => {
  const url = getUrlFromState(store)
  return axios.delete(`${url}/coupon/one/${id}`)
};

export const fetchUpdate = (id, update) => {
  const url = getUrlFromState(store)
  return axios.patch(`${url}/coupon/one/${id}`, { update })
};

export const fetchUpdateStatus = (id, status) => {
  const url = getUrlFromState(store)
  return axios.patch(`${url}/coupon/status/${id}`, { update: { status } })
};

export const fetchCouponList = (customerId) => {
  const url = getUrlFromState(store)
  return axios.get(`${url}/coupon/list/${customerId}`)
};

const couponSlice = createSlice({
  name: 'coupon',
  initialState: {
    coupons: [],
    activeCoupons: [],
    status: {
      fetchCoupon: 'idle',
      fetchActiveCoupon: 'idle',
    },
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchCoupon.pending]: (state) => {
      state.status.fetchCoupon = 'pending';
    },
    [fetchCoupon.fulfilled]: (state, action) => {
      state.status.fetchCoupon = 'succeeded';
      state.coupons = action.payload;
    },
    [fetchCoupon.rejected]: (state, action) => {
      state.status.fetchCoupon = 'rejected';
      state.error = action.payload;
    },

    [fetchActiveCoupon.pending]: (state) => {
      state.status.fetchActiveCoupon = 'pending';
    },
    [fetchActiveCoupon.fulfilled]: (state, action) => {
      state.status.fetchActiveCoupon = 'succeeded';
      state.activeCoupons = action.payload;
    },
    [fetchActiveCoupon.rejected]: (state, action) => {
      state.status.fetchActiveCoupon = 'rejected';
      state.error = action.payload;
    },
  },
});

export default couponSlice.reducer;
