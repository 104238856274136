import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import './ProductTypes.css';
import { Button, Select, Form } from 'antd';
import { ProductType, LocalSearch } from '../../components';
import { fetchProductTypes, setFilter } from '../../redux/productTypesSlice';

function ProductTypes() {

  const dispatch = useDispatch();
  const { Option } = Select;
  const { allProductTypes: productTypes, filter } = useSelector((state) => state.productTypes);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(fetchProductTypes(filter));
    form.setFieldsValue({ status: filter.status ? filter.status : "all" })
  }, [filter, dispatch, form]);

  const handleFilter = (value) => dispatch(setFilter(value));

  return (
    <div className="list-product_types-container">
      <div className="list-product_types-nav">
        <h2>Product Types</h2>
        <div>
          <Button type="text">Export</Button>
          <Button type="text">Import</Button>
          <Button type="text">Add New Product Type</Button>
        </div>
      </div>
      <div className="list-product_types-filter">
        <div className='search'>
          <LocalSearch items={productTypes} type="productTypes"/>
        </div>
        <Form
          form={form}
          className="product_types-filter-form"
          layout="inline"
        >
          <Form.Item name="status">
            <Select
              onChange={(e) => {
                if(e === "all") {
                  handleFilter({ ...filter, status: null });
                } else {
                  handleFilter({ ...filter, status: e });
                }
              }}
            >
              <Option value="all">All Status</Option>
              <Option value="Active">Active</Option>
              <Option value="Passive">Passive</Option>
            </Select>
          </Form.Item>
        </Form>
      </div>
      <div className="list-product_types-table-head">
        <div className="list-product_types-table-check">*</div>
        <div className="list-product_types-table-name">Name</div>
        <div className="list-product_types-table-status">Status</div>
      </div>
      {productTypes.map((item) => (
          <ProductType key={item._id} item={item} />
        ))}
    </div>
  );
}

export default ProductTypes;
