import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Input, Button, notification, Select } from "antd";
import {
  fetchSupplierApplication,
  fetchUpdate,
} from "../../redux/supplierApplicationSlice";
import "./EditSupplierApplication.css";
import { useEffect } from "react";
import TextArea from "antd/lib/input/TextArea";

const EditSupplierApplication = () => {
  const { id } = useParams();
  const { Option } = Select;
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [applicantName, setApplicantName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [inquiry, setInquiry] = useState("");
  const [notes, setNotes] = useState("");
  const [website, setWebsite] = useState("");
  const [instagram, setInstagram] = useState("");
  const [date, setDate] = useState("");
  const [applicationStatus, setApplicationStatus] = useState("");

  useEffect(() => {
    if (id) {
      fetchSupplierApplication({ supplierApplication: id }).then((response) => {
        setApplicantName(response?.data?.firstName + " " + response?.data?.lastName);
        setCompanyName(response?.data?.company_name);
        setPhoneNumber(response?.data?.phone);
        setInquiry(response?.data?.inquiry);
        setWebsite(response?.data?.website_link);
        setInstagram(response?.data?.instagram_page);
        setDate(response?.data?.createdAt);
        setApplicationStatus(response?.data?.status);
        setNotes(response?.data?.notes);
      });
    }
  }, [id]);

  useEffect(() => {
    form.setFieldsValue({
      applicantname: applicantName,
      companyname: companyName,
      phonenumber: phoneNumber,
      inquiry: inquiry,
      website: website,
      instagram: instagram,
      date: date,
      applicationstatus: applicationStatus,
      notes: notes,
    });
  }, [applicantName, companyName, phoneNumber, inquiry, website, instagram, date, applicationStatus, notes]);



  const handleSubmit = async () => {
    const updatedApplication = { status: applicationStatus, notes: notes };
    await fetchUpdate(id, updatedApplication)
      .then(async (response) => {
        notification["success"]({
          message: "Supplier application modified successfully",
        });
      })
      .catch((err) => {
        notification["error"]({
          message: err?.response?.data?.error,
        }).catch((err) => {
          notification["error"]({
            message: "Supplier Application couldn't modified successfully",
          });
        });
      });
  };

  return (
    <div className="supplierApplication-details">
      <Form
        form={form}
        className="supplierApplication-form"
        onFinish={handleSubmit}
        layout="vertical"
      >
        <div className="header-save">
          <h2 className="supplierApplication-header">Edit Supplier Application</h2>
          <div>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: "10px" }}
            >
              {`Save`}
            </Button>
            <Button
              type="secondary"
              onClick={() => {
                navigate("/supplier-applications");
              }}
            >
              Cancel
            </Button>
          </div>
        </div>

        <div className="form-top-supplierApplication">

          <div className="inline-status-reason-sup">
            <Form.Item label="Applicant Name" name="applicantname">
              <Input value={applicantName} disabled />
            </Form.Item>
            <Form.Item label="Company Name" name="companyname">
              <Input value={companyName} disabled />
            </Form.Item>
            <Form.Item label="Phone" name="phonenumber">
              <Input value={phoneNumber} disabled />
            </Form.Item>
            <Form.Item label="Date" name="date">
              <Input value={date} disabled />
            </Form.Item>

            <Form.Item label="Website" name="website">
              <Input value={website} disabled />
            </Form.Item>
            <Form.Item label="Instagram" name="instagram">
              <Input value={instagram} disabled />
            </Form.Item>

            <Form.Item label="Inquiry" name="inquiry">
              {/*               <Input value={inquiry} disabled /> */}
              <TextArea
                placeholder="Inquiry"
                value={inquiry}
                rows={4}
                onChange={(e) => setInquiry(e.target.value)}
                disabled
              />
            </Form.Item>
            <Form.Item label="Status" name="applicationstatus">
              <Select defaultValue={applicationStatus} onChange={setApplicationStatus}
              >
                <Option value="Accepted">Accepted</Option>
                <Option value='Rejected'>Rejected</Option>
                <Option value='In Progress'>In Progress</Option>
                <Option value='On Hold'>On Hold</Option>
                <Option value='Waitlist'>Waitlist</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Notes" name="notes">
              <TextArea
                placeholder="Notes"
                value={notes}
                rows={4}
                onChange={(e) => setNotes(e.target.value)}
              />
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default EditSupplierApplication;
