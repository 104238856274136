import React from "react";
import { Form, Input, Button, notification, InputNumber } from 'antd';
import "./EditSeries.css"
import { fetchCreate } from "../../redux/seriesSlice";

const EditSeries = ({ serie, setSerie, setShowForm }) => {

	const [form] = Form.useForm();

	const handleSubmit = () => {
		fetchCreate(serie)
      .then((res) => {
				notification['success']({
          message: 'Serie saved successfully',
        });
			})
      .catch((err) => {
				console.log(err)
				notification['error']({
          message: 'Serie couldn\'t saved successfully',
        });
			});
	}

	return(
		<div className="container">
			<div className="serie-details">
				<Form
					form={form}
					className="serie-form"
					onFinish={handleSubmit}
					layout="vertical"
				>
					<div className="header-save">
						<h2 className="serie-header">{serie?.name || "Code"}</h2>
						<div>
							<Button type="primary" htmlType="submit" style={{ marginRight: "10px" }}>
								Save
							</Button>
							<Button type="secondary" onClick={() => {
								setSerie({});
								setShowForm(false);
							}}>
								Cancel
							</Button>
						</div>
					</div>
						<div className="form-top">
							<Form.Item label="Name" name="name">
								<Input 
									value={serie?.name} 
									onChange={(e) => {
										
										setSerie({...serie, name: e.target.value})
									}}
								/>
							</Form.Item>
							<Form.Item label="Item Quantity" name="item_quantity">
								<InputNumber
									value={serie?.item_quantity} 
									style={{ width: "100%" }}
									onChange={(e) => {
										setSerie({...serie, item_quantity: e})
									}}
								/>
							</Form.Item>
						</div>
				</Form>
			</div>
		</div>
	)
};

export default EditSeries;