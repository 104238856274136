import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUrlFromState } from './userSlice';
import store from './store'

export const fetchProductTypes = createAsyncThunk(
  'productTypes/fetchProductTypes',
  async (filter) => {
    const url = getUrlFromState(store)
    const { data } = await axios.get(`${url}/product_type`, {
      params: filter,
    });
    return data;
  },
);

export const fetchAllProductTypes = createAsyncThunk(
  'productTypes/fetchAllProductTypes',
  async () => {
    const url = getUrlFromState(store)
    const { data } = await axios.get(`${url}/product_type/all`);
    return data;
  },
);

export const fetchDelete = (id) => {
  const url = getUrlFromState(store)
  return axios.delete(`${url}/product_type/one/${id}`);
}

const productTypeSlice = createSlice({
  name: 'productTypes',
  initialState: {
    filter: {},
    productTypes: [],
    allProductTypes: [],
    status: {
      fetchProductTypes: 'idle',
      fetchAllProductTypes: 'idle',
    },
    error: null,
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    // get query with filter
    [fetchProductTypes.pending]: (state) => {
      state.status.fetchProductTypes = 'pending';
    },
    [fetchProductTypes.fulfilled]: (state, action) => {
      state.status.fetchProductTypes = 'succeeded';
      state.productTypes = action.payload;
    },
    [fetchProductTypes.rejected]: (state, action) => {
      state.status.fetchProductTypes = 'rejected';
      state.error = action.payload;
    },

    // get all
    [fetchAllProductTypes.pending]: (state) => {
      state.status.fetchAllProductTypes = 'pending';
    },
    [fetchAllProductTypes.fulfilled]: (state, action) => {
      state.status.fetchAllProductTypes = 'succeeded';
      state.allProductTypes = action.payload;
    },
    [fetchAllProductTypes.rejected]: (state, action) => {
      state.status.fetchAllProductTypes = 'rejected';
      state.error = action.payload;
    },
  },
});

export const { setFilter } = productTypeSlice.actions;

export default productTypeSlice.reducer;
