import { useState } from 'react';
import { fetchDelete } from '../../redux/supplierUserSlice';

import './User.css';

import { Button, notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

function User({ item }) {
  const [visible, setVisible] = useState(true);
  const [selected, setSelected] = useState(false);

  const handleDelete = (e) => {
    e.preventDefault();
    fetchDelete(item._id)
      .then(() => {
        notification['success']({
          message: 'Deleting successful',
        });
        setVisible(false);
      })
      .catch((err) => {
        notification['error']({
          message: err.response.data.message,
        });
      });
  };
  if (!visible) return null;

  return (
    <div className="list-user">
      <Link 
        className="list-user-item" 
        to={`../users/${item?._id}`}
      >
        <div className="list-user-item-check" onClick={(e) => e.stopPropagation()}>
          <input
            value={selected}
            onChange={() => setSelected((state) => !state)}
            type="checkbox"
          />
        </div>
        <div className="list-user-item-role">{item?.full_name}</div>
        <div className="list-user-item-role">{item?.role}</div>
        <div className="list-user-item-admin-role">{item?.admin_role || "-"}</div>
        <div id="trash" className={!selected ? 'visibility-hidden' : null} onClick={(e) => e.stopPropagation()}>
          <Button onClick={(e) => handleDelete(e)} icon={<DeleteOutlined />} />
        </div>
      </Link>
    </div>
  );
}

export default User;
