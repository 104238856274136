import { useSelector } from 'react-redux';
import { Form, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const ColorSelector = ({ product, setProduct, setIsDirty }) => {

  const { colors } = useSelector((state) => state.colors);
  const handleColorChange = (e) => {
    if (!product?.color?.find((color) => color._id === e)) {
      setProduct({ ...product, color: [...product.color, colors.find((a) => a?._id === e)] });
    }
    setIsDirty(true);
  };

  const deleteColor = (color) => {
    setProduct({ ...product, color: product.color.filter((item) => item._id !== color._id) });
    setIsDirty(true);
  };


  return (
    <Form.Item name="color" label="Colors">
      <Select
        onChange={handleColorChange}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        placeholder="Add color"
      >
        {colors
          ?.slice()
          .sort((a, b) => a?.names?.en.localeCompare(b?.names?.en))
          .filter(
            (item1) =>
              !product?.color?.find((item2) => item1?.names?.en === item2?.names?.en)
          )
          .map((color, index) => (
            <Select.Option key={'colors-select' + index} value={color._id}>
              {color?.names?.en}
            </Select.Option>
          ))}
      </Select>
      <div className="multi-container">
        {product?.color?.length > 0 &&
          product?.color?.map((color, index) => (
            <div className="multi-item" key={index}>
              <CloseOutlined onClick={() => deleteColor(color)} />
              {color?.names?.en}
            </div>
          ))}
      </div>
    </Form.Item>
  );
};

export default ColorSelector;
