import React, { useEffect, useState } from 'react';

import './SupplierUsers.css';

import { Button } from 'antd';
import { User, LocalSearch, Pagination } from '../../components';
import { fetchUsers } from '../../redux/supplierUserSlice';
import { useNavigate } from 'react-router-dom';

const SupplierUsers = () => {

  const navigate = useNavigate();

  const [filter, setFilter] = useState({
    page: 1,
    limit: 100,
    sort: -1,
  });
  const [users, setUsers] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    fetchUsers(filter)
      .then((res) => setUsers(res.data))
      .catch((err) => console.log(err));
  }, [filter, refresh]);

  return (
    <div className="list-users-container">
      <div className="list-users-nav">
        <h2>Users</h2>
        <div>
          <Button type="text">Export</Button>
          <Button type="text">Import</Button>
          <Button onClick={() => navigate(`/users/new`)} type="text">Add New User</Button>
        </div>
      </div>
      <div className="list-users-filter">
        <div className='search'>
          <LocalSearch items={users} type="user" refresh={refresh} setRefresh={setRefresh} baseItems={users.slice()} setItems={setUsers} />
        </div>
      </div>
      <div className="list-users-table-head">
        <div className="list-users-table-check">*</div>
        <div className="list-users-table-name">User Name</div>
        <div className="list-users-table-role">User Role</div>
        <div className="list-users-table-admin-role">User Admin Role</div>
      </div>
      {users.map((item) => (
        <User key={item._id} item={item} />
      ))}
      <div>
        <Pagination filter={filter} setFilter={setFilter}/>
      </div>
    </div>
  );
}

export default SupplierUsers;
