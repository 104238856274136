import React from "react";
import { Form, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useTranslation } from "react-i18next";
import "./Refund.scss";

const ExchangeOperationInfo = ({ form, refund, setRefund, setHasChanged, setIsDirty }) => {

    const { t } = useTranslation();

    return (

        <div>
            <div className="inline-inputs-refund">
                <Form.Item>
                    <div className="checkbox-container-refund">
                        <label>
                            <input
                                type="checkbox"
                                checked={refund?.operation_info?.taken_from_customer}
                                onChange={(e) => {
                                    setRefund(prevRefund => ({
                                        ...prevRefund,
                                        operation_info: {
                                            ...prevRefund?.operation_info,
                                            taken_from_customer: e.target.checked
                                        }
                                    }));
                                    setHasChanged(c => !c)
                                    setIsDirty(true)
                                }}
                            />
                            &nbsp; Taken From Customer
                        </label>
                    </div>
                </Form.Item>

                <Form.Item>
                    <div className="checkbox-container-refund">
                        <label>
                            <input
                                type="checkbox"
                                checked={refund?.operation_info?.send_to_vendor}
                                onChange={(e) => {
                                    setRefund(prevRefund => ({
                                        ...prevRefund,
                                        operation_info: {
                                            ...prevRefund?.operation_info,
                                            send_to_vendor: e.target.checked
                                        }
                                    }));
                                    setHasChanged(c => !c)
                                    setIsDirty(true)
                                }}
                            />
                            &nbsp; Send to Vendor
                        </label>
                    </div>
                </Form.Item>

                <Form.Item>
                    <div className="checkbox-container-refund">
                        <label>
                            <input
                                type="checkbox"
                                checked={refund?.operation_info?.taken_from_vendor}
                                onChange={(e) => {
                                    setRefund(prevRefund => ({
                                        ...prevRefund,
                                        operation_info: {
                                            ...prevRefund?.operation_info,
                                            taken_from_vendor: e.target.checked
                                        }
                                    }));
                                    setHasChanged(c => !c)
                                    setIsDirty(true)
                                }}
                            />
                            &nbsp; Taken from Vendor
                        </label>
                    </div>
                </Form.Item>

                <Form.Item>
                    <div className="checkbox-container-refund">
                        <label>
                            <input
                                type="checkbox"
                                checked={refund?.operation_info?.send_to_customer}
                                onChange={(e) => {
                                    setRefund(prevRefund => ({
                                        ...prevRefund,
                                        operation_info: {
                                            ...prevRefund?.operation_info,
                                            send_to_customer: e.target.checked
                                        }
                                    }));
                                    setHasChanged(c => !c)
                                    setIsDirty(true)
                                }}
                            />
                            &nbsp; Send to Customer
                        </label>
                    </div>
                </Form.Item>
            </div> 
            <div className="inline-inputs-refund margin">
                <Form.Item name="taken_from_customer_note">
                    <TextArea
                        placeholder="Taken From Customer Note"
                        value={refund?.operation_info?.taken_from_customer_note}
                        rows={1}
                        onChange={(e) => {
                        setRefund(prevRefund => ({
                            ...prevRefund,
                            operation_info: {
                                ...prevRefund?.operation_info,
                                taken_from_customer_note: e.target.value
                            }
                        }));
                        setHasChanged(c => !c)
                        setIsDirty(true)
                        }}
                    />
                </Form.Item>

                <Form.Item name="send_to_vendor_note">
                    <TextArea
                        placeholder="Send to Vendor Note"
                        value={refund?.operation_info?.send_to_vendor_note}
                        rows={1}
                        onChange={(e) => {
                        setRefund(prevRefund => ({
                            ...prevRefund,
                            operation_info: {
                                ...prevRefund?.operation_info,
                                send_to_vendor_note: e.target.value
                            }
                        }));
                        setHasChanged(c => !c)
                        setIsDirty(true)
                        }}
                    />
                </Form.Item>

                <Form.Item name="taken_from_vendor_note">
                    <TextArea
                        placeholder="Taken from Vendor Note"
                        value={refund?.operation_info?.taken_from_vendor_note}
                        rows={1}
                        onChange={(e) => {
                        setRefund(prevRefund => ({
                            ...prevRefund,
                            operation_info: {
                                ...prevRefund?.operation_info,
                                taken_from_vendor_note: e.target.value
                            }
                        }));
                        setHasChanged(c => !c)
                        setIsDirty(true)
                        }}
                    />
                </Form.Item>

                <Form.Item name="send_to_customer_note">
                    <TextArea
                        placeholder="Send to Customer Note"
                        value={refund?.operation_info?.send_to_customer_note}
                        rows={1}
                        onChange={(e) => {
                        setRefund(prevRefund => ({
                            ...prevRefund,
                            operation_info: {
                                ...prevRefund?.operation_info,
                                send_to_customer_note: e.target.value
                            }
                        }));
                        setHasChanged(c => !c)
                        setIsDirty(true)
                        }}
                    />
                </Form.Item>
            </div>
            <div className="inline-inputs-refund margin">
                <Form.Item label="Stock Status" name="operationstockstatus">
                    <Select 
                        value={refund?.operation_stock_status}
                        onChange={(e) => {
                            setRefund(prevRefund => ({
                                ...prevRefund,
                                operation_stock_status: e
                            }));
                            setHasChanged(c => !c);
                            setIsDirty(true);
                        }}
                    >
                        <Option value=''></Option>
                        <Option value='Stocked In'>{ t("exchanges.operation_stock_status_stock_in") }</Option>
                        <Option value="Not Stocked In">{ t("exchanges.operation_stock_status_stock_out") }</Option>
                    </Select>
                </Form.Item>
            </div>              
        </div>

    );
};

export default ExchangeOperationInfo;
