import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Input, Modal, Select, Spin, notification } from "antd";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";

import { fetchProductAggregate, updateProductDetails } from "../../redux/productSlice";

import ResponsiveImage from '../../components/ResponsiveImage/ResponsiveImage';
import { Pagination } from "../../components";

import "./ProductDetails.scss";

const ProductDetails = () => {
    const { t } = useTranslation('translation');

    const { vendors } = useSelector((state) => state.vendors);
    const { allProductTypes: productTypes } = useSelector((state) => state.productTypes);

    const [pagination, setPagination] = useState({ page: 1, limit: 100 });
    const [products, setProducts] = useState([]);
    const [changedProducts, setChangedProducts] = useState([]);
    const [initialProducts, setInitialProducts] = useState([]);
    const [productImageIndices, setProductImageIndices] = useState({});
    const [refresh, setRefresh] = useState(false);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [filters, setFilters] = useState({ missing_tags: "", fabric: "" });
    const [totalCount, setTotalCount] = useState(0);
    const [showOverwriteWarning, setShowOverwriteWarning] = useState(false);
    const [conflictedProducts, setConflicftedProducts] = useState({});

    useEffect(() => {
        setFetchLoading(true);

        const source = axios.CancelToken.source();
        let isCancelled = false;

        fetchProductAggregate({
            filter: {
                pagination,
                searchText,
                filters
            },
            mode: "product_details",
            cancelToken: source.token
        })
            .then(res => {
                const count = res?.data?.[0]?.totalCount?.[0]?.count;
                let newTagProducts = res?.data?.[0]?.data;

                setProducts(newTagProducts);
                setInitialProducts(newTagProducts);
                setTotalCount(count);

                let newProductImages = {};
                newTagProducts?.forEach(p => {
                    newProductImages[p?._id?.toString()] = 0;
                });

                setProductImageIndices(newProductImages);
                if (!isCancelled) {
                    setFetchLoading(false);
                }
            })
            .catch(() => {
                if (!isCancelled) {
                    setFetchLoading(false);
                }
            })

        return () => {
            isCancelled = true;
            source.cancel('Operation canceled by the user.');
        }
    }, [pagination, searchText, filters, refresh]);

    const handleImageNavigation = (product, increment) => {
        const currentIndex = productImageIndices?.[product?._id?.toString()];

        if (increment === 1) {
            let newIndex = currentIndex + 1;
            if (currentIndex === product?.images?.length - 1) newIndex = 0;
            setProductImageIndices(indices => ({ ...indices, [product?._id?.toString()]: newIndex }));
            return;
        } else if (increment === -1) {
            let newIndex = currentIndex - 1;
            if (currentIndex === 0) newIndex = product?.images?.length - 1;
            setProductImageIndices(indices => ({ ...indices, [product?._id?.toString()]: newIndex }));
            return;
        }
    };

    const handleRefresh = () => {
        setProducts([]);
        setChangedProducts([]);
        setInitialProducts([]);
        setRefresh(r => !r);
    }

    const handleChange = (product, index, field, value) => {

        setProducts(products => [
            ...products?.slice(0, index),
            { 
                ...product, 
                description_details: {
                    ...product?.description_details,
                    tr: {
                        ...product?.description_details?.tr,
                        [field]: value
                    }
                },
                update_config: {...product?.update_config, [field]: 'fixed'}
            },
            ...products?.slice(index + 1),
        ]);

        const newChangedProducts = {
            ...changedProducts,
            [product?._id?.toString()]: {
                ...changedProducts?.[product?._id?.toString()],
                [field]: value
            }
        };

        // if back to initial value do no record as change
        const initialProduct = initialProducts?.find(p => p?._id?.toString() === product?._id?.toString());
        const initialValue = initialProduct?.description_details?.tr?.[field];

        if (value === initialValue) {
            delete newChangedProducts?.[product?._id?.toString()]?.[field];
            if (Object.keys(newChangedProducts?.[product?._id?.toString()])?.length === 0) delete newChangedProducts?.[product?._id?.toString()];
        }

        setChangedProducts(newChangedProducts);
    }

    const handleSubmit = () => {
        let checkConflictOnly = true;
        setSaveLoading(true);

        updateProductDetails({ changedProducts: changedProducts, initialProducts, checkConflictOnly })
            .then((response) => {
                if(response?.data?.isConflicted) {
                    setShowOverwriteWarning(true);
                    setConflicftedProducts(response?.data?.conflictedProducts);
                    setSaveLoading(false);
                } else {
                    setSaveLoading(false);
                    handleRefresh();
                    notification['success']({
                        message: 'Changes saved successfully',
                    });
                }
            })
            .catch(() => {
                setSaveLoading(false);

                notification['error']({
                    message: 'Changes couldn\'t saved successfully',
                });
            });
    }

    const handleOverwrite = (isOverwrite) => {
        setShowOverwriteWarning(false);
        let newChangedProducts = changedProducts;
        let checkConflictOnly = false;
        
        if(!isOverwrite) {
            Object.keys(conflictedProducts)?.forEach(conflictedId => {
                const conflictedKeys = conflictedProducts?.[conflictedId] || [];
                conflictedKeys?.forEach(conflictedKey => {
                    if (newChangedProducts?.[conflictedId]?.[conflictedKey]) delete newChangedProducts?.[conflictedId]?.[conflictedKey];
                })
            });
        }
        
        updateProductDetails({ changedProducts: newChangedProducts, initialProducts, checkConflictOnly })
            .then(() => {
                setSaveLoading(false);
                handleRefresh();
                notification['success']({
                    message: 'Changes saved successfully',
                });
            })
            .catch(() => {
                setSaveLoading(false);
                
                notification['error']({
                    message: 'Changes couldn\'t saved successfully',
                });
            });
    }

    return (
        <div className="product-details-container">
            <Modal
                title={t('tags.overwrite_warning_title')}
                visible={showOverwriteWarning}
                width={800}
                onCancel={() => setShowOverwriteWarning(false)}
                footer={[
                    <Button
                        key="ok" 
                        type="secondary"
                        onClick={() => handleOverwrite(true) }
                    >
                    {t('tags.save_and_overwrite')}
                    </Button>,
                    <Button 
                        key="cancel" 
                        type="primary"
                        onClick={() => handleOverwrite(false)}
                    >
                    {t('tags.save_not_overwrite')}
                    </Button>
                ]}
            >
                <p>{t('tags.overwrite_warning')}</p>
            </Modal>

            <div className="product-details-top-bar">
                <h2 className="product-details-header">
                    Product Details {"  "}
                    { fetchLoading && <Spin /> }
                   
                </h2>

                <span>Total Count: {totalCount}</span>

                <div className='product-details-top-btns'>
                    <Button className='product-details-save-btn' loading={saveLoading} type='primary' onClick={handleSubmit}>{t('buttons.Save')}</Button>
                    <Button 
                        className='product-details-discard-btn' 
                        type='primary' 
                        onClick={() => {
                            if (window.confirm('Do you want do discard all changes?')) handleRefresh();
                        }}
                    >
                        {t(`buttons.Discard Changes`)}
                    </Button>
                </div>
            </div>

            <div className="product-details-filter-labels">
                <span className="product-details-search-bar">Search</span>

                <span className="product-details-filter">Vendor</span>

                <span className="product-details-filter">Product Type</span>

                <span className="product-details-filter">Status</span>
            </div>

            <div className="product-details-filters">
                <Input className="product-details-search-bar" placeholder={t('placeholders.Search')} onChange={(e) => setSearchText(e.target.value)} />

                <Select 
                    className="product-details-filter"
                    placeholder={t('home.Vendor')} 
                    defaultValue={''} 
                    onChange={(value) => setFilters(filters => ({ ...filters, vendor: value }))}
                    showSearch 
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                    }
                >
                    <Option value="">All Vendors</Option>

                    { vendors.slice()
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((item) => (
                            <Option key={item.handle} value={item._id}>{item.name}</Option>
                        ))
                    }
                </Select>

                <Select
                    className="product-details-filter"
                    onChange={(value) => setFilters(filters => ({ ...filters, product_type: value }))}
                    defaultValue=""
                    showSearch 
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                    }
                >
                    <Option value="">All Types</Option>

                    {productTypes?.slice().sort((a, b) => a.names?.tr?.localeCompare(b.names?.tr)).map((type, index) => (
                        <Option key={'vendor-select' + index} value={type._id}>
                            {type?.names?.tr}
                        </Option>
                    ))}
                </Select>

                <Select
                    className="product-details-filter"
                    onChange={(value) => setFilters(filters => ({ ...filters, status: value }))}
                    defaultValue=""
                >
                    <Option value="">All Status</Option>
                    <Option value="Active">Active</Option>
                    <Option value="Passive">Passive</Option>
                    <Option value="Draft">Draft</Option>
                </Select>
            </div>

            <div className="product-details-bottom-labels">
                <span className="wider-product-details-filter">Has Missing Detail</span>
                <span className="wider-product-details-filter">Has Been Processed</span>
                <span className="wider-product-details-filter">Has Missing Details By Field</span>
            </div>

            <div className="product-details-filters">
                <Select
                    className="wider-product-details-filter"
                    onChange={(value) => setFilters(filters => ({ ...filters, has_missing_details: value }))}
                    defaultValue=""
                    placeholder="Has missing product details"
                >
                    <Option value="">All</Option>
                    <Option value={true}>Yes</Option>
                    <Option value={false}>No</Option>
                </Select>

                <Select
                    className="wider-product-details-filter"
                    onChange={(value) => setFilters(filters => ({ ...filters, has_been_processed: value }))}
                    defaultValue=""
                    placeholder="Has been processed"
                >
                    <Option value="">All</Option>
                    <Option value={true}>Yes</Option>
                    <Option value={false}>No</Option>
                </Select>

                <Select
                    className="wider-product-details-filter"
                    onChange={(value) => setFilters(filters => ({ ...filters, has_missing_details_by_type: value }))}
                    defaultValue=""
                    showSearch 
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                    }
                >
                    <Option value="">All Detail Types</Option>
                    <Option value="fabric">Fabric</Option>
                    <Option value="model_measurements">Model Measurement</Option>
                    <Option value="sample_size">Sample Size</Option>
                    <Option value="product_measurements">Product Measurement</Option>
                </Select>
            </div>

            <div className="product-details-items">
                { products?.map((product, index) => (
                    <div className={`product-details-item ${ !!changedProducts?.[product?._id?.toString()] ? "changed-product-details-item" : "" }`}>
                        <div className="product-details-item-image-container">
                            <LeftCircleOutlined className="product-details-item-image-left-icon" onClick={() => handleImageNavigation(product, -1)}/>

                            <ResponsiveImage 
                                className="product-details-item-image" 
                                height={400} 
                                src={product?.images?.length > 0 ? product?.images?.[productImageIndices?.[product?._id?.toString()]] : product?.main_image} 
                                alt="product-details"
                            />

                            <RightCircleOutlined className="product-details-item-image-right-icon" onClick={() => handleImageNavigation(product, 1)}/>

                            <div className="product-details-image-navigation-indicators">
                                { product?.images?.map((_, index) => (
                                    <div className={`product-details-image-navigation-indicator ${index === productImageIndices?.[product?._id?.toString()] ? "product-details-image-navigation-active-indicator" : ""}`} />
                                )) }
                            </div>
                        </div>

                        <div className="product-details-item-info">
                            <div className="product-details-item-general-info">
                                <div className="product-details-item-general-info-row">
                                    <span className="product-details-item-general-info-row-name-element" >Name</span>
                                    <span className="product-details-item-general-info-row-element" >Vendor</span>
                                    <span className="product-details-item-general-info-row-element" >Product Type</span>
                                    <span className="product-details-item-general-info-row-element" >Status</span>
                                </div>
                                <div className="product-details-item-general-info-row">
                                    <Input className="product-details-item-general-info-row-name-element" disabled value={product?.names?.en} />
                                    <Input className="product-details-item-general-info-row-element" disabled value={product?.vendor?.name} />
                                    <Input className="product-details-item-general-info-row-element" disabled value={product?.product_type?.name } />
                                    <Input className="product-details-item-general-info-row-element" disabled value={product?.status} />
                                </div>
                            </div>

                            <div className="product-details-descriptions">
                                <div className="product-details-description-line"> 
                                    <div className="product-details-description-label">Fabric</div>
                                    <TextArea 
                                        className={`product-details-description-input ${!!changedProducts?.[product?._id?.toString()]?.fabric ? "updated-product-details-description-input" : ""}`}
                                        placeholder="Fabric"
                                        value={product?.description_details?.tr?.fabric}
                                        onChange={e => handleChange(product, index, "fabric", e.target.value)}
                                        autoSize
                                    />
                                </div>

                                <div className="product-details-description-line"> 
                                    <div className="product-details-description-label">Model Measurement</div>
                                    <TextArea 
                                        className={`product-details-description-input ${!!changedProducts?.[product?._id?.toString()]?.model_measurements ? "updated-product-details-description-input" : ""}`}
                                        placeholder="Model Measurement"
                                        value={product?.description_details?.tr?.model_measurements} 
                                        onChange={e => handleChange(product, index, "model_measurements", e.target.value)}
                                        autoSize
                                    />
                                </div>

                                <div className="product-details-description-line"> 
                                    <div className="product-details-description-label">Sample Size</div>
                                    <TextArea 
                                        className={`product-details-description-input ${!!changedProducts?.[product?._id?.toString()]?.sample_size ? "updated-product-details-description-input" : ""}`}
                                        placeholder="Sample Size"
                                        value={product?.description_details?.tr?.sample_size}
                                        onChange={e => handleChange(product, index, "sample_size", e.target.value)}
                                        autoSize
                                    />
                                </div>

                                <div className="product-details-description-line"> 
                                    <div className="product-details-description-label">Product Measurement</div>
                                    <TextArea 
                                        className={`product-details-description-input ${!!changedProducts?.[product?._id?.toString()]?.product_measurements ? "updated-product-details-description-input" : ""}`}
                                        placeholder="Product Measurement"
                                        value={product?.description_details?.tr?.product_measurements}
                                        onChange={e => handleChange(product, index, "product_measurements", e.target.value)}
                                        autoSize
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )) }

                <Pagination filter={pagination} setFilter={setPagination} />
            </div>
        </div>
    )
};

export default ProductDetails;