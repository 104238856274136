import React, { useState, useEffect } from 'react';
import { Form, Select } from 'antd';

const VendorSelector = ({ vendors, product, setProduct, setIsDirty, isAllowed }) => {

    /*
    const { vendors: vendorsMain } = useSelector((state) => state.vendors);
    const [vendors, setVendors] = useState(vendorsMain.filter((i) => i.status === "Active"));

    useEffect(() => {
        setVendors(vendorsMain.filter((i) => i.status === "Active"));
    }, [vendorsMain]);

    useEffect(() => {
        if (product) {
            if (product.vendor) {
                const product_vendor = typeof product?.vendor === "object" ? product.vendor?._id : product.vendor;
                const selectedVendor = vendors?.find((vendor) => vendor?._id === product_vendor);
                setVendors(vendors => [
                    selectedVendor,
                    ...vendors.filter((vendor) => vendor?._id !== selectedVendor?._id)
                ]);
            }
        }
    }, [product?.vendor]);
    */

    const handleVendorChange = (e) => {
        const vendor = vendors.find(temp => temp?._id === e)
        setProduct({
        ...product,
        vendor,
        });
        setIsDirty(true);
    };

    return (
        <Form.Item name="vendor" label="Vendor">
        <Select
            showSearch
            optionFilterProp="children"
            required
            onChange={handleVendorChange}
            disabled={!isAllowed}
        >
            {vendors
            ?.slice()
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((vendor, index) => (
                <Select.Option key={'vendor-select' + index} value={vendor?._id}>
                {vendor?.name}
                </Select.Option>
            ))}
        </Select>
        </Form.Item>
    );
};

export default VendorSelector;
