import React, { useState, useEffect } from "react";
import { fetchAllExchangeRates } from '../../redux/exchangeRatesSlice';
import { Button } from "antd";
import { Coupon, EditCoupon } from '../../components';

import { fetchCouponList } from '../../redux/couponSlice';

import "./EditCustomer.css";

const Coupons = ({ customer }) => {

  const [coupons, setCoupons] = useState();
  const [coupon, setCoupon] = useState();
  const [currencies, setCurrencies] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [newCoupon, setNewCoupon] = useState({});
  const [addNew, setAddNew] = useState(false);

  useEffect(() => {
    if(!showForm){
      setAddNew(false)
      if(customer?._id){
        fetchCouponList(customer?._id)
          .then((res) => {
            setCoupons(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }, [showForm])

  useEffect(() => {
    if(customer?._id){
      fetchCouponList(customer?._id)
        .then((res) => {
          setCoupons(res.data)
        })
        .catch((err) => {
          console.log(err)
        })

      setNewCoupon((prev) => ({
        ...prev,
        special_customer: customer?._id,
      }))
    }
  },[customer])

  useEffect(() => {
		fetchAllExchangeRates()
		  .then(res => {
			  setCurrencies(res?.data);
		  })
	}, [])

  return (
    <div className="cart-form-top"> 

    {!showForm ?
      <div>
          <div className="add-coupon-button-container">
            <Button type="text" onClick={() => {
              setAddNew(true);
              setShowForm(true);
            }}>Add New Coupon</Button>
          </div>

        <div className="list-coupons-table-head">
          <div className="list-coupons-table-check">*</div>
          <div className="list-coupons-table-coupon">Coupon</div>
          <div className="list-coupons-table-status">Status</div>
          <div className="list-coupons-table-type">Type</div>
          <div className="list-coupons-table-amount">Amount</div>
          <div className="list-coupons-table-start">Start Date</div>
          <div className="list-coupons-table-end">End Date</div>
        </div>   
  
        {coupons?.map((item) => (
          <Coupon key={item._id} item={item} setShowForm={setShowForm} setCoupon={setCoupon} currencies={currencies}/>
        ))}

      </div>
      :

      <EditCoupon coupon={addNew ? newCoupon : coupon} setCoupon={addNew ? setNewCoupon : setCoupon} setShowForm={setShowForm} addNew={addNew} customer={customer}/>

    }  

      

    </div>
  )  
};

export default Coupons 