import React, { useState } from "react";
import { Modal, Button, Form, Input, Radio, notification } from "antd";

import { createFlow } from "../../redux/marketingFlowSlice";

import "./MarketingFlows.scss";

const AddNewMarketingFlow = ({ open, setOpen, refresh }) => {
    const [form] = Form.useForm();

    const [saveLoading, setSaveLoading] = useState(false);

    const handleSubmit = (values) => {
        setSaveLoading(true);

        createFlow(values)  
            .then(() => {
                setSaveLoading(false);
                notification['success']({
                    message: "Event created successfully",
                });
                refresh();
                setOpen(false);
                form.resetFields();
            })
            .catch(() => {
                setSaveLoading(false);
                notification['error']({
                    message: "Event couldn't be created",
                });
            })
    }

    return (
        <Modal 
            title="Add New Flow" 
            visible={open} 
            onOk={() => setOpen(false)} 
            onCancel={() => setOpen(false)}
            footer={[
                <>
                    <Button type="secondary" onClick={() => setOpen(false)}>Cancel</Button>
                    <Button type="primary" htmlType="submit" loading={saveLoading} onClick={() => form.submit()}>Submit</Button>
                </>
            ]}
        >
            <Form
                layout="vertical"
                form={form}
                onFinish={handleSubmit}
            >
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                        { 
                            required: true,
                            message: 'Please enter a name!',
                        }
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="event_name"
                    label="Event Code for Specific Channel"
                    rules={[
                        { 
                            required: true,
                            message: 'Please enter event code!',
                        }
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="description"
                    label="Description"
                >
                    <Input />
                </Form.Item>

                <div className="horizontal-two-inputs">
                    <Form.Item
                        name="channel"
                        label="Channel"
                        rules={[
                            { 
                                required: true,
                                message: 'Please pick a channel!',
                            }
                        ]}
                    >
                        <Radio.Group>
                            <Radio.Button value="Sendpulse">Sendpulse</Radio.Button>
                            <Radio.Button value="Brevo">Brevo</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </div>
            </Form>
        </Modal>
    );
};

export default AddNewMarketingFlow;