import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUrlFromState } from './userSlice';
import store from './store'



export const fetchPaymentTypes = createAsyncThunk(
  'payment/fetchPaymentTypes',
  async () => {
    try {
      const url = getUrlFromState(store)
      const { data } = await axios.get(`${url}/payment/list`);
      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

const paymentTypesSlice = createSlice({
  name: 'paymentTypes',
  initialState: {
    filter: {},
    paymentTypes: [],
    allPaymentTypes: [],
    status: {
      fetchPaymentTypes: 'idle',
    },
    error: null,
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    // get all payment types
    [fetchPaymentTypes.pending]: (state) => {
      state.status.fetchPaymentTypes = 'pending';
    },
    [fetchPaymentTypes.fulfilled]: (state, action) => {
      state.status.fetchPaymentTypes = 'succeeded';
      state.allPaymentTypes = action.payload;
    },
    [fetchPaymentTypes.rejected]: (state, action) => {
      state.status.fetchPaymentTypes = 'rejected';
      state.error = action.payload;
    },
  },
});

export const { setFilter } = paymentTypesSlice.actions;

export default paymentTypesSlice.reducer;
