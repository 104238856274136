import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Button, notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import './Filter.scss';

function Tag({ item }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [visible, setVisible] = useState(true);
  const [selected, setSelected] = useState(false);

  const handleDelete = (e) => {
    e.stopPropagation();

    fetchDelete(item._id)
      .then((data) => {
        notification['success']({
          message: 'Deleting successful',
        });

        setVisible(false);
      })
      .catch((err) => {
        notification['error']({
          message: err.response.data.message,
        });
      });
  };

  return (
    <div className="list-filter">
      <div 
        className="list-filter-item" 
        onClick={(e) => {
          e.preventDefault();
          navigate(`../filter/${item?._id}`);
        }}
      >
        <div className="list-filter-item-check" onClick={(e) => e.stopPropagation()}>
        </div>

        <div className="list-filter-item-tag">{item?.name}</div>
        <div className="list-filter-item-tag">{item?.type}</div>
        <div className="list-filter-item-handle">{item?.handle}</div>
        <div className="list-filter-item-status">{item?.status}</div>
        <div className="list-filter-item-url-order">{item?.ranking}</div>
        <div className="list-filter-item-ui-order">{item?.ui_order}</div>
        <div className="list-filter-item-type">{item?.category_naming}</div>
        
        <div 
          id="trash" 
          className={!selected ? 'visibility-hidden' : null}
          onClick={(e) => e.stopPropagation()}
        >
          <Button onClick={(e) => handleDelete(e)} icon={<DeleteOutlined />} />
        </div>
      </div>
    </div>
  );
}

export default Tag;
