import { Form, Button, Select, notification } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import _ from "underscore";

import { Pagination } from "../../components";
import "./ProductMatching.css";
import { useEffect, useState } from "react";
import {
  fetchFieldDicts,
  fetchUpdateUnmatched,
  setFilter,
} from "../../redux/fieldDictsSlice";
import {  fetchUniqueVendors } from '../../redux/newProductsSlice';
import { useDispatch, useSelector } from "react-redux";

function ProductMatching() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { filter, fieldDicts } = useSelector((state) => state.fieldDicts);
  const [updateResponse, setUpdateResponse] = useState("");
  const [idUpdate, setIdUpdate] = useState({});
  const [update, setUpdate] = useState({});
  const [loading, setLoading] = useState(false);
  const { allSeries: series } = useSelector((state) => state.series);
  const { colors } = useSelector((state) => state.colors);
  const { allProductTypes: productTypes } = useSelector((state) => state.productTypes);
  const [ vendors, setVendors] = useState([]);

  useEffect(() => {
    (async () => {
      const uniqueVendors = await fetchUniqueVendors();
      const vendorNames = uniqueVendors?.map(v => v?.name);
      setVendors(vendorNames);
    })()
  }, [])
 
  const { Option } = Select;

  const handleSubmit = () => {
    setLoading(true);

    fetchUpdateUnmatched(idUpdate, update)
      .then((data) => {
        setUpdateResponse(data);
        notification["success"]({
          message: "Modify successful",
        });
        setLoading(false);
      })
      .catch((err) => {
        notification["error"]({
          message: err.response.data.message,
        });
        setLoading(false);
      });
  };

  const handleUpdate = (id, update) => {
    fetchUpdateUnmatched(id, update)
      .then((data) => {
        setUpdateResponse(data);
        notification["success"]({
          message: "Modify successful",
        });
      })
      .catch((err) => {
        notification["error"]({
          message: err.response.data.message,
        });
      });
  };

  const handleFilter = (payload) => {
    dispatch(setFilter(payload));
  };

  useEffect(() => {
    dispatch(fetchFieldDicts(filter));
  }, [filter, dispatch, updateResponse]);

  return (
    <div className="list-products-container">
      <div className="list-products-nav">
        <h2 className="title">Product Matching</h2>
        <div className="list-products-unmatchedFilter">
          <Form
            form={form}
            layout="inline"
            initialValues={{
              type: filter?.query?.type ? filter.query?.type : "all",
              match: filter?.query?.match_id?.$exists !== undefined ? filter?.query?.match_id?.$exists?.toString() : "all",
            }}
          >
            <div className= "matchFilter">
              <Form.Item name="match">
                <Select
                  onChange={(e) => {
                    if (e === "all") {
                      handleFilter({ ...filter, query: _.omit(filter.query, "match_id"), });
                    } else if(e === "true"){
                      handleFilter({ ...filter, query: { ...filter.query, match_id: {$exists :  true} } });
                    }else if(e === "false"){
                      handleFilter({ ...filter, query: { ...filter.query, match_id: {$exists : false} } });
                    }

                  }}
                >
                  <Option value="all">Is Matched: All</Option>
                  <Option value="true">Is Matched: True</Option>
                  <Option value="false">Is Matched: False</Option>

                </Select>
              </Form.Item>
            </div>

            <div className= "typeFilter">
              <Form.Item name="type">
                <Select
                  onChange={(e) => {
                    if (e === "all") {
                      handleFilter({
                        ...filter,
                        query: _.omit(filter.query, "type"),
                      });
                    } else {
                      handleFilter({
                        ...filter,
                        query: { ...filter.query, type: e },
                      });
                    }
                  }}
                >
                  <Option value="all">Field Type: All</Option>
                  <Option value="color">Field Type: Colors</Option>
                  <Option value="productType">
                    Field Type: Product Types
                  </Option>
                  <Option value="serie">Field Type: Series</Option>
                </Select>
              </Form.Item>
            </div>

            <div  className= "vendorFilter">
              <Form.Item name="vendor">
                <Select
                  onChange={(e) => {
                    if (e === "all") {
                      handleFilter({
                        ...filter,
                        query: _.omit(filter.query, "vendor"),
                      });
                    } else {
                      handleFilter({
                        ...filter,
                        query: { ...filter.query, vendor: e },
                      });
                    }
                  }}
                  showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
                >
                 
      <Option value="all">All Vendors</Option>
      {vendors?.slice()?.sort((a, b) => a?.localeCompare(b)).map((vendor, index) => (
          <Option key={'vendor-select' + index} value={vendor}>
              {vendor}
          </Option>
      ))}
      </Select>               
                    </Form.Item>
                  </div>

          </Form>

          <Button
            type="primary"
            onClick={handleSubmit}
            loading={loading}
            className="button-margin"
            htmlType="submit"
            style={{ marginRight: "10px" }}
          >
            Save
          </Button>
        </div>
      </div>

      <div></div>
      <div className="list-type-table-head">
        <div className="list-type-table-check">*</div>
        <div className="list-type-table-unmatched">Unmatched Value</div>
        <div className="list-type-table-type">Field Type</div>
        <div className="list-type-table-vendor">Vendor</div>
        <div className="list-type-table-dropdown">Value to Match</div>
      </div>
      <div>
        {fieldDicts?.map((item) => (
          <div className="list-type">
            <div className="list-type-item" key={fieldDicts[0]?.key}>
              <div className="list-type-item-delete-button">
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    if (
                      window.confirm(
                        "This will delete the unmatched type, do you want to continue?"
                      )
                    )
                      handleUpdate(item?._id, { blacklist: true });
                  }}
                ></Button>
              </div>
              
              <div className="list-type-item-unmatched">{item?.key}</div>
              <div className="list-type-item-type">{item?.type}</div>
              <div className="list-type-item-vendor">{item?.vendor}</div>
              <div
                className="list-type-item-dropdown"
                onClick={(e) => e.stopPropagation()}
              >
                <Select
                  className="dropdown-select"
                  defaultValue={item.match_id}
                  showSearch
                  placeholder="Unspecified"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e) => {
                    setIdUpdate(item?._id);
                    setUpdate({ match_id: e });
                  }}
                >
                  <Option key={"default-select"} value={null}>
                    Unspecified
                  </Option>
                  {item?.type === "serie" ? (
                    series
                      ?.slice()
                      .sort((a, b) => a?.name?.localeCompare?.(b.name))
                      .map((serie, index) => (
                        <Option key={"serie-select" + index} value={serie?._id}>
                          {serie?.name}
                        </Option>
                      ))
                  ) : item?.type === "color" ? (
                    colors
                      ?.slice()
                      .sort((a, b) => a?.names?.en?.localeCompare?.(b?.names?.en))
                      .map((color, index) => (
                        <Option key={"color-select" + index} value={color?._id}>
                          {color?.names?.en}
                        </Option>
                      ))
                  ) : item?.type === "productType" ? (
                    productTypes
                      ?.slice()
                      .sort((a, b) => a?.name?.localeCompare?.(b?.name))
                      .map((ptype, index) => (
                        <Option key={"serie-select" + index} value={ptype?._id}>
                          {ptype?.names?.en}
                        </Option>
                      ))
                  ) : (
                    <Option>Invalid Type</Option>
                  )}
                </Select>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div>
        <Pagination filter={filter} setFilter={handleFilter} />
      </div>
    </div>
  );
}

export default ProductMatching;
