import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAddtionalServiceFilter } from '../../redux/filterSlice';
import { fetchAdditionalService } from '../../redux/additionalServiceSlice';
import { additionalServicesFilter } from '../../helpers';

import './AdditionalServices.css';

import { Button, Select, Form } from 'antd';
import { LocalSearch, Pagination, AdditionalService } from '../../components';

function AdditionalServices() {
  const [services, setServices] = useState([]);
  const { Option } = Select;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { additionalServiceFilter: filter } = useSelector((state) => state.filter);

  const setFilter = (value) => {
    dispatch(setAddtionalServiceFilter(value));
  };

  useEffect(() => {
    fetchAdditionalService(filter)
      .then((res) => setServices(res.data))
      .catch((err) => console.log(err));
  }, [filter]);

  return (
    <div className="list-services-container">
      <div className="list-services-nav">
        <h2>Additional Services</h2>
        <div>
          <Button type="text">Export</Button>
          <Button type="text">Import</Button>
          <Button type="text">Add New Additional Service</Button>
        </div>
      </div>
      <div className="list-services-filter">
        <div className="search">
          <LocalSearch items={services} type="service"/>
        </div>
        <Form
          form={form}
          layout="inline"
          initialValues={{
            status: "all",
            type: "all",
          }}
          className="filter-form"
        >
          <Form.Item name="status">
            <Select
              onChange={(e) => additionalServicesFilter(form, setFilter, filter, e, "type", "status")}
            >
              <Option value="all">All Status</Option>
              <Option value="Completed">Completed</Option>
              <Option value="Confirmed">Confirmed</Option>
            </Select>
          </Form.Item>
          <Form.Item name="type">
            <Select
              onChange={(e) => additionalServicesFilter(form, setFilter, filter, e, "status", "type")}
            >
              <Option value="all">All Types</Option>
              <Option value="Sample">Sample</Option>
              <Option value="Tag">Tag</Option>
              <Option value="Sewing">Sewing</Option>
              <Option value="Logo">Logo</Option>
            </Select>
          </Form.Item>
          <Select
            onChange={(value) => {
              const newValue = JSON.parse(value);
              if(newValue === '') setFilter({ filter: filter?.filter, sort: undefined });
              else setFilter({ filter: filter?.filter, sort: newValue });
            }}
            defaultValue='{}'
            style={{ width: "180px" }}
          >
            <Option value='{}'>Sort</Option>
            <Option value='{ "payment_at": 1 }'>
              Payment: Old to New
            </Option>
            <Option value='{ "payment_at": -1 }'>
              Payment: New to Old
            </Option>
            <Option value='{ "delivery_date": 1}'>
              Completed: Old to New
            </Option>
            <Option value='{ "delivery_date": -1}'>
              Completed: New to Old
            </Option>
          </Select>
        </Form>
      </div>
      <div className="list-services-table-head">
        <div className="list-services-table-check">*</div>
        <div className="list-services-table-fname">First Name</div>
        <div className="list-services-table-lname">Last Name</div>
        <div className="list-services-table-type">Type</div>
        <div className="list-services-table-status">Status</div>
        <div className="list-services-table-cost">Total Cost</div>
        <div className="list-services-table-date">Payment Date</div>
        <div className="list-services-table-date">Completed Date</div>
      </div>
      {services.map((item) => (
        <AdditionalService key={item._id} item={item} />
      ))}
      <div>
        <Pagination filter={filter} setFilter={setFilter} />
      </div>
    </div>
  );
}

export default AdditionalServices;
