import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFirstMileShipment } from '../../redux/filterSlice';
import { fetchFirstMileShipments } from '../../redux/firstMileShipment';
import './FirstMileShipments.css';
import { Button, Select, Form } from 'antd';
import { FirstMileShipment, Pagination } from '../../components';

function FirstMileShipments() {
  const [firstMileShipments, setFirstMileShipments] = useState([]);
  const { Option } = Select;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { firstMileShipment: filter } = useSelector((state) => state.filter);

  const setFilter = useCallback((value) => {
    dispatch(setFirstMileShipment(value));
  }, [dispatch]);

  useEffect(() => {
    fetchFirstMileShipments(filter)
      .then((res) => {
        setFirstMileShipments(res.data)
      })
      .catch((err) => console.log(err));
  }, [filter]);

  return (
    <div className="list-shipments-container">
      <div className="list-shipments-nav">
        <h2>First Mile Shipments</h2>
        <div>
          <Button type="text">Export</Button>
          <Button type="text">Import</Button>
          <Button type="text">Add New</Button>
        </div>
      </div>
      <div className="list-shipments-filter">
        <Form
          className='shipment-filter-form'
          layout="inline"
          form={form}
          initialValues={{
            sort: filter?.sort?.toString(),
          }}
        >
          <Form.Item name="sort">
            <Select
              onChange={(e) => {
                if (e === "all") {
                  setFilter({ ...filter, sort: {} })
                } else {
                  setFilter({ ...filter, sort:  e })
                }
              }}
              style={{ width: "150px" }}
            >
              <Option value="-1">Date: New to Old</Option>
              <Option value="1">Date: Old to New</Option>
            </Select>
          </Form.Item>
        </Form>
      </div>
      <div className="list-shipments-table-head">
        <div>*</div>
        <div>Shipment Date</div>
        <div>Total Cost($)</div>
        <div>Total Desi</div>
        <div>Per Desi Cost($)</div>
        <div># of orders</div>
        <div>Currency-Rate</div>
        <div>Invoice</div>
      </div>
      {firstMileShipments?.map((item) => (
        <FirstMileShipment key={item?._id} item={item}/>
      ))}
      <div>
        <Pagination filter={filter} setFilter={setFilter} />
      </div>
    </div>
  );
}

export default FirstMileShipments;
