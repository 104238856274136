import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";

import './Series.css';
import { Button, InputNumber, Form } from 'antd';
import { Serie, LocalSearch, EditSeries, Pagination } from '../../components';
import { fetchSeries } from '../../redux/seriesSlice';
import { setSeriesFilter } from '../../redux/filterSlice';

function Vendors() {

  const dispatch = useDispatch();
  const { series } = useSelector((state) => state.series);
  const { seriesFilter: filterPage } = useSelector((state) => state.filter);
  const [form] = Form.useForm();
  const [showForm, setShowForm] = useState(false);
  const [newSerie, setNewSerie] = useState({});

  const setFilterPage = useCallback((value) => {
    dispatch(setSeriesFilter(value));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchSeries(filterPage));
  }, [dispatch, filterPage]);

  if (!showForm) return (
    <div className="list-series-container">
      <div className="list-series-nav">
        <h2>Series</h2>
        <div>
          <Button type="text">Export</Button>
          <Button type="text">Import</Button>
          <Button type="text" onClick={() => {
            setShowForm(true);
          }}>Add New Series</Button>
        </div>
      </div>
      <div className="list-series-filter">
        <div className='search'>
          <LocalSearch items={series} type="series" />
        </div>
        <Form
          form={form}
          className="series-filter-form"
          layout="inline"
        >
          <Form.Item name="item_quantity">
            <InputNumber
              placeholder=''
              min="0"
              max="15"
              style={{ width: "100px" }}
              onChange={(e) => e ? setFilterPage({ ...filterPage, filter: { item_quantity: e } }) : setFilterPage({ ...filterPage, filter: {} })}
            />
          </Form.Item>
        </Form>
      </div>
      <div className="list-series-table-head">
        <div className="list-series-table-check">*</div>
        <div className="list-series-table-serie">Series</div>
        <div className="list-series-table-count">Item Count</div>
      </div>
      <div>
        {series.map((item) => (
          <Serie key={item._id} item={item} />
        ))}
      </div>
      <Pagination filter={filterPage} setFilter={setFilterPage} />
    </div>
  );

  if (showForm) return (
    <EditSeries serie={newSerie} setSerie={setNewSerie} setShowForm={setShowForm} />
  );
}

export default Vendors;
