import React, { useState, useEffect } from 'react';
import { Form, Input, Button, notification, Tag } from 'antd';
import "./EditAdminRole.css";
import { fetchUpdateRole, fetchRoleById, fetchCreateRole, fetchRoles } from "../../redux/roleSlice";
import fetchEnums from '../../redux/enumsSlice';

import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const EditAdminRole = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const isNew = id === "new";

  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [role, setRole] = useState(null);
  const [allTabs, setAllTabs] = useState([]);

  useEffect(() => {

    fetchEnums({ type: 'admin_tabs' })
      .then(res => {
        if(role?.tabs){
            let rest = res?.filter((tab) => !role?.tabs?.includes(tab))
            setAllTabs(rest)
        }else{
            setAllTabs(res);
        }
        
      })
  }, [role]);

  useEffect(() => {
    if (!isNew) {
      fetchRoleById(id)
        .then((res) => {
          setRole(res);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);
  
const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
  
    const sourceListId = result.source.droppableId;
    const destinationListId = result.destination.droppableId;
    const draggedTabIndex = result.source.index;
  
    if (sourceListId === destinationListId) {
      return;
    }
  
    const newAllTabs = [...allTabs];
    const newRoleTabs = role?.tabs ? [...role?.tabs] : [];
  
    if (sourceListId === 'roleTabs') {
      const draggedTab = newRoleTabs.splice(draggedTabIndex, 1)[0]; 
      newAllTabs.splice(result.destination.index, 0, draggedTab); 
    } else if (sourceListId === 'allTabs') {

      const draggedTab = newAllTabs.splice(draggedTabIndex, 1)[0]; 
      newRoleTabs.splice(result.destination.index, 0, draggedTab); 
    }
  
    setAllTabs(newAllTabs);
    setRole({ ...role, tabs: newRoleTabs });
  };
  
  
  
  
  const handleSubmit = () => {
    if (!isNew) {
      fetchUpdateRole(role._id, {name : role?.name, tabs : role?.tabs})
        .then((res) => {
          notification['success']({
            message: 'Admin role saved successfully',
          });
          dispatch(fetchRoles());
        })
        .catch((err) => {
          console.log(err);
          notification['error']({
            message: 'Admin role couldn\'t be saved successfully',
          });
        });
    } else {
      fetchCreateRole(role)
        .then((res) => {
          notification['success']({
            message: 'Admin role created successfully',
          });
          dispatch(fetchRoles());
          navigate('../roles');
        })
        .catch((err) => {
          console.log(err);
          notification['error']({
            message: 'Admin role couldn\'t be created successfully',
          });
        });
    }
  };

  return (
    <div className="container">
      <div className="customer-details">
        <Form
          form={form}
          className="customer-form"
          onFinish={handleSubmit}
          layout="vertical"
        >
          <div className="header-save">
            <h2 className="customer-header">{role?.name}</h2>
            <div>
              <Button type="primary" htmlType="submit" style={{ marginRight: "10px" }}>
                Save
              </Button>
              <Button type="secondary" onClick={() => navigate('../roles')}>
                Cancel
              </Button>
            </div>
          </div>
          <div className="cart-form-top">
                <Form.Item label="Admin Role Info">
              <div className="role-box">
                <Form.Item label="Name">
                  <Input
                    value={role?.name}
                    onChange={(e) => setRole({ ...role, name: e.target.value })}
                  />
                </Form.Item>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <div className="role-box">
                            <Form.Item label="Current Tabs">
                            <Droppable droppableId="roleTabs">
                                {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {role?.tabs?.map((tab, index) => (
                                    <Draggable key={tab} draggableId={tab} index={index}>
                                        {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <Tag>{tab}</Tag>
                                        </div>
                                        )}
                                    </Draggable>
                                    ))}
                                    {!role?.tabs ? <div>No Tabs</div> : null}
                                    {provided.placeholder}
                                </div>
                                )}
                            </Droppable>
                            </Form.Item>
                            <Form.Item label="Tabs to Add">
                            <Droppable droppableId="allTabs">
                                {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {allTabs?.map((tab, index) => (
                                    <Draggable key={tab} draggableId={tab} index={index}>
                                        {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <Tag>{tab}</Tag>
                                        </div>
                                        )}
                                    </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                                )}
                            </Droppable>
                            </Form.Item>
                        </div>
                    </DragDropContext>

               
              </div>
            </Form.Item>

          </div>
        </Form>
      </div>
    </div>
  );
};

export default EditAdminRole;
