import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialEnvironment = localStorage.getItem('testMode') === 'test' ? 'test' : 'live';
const initialUrl = localStorage.getItem('url') || process.env.REACT_APP_BASE_ENDPOINT;

const url = process.env.REACT_APP_BASE_ENDPOINT;
const urlDev = process.env.REACT_APP_BASE_ENDPOINT_DEV;

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('lonca-admin-token');
    if (token) config.headers.authorization = `Bearer ${token}`;

    config.headers['x-request-init-timestamp'] = Date.now().toString();
    // Add x-request-origin header with the prodject indicator
    config.headers['x-request-origin'] = "admin";

    return config;
  },
  (error) => Promise.reject(error),
);

export const fetchLogin = createAsyncThunk(
  'user/login',
  async (userData, { rejectWithValue }) => {
    try {
      const origin = 'Admin';

      const { data } = await axios.post(`${url}/user/login`, { ...userData, origin });
      return data;
    } catch (error) {
      if (!error.response) {
        return rejectWithValue('Login Failed');
      }
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchMe = createAsyncThunk('user/me', async () => {
  const { data } = await axios.get(`${url}/user/me`);

  return data;
});

export const fetchChangeSelectedLanguage = async ({ language }) => {
  const { data } = await axios.patch(`${url}/user/language`, { language });

  return data;
};

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: {},
    url: initialUrl,
    environment: initialEnvironment,
    selectedLanguage: { name: 'Turkish', code: 'tr' },
    languagePath: '',
    status: {
      auth: 'idle',
      fetchLogin: 'idle',
      fetchMe: 'idle',
    },
    error: null,
  },
  reducers: {
    setSelectedLanguage: (state, action) => {
      state.selectedLanguage = action.payload;
      state.languagePath = !action.payload?.code || action.payload?.code === 'tr' ? '' : `/${action.payload?.code}`;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    toggleUrl: (state) => {
      state.url = state.environment === 'live' ? urlDev : url;
      state.environment = state.environment === 'live' ? 'test' : 'live'
      localStorage.setItem('testMode', state.environment);
      localStorage.setItem('url', state.url);
    },
    logOut: (state) => {
      localStorage.removeItem('lonca-admin-token');
      state.status.auth = 'idle';
      state.user = {};
      // window.location.reload();
    },
  },
  extraReducers: {
    [fetchLogin.pending]: (state) => {
      state.status.fetchLogin = 'pending';
    },
    [fetchLogin.fulfilled]: (state, action) => {
      state.status.fetchLogin = 'succeeded';
      state.status.auth = 'succeeded';
      state.user = action.payload;
      if(action.payload.tokens.access_token) window.localStorage.setItem('lonca-admin-token', action.payload.tokens.access_token);
    },
    [fetchLogin.rejected]: (state, action) => {
      state.status.fetchLogin = 'rejected';
      state.error = action.payload;
    },
    [fetchMe.pending]: (state) => {
      state.status.fetchMe = 'pending';
    },
    [fetchMe.fulfilled]: (state, action) => {
      state.status.fetchMe = 'succeeded';
      state.status.auth = 'succeeded';

      const user = action.payload?.user;
      const token = action.payload?.token;

      state.user = user;
      if(token) window.localStorage.setItem('lonca-admin-token', token);
    },
    [fetchMe.rejected]: (state, action) => {
      state.status.fetchMe = 'rejected';
      state.error = action.payload;
    },
  },
});

export const getUrlFromState = (store) => {
  const state = store.getState();
  return state.user.url;
};

export const {
  logOut,
  setUser,
  toggleUrl,
  setSelectedLanguage,
} = userSlice.actions;
export default userSlice.reducer;
