import React, { useEffect, useState } from "react";
import { Form, Button, notification, Input, Card, InputNumber } from "antd";
import { useDispatch, useSelector } from "react-redux";

import "./Configurations.css";
import {
  fetchAllConfigurations,
  fetchUpdateConfiguration,
  fetchCreateConfiguration,
} from "../../redux/configurationsSlice";
import TextArea from "antd/lib/input/TextArea";

function Configurations() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { configurations } = useSelector((state) => state.configurations);
  const [loading, setLoading] = useState(false);
  const [selectedConfigId, setSelectedConfigId] = useState(null);
  const [selectedConfigConstants, setSelectedConfigConstants] = useState({});

  useEffect(() => {
    dispatch(fetchAllConfigurations());
  }, [dispatch]);

  
  const handleSelectConfig = (config) => {
    if (selectedConfigId === config._id) {
      setSelectedConfigId(null);
      setSelectedConfigConstants({});
    } else {
      setSelectedConfigId(config._id);
      setSelectedConfigConstants(config.constants);
      form.setFieldsValue({ ...config.constants });
    }
  };
  

  const handleSubmitUpdates = () => {
    let updatedConstants = selectedConfigConstants.map((constant) => {
      let updatedConstant = { ...constant };

      if (form.getFieldValue(constant.name) !== undefined && form.getFieldValue(constant.name) !== constant.value) {
          updatedConstant.value = form.getFieldValue(constant.name);
      }

      return updatedConstant;
  });

    setLoading(true);

    fetchUpdateConfiguration({ id: selectedConfigId, updatedConfig: {constants: updatedConstants}})
      .then(() => {
        notification["success"]({
          message: "Configuration updated successfully",
        });
        setLoading(false);
      })
      .catch((err) => {
        notification["error"]({
          message: err.response?.data?.message || "Failed to update configuration",
        });
        setLoading(false);
      });
  };


  return (
    <div className="configurations-container">
      <div className="configurations-nav">
        <h2 className="title">Configurations Management</h2>
      </div>

      <div className="configurations-list" key={configurations}>
        {configurations?.map((config) => (
          <Card
            key={config._id}
            title={config.name}
            extra={
              <Button
                style={{ width: "5rem" }}
                onClick={() => handleSelectConfig(config)}
              >
                {selectedConfigId === config._id ? "Close" : "Edit"}
              </Button>
            }
          >
            {selectedConfigId === config._id && (
              <div className="configurations-form">
                <Form
                  form={form}
                  onFinish={handleSubmitUpdates}
                  initialValues={selectedConfigConstants}
                >
                  {selectedConfigConstants.map((constant) => (
                    <div className="constant-row-container">
                    <div className="constant-row" key={constant?.name}>
                      <Input
                        style={{fontWeight: 'bold'}}
                        placeholder="Constant Key"
                        disabled
                        value={constant?.name}
                      />
                     
                      <Form.Item name={constant?.name} style={{ marginBottom: "0px" }}>
                        <InputNumber
                          placeholder="Constant Value"
                          defaultValue={constant?.value}
                        />
                      </Form.Item>
                    </div>
                     <TextArea
                     value={constant?.description}
                     disabled
                     />
                     </div>
                  ))}


                  <Button
                    type="primary"
                    className="configurations-save-button"
                    htmlType="submit"
                    loading={loading}
                  >
                    Save Changes
                  </Button>
                </Form>
              </div>
            )}
          </Card>
        ))}
      </div>



    </div>
  );
}

export default Configurations;
