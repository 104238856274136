import { useState } from 'react';

import './CreditStatus.css';

import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { loncaRound, loncaFormatDate } from '../../helpers';

function CreditStatus({ item, isCustomerPage = false }) {
  const [selected, setSelected] = useState(false);

  return (
    <div className="list-credit-status">
      <Link 
        className="list-credit-status-item" 
        to="/credit-status"
        onClick={(event) =>{
          event.preventDefault();
          if(isCustomerPage){
            window.open(`/orders/${item?._id}`)
          } else {
            window.open(`/customers/${item?.customer?._id}`)
          }
        }}
      >
        <div className="list-credit-status-item-check" onClick={(e) => e.stopPropagation()}>
          <input
            value={selected}
            onChange={() => setSelected((state) => !state)}
            type="checkbox"
          />
        </div>
        <div className="list-credit-status-item-first">{item?.customer?.firstName}</div>
        <div className="list-credit-status-item-last">{item?.customer?.lastName}</div>
        <div className="list-credit-status-item-total-credit">${loncaRound(parseFloat(item?.discounted_net_volume) + parseFloat(item?.returns || 0))}</div>
        <div className="list-credit-status-item-amount-due">${loncaRound(parseFloat(item?.discounted_net_volume) + parseFloat(item?.returns || 0) - parseFloat(item?.paid_amount || 0))}</div>
        <div className="list-credit-status-item-due-date">{loncaFormatDate(item?.last_credit_payment_date)}</div>
        <div id="trash" className={!selected ? 'visibility-hidden' : null} onClick={(e) => e.stopPropagation()}>
          <Button icon={<DeleteOutlined />} />
        </div>
      </Link>
    </div>
  );
}

export default CreditStatus;
