import React from "react";
import { Form, Tag } from 'antd';
import _ from 'underscore';
import TextArea from "antd/lib/input/TextArea";
import "./EditVendor.scss";
import { LANGUAGE_CODES } from "../../constants";

const EditTranslations = ({
    updatedVendor,
    setUpdatedVendor,
    initialVendor,
    setSourceLanguage,
    allwaysManualLanguages
}) => {

    const categories = [{ value: 'abouts', title: 'About Vendor' }, { value: 'seo_abouts', title: 'Top Vendor SEO Description' }];

    const isManualTranslationEnabled = ({ language, category }) => {
        return (updatedVendor?.[`translation_config.${category}.manual_translation_languages`]?.[language] !== undefined
            ? updatedVendor?.[`translation_config.${category}.manual_translation_languages`]?.[language]
            : initialVendor?.translation_config?.[category]?.manual_translation_languages?.[language])
    }

    return (
        <div className="form-top-vendor">
            {categories.map((category) => (
                <div className={`vendor-translations-${category.value}`} key={category.value}>
                    <h2>{category.title}</h2>
                    <div className={`${category.value}-translation-forms-container`}>
                        {LANGUAGE_CODES.filter((language) => !allwaysManualLanguages.includes(language)).map((language) => (
                            <div className={`${category.value}-translation-container`} key={language + category.value}>
                                <div className="language-title">
                                    {`${category.title} (${language})`}
                                    <Tag.CheckableTag
                                        checked={isManualTranslationEnabled({ language, category: [category.value] })}
                                        onChange={(checked) => {
                                            setUpdatedVendor({
                                                ...updatedVendor,
                                                ...updatedVendor?.[category.value],
                                                [`translation_config.${category.value}.manual_translation_languages`]: {
                                                    ...initialVendor?.translation_config?.[category.value]?.manual_translation_languages,
                                                    ...updatedVendor?.[`translation_config.${category.value}.manual_translation_languages`],
                                                    ...Object.fromEntries(allwaysManualLanguages.map(language => [language, true])),
                                                    [language]: language === 'en' ? true : checked,
                                                },
                                            });
                                        }}
                                    >
                                        Manual Translation
                                    </Tag.CheckableTag>
                                </div>
                                <Form.Item name={`${category.value}_${language}`}>
                                    <TextArea
                                        value={initialVendor?.[category.value]?.[language]}
                                        placeholder="Optional"
                                        rows={3}
                                        disabled={!isManualTranslationEnabled({ language, category: [category.value] })}
                                        onChange={(e) => {
                                            setSourceLanguage(language);
                                            setUpdatedVendor({
                                                ...updatedVendor,
                                                [`translation_config.${category.value}.manual_translation_languages`]: {
                                                    ...initialVendor?.translation_config?.[category.value]?.manual_translation_languages,
                                                    ...updatedVendor?.[`translation_config.${category.value}.manual_translation_languages`],
                                                    ...Object.fromEntries(allwaysManualLanguages.map(language => [language, true])),
                                                },
                                                [`${category.value}.${language}`]: e.target.value,
                                            });
                                        }}
                                    />
                                </Form.Item>
                            </div>
                        ))}
                    </div>
                </div>
            ))
            }
        </div>
    )
};

export default EditTranslations;