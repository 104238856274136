import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Collapse, Checkbox, Button, Spin, Modal, notification } from "antd";

import { fetchFlows } from "../../redux/marketingFlowSlice";

import AddNewMarketingFlow from "./AddNewMarketingFlow";

import "./MarketingFlows.scss";

const MarketingFlows = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [flows, setFlows] = useState([]);
    const [refresh, setRefresh] = useState(false);

    useEffect(async () => {
        setIsLoading(true);

        const flows = await fetchFlows();
        setFlows(flows);

        setIsLoading(false);
    }, [refresh]);

    return (
        <div className="marketing-flows-container">

            <AddNewMarketingFlow open={isCreateModalOpen} setOpen={setIsCreateModalOpen} refresh={() => setRefresh(r => !r)}/>

            <div className="marketing-flows-header">
                <div className="marketing-flows-header-left">
                    <h2>Marketing Flows</h2>
                    <Spin spinning={isLoading}/>
                </div>

                <div className="marketing-flows-header-right">
                    <Button 
                        type="primary" 
                        onClick={() => setIsCreateModalOpen(true)}
                    >
                        Add New Flow
                    </Button>
                </div>
            </div>

            <div className="marketing-flows-list">

                <div className="marketing-flow-header">
                    <span>*</span>
                    <span>Name</span>
                    <span>Description</span>
                    <span>Event Code</span>
                    <span>Channel</span>
                    <span>Event Type</span>
                </div>

                { flows?.map(flow => {
                    return (
                        <Link className="marketing-flow" to={`../marketing-flows/${flow?._id}`}>
                            <span className="marketing-flow-checkbox-container"><Checkbox /></span>
                            <span>{flow.name}</span>
                            <span>{flow.description}</span>
                            <span>{flow.event_name}</span>
                            <span>{flow.channel}</span>
                            <span>{flow.type}</span>
                        </Link>
                    )
                }) }
            </div>
        </div>
    )
};

export default MarketingFlows;
