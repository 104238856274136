import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

function Protect({ childeren }) {
  const { status } = useSelector((state) => state.user);
  const location = useLocation();
  if (status.auth !== "succeeded")
    return <Navigate to="/" state={{ from: location }} replace />;

  return <>{childeren}</>;
}

export default Protect;
