import './SupplierApplication.css';
import { useNavigate } from 'react-router-dom';
import { Tag } from 'antd';
import { loncaFormatDate } from '../../helpers';

function SupplierApplication({ item }) {

  const navigate = useNavigate();

  if (!item) return null;

  const getStatusColor = (status) => {
    switch (status) {
      case 'Waitlist':
        return 'blue';
      case 'Accepted':
        return 'green';
      case 'Rejected':
        return 'red';
      case 'On Hold':
        return 'yellow';
      default:
        return 'default';
    }
  };

  return (
    <div className="list-application">
      <div
        className="list-application-item-supplier"
        onClick={() => navigate(`/supplier-applications/${item?._id}`)}
      >
        <div className="list-application-item-supplier-applicant">{item?.firstName + ' ' + item?.lastName}</div>
        <div className="list-application-item-supplier-company">{item?.company_name}</div>
        <div className="list-application-item-supplier-status">
          <Tag color={getStatusColor(item?.status)}>{item?.status}</Tag>
        </div>
        <div className="list-application-item-supplier-date">{loncaFormatDate(item?.createdAt)}</div>
        <div className="list-application-item-supplier-search"></div>
      </div>
    </div>
  );
}

export default SupplierApplication;