import './Refund.css';
import { Tag } from 'antd';
import { loncaFormatDate } from '../../helpers';

function Refund({ item }) {

  if (!item) return null;

  const getStatusColor = (status) => {
    switch (status) {
      case 'Submitted':
        return 'yellow';
      case 'Approved':
        return 'blue';
      case 'Completed':
        return 'green';
      case 'Disapproved':
        return 'red';
      case 'Customer Pending':
        return 'yellow';
      case 'Operations Pending':
        return 'gray';
      case 'Vendor Pending':
        return 'brown';
      default:
        return 'default';
    }
  };

  return (
    <div className="list-refund">
      <div
        className="list-refund-item"
        onClick={() => window.open(`/refunds/${item?._id}`)}
      >
        <div className="list-refund-item-order">{item?.unique_order_id}</div>
        <div className="list-refund-item-customer">{item?.customer?.firstName + " " + item?.customer?.lastName}</div>
        <div className="list-refund-item-count">{item?.refunds?.length}</div>
        <div className="list-refund-item-status">
          <Tag color={getStatusColor(item?.order_refund_status)}>{item?.order_refund_status}</Tag>
        </div>
        <div className='list-refund-item-order-delivery-date'>{loncaFormatDate(item?.refunds?.[0]?.order?.delivery_date)}</div>
        <div className='list-refund-item-order-delivery-date'>{loncaFormatDate(item?.refunds?.[0]?.createdAt)}</div>

      </div>
    </div>
  );
}

export default Refund;