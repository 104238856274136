import { notification } from "antd";
import { createOrderExchange } from "../../../redux/refundSlice";

const reasonsForItemCountNotSeries = ["Defected", "Missing Items", "Wrong Series"]

const showItemCountNotSeries = (selectedExchangeProduct) => {
    if (selectedExchangeProduct?.refund_reason && reasonsForItemCountNotSeries.includes(selectedExchangeProduct?.refund_reason)) {
        return true;
    }
    return false;
}

const handleAddToListHelper = (order, selectedExchangeProduct, exchanges, setExchanges, refundableProducts, setRefundableProducts) => {
    const showItemCount = showItemCountNotSeries(selectedExchangeProduct);

    const productId = selectedExchangeProduct?.product;
    const customerId = order?.customer?._id;
    const refundReason = selectedExchangeProduct?.refund_reason;
    const note = "";
    const images = [];
    const seriesAmount = selectedExchangeProduct?.series_amount;
    const itemCount = selectedExchangeProduct?.item_count;
    const itemNotes = [];
    const series = selectedExchangeProduct?.series;
    const free_return_available = selectedExchangeProduct?.free_return_available;

    const cart = {
        product_id: productId,
        customer_id: customerId,
        refund_reason: refundReason,
        note: note,
        images: images,
        series_amount: showItemCount ? "" : seriesAmount,
        item_count: showItemCount ? itemCount : "",
        item_notes: itemNotes,
        series: series,
        free_return_available: free_return_available
    }
    
    setExchanges([
        ...exchanges,
        cart
    ]);

    /* const newRefundableProducts = refundableProducts?.filter((refundableProduct) => refundableProduct?.product?._id !== productId);
    setRefundableProducts(newRefundableProducts); */
}

const handleRemoveExchangeProductHelper = (productId, selectedExchangeProducts, setSelectedExchangeProducts, exchanges, setExchanges) => {
    const newSelectedExchangeProducts = selectedExchangeProducts?.filter((exchangeProduct) => exchangeProduct?.product !== productId);
    const newExchanges = exchanges?.filter((exchange) => exchange?.product_id !== productId);
    setSelectedExchangeProducts(newSelectedExchangeProducts);
    setExchanges(newExchanges);
}

const getVendorStatusChangeLogs = (oldVendorStatus, newVendorStatus, user, oldVendorStatusLogs, source) => {
    let oldLogs = [...oldVendorStatusLogs];
    if(oldVendorStatus !== newVendorStatus) {
        const newVendorStatusLog = {
            user: user?.full_name,
            source: source,
            from: oldVendorStatus || "",
            to: newVendorStatus,
            date: new Date()
        }
        oldLogs.push(newVendorStatusLog);
    }

    return oldLogs;
}

export {
    handleAddToListHelper,
    showItemCountNotSeries,
    handleRemoveExchangeProductHelper,
    getVendorStatusChangeLogs
}