import axios from 'axios';
import store from './store'
import { getUrlFromState } from './userSlice';


export const fetchCreditApplications = async (filter) => {
  const url = getUrlFromState(store)
  const { data } = await axios.post(`${url}/credit-application/list`, filter);
  return data;
};

export const approveCreditApplications = async (id, status) => {
  const url = getUrlFromState(store)
  const { data } = await axios.patch(`${url}/credit-application?id=${id}`, { status });
  return data;
};

export const updateCreditApplications = async (id, update) => {
  const url = getUrlFromState(store)
  const { data } = await axios.patch(`${url}/credit-application/update?id=${id}`, { update });
  return data;
};
