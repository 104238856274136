import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Select, Spin, Button, notification, Input } from 'antd';
import { useTranslation } from "react-i18next";
import _ from 'underscore';

import { fetchReasons, fetchRefundProducts, setFilter, fetchUpdate } from '../../redux/refundSlice';
import { fetchAllExchangeRates } from "../../redux/exchangeRatesSlice";

import { Pagination, ProductForRefund } from '../../components';
import { REASONS_FOR_ITEM_COUNT_NOT_SERIES } from '../../constants';

import './RefundProducts.css';

const RefundProducts = () => {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();

  const { Option } = Select;
  
  const { vendors } = useSelector((state) => state.vendors);
  const { filter, refundProducts } = useSelector((state) => state.refunds);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [refundReasons, setRefundReasons] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [changedRefunds, setChangedRefunds] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchForm] = Form.useForm();
  const [couponPercentage, setCouponPercentage] = useState(0);

  const handleFilter = (payload) => {
    dispatch(setFilter(payload));
  };
  
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    let isCancelled = false;
    const fetchData = async () => {
      try {
        setLoading(true);

        await dispatch(fetchRefundProducts({filter, signal}));
        if (!isCancelled) {
          setLoading(false); 
        }
      } catch (error) {
        if (signal.aborted) {
          console.log('Request canceled:', error.message);
        } else {
          console.error('Error:', error);
        }
        if (!isCancelled) {
          setLoading(false); 
        }
      }
    };

    fetchData();

    return () => {
      isCancelled = true;
      controller.abort();
    };

  }, [filter, dispatch]);

  useEffect(() => {
    fetchReasons()
      .then(res => {
        if (res?.data) 
          setRefundReasons(res.data)
      })
    fetchAllExchangeRates()
      .then(res => {
        setCurrencies(res?.data);
      })
  }, []);

  const updateChangedRefunds = (id, updatedRefund, customer_name, unique_order_id) => {
    const existingIndex = changedRefunds.findIndex((data) => data?.id === id);
    const existingRefundProduct = refundProducts.find((data) => data._id?.toString() === id?.toString());
    let newChangedRefunds;

    if (existingIndex !== -1) {
      const updatedData = [...changedRefunds];
      updatedData[existingIndex].updatedRefund = updatedRefund;
      updatedData[existingIndex].customer_name = customer_name;
      updatedData[existingIndex].unique_order_id = unique_order_id;
      newChangedRefunds = updatedData;
      setChangedRefunds(updatedData);
    } else {
      newChangedRefunds =  [...changedRefunds, { id, updatedRefund, customer_name, unique_order_id }];
      setChangedRefunds((prevData) => [...prevData, { id, updatedRefund, customer_name, unique_order_id }]);
    }

    if(existingRefundProduct?.vendor_status === "Vendor Pending" && typeof updatedRefund?.vendor_status !== "undefined") {
      let hasAnyVendorPendingRefund = false;
      let changedFromVendorPending = false;
      
      for (const refund of refundProducts?.filter(rp => rp?.orderId === existingRefundProduct?.orderId)) {
        const correspondingChangedRefund = newChangedRefunds?.find(changed => changed?.id?.toString() === refund?._id?.toString())?.updatedRefund;
        const correspondingRefund = correspondingChangedRefund || refund;

        if (correspondingChangedRefund) {
          if (correspondingChangedRefund?.vendor_status !== "Vendor Pending" && refund?.vendor_status === "Vendor Pending") {
            changedFromVendorPending = true;
          }
        }

        if (correspondingRefund?.vendor_status === "Vendor Pending") {
          hasAnyVendorPendingRefund = true;
        }
      };

      let newOrderRefundStatus;
      const existingIndex = newChangedRefunds.findIndex((data) => data.id === id);
      const updatedData = [...newChangedRefunds];

      if(!hasAnyVendorPendingRefund && changedFromVendorPending) { 
        newOrderRefundStatus = "Customer Pending";
      }

      if (hasAnyVendorPendingRefund && updatedData?.[existingIndex]?.updatedRefund?.refund_status !== "Vendor Pending") {
        newOrderRefundStatus = "Vendor Pending";
      }

      if (newOrderRefundStatus) {
        
        const sameOrderRefunds = refundProducts?.filter(rp => rp?.orderId === existingRefundProduct?.orderId);

        newChangedRefunds = newChangedRefunds?.map(cr => {
          if (cr?.orderId === existingRefundProduct?.order?.toString()) {
            return { ...cr, updatedRefund: { ...cr?.updatedRefund, updated_order_refund_status: newOrderRefundStatus } }
          }

          return cr;
        });

        /* sameOrderRefunds?.forEach(refund => {
          if (!newChangedRefunds?.find(cr => cr?.id?.toString() === refund?._id?.toString())) {
            const customer_name = refund?.order?.customer_name;
            const unique_order_id = refund?.order?.unique_order_id;

            let updatedRefund = { 
              updated_order_refund_status: newOrderRefundStatus
            };

            newChangedRefunds.push({ id: refund?._id?.toString(), updatedRefund, customer_name, unique_order_id });
          }
        }); */

        setChangedRefunds(newChangedRefunds);
      }
    }
  };

  const handleRefundProductChanges = async () => {
    let isAnyError = false;
    changedRefunds?.map(changedRefund => {
      const isItemCount = changedRefund?.updatedRefund?.refund_reason && REASONS_FOR_ITEM_COUNT_NOT_SERIES?.includes(changedRefund?.updatedRefund?.refund_reason);

      if(isItemCount) {
        const itemNotes = changedRefund?.updatedRefund?.item_notes;
        const itemCount = Number(changedRefund?.updatedRefund?.item_count);
        const isValidToUpdate = itemCount === itemNotes?.length && !itemNotes?.some(itemNote => itemNote?.trim().length === 0);
        
        if(itemCount === 0) { 
          notification["error"]({
            message: "Item count cannot be 0!"
          });
          isAnyError = true;
        } else if(!isValidToUpdate) {
          notification["error"]({
            message: "Please fill in the item notes!"
          });
          isAnyError = true;
        }
      } else {
        const isValidToUpdate = !!changedRefund?.updatedRefund?.series_amount;
        if(!isValidToUpdate) {
          notification["error"]({
            message: "Please fill in the series amount"
          });
          isAnyError = true;
        }
      }
    });

    if(isAnyError) {
      return false; 
    }

    const promises = changedRefunds.map(async (refund) => {
      const { id, updatedRefund, customer_name, unique_order_id } = refund;
            
      try {
        await fetchUpdate(id, updatedRefund, { uniqueOrderId: unique_order_id, customerName: customer_name, source: "Refund Products" });
        notification["success"]({
          message: `Refunds modified successfully`,
        });
      } catch (err) {
        console.error("Error updating refunds:", err);
        notification["error"]({
          message: err?.response?.data?.error || `Refunds couldn't be modified successfully`,
        });
      }
    });

    try {
      await Promise.all(promises);
      setLoading(true)
      setTimeout(() => {
        window.location.reload();
      }, 1000);
  
    } catch (err) {
      console.error("Error updating refunds:", err);
      notification["error"]({
        message: err?.response?.data?.error || `Refunds couldn't be modified successfully`,
      });
    }
  };
  
  return (
    <div className="list-refund-products-container">
      <div className="list-refund-products-nav">
        <h2>Return Products</h2>
        <div>
            <Button
              type="primary"
              onClick={async () => {
                await handleRefundProductChanges();
              }}

              style={{ marginRight: "20px"}}
            >
              {`Save Changes`}
            </Button>
        </div>
      </div>
      <div className="list-refund-products-filter">
        <div className="refund-products-search"> 
          <Form form={searchForm} onFinish={() => handleFilter({...filter , search_key : searchText})}>
            <Form.Item name="search">
              <Input value={searchText} placeholder={t('placeholders.Search')} onChange={(e) => setSearchText(e.target.value)} />
            </Form.Item>
          </Form>
        </div>

        <Form className='refund-products-filter-form'
          form={form}
          layout="inline"
        > 

          <Form.Item className='filter-refund-products'>
            <Select
              onChange={(value) => {
                let operationStatusValue;

                // Mapping values to keys in the database
                switch (value) {
                  case 'Taken From Customer':
                    operationStatusValue = 'taken_from_customer';
                    break;
                  case 'Send to Vendor':
                    operationStatusValue = 'send_to_vendor';
                    break;
                  case 'Taken From Vendor':
                    operationStatusValue = 'taken_from_vendor';
                    break;
                  case 'Send to Customer':
                    operationStatusValue = 'send_to_customer';
                    break;
                  default:
                    operationStatusValue = null;
                }

                if (value === 'all') {
                  handleFilter({...filter , filter : (_.omit(filter.filter,'operation_info'))});

                } else {
                  handleFilter({
                    ...filter,
                    filter: {
                      ...filter.filter,
                      operation_info: {
                        [operationStatusValue]: true,
                      },
                    },
                  });
                }
              }}
              defaultValue='all' 
            >
              <Option value='all'>Operation Status</Option>
              <Option value="Taken From Customer">Taken From Customer</Option>
              <Option value="Send to Vendor">Send to Vendor</Option>
              <Option value="Taken From Vendor">Taken From Vendor</Option>
              <Option value="Send to Customer">Send to Customer</Option>
            </Select>
          </Form.Item>
          <Form.Item className='filter-refund-products'>
            <Select
              onChange={(value) => {
                if (value === 'all') {
                  handleFilter({...filter , filter : (_.omit(filter.filter,'invoice_status'))});
                } else {
                  handleFilter({ ...filter, filter : {...filter.filter, invoice_status: value }});
                }
              }}
              defaultValue='all' 
            >
              <Option value='all'>Invoice</Option>
              <Option value=''></Option>
              <Option value="Refund Completed">Invoice Completed</Option>
              <Option value="Refund Needed">Invoice Needed</Option>
              <Option value='Refund Not Needed'>Invoice Not Needed</Option>
            </Select>
          </Form.Item>
          <Form.Item className='filter-refund-products'>
            <Select
              onChange={(value) => {
                if (value === 'all') {
                  handleFilter({...filter , filter : (_.omit(filter.filter,'vendor_status'))});
                } else {
                  handleFilter({ ...filter, filter : {...filter.filter, vendor_status: value }});
                }
              }}
              defaultValue='all' 
            >
              <Option value='all'>Vendor Status</Option>
              <Option value=''></Option>
              <Option value='Product Exist'>Product Exist</Option>
              <Option value="Vendor Pending">Vendor Pending</Option>
              <Option value='Vendor Disapproved'>Vendor Disapproved</Option>
              <Option value="Vendor Approved">Vendor Approved</Option>
            </Select>
          </Form.Item>
          <Form.Item className='filter-refund-products'>
            <Select
              onChange={(value) => {
                if (value === 'all') {
                  handleFilter({...filter , filter : (_.omit(filter.filter,'refund_status'))});
                } else {
                  handleFilter({ ...filter, filter : {...filter.filter, refund_status: value }});
                }
              }}
              defaultValue='all' 
            >
              <Option value='all'>Product Status</Option>
              <Option value='Submitted'>Submitted</Option>
              <Option value='Disapproved'>Disapproved</Option>
              <Option value="Approved">Approved</Option>
              <Option value="On Hold">On Hold</Option>                    
              <Option value="Completed">Completed</Option>
            </Select>
          </Form.Item>
          <Form.Item name="sort" className='filter-refund-products'>
            <Select
              onChange={(e) => {
                const newValue = JSON.parse(e);
                handleFilter({...filter , sort: newValue});
              }}  
              defaultValue='{"refund_request_date":-1}'
            >
              <Option value='{"refund_request_date":-1}'>Request: {t('sort.New to Old')}</Option>
              <Option value='{"refund_request_date":1}'>Request: {t('sort.Old to New')}</Option>              
            </Select>
          </Form.Item>
          
        </Form>
      </div>

      <div className="list-refund-products-filter">
        <Form className='refund-products-filter-form'
          form={form}
          layout="inline">

          <Form.Item className='filter-refund-products'>
              <Select
                onChange={(value) => {
                  if (value === 'all') {
                    handleFilter({...filter , filter : (_.omit(filter.filter,'customer_return_invoice'))});
                  } else {
                    handleFilter({ ...filter, filter : {...filter.filter, customer_return_invoice: value }});
                  }
                }}
                defaultValue='all' 
              >
                <Option value='all'>Customer Return Invoice</Option>
                <Option value=''></Option>
                <Option value='Refund Needed'>Invoice Needed</Option>
                <Option value='Refund Not Needed'>Invoice Not Needed</Option>
                <Option value='Refund Completed'>Invoice Completed</Option>
              </Select>
          </Form.Item>

          <Form.Item className='filter-refund-products'>
              <Select
                onChange={(value) => {
                  if (value === 'all') {
                    handleFilter({...filter , filter : (_.omit(filter.filter,'vendor_stock_status'))});
                  } else {
                    handleFilter({ ...filter, filter : {...filter.filter, vendor_stock_status: value }});
                  }
                }}
                defaultValue='all' 
              >
                <Option value='all'>Vendor Stock Status</Option>
                <Option value=''></Option>
                <Option value='Will Be Stock In'>Will Be Stock In</Option>
                <Option value="Will Be Sent to Vendors">Will Be Sent to Vendors</Option>
                <Option value="Will Be Held">Will Be Held</Option>
              </Select>
          </Form.Item>

          <Form.Item className='filter-refund-products'>
              <Select
                onChange={(value) => {
                  if (value === 'all') {
                    handleFilter({...filter , filter : (_.omit(filter.filter,'operation_stock_status'))});
                  } else {
                    handleFilter({ ...filter, filter : {...filter.filter, operation_stock_status: value }});
                  }
                }}
                defaultValue='all' 
              >
                <Option value='all'>Operation Stock Status</Option>
                <Option value=''></Option>
                <Option value='Stocked In'>{t("exchanges.operation_stock_status_stock_in")}</Option>
                <Option value="Not Stocked In">{t("exchanges.operation_stock_status_stock_out")}</Option>
              </Select>
          </Form.Item>

          <Form.Item name="vendor" className='filter-refund-products'>
            <Select
              onChange={(e) => {
                if (e === 'all') {
                  handleFilter({...filter , filter: (_.omit(filter.filter,'vendor'))});
                } else {
                  handleFilter({ ...filter, filter: { ...filter.filter, vendor: [e] } });
                }
              }}
              defaultValue="all"
              placeholder="Search Vendor"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
              }
            >
              <Option value="all">All Vendors</Option>
              {vendors?.slice().sort((a, b) => a.name.localeCompare(b.name)).map((vendor, index) => (
                <Option key={'vendor-select' + index} value={vendor._id}>
                  {vendor.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item className='filter-refund-products'>
              <Select
                onChange={(value) => {
                  if (value === 'all') {
                    handleFilter({...filter , filter : (_.omit(filter.filter,'region'))});
                  } else {
                    handleFilter({ ...filter, filter : {...filter.filter, region: value }});
                  }
                }}
                defaultValue='all' 
              >
                <Option value='all'>Region</Option>
                <Option value="Europe">Europe</Option>
                <Option value="Other Europe">Other Europe</Option>
                <Option value='Turkey'>Turkey</Option>
                <Option value='Other Turkey'>Other Turkey</Option>
                <Option value='Neighbor'>Neighbor</Option>
                <Option value='Others'>Others</Option>
              </Select>
          </Form.Item>
          <Form.Item className="filter-refund-products">
            <Select
              onChange={(value) => {
                if (value === 'all') {
                  handleFilter({...filter , filter : (_.omit(filter.filter,'order_refund_status'))});
                } else {
                  handleFilter({ ...filter, filter : {...filter.filter, order_refund_status: value }});
                }
              }}
              defaultValue='all' 
            >
              <Option value='all'>Order Status</Option>
              <Option value='Submitted'>Submitted</Option>
              <Option value="Customer Pending">Customer Pending</Option>
              <Option value="Vendor Pending">Vendor Pending</Option>
              <Option value="Operations Pending">Operations Pending</Option>
              <Option value='Disapproved'>Disapproved</Option>
              <Option value='Approved'>Approved</Option>
              <Option value='Completed'>Completed</Option>
            </Select>
          </Form.Item>
          
        </Form>
        
      </div>

      {loading && <Spin style={{ "marginTop": "20px" }} tip={"Loading Refunds..."} />}

      { 
        refundProducts?.map((refund) => {
          return (
            <ProductForRefund key={refund?._id} id={refund?._id}  updateChangedRefunds={updateChangedRefunds} refundReasons={refund?.free_return_available === false ? refundReasons.filter(reason => !reason?.free_return_reason) : refundReasons} currencies={currencies} changedRefunds={changedRefunds} page={"exchange-products"} setCouponPercentage={setCouponPercentage}/>
          )
        })
      }
      
      <div>
        <Pagination filter={filter} setFilter={handleFilter} />
      </div>
    </div>
  );
}

export default RefundProducts;
