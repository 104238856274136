import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage';
import "./CargoCompany.css"

import { Select } from 'antd';
import { defaultImage } from '../../helpers';

function CargoCompany({ item }) {

  const [selected, setSelected] = useState(false);
  const navigate = useNavigate();
  const { Option } = Select;


  return (
    <div className="list-cargo_company" onClick={() => navigate(`/cargo-company/${item?._id}`, { state: item })} >
      <div className="list-cargo_company-item">
        <div className="list-cargo_company-item-check">
          {/* <input
            value={selected}
            onChange={() => setSelected((state) => !state)}
            type="checkbox"
          /> */}
        </div>
        <div className="list-cargo_company-item-image">
          <ResponsiveImage height={60} width={120} src={item.logo || defaultImage} />
        </div>
        <div className="list-cargo_company-item-cargo_company">{item.name}</div>
        <div className="list-cargo_company-item-status">
          <Select
            disabled
            defaultValue={item.status || 'Active'}
            onChange={(e) => handleUpdate(item._id, e)}
          >
            <Option value="Active">Active</Option>
            <Option value="Passive">Passive</Option>
            <Option value="Archived">Archived</Option>
          </Select>
        </div>
        <div className="list-cargo_company-item-type">
          {item.express ? 'express' : false}
        </div>
        <div className="list-cargo_company-item-cargo_company">{item.own_cargo ? 'own cargo' : false}</div>
      </div>
    </div>
  );
}

export default CargoCompany;
