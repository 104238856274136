import { Link } from "react-router-dom";
import { getDate } from '../../helpers';
import { FileOutlined } from '@ant-design/icons';

import './FirstMileShipment.css';

function FirstMileShipment({ item }) {

  return (
    <div className="list-shipment">
      <Link 
        className="list-shipment-item"
        to={`../first-mile-shipments`}
      >
        <div></div>
        <div>
          {getDate(item?.shipment_date)}
        </div>
        <div>
          {item?.total_cost}
        </div>
        <div>
          {item?.total_desi}
        </div>
        <div>
          {item?.per_desi_cost}
        </div>
        <div>
          {item?.orders?.length}
        </div>
        <div>
          {item?.currency}-{item?.rate_usd_buying}
        </div>
        <div>
          <FileOutlined
              style={{ fontSize: '16px', cursor: 'pointer' }}
              onClick={(e) => {
                  e.stopPropagation();
                  window.open(item?.invoice, '_blank', 'noopener,noreferrer');
              }}
          />
        </div>
      </Link>
    </div>
  );
}

export default FirstMileShipment;
