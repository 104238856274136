import axios from 'axios';
import { getUrlFromState } from './userSlice';
import store from './store';

export const fetchAdditionalService = (filter) => {
  const url = getUrlFromState(store)
  return axios.post(
  `${url}/additional_service/filter`, filter,
 
)};

export const fetchDelete = (id) => {
  const url = getUrlFromState(store)
  return axios.delete(`${url}/additional_service/${id}`);
}
