import axios from 'axios';
import store from './store';
import { getUrlFromState } from './userSlice';

export const fetchCompanies = async (query) => {
  const url = getUrlFromState(store)
  const { data } = await axios.post(
    `${url}/cargo_company/`,
    { ...query },
  );
  return data;
};

export const fetchTntCargo = async (orders, mode) => {
  const url = getUrlFromState(store)
  const { data } = await axios.post(
    `${url}/cargo_company/tnt-cargo`,
     orders, mode,
  );
  return data;
};

export const fetchAllCompanies = async () => {
  const url = getUrlFromState(store)
  const { data } = await axios.post(
    `${url}/cargo_company/all`,
  );
  return data;
};
export const fetchCreate = (cargoCompany) => {
  const url = getUrlFromState(store)
  return axios.post(`${url}/cargo_company/create`, cargoCompany)
};

export const fetchUpdate = (id, update) => {
  const url = getUrlFromState(store)
  return axios.patch(`${url}/cargo_company/one/${id}`, {
  update
})
};


