import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUrlFromState } from "./userSlice";
import store from './store';

// Async thunk to fetch a configuration by name
export const fetchConfigurationByName = createAsyncThunk(
  "configurations/fetchConfigurationByName",
  async (configName) => {
    const url = getUrlFromState(store);
    const { data } = await axios.get(`${url}/configurations/${configName}`);
    return data;
  }
);

// Async thunk to fetch all configurations
export const fetchAllConfigurations = createAsyncThunk(
  "configurations/fetchAllConfigurations",
  async () => {
    const url = getUrlFromState(store);
    const { data } = await axios.get(`${url}/configurations`);
    return data;
  }
);

export const fetchUpdateConfiguration = 
  async ({ id, updatedConfig }) => {

    const url = getUrlFromState(store);
    const { data } = await axios.patch(`${url}/configurations/update/${id}`, updatedConfig);
    return data;
  }

export const fetchCreateConfiguration = 
  async (newConfig) => {
    const url = getUrlFromState(store);
    const { data } = await axios.post(`${url}/configurations/new`, newConfig);
    return data;
  }

const configurationsSlice = createSlice({
  name: "configurations",
  initialState: {
    configurations: [],
    configuration: null,
    status: "idle",
    error: null,
  },
  reducers: {
    // Optional: Add any additional reducer logic here
  },
  extraReducers: {
    // Handle fetchAllConfigurations
    [fetchAllConfigurations.pending]: (state) => {
      state.status = "loading";
    },
    [fetchAllConfigurations.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.configurations = action.payload;
    },
    [fetchAllConfigurations.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    // Handle fetchConfigurationByName
    [fetchConfigurationByName.pending]: (state) => {
      state.status = "loading";
    },
    [fetchConfigurationByName.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.configuration = action.payload;
    },
    [fetchConfigurationByName.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export default configurationsSlice.reducer;