import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, notification, Select } from 'antd';

import { fetchCreate, fetchUpdate, addTag, fetchTags } from "../../redux/tagSlice";

import "./EditTag.scss";

const EditTag = () => {
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const { id } = useParams();
    const isNew =  id === "new";

    const { filterTypes } = useSelector((state) => state.filterType);
    const { tags } = useSelector((state) => state.tags);

    const [tag, setTag] = useState({});

    useEffect(() => {
        if(!isNew) {
            const id = location.pathname.split('/').pop();
            setTag(tags.find(tag => tag?._id?.toString() === id?.toString()));
        }
    }, [tags]);

    useEffect(() => {
        form.setFieldsValue({
            name: tag?.name,
            handle: tag?.handle,
            type: tag?.type,
            status: tag?.status
        });

        Object.keys((tag?.names || {}))?.forEach(key => form.setFieldsValue(`name_${key}`, tag?.names?.[key]))
    }, [form, tag]);

    const handleSubmit = async () => {
        if(isNew) {
            fetchCreate(tag)
                .then(() => {
                    notification['success']({
                        message: "Tag created successfully"
                    });

                    dispatch(fetchTags());
                    navigate("/tags")
                })
                .catch((err) => {
                    notification['error']({
                        message: err.response.data.error
                    })
                    .catch((err) => {
                        notification['error']({
                            message: "Tag couldn't created successfully"
                        });
                    })
                })
        } else {
            fetchUpdate(tag?._id, tag)
                .then(response => {
                    notification['success']({
                        message: "Tag modified successfully"
                    });

                    setTag(response);
                    dispatch(fetchTags());
                }).catch((err) => {
                    notification['error']({
                        message: err?.response?.data?.error
                    })
                    .catch((err) => {
                        notification['error']({
                            message: "Tag couldn't modified successfully"
                        });
                    })
                })
        }
    }

    const setInfo = ({ parentField, field, value }) => {
        if (parentField) {
            setTag({
                ...tag,
                [parentField]: {
                    ...tag[parentField],
                    [field]: value
                }
            })
        } else {
            setTag({
                ...tag,
                [field]: value
            })
        }
    }

    return (
        <div className="edit-tag-container">

            <Form
                form={form}
                className="vendor-form"
                onFinish={handleSubmit}
                layout="vertical"
            >
                <div className="edit-tag-header">
                    <div className="tag-header">
                        <h2>{ tag?.name }</h2>
                    </div>

                    <div>
                        <Button type="primary" htmlType="submit" style={{ marginRight: "10px" }}>
                            Save
                        </Button>
                        <Button type="secondary" onClick={() => {navigate("/tags")}}>
                            Cancel
                        </Button>
                    </div>
                </div>

                <div className="info-container">
                    <div className="info-header">
                        <h3>General Info</h3>
                    </div>

                    <div className="info">
                        <div className="inline-three-inputs-vendor">
                            <Form.Item label="Name"  name="name">
                                <Input
                                    value={tag?.name}
                                    placeholder="Name"
                                    onChange={(e) => setInfo({ field: "name", value: e.target.value}) }>
                                </Input>
                            </Form.Item>

                            <Form.Item label="Handle"  name="handle">
                                <Input
                                    value={tag?.handle}
                                    placeholder="Handle"
                                    onChange={(e) => setInfo({ field: "handle", value: e.target.value}) }>
                                </Input>
                            </Form.Item>

                            <Form.Item label="Type"  name="type">
                                <Select
                                    value={tag?.type}
                                    onChange={(e) => {
                                        setInfo({ field: "type", value: e})
                                    }}>
                                        { filterTypes
                                            ?.filter(filterType => filterType?.status === "Active" && filterType?.dynamic)
                                            ?.map(filterType => (
                                                <Option value={filterType?.type}>{filterType?.name}</Option>
                                            ))
                                         }
                                </Select>
                            </Form.Item>

                            <Form.Item label="Status"  name="status">
                                <Select
                                    value={tag?.status}
                                    onChange={(e) => {
                                        setInfo({ field: "status", value: e})
                                    }}>
                                        <Option value="Active">Active</Option>
                                        <Option value="Passive">Passive</Option>
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </div>

                { Object.keys((tag?.names || {}))?.length > 0 && <div className="info-container">
                    <div className="info-header">
                        <h3>Translations</h3>
                    </div>

                    <div className="info">
                        <div className="translations-container">
                            { Object.keys((tag?.names || {}))?.map(language => (
                                <Form.Item label={`Name ${language?.toUpperCase()}`}  name={`name_${language}`}>
                                    <div></div>
                                    <Input
                                        value={tag?.names?.[language]}
                                        placeholder={`Name ${language?.toUpperCase()}`}
                                        onChange={(e) => setInfo({ parentField: "names", field: language, value: e.target.value}) }>
                                    </Input>
                                </Form.Item>
                            )) }
                        </div>
                    </div>
                </div>}
            </Form>
        </div>
    )
};

export default EditTag;