import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Button, Spin } from 'antd';
import { ResponsiveImage } from "../../components";
import "./EditFavorites.css"
import {  fetchFavoriteById } from "../../redux/favoriteSlice";
import {  calculateFavoriteProductPrice, loncaRound } from "../../helpers";

const EditFavorites = () => {

	const [form] = Form.useForm();
	const [favorite, setFavorite] = useState();
	const [loading, setLoading] = useState(true);

	const { id } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		fetchFavoriteById(id)
			.then((res) => {
				setFavorite(res.data)
				setLoading(false)
			})
	}, [id]);

	if (loading){
		return (
			<Spin />			
		)
	}

	return (
		<div className="container">
			<div className="favorite-details">

				<Form
					form={form}
					className="favorite-form"
					layout="vertical"
				>
					<div className="header-save">
						<h2 className="favorite-header">{favorite?.customer_id?.firstName} {favorite?.customer_id?.lastName}</h2>
						<div>
							<Button type="secondary" onClick={() => navigate('../favorites')}>
								Cancel
							</Button>
						</div>
					</div>
					{favorite?.product?.length ?
						<div className="favorite-form-top">
							<p># of items: {favorite?.product?.length}</p>
							{favorite?.product?.map((item) => (
								<div className="cart_item" key={item._id}>
									<div className="cart_item_image">
										<ResponsiveImage src={item?.main_image} alt={`${item?.names?.en}`} height={300}/>
									</div>
									<div className="favorite_item_info">
										<p>{item?.names?.en}</p>
										<p>{item?.vendor?.name}</p>
										<p>Series: {item?.series?.name}</p>
										<p>Price: ${calculateFavoriteProductPrice({ item })}</p>
										<p>Status: {item?.status}</p>

										<p>
											<a href={`https://test.lonca.co/product/${item?.handle}`} rel="noopener noreferrer nofollow" target="_blank"><strong>Open in Website</strong></a>
										</p>
										<p onClick={() => window.open(`../product/${item?._id}`)}>
											<a><strong>Open in Admin</strong></a>
										</p>
									</div>
									<div className="close-price">
										{isNaN(item?.series?.item_quantity) ? null :
											<p>${loncaRound(calculateFavoriteProductPrice({ item }) * item?.series?.item_quantity)}</p>
										}
									</div>
								</div>
							))}
						</div>
						: null}
				</Form>
			</div>
		</div>
	)
};

export default EditFavorites;