import { useState } from 'react';
import { fetchDelete } from '../../redux/seriesSlice';

import './Serie.css';

import { Button, notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

function Serie({ item }) {
  const [visible, setVisible] = useState(true);
  const [selected, setSelected] = useState(false);

  const handleDelete = () => {
    fetchDelete(item._id)
      .then((data) => {
        notification['success']({
          message: 'Deleting successful',
        });
        setVisible(false);
      })
      .catch((err) => {
        notification['error']({
          message: err.response.data.message,
        });
      });
  };

  if (!visible || !item) return null;

  return (
    <div className="list-serie">
      <div className="list-serie-item">
        <div className="list-serie-item-check">
          <input
            value={selected}
            onChange={() => setSelected((state) => !state)}
            type="checkbox"
          />
        </div>
        <div className="list-serie-item-serie">{item.name}</div>
        <div className="list-serie-item-count">{item.item_quantity}</div>
        <div id="trash" className={!selected ? 'visibility-hidden' : null}>
          <Button onClick={handleDelete} icon={<DeleteOutlined />} />
        </div>
      </div>
    </div>
  );
}

export default Serie;
