import axios from 'axios';
import { getUrlFromState } from './userSlice';
import store from './store'

export const fetchGroupById = (id) => {
    const url = getUrlFromState(store)
    return axios.get(`${url}/product_group/one/${id}`);
}

export const fetchUpdateGroupById = (id, group) => {
    const url = getUrlFromState(store)
    return axios.patch(`${url}/product_group/one/${id}`, group);
}

export const fetchInsertGroup = (group) => {
    const url = getUrlFromState(store)
    return axios.post(`${url}/product_group/one`, group);
}
