import axios from 'axios';
import store from './store';
import { getUrlFromState } from './userSlice';

export const fetchCart = (filter) => {
  const url = getUrlFromState(store)
  return axios.get(`${url}/cart/list`, {
  params: filter,
})
};

export const fetchCartById = (id) => {
  const url = getUrlFromState(store)
  return axios.get(`${url}/cart/one/${id}`)
};

