import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUrlFromState } from './userSlice';
import store from './store'

export const fetchInputs = createAsyncThunk(
  'registrationInput/fetchInputs',
  async () => {
    const url = getUrlFromState(store)
    const { data } = await axios.get(`${url}/registration_input/inputs`);
    return data;
  },
);

const registrationInputSlice = createSlice({
  name: 'registrationInput',
  initialState: {
    inputs: [],
    status: {
      fetchInputs: 'idle',
    },
    error: null,
  },
  reducers: {

  },
  extraReducers: {
    [fetchInputs.pending]: (state) => {
      state.status.fetchInputs = 'pending';
    },
    [fetchInputs.fulfilled]: (state, action) => {
      state.status.fetchInputs = 'succeeded';
      state.inputs = action.payload;
    },
    [fetchInputs.rejected]: (state, action) => {
      state.status.fetchInputs = 'rejected';
      state.error = action.payload;
    },
  },
});

export default registrationInputSlice.reducer;
