import { useState } from 'react';
import { fetchUpdateStatus } from '../../redux/vendorSlice';
import { useNavigate } from 'react-router-dom';
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage';
import "./Vendor.css"

import { Image, Select, Button, notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { defaultImage } from '../../helpers';

function Vendor({ item }) {
  
  // const [visible, setVisible] = useState(true);
  const [selected, setSelected] = useState(false);
  const navigate = useNavigate();
  const { Option } = Select;

  const handleUpdate = (id, status) => {
    fetchUpdateStatus(id, status)
      .then((data) => {
        notification['success']({
          message: 'Modify successful',
        });
      })
      .catch((err) => {
        notification['error']({
          message: err.response.data.message,
        });
      });
  };

  const handleDelete = () => {
    // fetchDelete(item._id)
    //   .then((data) => {
    //     notification['success']({
    //       message: 'Deleting successful',
    //     });
    //     setVisible(false);
    //   })
    //   .catch((err) => {
    //     notification['error']({
    //       message: err.response.data.message,
    //     });
    //   });
  };

  // if (!visible) return null;

  return (
    <div className="list-vendor" onClick={() => navigate(`/vendor/${item?.handle}?tab=vendor-info`, {state : item})} >
      <div className="list-vendor-item">
        <div className="list-vendor-item-check">
          <input
            value={selected}
            onChange={() => setSelected((state) => !state)}
            type="checkbox"
          />
        </div>
        <div className="list-vendor-item-image">
          <ResponsiveImage height={60} width={120} src={item.logo || defaultImage}/>
        </div>
        <div className="list-vendor-item-vendor">{item.name}</div>
        <div className="list-vendor-item-status">
          <Select
            defaultValue={item.status || 'Active'}
            onChange={(e) => handleUpdate(item._id, e)}
          >
            <Option value="Active">Active</Option>
            <Option value="Passive">Passive</Option>
            <Option value="Archived">Archived</Option>
          </Select>
        </div>
        <div className="list-vendor-item-type">
          /{item.handle || 'NO HANDLE'}
        </div>
        <div className="list-vendor-item-vendor">{item.user || 'NO USER'}</div>
        <div id="trash" className={!selected ? 'visibility-hidden' : null}>
          <Button onClick={handleDelete} icon={<DeleteOutlined />} />
        </div>
      </div>
    </div>
  );
}

export default Vendor;
