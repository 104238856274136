import axios from "axios";
import store from "./store";
import { getUrlFromState } from "./userSlice";

export const fetchCustomerCreditPayments = (customerId) => {
  const url = getUrlFromState(store);
  return axios.get(`${url}/credit_payment/customer/${customerId}`);
};

export const fetchDeleteCreditPayment = (paymentId) => {
  const url = getUrlFromState(store);
  return axios.delete(`${url}/credit_payment/${paymentId}`);
};

export const fetchSaveCreditPayment = (payment) => {
  const url = getUrlFromState(store);
  return axios.post(`${url}/credit_payment`, { payment });
};
