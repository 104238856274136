import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './Customers.css';

import { Button, Select, Form, Spin } from 'antd';
import { Customer, CustomerSearchBar, Pagination } from '../../components';
import { fetchCustomers } from '../../redux/customerSlice';
import { setCustomerFilter } from '../../redux/filterSlice';

function Customers() {

  const { Option } = Select;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { customerFilter: filter } = useSelector((state) => state.filter);

  const [Customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false)

  const setFilter = useCallback((value) => {
    dispatch(setCustomerFilter(value));
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);

    fetchCustomers(filter, 'query_list')
      .then((res) => {
        setCustomers(res.data)
        setLoading(false);
      })
      .catch((err) => {
        console.log(err)
        setLoading(false);
      });
  }, [filter]);



  return (
    <div className="list-customers-container">
      <div className="list-customers-nav">
        <h2>Customers</h2>
        <div>
          <Button type="text">Export</Button>
          <Button type="text">Import</Button>
          <Button type="text">Add New Customer</Button>
        </div>
      </div>
      <div className="list-customers-filter">
        <div className='customer-search'>
          <CustomerSearchBar type="customer"/>
        </div>
        <Form
          className="customer-filter-form"
          layout="inline"
          form={form}
          initialValues={{
            sort: filter.sort
          }}
        >
          <Form.Item name="sort">
            <Select
              onChange={(e) => {
               setFilter({ ...filter, sort: e });
              }}
              style={{ width: "150px" }}
            >
              <Option value={-1}>Date: New to Old</Option>
              <Option value={1}>Date: Old to New</Option>
            </Select>
          </Form.Item>
        </Form>
      </div>
      <div className="list-customers-table-head">
        <div className="list-customers-table-check">*</div>
        <div className="list-customers-table-first">First Name</div>
        <div className="list-customers-table-last">Last Name</div>
        <div className="list-customers-table-email">Email</div>
        <div className="list-customers-table-phone">Phone Number</div>
        <div className="list-customers-table-shipment">Shipment Country</div>
      </div>
      {loading && <Spin style={{ "marginTop": "20px" }} tip={"Loading Customers..."} />}
      {Customers.map((item) => (
        <Customer key={item._id} item={item} />
      ))}
      <div>
        <Pagination filter={filter} setFilter={setFilter}/>
      </div>
    </div>
  );
}

export default Customers;

// TODO: Pagination
// TODO: Search
