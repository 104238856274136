
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { Form, Input, Button, Select, InputNumber, notification, Upload, Modal } from 'antd';
import { CircularProgress } from '@mui/material';
import { CloseOutlined, PlusOutlined, CheckCircleOutlined, DeleteOutlined, LinkOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import 'react-image-picker/dist/index.css'

import "./EditNewProduct.css"

import usePrompt from "../../hooks/usePrompt";

import { loncaRound, getBase64, uploadMedias, uploadChartImage } from "../../helpers"
import { fetchNewProductById, fetchNewProducts, fetchCreateNewProduct } from "../../redux/newProductsSlice";
import { fetchVendors } from "../../redux/vendorSlice";
import { fetchExchangeRates } from "../../redux/exchangeRatesSlice";
import { t } from "i18next";
import { StockInformationSubSeries, TagSelector } from "../../components";

const EditNewProduct = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { genders } = useSelector((state) => state.gender);
  const { tags } = useSelector((state) => state.tags);
  const { vendors: vendorsMain } = useSelector((state) => state.vendors);
  const { allProductTypes: productTypes } = useSelector((state) => state.productTypes);
  const { colors } = useSelector((state) => state.colors);
  const { allSeries: seriesMain } = useSelector((state) => state.series);
  const { user } = useSelector((state) => state.user);

  const { Option } = Select;
  const [form] = Form.useForm();
  const { id } = useParams();
  
  const { data: newProduct } = useQuery(['new-product', id], () => fetchNewProductById(id));
  const [searchParams, _] = useSearchParams();

  const [tab, setTab] = useState("home");
  const [vendors, setVendors] = useState(vendorsMain.filter((i) => i.status === "Active"));
  const [isDirty, setIsDirty] = useState(false);
  const [TL_USD_BUYING, setTL_USD_BUYING] = useState();
  const [TL_USD_SELLING, setTL_USD_SELLING] = useState();
  const [product, setProduct] = useState({ color: [], tags: [], gender: [] });
  const [cogstl, setCogstl] = useState(0);
  const [cogsusd, setCogsusd] = useState(0);
  const [pricetl, setPricetl] = useState(0);
  const [priceusd, setPriceusd] = useState(0);
  const [series, setSeries] = useState("");
  const [status, setStatus] = useState("");
  const [season, setSeason] = useState("");
  const [gender, setGender] = useState("");
  const [fabric, setFabric] = useState("");
  const [modelMeasurements, setModelMeasurements] = useState("");
  const [sampleSize, setSampleSize] = useState("");
  const [productMeasurements, setProductMeasurements] = useState("");
  const [color,  setColor] = useState([]);
  const [product_type,setProductType] = useState({});
  const [skuCode, setSkuCode] = useState();
  const [vendorProductGroupItems, setVendorProductGroupItems] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [chartFileList, setChartFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [loading, setLoading] = useState(false);
  
  usePrompt("You have unsaved changes, do you want to leave?", isDirty);
  
  useEffect(() => {
    fetchExchangeRates({ abbreviation: "TRY" })
      .then(res => {
        setTL_USD_BUYING(res?.data?.rate_usd_buying)
        setTL_USD_SELLING(res?.data?.rate_usd_selling)
      })

    dispatch(fetchVendors());
  }, []);

  useEffect(() => {
    setTab(searchParams?.get("tab") || "home");
}, [searchParams?.get("tab")]);

  useEffect(() => {
    if(newProduct?.vendor_product_group){
    const filter = { filter : {
      vendor_product_group: newProduct?.vendor_product_group,
      vendor: newProduct?.vendor,
      // exclude itself
      stock_code: { $nin: [newProduct?.stock_code] }},
    };
  
    dispatch(fetchNewProducts({ filter })).then((res) => {
      setVendorProductGroupItems(res?.payload);
    });
  }
  }, [dispatch, newProduct?.vendor_product_group, newProduct?.stock_code, newProduct?.vendor]);
  

  useEffect(() => {
    setVendors(vendorsMain.filter((i) => i.status === "Active"));
  }, [vendorsMain]);

  useEffect(() => {
    const vendor = vendors.find((i) => i?.name === newProduct?.vendor);
    setSkuCode(`${vendor?.vendor_initial || ""}${parseInt(vendor?.vendor_product_count || 10000) + 1}`);
  }, [vendors, newProduct?.vendor]);

  useEffect(() => {
    if (newProduct) {
      form.setFieldsValue({
        stock_code: newProduct?.stock_code,
        supplier_stock: newProduct?.quantity,
      });

      const vendor = vendors.find((i) => i?.name === newProduct?.vendor);

      const compoundMargin = ((1 + (vendor?.lonca_vendor_margin / 100)) * (1 + (vendor?.lonca_boutique_margin / 100)));

      switch (newProduct.price_unit) {
        case "USD":
          setCogsusd(loncaRound(newProduct.price / (vendor?.margin_included ? compoundMargin : 1)));
          setPriceusd(loncaRound(newProduct.price * (vendor?.margin_included ? 1 : compoundMargin)));
          setCogstl(loncaRound(newProduct.price * TL_USD_SELLING * (vendor?.margin_included ? compoundMargin : 1)));
          setPricetl(loncaRound(newProduct.price * TL_USD_SELLING * (vendor?.margin_included ? 1 : compoundMargin)));
          break;
        case "TL":
          setCogsusd(loncaRound((newProduct.price) / (vendor?.margin_included ? compoundMargin : 1) / TL_USD_BUYING));
          setPriceusd(loncaRound((newProduct.price * (vendor?.margin_included ? 1 : compoundMargin)) / TL_USD_BUYING));
          setCogstl(loncaRound(newProduct.price * (vendor?.margin_included ? compoundMargin : 1)));
          setPricetl(loncaRound(newProduct.price * (vendor?.margin_included ? 1 : compoundMargin)));
          break;
        default:
          setCogsusd(0);
          setPriceusd(0);
          setCogstl(0);
          setPricetl(0);
      }
      
      setStatus(newProduct?.status);
      setSeries(seriesMain.find((i) => i?.name === newProduct?.series)?._id)
      setSeason(tags.find(t => t?.name === newProduct?.season))
      setColor((colors?.filter(pt => newProduct?.color?.includes(pt?.names?.tr)).length > 0) ?
                colors?.filter(pt => newProduct?.color?.includes(pt?.names?.tr)) :
                colors?.filter(pt => newProduct?.color?.includes(pt?.names?.en)))
      setProductType(productTypes.find(pt => pt?.names?.tr  === newProduct?.product_type) ?
                     productTypes.find(pt => pt?.names?.tr  === newProduct?.product_type): 
                     productTypes.find(pt => pt?.names?.en === newProduct?.product_type))
      setGender(genders.filter((item) => item.handle === newProduct?.gender?.toLowerCase()))
      setFabric(newProduct?.fabric);
      setModelMeasurements(newProduct?.model_measurements);
      setSampleSize(newProduct?.sample_size);
      setProductMeasurements(newProduct?.product_measurements);

      setFileList(newProduct.images.map((image, index) => {
        return {
          uid: index+1,
          status: "done",
          url: image,
          name: `Image_${index+1}`,
          existBeforeUpdate: true,
          is_main: index === 0
        }
      }))

      const initialProduct = {
        ...product,
        newProductId: newProduct._id,
        cogs: cogsusd,
        price: priceusd,
        vendor: vendor?._id,
        vendor_name: newProduct.vendor,
        color: color,
        gender: gender,
        status: status,
        series: series,
        main_image: newProduct.images[0],
        images: newProduct.images,
        stock_code: newProduct.stock_code,
        supplier_stock: newProduct?.quantity || 0,
        sku_code: skuCode,
        sub_series: newProduct?.sub_series,
        vendor_site_link: newProduct.vendor_site_link,
        vendor_product_group : newProduct?.vendor_product_group,
        vendor_product_id : newProduct?.vendor_product_id,
        product_type: product_type,
        names :  {tr: createNameWithSku(newProduct?.name)},
        description_details : {tr : {fabric, model_measurements : modelMeasurements, sample_size : sampleSize, product_measurements : productMeasurements}},
        tags: season ? [season] : []
      }
      
      setProduct(initialProduct);

    }
  }, [newProduct, series, skuCode, cogsusd, TL_USD_BUYING, TL_USD_SELLING]);

  const updateTabParam = (newTab) => {
    const newUrl = `${location.pathname}?tab=${newTab}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
    setTab(newTab);
  };

  const deleteTag = (tag) => {
    setProduct({ ...product, tags: product.tags.filter((item) => item._id !== tag._id) });
    setIsDirty(true);
  };

  const deleteColor = (color) => {
    setProduct({ ...product, color: product.color.filter((item) => item._id !== color._id) });
    setIsDirty(true);
  };

  const deleteGender = (handle) => {
    setProduct({ ...product, gender: product.gender.filter((item) => item.handle !== handle) });
    setIsDirty(true);
  }

  const createNameWithoutSku = (name) => {
    const dashArr = name?.split("-");

    if(dashArr?.length) {
      return dashArr?.slice(1)?.join("-")?.trimStart();
    }

    return "";
  }

  const createNameWithSku = (name) => {  
    return skuCode + " - " + name;
  }

  const handleSubmit = async () => {
    setLoading(true);
    
    const isMisfire = vendors?.find((i) => i?._id === product?.vendor?._id || i?._id === product?.vendor)?.is_misfire;
    const misfireLimit = vendors?.find((i) => i?._id === product?.vendor?._id || i?._id === product?.vendor)?.misfire_limit;

    const totalStock = ((isMisfire && product?.sub_series?.length > 0) ? product?.sub_series?.reduce((acc, crr) => acc + crr?.stock, 0) : product?.stock) || 0;
    const totalSupplierStock = (isMisfire && product?.sub_series?.length > 0) ? product?.sub_series?.reduce((acc, crr) => acc + crr?.supplier_stock, 0) : product?.supplier_stock;
    const limit = (isMisfire && product?.sub_series?.length && misfireLimit > 0) ? misfireLimit: 1;
    
    if (!(totalStock >= limit) && !(totalSupplierStock >= limit) && product?.status === "Active") {
      notification['error']({
        message: (product?.sub_series?.length && misfireLimit > 0) 
          ? `${t(`errors.Minimum required total stock`)}: ${limit}`
          : t(`errors.Active products must have either stock or supplier stock.`),
      });
      setLoading(false);
      return;
    }
 
    const seasonTag = product?.tags?.find((tag) => tag?.type?.includes("season"));
    if ((product?.status === "Active" || product?.status === "Passive") && !seasonTag) {
      notification['error']({
        message: t(`errors.Product needs a seasonal tag`),
      });
      setLoading(false);
      return;
    }

    if(( product?.price <= 0) || (product?.cogs <= 0)) {
      notification['error']({
        message: t(`errors.Price can't be smaller than 0`),
      });
      setLoading(false);
      return;
    }

    if(fileList.length === 0){
      notification['error']({
        message: 'You need to upload at least one product image',
      });
      setLoading(false)
      return
    }
    let imageLinks = [];
    
    const beforeImageCount = fileList?.filter(f => f?.existBeforeUpdate)?.length;

    let selectedImage = fileList?.find(f => f?.is_main);
    let selectedImageLink = "";

    let existBeforeUpdateMainIndex = fileList?.findIndex(f => f?.is_main);
    let existBeforeUpdateCountTillIndex = fileList?.slice(0, existBeforeUpdateMainIndex + 1)?.filter(f => f?.existBeforeUpdate)?.length;
    
    if(fileList?.length) imageLinks = await uploadMedias(
      vendors?.find((i) => i?._id === product?.vendor)?.name, 
      fileList?.filter(f => !f?.existBeforeUpdate), 
      skuCode,
      beforeImageCount
    );

    if (imageLinks.length === fileList?.filter(f => !f?.existBeforeUpdate)?.length) {

      if (selectedImage?.existBeforeUpdate) {
        selectedImageLink = selectedImage?.url;
      } else {
        selectedImageLink = imageLinks[existBeforeUpdateMainIndex - existBeforeUpdateCountTillIndex];
      }
  
      if(!selectedImageLink){
        notification['error']({
          message: 'You need to specify main image',
        });
        setLoading(false);
        return
      }
      
      notification['success']({
        message: 'All images loaded to AWS successfully',
      });
    } else {
      notification['error']({
        message: 'All images couldn\'t loaded to AWS successfully',
      });
    };

    const required = [
      {
        name: "Series",
        field: "series" 
      },
      {
        name: "COGS",
        field: "cogs" 
      },
      {
        name: "Price",
        field: "price" 
      },
      {
        name: "Product Type",
        field: "product_type" 
      }
    ];
    
    for (const requiredField of required) {
      if(!product[requiredField?.field]) {
        notification['error']({
          message: `${requiredField?.name} is missing`,
        });
        setLoading(false)
        return;
      }
    }

    let chartImage = "";

    if(chartFileList?.filter(i => !i?.existBeforeUpdate)?.length) chartImage = await uploadChartImage(
      vendors?.find((i) => i?._id === product?.vendor?._id || i?._id === product?.vendor)?.name, 
      chartFileList, 
      skuCode
    );
      
    let productEnterLog = {
      role: user?.role,
      user: user?.full_name,
      type: "Draft"
    };
    
    const updatedProduct = {
      ...product,
      images: fileList.filter(f => f?.existBeforeUpdate).map(f => f?.url),
      product_enter_log: productEnterLog,
      vendor: vendors?.find(v => v?._id?.toString() === product?.vendor?.toString()),
      series: seriesMain?.find(s => s?._id?.toString() === product?.series?.toString())
    }  

    const vendor = vendors.find((i) => i?.name === newProduct?.vendor);
    const vendorLastProductCount = vendor?.vendor_product_count;
    
    fetchCreateNewProduct(updatedProduct, imageLinks, selectedImageLink, chartImage, vendorLastProductCount).then((res) => {
      notification['success']({
        message: 'Product created successfully',
      });
      dispatch(fetchVendors());
      navigate("/new-products");
      setLoading(false)
    })
      .catch((err) => {
        if(err?.response?.status === 409) {
          notification['error']({
            message: 'You have possibly created a product for the same vendor in a separate tab! Refreshing the page...',
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          notification['error']({
            message: 'Product couldn\'t created successfully',
          });
        }
        setLoading(false)
      });
    setIsDirty(false);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const capitalize = (text) => {
    let words = text.split(" ");
    words = words.map(word => (word[0]?.toUpperCase() || "") + (word?.slice(1)?.toLowerCase() || ""));
    return words.join(" ");
  }

  return (
    <div className='container'>
      <div className='product-details'>
        <Form
          form={form}
          className="product-form"
          onFinish={handleSubmit}
          layout="vertical"
        >
          <div className="header-save product-header-save">
            <div className="product-tabs">
              <div onClick={() => updateTabParam(`home`)} className={tab === "home" && "selected"}>Home</div>
            </div>
            <h2 className="product-header">{`${["Allday"].includes(newProduct?.vendor) ? "" : newProduct?.name} ${newProduct?.stock_code}`}</h2>
            <div>
            <div>
              <Button type="primary"  disabled= {loading} htmlType="submit" style={{ marginRight: "10px", width: "80px"}}> 
                
              {!loading ? 'Save' :
              <>&nbsp;
                <CircularProgress className='cart-spinner' size='1.2em' />
              </>}
              </Button>
              <Button type="secondary" onClick={() => navigate("/new-products")}>
                Cancel
              </Button>
            </div>
            </div>
          </div>
          <section className="home">
          <div className='form-top'>
            <div className='form-left'>
              <div className="inline-inputs">
                <Form.Item label="Tr Name">
                  <Input
                    value={product?.names?.tr}
                    disabled
                  />
                </Form.Item>
                <Form.Item label='Raw Tr Name'>
                  <Input
                    value={createNameWithoutSku(product?.names?.tr)}
                    required
                    className={`${!createNameWithoutSku(product?.names?.tr) ? "error-display" : ""}`}
                    onChange={(e) => {
                      setProduct({ ...product, names : {...product.names, tr: createNameWithSku(e.target.value)}});
                      setIsDirty(true);
                    }}>
                  </Input>
                </Form.Item>
              </div>
              <div className="inline-inputs">
                <Form.Item label="SKU Code">
                  <Input
                    value={skuCode}
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="description_box">
                <h4 style={{ fontWeight: 800 }}>Description From Supplier</h4>
                <p style={{ textAlign: 'left' }}>{newProduct?.description}</p>
                <a target="_blank" rel="noreferrer" href={newProduct?.vendor_site_link}>{newProduct?.vendor_site_link}</a>

                <div className="supplier_stock_code">
                  <Form.Item name="stock_code" label="Supplier Stock Code">
                    <Input
                      value={product?.stock_code}
                      min={0}
                      onChange={(e) => {
                        const stock_code = e.target.value
                        setProduct({ ...product, stock_code });
                        setIsDirty(true);
                      }}
                      className="input-width"
                    />
                  </Form.Item>
                </div>
              </div>
              { (product?.sub_series?.length > 0  &&  vendors?.find((i) => i?._id === product?.vendor?._id || i?._id === product?.vendor)?.is_misfire) ?
                <StockInformationSubSeries product={product} setProduct={setProduct} setIsDirty={setIsDirty} user={user} isDraftProduct isAllowed/>
                :
              <Form.Item label='Stock Information' >
                <div className="description_box">
                  <div className="inline-inputs">
                    <Form.Item name="stock" label="Stock">
                      <InputNumber
                        value={product?.stock}
                        min={0}
                        onChange={(e) => {
                          const stock = parseInt(e)
                          setProduct({ ...product, stock });
                          setIsDirty(true);
                        }}
                        className={`input-width ${product?.status === "Active" && !product?.stock ? "error-display" : ""}`}
                      />
                    </Form.Item>
                    <Form.Item name="supplier_stock" label="Supplier Stock">
                      <InputNumber
                        value={product?.supplier_stock}
                        min={0}
                        onChange={(e) => {
                          const supplier_stock = parseInt(e)
                      
                          setProduct({ ...product, supplier_stock });
                          setIsDirty(true);
                        }}
                        className={`input-width ${product?.status === "Active" && !product?.supplier_stock  ? "error-display" : ""}`}
                      />
                    </Form.Item>
                  </div>
                  <div className="inline-inputs">
                    <Form.Item name="in_transit_stock" label="In Transit Stock">
                      <InputNumber
                        value={product?.in_transit_stock}
                        min={0}
                        onChange={(e) => {
                          const in_transit_stock = parseInt(e)
                          setProduct({ ...product, in_transit_stock });
                          setIsDirty(true);
                        }}
                        className="input-width"
                      />
                    </Form.Item>
                    <Form.Item name="reserved_stock" label="Reserved Stock">
                      <InputNumber
                        value={product?.reserved_stock}
                        min={0}
                        onChange={(e) => {
                          const reserved_stock = parseInt(e)
                          setProduct({ ...product, reserved_stock });
                          setIsDirty(true);
                        }}
                        className="input-width"
                      />
                    </Form.Item>
                  </div>
                </div>
              </Form.Item>}

              <Form.Item label="Description Tr">
                <div className="description_box">
                  <div className="inline-inputs">
                    <Form.Item label="Fabric">
                      <TextArea
                        value={product?.description_details?.tr?.fabric}
                        onChange={(e) => {
                          setProduct({
                            ...product,
                            description_details: {
                              ...product.description_details,
                              tr : {
                               ...product?.description_details?.tr,  fabric: capitalize(e.target.value)
                              }
                            }
                          });
                          setIsDirty(true);
                        }}
                      />
                    </Form.Item>
                    <Form.Item label="Model Measurement">
                      <TextArea
                        value={product?.description_details?.tr?.model_measurements}
                        onChange={(e) => {
                          setProduct({
                            ...product,
                            description_details: {
                              ...product.description_details,
                              tr : {
                               ...product?.description_details?.tr,  model_measurements: e.target.value
                              }
                            }
                          });
                          setIsDirty(true);
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className="inline-inputs">
                    <Form.Item label="Sample Size">
                      <TextArea
                        value={product?.description_details?.tr?.product_measurementsleSize}
                        onChange={(e) => {
                          setProduct({
                            ...product,
                            description_details: {
                              ...product.description_details,
                              tr : {
                               ...product?.description_details?.tr,  sample_size: e.target.value
                              }
                            }
                          });
                          setIsDirty(true);
                        }}
                      />
                    </Form.Item>
                    <Form.Item label="Product Measurement">
                      <TextArea
                        value={product?.description_details?.tr?.product_measurements}
                        onChange={(e) => {
                          setProduct({
                            ...product,
                            description_details: {
                              ...product.description_details,
                              tr : {
                               ...product?.description_details?.tr,  product_measurements: e.target.value
                              }
                            }
                          });
                          setIsDirty(true);
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Form.Item>

              <div className="description_box-cogs">
                <h3>{`Price Unit: ${newProduct?.price_unit}`}</h3>
                <div className="inline-inputs">
                  <Form.Item label="COGS(TL)">
                    <Input
                      className={`${!cogstl ? "error-display": ""}`}
                      disabled={true}
                      prefix="₺"
                      value={cogstl}
                    />
                  </Form.Item>
                  <Form.Item label="COGS(USD)">
                    <Input
                      className={`${!cogsusd ? "error-display": ""}`}
                      disabled={true}
                      prefix="$"
                      value={cogsusd}
                    />
                  </Form.Item>
                </div>
                <div className="inline-inputs">
                  <Form.Item label="Price(TL)">
                    <Input
                      className={`${pricetl <= 0 || !pricetl ? "error-display": ""}`}
                      disabled={true}
                      prefix="₺"
                      value={pricetl}
                    />
                  </Form.Item>
                  <Form.Item label="Price(USD)">
                    <Input
                      className={`${priceusd <= 0 || !priceusd  ? "error-display": ""}`}
                      disabled={true}
                      prefix="$"
                      value={priceusd}
                    />
                  </Form.Item>
                </div>
              </div>
              <Form.Item label='Vendor Product Group' className="product-group-box">
                <div className="product-group-container" >
                  {vendorProductGroupItems?.slice(0,10).map((item) => (
                      <div className="product-group-item" key={item.handle}  onClick={(e) => {
                        e.preventDefault(); // prevent the default context menu from showing up
                        window.open(`/new-product/${item?._id}`, "_blank")}} 
                    >
                        <span className="product-group-item-title">{item.names?.en}</span>
                        <span className="product-group-item-color">{item.color[0] ? item.color[0].color : 'NO COLOR MIGHT EXPLODE'}</span>
                      </div>
                    ))}
                </div>
              </Form.Item>
            </div>
            <div className='form-right'>
              <Form.Item name="status" label="Status">
                <Input
                  disabled
                  value={product?.status}
                  placeholder={product?.status}
                >
                </Input>
              </Form.Item>    
              {!product?.product_type ? 
              (newProduct?.product_type ?
              <div>
                {<p>Product Type from Supplier:  {newProduct?.product_type}</p>}
              </div> :
              <div>
              {<p>Product Type from Supplier:  No Product Type Info</p>}
            </div>) : 
            null
            }
              <Form.Item label="Product Type" className={`${!product?.product_type ? "error-display": ""}`}>
                <Select
                  showSearch
                  value={product?.product_type?.name}
                  optionFilterProp="children"
                  onChange={(e) => {
                    setProduct({
                      ...product,
                      product_type: productTypes.find((type) => type?._id === e)
                    });
                    setIsDirty(true);
                  }}
                >
                  {productTypes?.slice().sort((a, b) => a.names?.en?.localeCompare(b.names?.en)).map((type, index) => (
                    <Option key={'type-select' + index} value={type._id}>
                      {type.names?.en}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="vendor" label="Vendor">
                <Input
                  disabled
                  value={newProduct?.vendor}
                  placeholder={newProduct?.vendor}
                >
                </Input>
              </Form.Item>
              
                  {!product?.series  ?
            (newProduct?.series ? 
            <div>
            {<p>Series from Supplier: {product?.series ? product?.series : newProduct?.series}</p>}
            </div> : 
            <div>
            {<p>Series from Supplier: No Series Info</p>}
            </div>) : 
            null
            } 
              <Form.Item label="Series" className={`${!product?.series ? "error-display": ""}`}>
                <Select
                  value={product?.series}
                  onChange={(e) => {
                    setProduct({
                      ...product,
                      series: e
                    });
                    setIsDirty(true);
                  }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={null}>None</Option>
                  {seriesMain.map((serie, index) => (
                    <Option key={'series-select' + index} value={serie?._id}>
                      {serie?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {((newProduct?.color?.length > 0) ?
              <div>
                {<p>Colors from Supplier: {newProduct?.color?.join(', ') }</p>}
              </div> :
              <div> {<p>placeholders.Colors from Supplier : No Color Info</p>}
              </div>)}
              <Form.Item name="color" label="Colors">
                <Select
                  onChange={(e) => {
                    if (!product?.color?.find(color => color._id === e)) {
                      setProduct({ ...product, color: [...product.color,  colors.find((a) => a?._id === e)] });
                    }
                    setIsDirty(true);
                  }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Add color"
                >
                  {colors?.slice().sort((a, b) => a?.names?.en.localeCompare(b.color)).filter(item1 => !product?.color?.find(item2 => item1?.names?.en === item2?.names?.en)).map((color, index) => (
                    <Option key={'colors-select' + index} value={color._id}>
                      {color?.names?.en}
                    </Option>
                  ))}
                </Select>
                <div className="multi-container">
                  {product?.color?.map((color, index) => (
                    <div className="multi-item" key={index}>
                      <CloseOutlined onClick={() => deleteColor(color)} />
                      {color?.names?.en}
                    </div>
                  ))}
                </div>
              </Form.Item>
         {!(product?.gender?.length > 0) ? 
              (newProduct?.gender ?
              <div>
                {<p>Gender from Supplier:  {newProduct?.gender}</p>}
              </div> :
              <div>
              {<p>Gender from Supplier:  No Gender Info</p>}
            </div>) : 
            null
            }
              {product?.gender && <Form.Item name="gender" label="Genders">
                <Select
                  onChange={(e) => {
                    const gender = genders.find((item) => item.handle === e);


                    setProduct({ ...product, gender: [...product.gender, gender] });

                    setIsDirty(true);
                  }}
                  showSearch
                  optionFilterProp="children"

                  placeholder="Add gender"
                >

                  {genders?.slice().filter(item1 => !product?.gender?.find(item2 => item1?.handle === item2?.handle)).map((gender, index) => (
                    <Option key={gender.handle} value={gender.handle}>
                      {gender?.names?.en}
                    </Option>
                  ))}
                </Select>
                <div className="multi-container">
                  {product?.gender?.length > 0 && product?.gender?.map((gender) => (
                    <div className="multi-item" key={gender.handle}>
                      <CloseOutlined onClick={() => deleteGender(gender.handle)} />
                      {gender?.names?.en}
                    </div>
                  ))}
                </div>
              </Form.Item>}

              <Form.Item name="Size Chart" label="Size Chart">
                <Upload
                  listType="picture-card"
                  multiple={true}
                  fileList={chartFileList}
                  onPreview={handlePreview}
                  onChange={({ file: updatedFile, fileList: newFileList }) => {
                    if(chartFileList.length < newFileList.length) {
                      setChartFileList(currentList => [...currentList, { ...updatedFile, status: "done" }]);
                    } else if (chartFileList.length > newFileList.length){
                      setChartFileList(newFileList.filter(f => f.status !== "removed"));
                    }
                  }}
                >
                    { chartFileList?.length < 1 ? <div>
                    <PlusOutlined />
                    <div
                      className="plus-outlined-margin"
                    >
                      Upload
                    </div></div> : null }
                </Upload>

              </Form.Item>
            </div>
          </div>

          <div className={`form-bottom ${fileList?.length <= 0 ? "error-display" : ""}`}>
            <p>Add Additional Photos for Product</p>
            <p>To select main image or delete an image, hover the cursor over the product and use buttons</p>

            <Upload
              listType="picture-card"
              multiple={true}
              fileList={fileList}
              onPreview={handlePreview}
              itemRender={(originNode, file, fileList, { remove }) => {
                return (
                  <div className={`single-image ${file?.is_main ? "main-img" : ""}`}  onClick={(e) => {handlePreview(file)}}>
                    <img width={86} height={86} src={file.url} alt={file.uid}/>
                    <div className="shadow-img">
                      <CheckCircleOutlined className="main-selector" onClick={(e) => {
                        e.stopPropagation();
                        setFileList(list => [
                          { ...file, is_main: true },
                          ...(list.filter(f => f?.uid !== file?.uid).map(f => ({ ...f, is_main: false })))
                        ]);
                      }}/>
                      <DeleteOutlined className="trash-selector" onClick={(e) => {
                        e.stopPropagation();
                        remove();
                      }}/>
                    </div>
                  </div>
                )
              }}
              onChange={async ({ file: updatedFile, fileList: newFileList }) => {
                if(fileList.length < newFileList.length) {
                  const url = await getBase64(updatedFile.originFileObj)
                  setFileList(currentList => [...currentList, { ...updatedFile, status: "done", url }]);
                } else if (fileList.length > newFileList.length){
                  let updatedList = newFileList.filter(f => f.status !== "removed");

                  if(updatedFile?.is_main) {
                    updatedList = [ { ...updatedList[0], is_main: true }, ...updatedList.slice(1) ]
                  }

                  setFileList(updatedList);
                }
              }}
            >
              <div>
                <PlusOutlined />
                <div
                  className="plus-outlined-margin"
                >
                  Upload
                </div>
              </div>
            </Upload>

            <Modal 
              visible={previewVisible} 
              title={
                <span>
                  <a href={previewImage} target="_blank" rel="noopener noreferrer">
                    {previewTitle} <LinkOutlined />
                  </a>
                </span>
              } 
              footer={null} 
              onCancel={() => setPreviewVisible(false)
            }>
              <img
                alt="example"
                className="input-width"
                src={previewImage}
              />
            </Modal>

          </div>
            <section>
              <TagSelector  product={product} setProduct={setProduct} setIsDirty={setIsDirty}/>
            </section>
          </section>
          
        </Form>
      </div>
    </div>
  );

};

export default EditNewProduct;