import { getDate } from '../../helpers';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import './OrderForStatus.css';
import { addOrder, removeOrder } from '../../redux/bulkInvoiceSlice';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';



function OrderForStatus({ item, isAllSelected }) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(isAllSelected ? true : false);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelected(isAllSelected);
  },[isAllSelected])

  return (
    <div className="list-order">
      <Link
        className="list-order-status-item"
        to={`../orders/${item?._id}`}
        target='_blank'
      >
         <div className="list-order-item-check" onClick={(e) => e.stopPropagation()}>
          <input
            value={selected}
            checked={selected}
            onChange={() =>  {
              const { checked } = event.target;
              if (checked) {
                dispatch(addOrder(item));
              } else {
                dispatch(removeOrder(item));
              }
              setSelected((state) => !state)}
            }
            type="checkbox"
          />
        </div>
        <div className="list-order-item-fname">
          {item.firstName || 'User Not Found'}
        </div>
        <div className="list-order-item-lname">
          {item.lastName || 'User Not Found'}
        </div>
        <div className="list-order-item-status">{t(`fields.order_status.${item?.status}`)}</div>
        <div className="list-order-item-created">{item?.payment_at ? getDate(item?.payment_at) : t('fields.order_status.Not Paid')}</div>
        <div className="list-order-item-received">
          {item.received}
        </div>
        <div className="list-order-item-stockout">
          {item.stockin}
        </div>
        <div className="list-order-item-confirmed">
          {item.confirmed}
        </div>
        <div className="list-order-item-returned">
          {item.supplier_return}
        </div>
        <div className="list-order-item-cargo-company">
          {item.cargo_company_name}
        </div>
        <div className="list-order-item-customs-document">
          {item?.customs_document_name ? item.customs_document_name : '-'}
        </div>
        <div className="list-order-item-packaged">
          {item?.packages?.length > 0 ? t((`fields.order_status.Yes`)) : '-'}
        
        </div>
        <div className="list-order-item-invoice">
          {item?.invoice?.[0] ? t((`fields.order_status.Yes`)) : '-'}
        </div>
      </Link>
    </div>
  );
}

export default OrderForStatus;




