/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, Button, notification, Select } from 'antd';
import "./EditSupplierUser.css"
import { fetchUpdate, fetchUserById, fetchCreate } from "../../redux/supplierUserSlice";
import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { LockOutlined } from "@ant-design/icons";

const EditSupplierUser = () => {
  const { id } = useParams();
  const isNew = id === "new";
  const { roles } = useSelector((s) => s.role);

  const navigate = useNavigate();

  const { vendors } = useSelector((state) => state.vendors);

  const { Option } = Select;
  const [form] = Form.useForm();
  const [user, setUser] = useState(null);
  const [passwordChanged, setPasswordChanged] = useState(null);


  useEffect(() => {
    if(!isNew) fetchUserById(id)
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => console.log(err));
  }, [id]);

  const handleSubmit = () => {

    if(!isNew) {
        fetchUpdate(user._id, user, passwordChanged)
            .then((res) => {
                notification['success']({
                    message: 'Supplier user saved successfully',
                });
            })
            .catch((err) => {
                console.log(err)
                notification['error']({
                    message: 'Supplier user couldn\'t saved successfully',
                });
            });
    } else {
        if(user?.password?.length < 6) {
            notification['error']({
                message: 'Password must contain at least 6 characters!',
            });
            return;
        }

        fetchCreate(user)
            .then((res) => {
                notification['success']({
                    message: 'Supplier user created successfully',
                });
                navigate(-1);
            })
            .catch((err) => {
                console.log(err)
                notification['error']({
                    message: 'Supplier user couldn\'t created successfully',
                });
            });
    }
  }

  return (
    <div className="container">
      <div className="customer-details">
        <Form
          form={form}
          className="customer-form"
          onFinish={handleSubmit}
          layout="vertical"
        >
          <div className="header-save">
            <h2 className="customer-header">{user?.full_name}</h2>
            <div>
              <Button type="primary" htmlType="submit" style={{ marginRight: "10px" }}>
                Save
              </Button>
              <Button type="secondary" onClick={() => navigate('../users')}>
                Cancel
              </Button>
            </div>
          </div>
          <div className="cart-form-top">
            <Form.Item label="User Info">
              <div className="supplier-box">

                <Form.Item label="Full Name">
                  <Input
                    value={user?.full_name}
                    onChange={(e) => setUser({ ...user, full_name: e.target.value })}
                  />
                </Form.Item>

                <Form.Item label="Email">
                  <Input
                    value={user?.email}
                    onChange={(e) => setUser({ ...user, email: e.target.value })}
                  />
                </Form.Item>
 
                <Form.Item
                  name="password"
                  label="Password"
                  rules={isNew ? [
                    {
                      required: true,
                      message: "Please input password!",
                    },
                  ] : null}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder={isNew ? "Password" : "Change Password"}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        password: e.target.value
                      });
                      setPasswordChanged(true)
                    }}
                  />
                </Form.Item>
                  
                <Form.Item label="Role">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    required
                    value={user?.role}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        role: e
                      });
                    }}
                  >
                    <Option key={'vendor-select-vendor'} value="Vendor">
                      Vendor
                    </Option>
                    <Option key={'vendor-select-blogger'} value="Blogger">
                      Blogger
                    </Option>
                    <Option key={'vendor-select-seo-content-editor'} value="SeoContentEditor">
                      SEO Content Editor
                    </Option>
                    <Option key={'vendor-select-admin'} value="Admin">
                      Admin
                    </Option>
                  </Select>
                </Form.Item>


                { user?.role === "Vendor" ?
                  <Form.Item label="Vendor">
                    <Select
                      showSearch
                      optionFilterProp="children"
                      required
                      value={ user?.vendor?._id || user?.vendor }
                      onChange={(e) => {
                        setUser({
                          ...user,
                          vendor: e
                        });
                      }}
                    >
                      {vendors?.slice().sort((a, b) => a.name.localeCompare(b.name)).map((vendor, index) => (
                        <Option key={'vendor-select' + index} value={vendor?._id}>
                          {vendor?.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                 : null}

                { user?.role === "Admin" ?
                  <Form.Item label="Admin Role">
                    <Select
                      showSearch
                      optionFilterProp="children"
                      required
                      value={ user?.admin_role }
                      onChange={(e) => {
                        setUser({
                          ...user,
                          admin_role: e
                        });
                      }}
                      rules={isNew && user?.role === "Admin" ? [
                        {
                          required: true,
                          message: "Please select an admin role!",
                        },
                      ] : null}
                    >
                    {roles ? roles?.map((role) => (
                          <Option key={`admin-select-${role?.name}`} value={role?.name}>
                            {role?.name}
                          </Option>
                        )) : null
                      }

                    </Select>
                  </Form.Item>
                 : null}
              </div>
            </Form.Item>
          </div>

        </Form>
      </div >
    </div >
  )
};

export default EditSupplierUser;