import axios from 'axios';
import store from './store';
import { getUrlFromState } from './userSlice';

export const fetchFavorite = (filter) => {
  const url = getUrlFromState(store)
  return axios.get(`${url}/favorite_product/list`, {
  params: filter,
})
};

export const fetchFavoriteById = (id) => {
  const url = getUrlFromState(store)
  return axios.get(`${url}/favorite_product/one/${id}`)
};

