import React from 'react';
import { useSelector } from 'react-redux';
import { loncaRound } from "../../helpers";
import { Form, Input } from 'antd';


const PriceBox = ({
    discountCheckbox,
    discountTurkiyeCheckbox,
    setDiscountCheckbox,
    setDiscountTurkiyeCheckbox,
    product,
    setProduct,
    setIsDirty,
    TL_USD_BUYING,
    TL_USD_SELLING,
    compoundMargin,
    pricetl,
    priceTurkiyetl,
    setPricetl,
    setPriceTurkiyetl,
    priceusd,
    priceTurkiyeusd,
    setPriceusd,
    setPriceTurkiyeusd,
    cogstl,
    cogsTurkiyetl,
    setCogstl,
    setCogsTurkiyetl,
    cogsusd,
    cogsTurkiyeusd,
    setCogsusd,
    setCogsTurkiyeusd,
    discountTL,
    discountTurkiyeTL,
    setDiscountTL,
    setDiscountTurkiyeTL,
    discountUSD,
    discountTurkiyeUSD,
    setDiscountUSD,
    setDiscountTurkiyeUSD,
    discountCogsTL,
    discountCogsTurkiyeTL,
    setDiscountCogsTL,
    setDiscountCogsTurkiyeTL,
    discountCogsUSD,
    discountCogsTurkiyeUSD,
    setDiscountCogsUSD,
    setDiscountCogsTurkiyeUSD
  }) => {

    const handleCheckboxChange = ( mode ) => {
        if(mode === 'Turkiye'){
            const isDiscountedTr = !discountTurkiyeCheckbox;
            setDiscountTurkiyeCheckbox((prevChecked) => !prevChecked);
            setProduct((prevProduct) => ({
                ...prevProduct,
                is_discounted_tr: isDiscountedTr, 
            }));
            return
        }
        
        setDiscountCheckbox((prevChecked) => !prevChecked);
    
        setProduct((prevProduct) => ({
          ...prevProduct,
          is_discounted: !discountCheckbox, 
        }));
    
    };

    return (
        <>
        <div className="description_box-cogs">
            <div className="checkbox-container">
                <label>
                <input
                    type="checkbox"
                    checked={discountCheckbox}
                    onChange={() => handleCheckboxChange()}
                />
                Enable Price Discount
                </label>
            </div>
            <div className="inline-inputs">
                <Form.Item label="Price(TL)">
                <Input
                    prefix="₺"
                    value={pricetl}
                    disabled={!product?.vendor?.margin_included}
                    onChange={(e) => {
                    setProduct({
                        ...product,
                        price: loncaRound(parseFloat(e.target.value) / TL_USD_BUYING),
                        cogs: loncaRound((parseFloat(e.target.value) / TL_USD_BUYING) / compoundMargin),
                        update_config: {...product?.update_config, price: 'fixed'}
                    });
                    setPricetl(e.target.value);
                    setPriceusd(loncaRound(parseFloat(e.target.value) / TL_USD_BUYING));
                    setCogstl(loncaRound(parseFloat(e.target.value || 0) / compoundMargin));
                    setCogsusd(loncaRound((parseFloat(e.target.value || 0) / TL_USD_BUYING) / compoundMargin))
                    setIsDirty(true);
                    }}
                />
                </Form.Item>
                <Form.Item label="Price(USD)">
                <Input
                    prefix="$"
                    value={priceusd}
                    disabled={!product?.vendor?.margin_included}
                    onChange={(e) => {
                    setProduct({
                        ...product,
                        price: loncaRound(e.target.value),
                        cogs: loncaRound((parseFloat(e.target.value) / compoundMargin)),
                        update_config: {...product?.update_config, price: 'fixed'}
                    });
                    setPricetl(loncaRound(parseFloat(e.target.value) * TL_USD_SELLING))
                    setPriceusd(e.target.value);
                    setCogstl(loncaRound((parseFloat(e.target.value || 0) * TL_USD_SELLING) / compoundMargin));
                    setCogsusd(loncaRound(parseFloat(e.target.value || 0) / compoundMargin))
                    setIsDirty(true);
                    }}
                />
                </Form.Item>
            </div>

            <div className="inline-inputs">
                <Form.Item label="COGS(TL)">
                <Input
                    prefix="₺"
                    value={cogstl}
                    disabled={product?.vendor?.margin_included}
                    onChange={(e) => {
                    setProduct({
                        ...product,
                        price: loncaRound(parseFloat(e.target.value) / TL_USD_BUYING * compoundMargin),
                        cogs: loncaRound((parseFloat(parseFloat(e.target.value) / TL_USD_BUYING))),
                        update_config: {...product?.update_config, price: 'fixed'}
                    });
                    setPricetl(loncaRound(parseFloat(e.target.value) * compoundMargin));
                    setPriceusd(loncaRound(parseFloat(parseFloat(e.target.value) / TL_USD_BUYING) * compoundMargin));
                    setCogstl(e.target.value);
                    setCogsusd(loncaRound(parseFloat(e.target.value) / TL_USD_BUYING));
                    setIsDirty(true);
                    }}
                />
                </Form.Item>
                <Form.Item label="COGS(USD)">
                <Input
                    prefix="$"
                    value={cogsusd}
                    disabled={product?.vendor?.margin_included}
                    onChange={(e) => {
                    setProduct({
                        ...product,
                        price: loncaRound(parseFloat(e.target.value) * compoundMargin),
                        cogs: loncaRound(e.target.value),
                        update_config: {...product?.update_config, price: 'fixed'}
                    });
                    setPricetl(loncaRound(parseFloat(e.target.value || 0) * TL_USD_SELLING * compoundMargin))
                    setPriceusd(loncaRound(parseFloat(e.target.value || 0) * compoundMargin));
                    setCogstl(loncaRound(parseFloat(e.target.value) * TL_USD_SELLING));
                    setCogsusd(e.target.value);
                    setIsDirty(true);
                    }}
                />
                </Form.Item>
            </div>
            {discountCheckbox &&
            <>
            <div className="inline-inputs">
                <Form.Item label="Discount Price(TL)">
                <Input
                    prefix="₺"
                    value={discountTL}
                    disabled={((discountCheckbox) ? (!product?.vendor?.margin_included) : true )}
                    onChange={(e) => {
                    setProduct({
                        ...product,
                        discounted_price: loncaRound(parseFloat(e.target.value) / TL_USD_BUYING),
                        discounted_cogs: loncaRound((parseFloat(e.target.value) / TL_USD_BUYING) / compoundMargin),
                        update_config: {...product?.update_config, price: 'fixed'}
                    });
                    setDiscountTL(e.target.value);
                    setDiscountUSD(loncaRound(parseFloat(e.target.value) / TL_USD_BUYING));
                    setDiscountCogsTL(loncaRound(parseFloat(e.target.value || 0) / compoundMargin));
                    setDiscountCogsUSD(loncaRound((parseFloat(e.target.value || 0) / TL_USD_BUYING) / compoundMargin));
                    setIsDirty(true);
                    }}
                />
                </Form.Item>
                <Form.Item label="Discount Price(USD)">
                <Input
                    prefix="$"
                    value={discountUSD}
                    disabled={((discountCheckbox) ? (!product?.vendor?.margin_included) : true )}
                    onChange={(e) => {
                    setProduct({
                        ...product,
                        discounted_price: loncaRound(e.target.value),
                        discounted_cogs: loncaRound((parseFloat(e.target.value) / compoundMargin)),
                        update_config: {...product?.update_config, price: 'fixed'}
                    });
                    setDiscountUSD(e.target.value);
                    setDiscountTL(loncaRound(parseFloat(e.target.value) * TL_USD_SELLING));
                    setDiscountCogsTL(loncaRound((parseFloat(e.target.value || 0) * TL_USD_SELLING) / compoundMargin));
                    setDiscountCogsUSD(loncaRound(parseFloat(e.target.value || 0) / compoundMargin));
                    setIsDirty(true);
                    }}
                />
                </Form.Item>
            </div>
            
            <div className="inline-inputs">
                <Form.Item label="Discount COGS(TL)">
                <Input
                    prefix="₺"
                    value={discountCogsTL}
                    disabled={((discountCheckbox) ? (product?.vendor?.margin_included) : true )}
                    onChange={(e) => {
                    setProduct({
                        ...product,
                        discounted_price: loncaRound(parseFloat(e.target.value) / TL_USD_BUYING * compoundMargin),
                        discounted_cogs: loncaRound((parseFloat(parseFloat(e.target.value) / TL_USD_BUYING))),
                        update_config: {...product?.update_config, price: 'fixed'}
                    });
                    setDiscountTL(loncaRound(parseFloat(e.target.value) * compoundMargin));
                    setDiscountUSD(loncaRound(parseFloat(parseFloat(e.target.value) / TL_USD_BUYING) * compoundMargin));
                    setDiscountCogsTL(e.target.value);
                    setDiscountCogsUSD(loncaRound(parseFloat(e.target.value) / TL_USD_BUYING));
                    setIsDirty(true);
                    }}
                />
                </Form.Item>
                <Form.Item label="Discount COGS(USD)">
                <Input
                    prefix="$"
                    value={discountCogsUSD}
                    disabled={((discountCheckbox) ? (product?.vendor?.margin_included) : true )}
                    onChange={(e) => {
                    setProduct({
                        ...product,
                        discounted_price: loncaRound(parseFloat(e.target.value) * compoundMargin),
                        discounted_cogs: loncaRound(e.target.value),
                        update_config: {...product?.update_config, price: 'fixed'}
                    });
                    setDiscountTL(loncaRound(parseFloat(e.target.value || 0) * TL_USD_SELLING * compoundMargin))
                    setDiscountUSD(loncaRound(parseFloat(e.target.value || 0) * compoundMargin));
                    setDiscountCogsTL(loncaRound(parseFloat(e.target.value) * TL_USD_SELLING));
                    setDiscountCogsUSD(e.target.value);
                    setIsDirty(true);
                    }}
                />
                </Form.Item>
            </div>
            </> 
            }
        </div>
        {product?.vendor?.enable_tr_price ?
        <div className="description_box-cogs">
            <div className="checkbox-container">
                <label>
                <input
                    type="checkbox"
                    checked={discountTurkiyeCheckbox}
                    onChange={ () => handleCheckboxChange('Turkiye')}
                />
                Enable Turkiye Price Discount
                </label>
            </div>
            <div className="inline-inputs">
                <Form.Item label="Price Turkiye (TL)">
                <Input
                    prefix="₺"
                    value={priceTurkiyetl}
                    disabled={!product?.vendor?.margin_included}
                    onChange={(e) => {
                    setProduct({
                        ...product,
                        price_tr: loncaRound(parseFloat(e.target.value) / TL_USD_BUYING),
                        cogs_tr: loncaRound((parseFloat(e.target.value) / TL_USD_BUYING) / compoundMargin),
                        update_config: {...product?.update_config, price: 'fixed'}
                    });
                    setPriceTurkiyetl(e.target.value);
                    setPriceTurkiyeusd(loncaRound(parseFloat(e.target.value) / TL_USD_BUYING));
                    setCogsTurkiyetl(loncaRound(parseFloat(e.target.value || 0) / compoundMargin));
                    setCogsTurkiyeusd(loncaRound((parseFloat(e.target.value || 0) / TL_USD_BUYING) / compoundMargin))
                    setIsDirty(true);
                    }}
                />
                </Form.Item>
                <Form.Item label="Price Turkiye (USD)">
                <Input
                    prefix="$"
                    value={priceTurkiyeusd}
                    disabled={!product?.vendor?.margin_included}
                    onChange={(e) => {
                    setProduct({
                        ...product,
                        price_tr: loncaRound(e.target.value),
                        cogs_tr: loncaRound((parseFloat(e.target.value) / compoundMargin)),
                        update_config: {...product?.update_config, price: 'fixed'}
                    });
                    setPriceTurkiyetl(loncaRound(parseFloat(e.target.value) * TL_USD_SELLING))
                    setPriceTurkiyeusd(e.target.value);
                    setCogsTurkiyetl(loncaRound((parseFloat(e.target.value || 0) * TL_USD_SELLING) / compoundMargin));
                    setCogsTurkiyeusd(loncaRound(parseFloat(e.target.value || 0) / compoundMargin))
                    setIsDirty(true);
                    }}
                />
                </Form.Item>
            </div>

            <div className="inline-inputs">
                <Form.Item label="COGS Turkiye (TL)">
                <Input
                    prefix="₺"
                    value={cogsTurkiyetl}
                    disabled={product?.vendor?.margin_included}
                    onChange={(e) => {
                    setProduct({
                        ...product,
                        price_tr: loncaRound(parseFloat(e.target.value) / TL_USD_BUYING * compoundMargin),
                        cogs_tr: loncaRound((parseFloat(parseFloat(e.target.value) / TL_USD_BUYING))),
                        update_config: {...product?.update_config, price: 'fixed'}
                    });
                    setPriceTurkiyetl(loncaRound(parseFloat(e.target.value) * compoundMargin));
                    setPriceTurkiyeusd(loncaRound(parseFloat(parseFloat(e.target.value) / TL_USD_BUYING) * compoundMargin));
                    setCogsTurkiyetl(e.target.value);
                    setCogsTurkiyeusd(loncaRound(parseFloat(e.target.value) / TL_USD_BUYING));
                    setIsDirty(true);
                    }}
                />
                </Form.Item>
                <Form.Item label="COGS Turkiye (USD)">
                <Input
                    prefix="$"
                    value={cogsTurkiyeusd}
                    disabled={product?.vendor?.margin_included}
                    onChange={(e) => {
                    setProduct({
                        ...product,
                        price_tr: loncaRound(parseFloat(e.target.value) * compoundMargin),
                        cogs_tr: loncaRound(e.target.value),
                        update_config: {...product?.update_config, price: 'fixed'}
                    });
                    setPriceTurkiyetl(loncaRound(parseFloat(e.target.value || 0) * TL_USD_SELLING * compoundMargin))
                    setPriceTurkiyeusd(loncaRound(parseFloat(e.target.value || 0) * compoundMargin));
                    setCogsTurkiyetl(loncaRound(parseFloat(e.target.value) * TL_USD_SELLING));
                    setCogsTurkiyeusd(e.target.value);
                    setIsDirty(true);
                    }}
                />
                </Form.Item>
            </div>
            {discountTurkiyeCheckbox &&
            <>
            <div className="inline-inputs">
                <Form.Item label="Discount Price Turkiye (TL)">
                <Input
                    prefix="₺"
                    value={discountTurkiyeTL}
                    disabled={((discountTurkiyeCheckbox) ? (!product?.vendor?.margin_included) : true )}
                    onChange={(e) => {
                    setProduct({
                        ...product,
                        discounted_price_tr: loncaRound(parseFloat(e.target.value) / TL_USD_BUYING),
                        discounted_cogs_tr: loncaRound((parseFloat(e.target.value) / TL_USD_BUYING) / compoundMargin),
                        update_config: {...product?.update_config, price: 'fixed'}
                    });
                    setDiscountTurkiyeTL(e.target.value);
                    setDiscountTurkiyeUSD(loncaRound(parseFloat(e.target.value) / TL_USD_BUYING));
                    setDiscountCogsTurkiyeTL(loncaRound(parseFloat(e.target.value || 0) / compoundMargin));
                    setDiscountCogsTurkiyeUSD(loncaRound((parseFloat(e.target.value || 0) / TL_USD_BUYING) / compoundMargin));
                    setIsDirty(true);
                    }}
                />
                </Form.Item>
                <Form.Item label="Discount Price Turkiye (USD)">
                <Input
                    prefix="$"
                    value={discountTurkiyeUSD}
                    disabled={((discountTurkiyeCheckbox) ? (!product?.vendor?.margin_included) : true )}
                    onChange={(e) => {
                    setProduct({
                        ...product,
                        discounted_price_tr: loncaRound(e.target.value),
                        discounted_cogs_tr: loncaRound((parseFloat(e.target.value) / compoundMargin)),
                        update_config: {...product?.update_config, price: 'fixed'}
                    });
                    setDiscountTurkiyeUSD(e.target.value);
                    setDiscountTurkiyeTL(loncaRound(parseFloat(e.target.value) * TL_USD_SELLING));
                    setDiscountCogsTurkiyeTL(loncaRound((parseFloat(e.target.value || 0) * TL_USD_SELLING) / compoundMargin));
                    setDiscountCogsTurkiyeUSD(loncaRound(parseFloat(e.target.value || 0) / compoundMargin));
                    setIsDirty(true);
                    }}
                />
                </Form.Item>
            </div>
            
            <div className="inline-inputs">
                <Form.Item label="Discount COGS Turkiye (TL)">
                <Input
                    prefix="₺"
                    value={discountCogsTurkiyeTL}
                    disabled={((discountTurkiyeCheckbox) ? (product?.vendor?.margin_included) : true )}
                    onChange={(e) => {
                    setProduct({
                        ...product,
                        discounted_price_tr: loncaRound(parseFloat(e.target.value) / TL_USD_BUYING * compoundMargin),
                        discounted_cogs_tr: loncaRound((parseFloat(parseFloat(e.target.value) / TL_USD_BUYING))),
                        update_config: {...product?.update_config, price: 'fixed'}
                    });
                    setDiscountTurkiyeTL(loncaRound(parseFloat(e.target.value) * compoundMargin));
                    setDiscountTurkiyeUSD(loncaRound(parseFloat(parseFloat(e.target.value) / TL_USD_BUYING) * compoundMargin));
                    setDiscountCogsTurkiyeTL(e.target.value);
                    setDiscountCogsTurkiyeUSD(loncaRound(parseFloat(e.target.value) / TL_USD_BUYING));
                    setIsDirty(true);
                    }}
                />
                </Form.Item>
                <Form.Item label="Discount COGS Turkiye (USD)">
                <Input
                    prefix="$"
                    value={discountCogsTurkiyeUSD}
                    disabled={((discountTurkiyeCheckbox) ? (product?.vendor?.margin_included) : true )}
                    onChange={(e) => {
                    setProduct({
                        ...product,
                        discounted_price_tr: loncaRound(parseFloat(e.target.value) * compoundMargin),
                        discounted_cogs_tr: loncaRound(e.target.value),
                        update_config: {...product?.update_config, price: 'fixed'}
                    });
                    setDiscountTurkiyeTL(loncaRound(parseFloat(e.target.value || 0) * TL_USD_SELLING * compoundMargin))
                    setDiscountTurkiyeUSD(loncaRound(parseFloat(e.target.value || 0) * compoundMargin));
                    setDiscountCogsTurkiyeTL(loncaRound(parseFloat(e.target.value) * TL_USD_SELLING));
                    setDiscountCogsTurkiyeUSD(e.target.value);
                    setIsDirty(true);
                    }}
                />
                </Form.Item>
            </div> 
            </> 
            
            }
            </div> : null}
            </>
    );
};

export default PriceBox;
