import React from "react";
import { useParams } from "react-router-dom";

import EditHomePageTopVendors from "./components/EditHomepageTopVendors";
import EditVendorsPageSorting from "./components/EditVendorsPageSorting";
import EditGoogleReviews from "./components/EditGoogleReviews";

import "./EditStaticContent.scss";

const EditStaticContent = () => {
    const type = useParams()?.type;

    if (type === "homepage-top-vendors") {
        return <EditHomePageTopVendors />;
    }

    if (type === "vendors-page-sorting") {
        return <EditVendorsPageSorting />;
    }

    if (type === "google-reviews") {
        return <EditGoogleReviews />;
    }

    return null;
};

export default EditStaticContent;