import React  from "react";
import { Form, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useTranslation } from "react-i18next";
import "./Refund.scss";

const ExchangeVendorInfo = ({ form, refund, setRefund, setHasChanged, setIsDirty }) => {

    const { t } = useTranslation();

    return (

        <div className="inline-inputs-refund">
            <Form.Item label="Vendor Status" name="vendor_status">
                <Select defaultValue={refund?.vendor_status}  
                    onChange={(e) => {
                        setRefund({...refund, vendor_status: e});
                        setHasChanged(c => !c)
                        setIsDirty(true)
                    }}
                >
                    <Option value=''></Option>
                    <Option value='Product Exist'>Product Exist</Option>
                    <Option value="Vendor Pending">Vendor Pending</Option>
                    <Option value='Vendor Disapproved'>Vendor Disapproved</Option>
                    <Option value="Vendor Approved">Vendor Approved</Option>
                </Select>
            </Form.Item>

            <Form.Item label="Vendor Note" name="vendor_note">
                <TextArea
                    placeholder="Vendor Note"
                    value={refund?.vendor_note}
                    rows={1}
                    onChange={(e) => {
                        setRefund({...refund, vendor_note: e.target.value});
                        setHasChanged(c => !c)
                        setIsDirty(true)
                    }}
                />
            </Form.Item>

            <Form.Item label="Stock Status" name="vendorstockstatus">
                <Select value={refund?.vendor_stock_status}
                    onChange={(e) => {
                        setRefund(prevRefund => ({
                            ...prevRefund,
                            vendor_stock_status: e
                        }));
                        setHasChanged(c => !c);
                        setIsDirty(true);
                    }}
                >
                    <Option value=''></Option>
                    <Option value='Will Be Stock In'>{ t("exchanges.vendor_will_be_in_stock_in") }</Option>
                    <Option value="Will Be Sent to Vendors">{ t("exchanges.vendor_will_be_sent_to_vendors") }</Option>
                    <Option value="Will Be Held">{ t("exchanges.vendor_will_be_held") }</Option>
                </Select>
            </Form.Item>
        </div> 
                 
    );
};

export default ExchangeVendorInfo;
