import './ProductSearchBar.css';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { setFilter } from '../../redux/productSlice';

import {
  fetchSearchBarResults,
  searchBarItemsSelector,
  searchTypeSelector,
  setSearchText,
  setSearchType,
  reset,
  setSearchBarItems,
} from '../../redux/searchSlice';

function ProductSearchBar({
  type, setListAllResults, localSearchText, setLocalSearchText, setAllResultsPath, clearSearch,
}) {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchBarItems = useSelector(searchBarItemsSelector);
  const searchType = useSelector(searchTypeSelector);

  const { filter } = useSelector((state) => state.products);

  const [display, setDisplay] = useState(false);

  useEffect(() => {
    dispatch(reset());
    dispatch(setSearchType(type));
  }, [dispatch, type]);

  const debouncedFetchResults = useCallback(
    debounce((searchText) => {
      dispatch(fetchSearchBarResults({ searchText, searchType }));
    }, 300),
    [dispatch, searchType],
  );

  useEffect(() => {
    if (localSearchText?.length > 0) {
      debouncedFetchResults(localSearchText);
    }
  }, [localSearchText]);

  return (
    <div className="search-wrapper">
      <div className="search-input">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setDisplay(false);

            if (localSearchText?.length !== 0) {
              dispatch(setSearchText(localSearchText));
            }
          }}

        >
          <div className="search-area">
            <i className="bi bi-search search-icon" />
            <input
              type="text"
              placeholder={t('placeholders.Search')}
              value={localSearchText}
              onChange={(e) => {
                setLocalSearchText(e.target.value);
                if (e.target.value.length > 0) {
                  setDisplay(true);
                } else {
                  setDisplay(false);
                }
              }}
            />
            <i
              className="cancel-icon"
              role="button"
              tabIndex={0}
              onClick={() => {
                setFilter({ ...filter, filter: {} });
                setLocalSearchText('');
                setListAllResults(false);
                setAllResultsPath('name');
                clearSearch();
                dispatch(setSearchBarItems([]));
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setFilter({ ...filter, filter: {} });
                  setLocalSearchText('');
                  setListAllResults(false);
                  setAllResultsPath('name');
                  clearSearch();
                  dispatch(setSearchBarItems([]));
                }
              }}
            >
              &times;
            </i>

          </div>
          {display && searchBarItems.length > 0 && (
            <div className="search-results" key={[searchBarItems, localSearchText]}>
              <ul className="search-result">
                {searchBarItems?.slice(0, 6).map((result) => (
                  <li
                    key={result._id}
                    onClick={() => {
                      setDisplay(false);
                      setLocalSearchText('');
                      navigate(`/product/${result._id}`);
                    }}
                    onKeyDown={() => {
                      setDisplay(false);
                      setLocalSearchText('');
                      navigate(`/product/${result._id}`);
                    }}
                    aria-hidden="true"

                  >
                    <div className="search-result-line">
                      <span className="name">{result?.search_path === 'supplier_stock_code' ? result.supplier_stock_code : result?.names?.en}</span>
                      <span className="type text-muted">{result?.search_path === 'supplier_stock_code' ? 'Stock Code' : 'Name'}</span>
                    </div>
                  </li>
                ))}
              </ul>
              <Button
                className="w-100 button-primary list-all"
                type="primary"
                onClick={() => {
                  setAllResultsPath('name');
                  setListAllResults(true);
                  dispatch(setSearchBarItems([]));
                }}

              >
                {t('placeholders.List All Results For Name')}
              </Button>
              <Button
                className="w-100 button-primary list-all"
                type="primary"
                onClick={() => {
                  setAllResultsPath('supplier_stock_code');
                  setListAllResults(true);
                  dispatch(setSearchBarItems([]));
                }}
              >
                {t('placeholders.List All Results For Stock Code')}
              </Button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default ProductSearchBar;
