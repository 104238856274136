import { useState } from 'react';
import { getDate } from '../../helpers';
import { fetchDelete } from '../../redux/invoiceSlice';

import './Invoice.css';

import { Button, notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

function Invoice({ item, setInvoice, setShowForm }) {
  const [visible, setVisible] = useState(true);
  const [selected, setSelected] = useState(false);

  const handleDelete = (e) => {
    e.stopPropagation()
    fetchDelete(item._id)
      .then(() => {
        notification['success']({
          message: 'Deleting successful',
        });
        setVisible(false);
      })
      .catch((err) => {
        notification['error']({
          message: err.response.data.message,
        });
      });
  };
  if (!visible) return null;

  return (
    <div className="list-invoice">
      <div 
        className="list-invoice-item"
        onClick={() => {
          setShowForm(true);
          setInvoice(item);
        }}
      >
        <div className="list-invoice-item-check" onClick={(e) => e.stopPropagation()}>
          <input
            value={selected}
            onChange={() => setSelected((state) => !state)}
            type="checkbox"
          />
        </div>
        <div className="list-invoice-item-code">
          {item?.code}
        </div>
        <div className="list-invoice-item-fname">
          {item.customer?.firstName + " " + item.customer?.lastName|| 'User Not Found'}
        </div>
        <div className="list-invoice-item-idate">{getDate(item.issue_date)} </div>
        <div className="list-invoice-item-subcost">${item?.subtotal_cost}</div>
        <div className="list-invoice-item-discount">${item?.discount ? item.discount : 0}</div>
        <div className="list-invoice-item-totcost">${item?.total_cost}</div>
        <div id="trash" className={!selected ? 'visibility-hidden' : null}>
          <Button onClick={(e) => handleDelete(e)} icon={<DeleteOutlined />} />
        </div>
      </div>
    </div>
  );
}

export default Invoice;
