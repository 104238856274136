import React from "react";
import { Form, Input, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import "./Refund.scss";

const ExchangeAccountInfo = ({ form, refund, setRefund, setHasChanged, setIsDirty, unitCogs, totalSupplierPayment, realUnitCogs, realUnitCogsTRY, realSupplierPayment }) => {

    return (

        <div>
            <div className="inline-inputs-refund">
                <Form.Item label="Unit Cogs" name="unitcogs">
                  <Input value={unitCogs} disabled />
                </Form.Item>

                <Form.Item label="Total Supplier Payment (VAT Included) " name="totalsupplierpayment">
                  <Input value={totalSupplierPayment} disabled />
                </Form.Item>

                <Form.Item label="Vendor Return Invoice" name="refundInvoice">
                    <Select 
                        defaultValue={refund?.invoice_status} 
                        onChange={(e) => {
                            setRefund({...refund, invoice_status: e});
                            setHasChanged(c => !c)
                            setIsDirty(true)
                        }}
                    >
                        <Option value=''></Option>
                        <Option value='Refund Needed'>Invoice Needed</Option>
                        <Option value='Refund Not Needed'>Invoice Not Needed</Option>
                        <Option value='Refund Completed'>Invoice Completed</Option>
                        
                    </Select>
                </Form.Item>
            </div> 

            <div className="inline-inputs-refund margin">
                <Form.Item label={`Real Cogs Amount ($)`} name="realunitcogs">
                  <TextArea
                    placeholder="Real Return Amount"
                    value={realUnitCogs}
                    rows={1}
                    onChange={(e) => {
                      setRefund({...refund, real_cogs_amount: e.target.value});
                      setHasChanged(c => !c)
                      setIsDirty(true)
                    }}
                  />
                </Form.Item>

                <Form.Item label="Real Cogs Amount (₺)" name="realunitcogsTRY">
                  <div></div>
                  <Input value={realUnitCogsTRY} disabled />
                </Form.Item>

                <Form.Item label="Real Supplier Payment (VAT Included) " name="realsupplierpayment">
                  <div></div>
                  <Input value={realSupplierPayment} disabled />
                </Form.Item>
            </div>       
        </div>

    );
};

export default ExchangeAccountInfo;
