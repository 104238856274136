import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";
import { Form, Input, Button, Select, InputNumber, Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { ResponsiveImage } from "../../components";
import "./EditCart.css"
import {  fetchCartById } from "../../redux/cartSlice";
import {  calculateItemPrice, changeCartItem, loncaRound } from "../../helpers";

const EditCart = () => {

	const { Option } = Select;
	const [form] = Form.useForm();
	const { allSeries: seriesMain } = useSelector((state) => state.series);
	const [cart, setCart] = useState();

	const { id } = useParams();
	const navigate = useNavigate();

	const [showAlert, setShowAlert] = useState(false);

	useEffect(() => {
		fetchCartById(id)
			.then((res) => {
				const mappedCart = res.data?.cart_item?.map((item => {
					if (item.is_sample) {
						item.product.series.name = "SAMPLE";
						item.product.series.item_quantity = 1;
					}
					return item;
				}));
				setCart({ ...res.data, cart_item: mappedCart })
			})
	}, [id, setCart]);

	return (
		<div className="container">
			<div className="cart-details">

				<Form
					form={form}
					className="cart-form"
					layout="vertical"
				>
					<div className="header-save">
						<h2 className="cart-header">{cart?.customer?.firstName} {cart?.customer?.lastName}</h2>
						<div>
							<Button type="secondary" onClick={() => navigate('../carts')}>
								Cancel
							</Button>
						</div>
					</div>
					<div className="cart-form-top">
						<div className="inline_inputs">
							<Form.Item label="First Name">
								<Input
									value={cart?.customer?.firstName}
									disabled
								/>
							</Form.Item>
							<Form.Item label="Last Name">
								<Input
									value={cart?.customer?.lastName}
									disabled
								/>
							</Form.Item>
						</div>
						<div className="inline_inputs">
							<Form.Item label="Total Cost">
								<Input
									prefix="$"
									value={cart?.total_cost}
									disabled
								/>
							</Form.Item>
						</div>
					</div>
					{cart?.cart_item?.length ?
						<div className="cart-form-top">
							<p># of items: {cart?.cart_item?.length}</p>
							{cart?.cart_item?.map((item) => (
								<div className="cart_item" key={item._id}>
									<div className="cart_item_image">
										<ResponsiveImage src={item?.product?.main_image} alt={`${item.name}`} height={300}/>
									</div>
									<div className="cart_item_info">
										<p>{item?.product?.names?.en}</p>
										<p>{item?.product?.vendor?.name}</p>
										<Form.Item name="series" style={{ width: "40%" }}>
											{"Series: "}
											<Select
												value={item?.product?.series?.name}
												onChange={(e) => {

														changeCartItem({
														  item: cart, 
														  setItem: setCart, 
														  field: "series", 
														  value: e,
														  currentItem: item, 
														  series: seriesMain
														})
												}}
												showSearch
												optionFilterProp="children"
												filterOption={(input, option) =>
													option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
												}
											>
												{seriesMain.map((serie, index) => (
													<Option key={'series-select' + index} value={serie.name}>
														{serie.name}
													</Option>
												))}
											</Select>
										</Form.Item>
										<Form.Item>
											{"Price:  "}
											<InputNumber
												prefix="$"
												value={calculateItemPrice({ item })}
												onChange={(e) => {
													changeCartItem({
														item: cart, 
														setItem: setCart, 
														field: "price", 
														value: e,
														currentItem: item, 
														series: seriesMain
													  })
												}}
											/>
										</Form.Item>
										<Form.Item>
											{"Quantity:  "}
											<InputNumber
												value={item.quantity || ""}
												onChange={(e) => {
													changeCartItem({
														item: cart, 
														setItem: setCart, 
														field: "quantity", 
														value: e,
														currentItem: item, 
														series: seriesMain
													  })													
												}}
											/>
										</Form.Item>
									</div>
									<div className="close-price">
										{isNaN(item?.product?.series?.item_quantity) ? null :
											<p>${loncaRound(calculateItemPrice({ item }) * item?.product?.series?.item_quantity * item.quantity)}</p>
										}
									</div>
								</div>
							))}
						</div>
						: null}
				</Form>
			</div>
		</div>
	)
};

export default EditCart;