import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Input, Select, DatePicker, Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { fetchInputs } from "../../redux/registrationInputSlice";
import  { fetchCountries }  from "../../redux/countrySlice";
import  fetchEnums  from '../../redux/enumsSlice';
import { useTranslation } from "react-i18next";
import moment from "moment";

import { GROWTHBOOK_EXPERIMENT_VALUES, SENDPULSE_OPERATORS } from "../../constants";


import "./EditCustomer.css";

const PersonalInfo = ({ customer, setCustomer }) => {
  const { Option } = Select;
  const dispatch = useDispatch();

  const { t } = useTranslation('translation');
  const { inputs: registrationInputs } = useSelector(state => state.registrationInput);

  const [closedLostUnqualifiedReasons, setClosedLostUnqualifiedReasons] = useState([]);
  const [churnReasons, setChurnReasons] = useState([]);
  const [countryList, setCountryList] = useState([]);

  const [showNewAddressForm, setshowNewAddressForm] = useState(false);

  const addNewAddressButton = useCallback(() => {
    setshowNewAddressForm(prevState => !prevState);
  }, []);

  const [showNewBillingAddressForm, setshowNewBillingAddressForm] = useState(false);

  const addNewBillingAddressButton = useCallback(() => {
    setshowNewBillingAddressForm(prevState => !prevState);
  }, []);

  const detailMapping = {
    annualSales: 'annual_sales',
    apparelBoutiqueCategory: 'product_category',
    plannedOpeningMonth: 'planned_opening_month',
    storeCategory: 'store_category',
    storeType: 'store_type',
    womenSubCategory: 'product_style',
    yearsInBusiness: 'years_in_business',
    otherCategory: 'store_category_other'
  };

  useEffect(() => {
    if (!registrationInputs) dispatch(fetchInputs())
  }, []);

  useEffect(() => {
    fetchEnums({ type: 'closed_lost_unqualified_reason' })
      .then(res => {
        setClosedLostUnqualifiedReasons(res)
      })
      fetchEnums({ type: 'churn_reason' })
      .then(res => {
        setChurnReasons(res)
      })
  }, []);

  useEffect(() => {
    fetchCountries()
      .then((res) => {
        setCountryList(res?.sort((a, b) => a?.name?.localeCompare(b?.name)));
      })
  }, []);

  const detailCustomOrdering = {
    yearsInBusiness: [3, 2, 1, 0]
  };

  const optionssOrdered = useMemo(() => {
    if (registrationInputs?.length) {
      let result = JSON.parse(JSON.stringify(registrationInputs));

      for (const key in detailCustomOrdering) {
        for (const [oldIndex, newIndex] of detailCustomOrdering[key]?.entries()) {
          const inputIndex = registrationInputs?.findIndex(input => input?._id === detailMapping[key]);
          result[inputIndex].options[newIndex] = registrationInputs[inputIndex]?.options[oldIndex];
        }
      }
      return result;
    }
    return []
  }, [registrationInputs]);

  return (
    <div className="cart-form-top">
      <Form.Item label="Personal Details">
        <div className="box">
          <div className="inline_five_inputs">
            <Form.Item label="First Name">
              <Input
                value={customer?.firstName}
                onChange={(e) => setCustomer({ ...customer, firstName: e.target.value })}
              />
            </Form.Item>

            <Form.Item label="Last Name">
              <Input
                value={customer?.lastName}
                onChange={(e) => setCustomer({ ...customer, lastName: e.target.value })}
              />
            </Form.Item>

            <Form.Item label="Email">
              <Input
                value={customer?.email}
                onChange={(e) => setCustomer({ ...customer, email: e.target.value })}
              />
            </Form.Item>

            <Form.Item label="Phone Number">
              <Input
                value={customer?.phone_number}
                onChange={(e) => setCustomer({ ...customer, phone_number: e.target.value })}
              />
            </Form.Item>

            <Form.Item label="Personal ID">
              <Input 
                value={customer?.customer_details?.personalID ? customer?.customer_details?.personalID : ""} 
                onChange={(e) => {
                  setCustomer({ 
                    ...customer, 
                    customer_details: {
                      ...customer.customer_details,
                      ...(customer.customer_details && customer.customer_details.personalID
                        ? { personalID:  e.target.value}
                        : { ...customer.customer_details, personalID:  e.target.value}
                      )
                    }
                  })
                }}
              />
            </Form.Item> 

            <Form.Item label="Birthday" >
                  <DatePicker
                    placeholder={t('fields.edit_order.Select Date')}
                    value={customer?.customer_details?.birthday ? moment(customer?.customer_details?.birthday) : null}
                    onChange={(e) => {
                      setCustomer({ 
                        ...customer, 
                        customer_details: {
                          ...customer.customer_details,
                          ...(customer.customer_details && customer.customer_details.birthday
                            ? { birthday: new Date(e._d).toISOString() }
                            : { ...customer.customer_details, birthday: new Date(e._d).toISOString() }
                          )
                        }
                      })
                    }}
                  />
            </Form.Item>

            {customer?.createdAt ?
              <Form.Item label="Created At">
                <Input disabled defaultValue={customer?.createdAt.slice(0, 10)} />
              </Form.Item> : null
            }

            {customer?.shipment_country ?
              <Form.Item label="Shipment Country">
                <Input disabled defaultValue={customer?.shipment_country?.name} />
              </Form.Item> : null
            }

            <Form.Item
              label="Boutique Location"
              className='details-item boutique-location'
            >
              <Select
                value={customer?.customer_details?.boutiqueLocation?._id}
                onChange={(e) => {
                  const country = countryList?.find(c => c?._id?.toString() === e?.toString());
                  setCustomer({ ...customer, customer_details: { ...customer.customer_details, boutiqueLocation: country } });
                }}
              >
                {
                  countryList?.map(({ _id, name }) => (
                    <Option key={_id} value={_id}>
                      {name}
                    </Option>
                  ))
                }
              </Select>
            </Form.Item>

            {customer?.executive ?
              <Form.Item label="Executive">
                <Input disabled defaultValue={customer?.executive} />
              </Form.Item> : null
            }

            <Form.Item
              label="Ask For Product"
            >
              <Select
                value={customer?.ask_for_product}
                onChange={(e) => {
                  setCustomer({
                    ...customer,
                    ask_for_product: e
                  });
                }}
              >
                <Option key="true" value={true}>True</Option>
                <Option key="false" value={false}>False</Option>

              </Select>
            </Form.Item>

            {customer?.segment ?
              <Form.Item label="Segment">
                <Input disabled defaultValue={customer?.segment} />
              </Form.Item> : null
            }

          </div>
        </div>
      </Form.Item>

      { customer?.experiments && customer?.experiments?.length > 0 && 
        <Form.Item label="Experiment Values">
          <div className='details-container box experiments-box'>
            { customer?.experiments?.filter(experiment => !!experiment?.isIncluded && GROWTHBOOK_EXPERIMENT_VALUES?.includes(experiment?.test))?.map(experiment => {
              return (
                <div className="experiment-container">
                  <div> <span className="experiment-key">Key:</span> {experiment?.test}</div>
                  <div> <span className="experiment-key">Value:</span> {typeof experiment?.value === "boolean" ? (experiment?.value ? "Yes" : "No") : experiment?.value}</div>
                  <div> <span className="experiment-key">Description:</span> {experiment?.description}</div>
                </div>
              )
            }) }
          </div>
        </Form.Item>
      }

      <Form.Item label="Store Details">
          <div className='details-container box'>
            <div className='inline_four_inputs' >

                <Form.Item label="Boutique Name" className='details-item'>
                  <Input 
                    value={customer?.customer_details?.boutique_name ? customer?.customer_details?.boutique_name : ""}
                    onChange={(e) => {
                      setCustomer({ 
                        ...customer, 
                        customer_details: {
                          ...customer.customer_details,
                          ...(customer.customer_details && customer.customer_details.boutique_name
                            ? { boutique_name:  e.target.value}
                            : { ...customer.customer_details, boutique_name:  e.target.value}
                          )
                        }
                      })
                    }} 
                  
                  />
                </Form.Item> 

                <Form.Item label="Company Title" className='details-item'>
                  <Input 
                    value={customer?.customer_details?.company_title ? customer?.customer_details?.company_title : ""}
                    onChange={(e) => {
                      setCustomer({ 
                        ...customer, 
                        customer_details: {
                          ...customer.customer_details,
                          ...(customer.customer_details && customer.customer_details.company_title
                            ? { company_title:  e.target.value}
                            : { ...customer.customer_details, company_title:  e.target.value}
                          )
                        }
                      })
                    }} 
                  
                  />
                </Form.Item> 

                <Form.Item label="Company Phone Number" className='details-item'>
                  <Input 
                    value={customer?.customer_details?.company_phone_number ? customer?.customer_details?.company_phone_number : ""}
                    onChange={(e) => {
                      setCustomer({ 
                        ...customer, 
                        customer_details: {
                          ...customer.customer_details,
                          ...(customer.customer_details && customer.customer_details.company_phone_number
                            ? { company_phone_number:  e.target.value}
                            : { ...customer.customer_details, company_phone_number:  e.target.value}
                          )
                        }
                      })
                    }} 
                  
                  />
                </Form.Item> 

                <Form.Item label="Company Vat ID" className='details-item'>
                  <Input 
                    value={customer?.customer_details?.company_vat ? customer?.customer_details?.company_vat : ""}
                    onChange={(e) => {
                      setCustomer({ 
                        ...customer, 
                        customer_details: {
                          ...customer.customer_details,
                          ...(customer.customer_details && customer.customer_details.company_vat
                            ? { company_vat:  e.target.value}
                            : { ...customer.customer_details, company_vat:  e.target.value}
                          )
                        }
                      })
                    }} 
                  
                  />
                </Form.Item> 

                <Form.Item label="Google Maps URL" className='details-item'>
                  <Input 
                    value={customer?.customer_details?.company_google_maps ? customer?.customer_details?.company_google_maps : ""}
                    onChange={(e) => {
                      setCustomer({ 
                        ...customer, 
                        customer_details: {
                          ...customer.customer_details,
                          ...(customer.customer_details && customer.customer_details.company_google_maps
                            ? { company_google_maps:  e.target.value}
                            : { ...customer.customer_details, company_google_maps:  e.target.value}
                          )
                        }
                      })
                    }} 
                  
                  />
                </Form.Item> 

                <Form.Item label="Social Media" className='details-item'>
                  <Input 
                    value={customer?.customer_details?.socialMediaURL ? customer?.customer_details?.socialMediaURL : ""} 
                    onChange={(e) => {
                      setCustomer({ 
                        ...customer, 
                        customer_details: {
                          ...customer.customer_details,
                          ...(customer.customer_details && customer.customer_details.socialMediaURL
                            ? { socialMediaURL:  e.target.value}
                            : { ...customer.customer_details, socialMediaURL:  e.target.value}
                          )
                        }
                      })
                    }} 
                  />
                </Form.Item> 
              
                <Form.Item label="Website" className='details-item'>
                  <Input 
                    value={customer?.customer_details?.websiteURL ? customer?.customer_details?.websiteURL : ""}
                    onChange={(e) => {
                      setCustomer({ 
                        ...customer, 
                        customer_details: {
                          ...customer.customer_details,
                          ...(customer.customer_details && customer.customer_details.websiteURL
                            ? { websiteURL:  e.target.value}
                            : { ...customer.customer_details, websiteURL:  e.target.value}
                          )
                        }
                      })
                    }}  
                  
                  />
                </Form.Item>
              
                <Form.Item label="Trendyol" className='details-item'>
                  <Input 
                    value={customer?.customer_details?.trendyolUrl ? customer?.customer_details?.trendyolUrl : ""}
                    onChange={(e) => {
                      setCustomer({ 
                        ...customer, 
                        customer_details: {
                          ...customer.customer_details,
                          ...(customer.customer_details && customer.customer_details.trendyolUrl
                            ? { trendyolUrl:  e.target.value}
                            : { ...customer.customer_details, trendyolUrl:  e.target.value}
                          )
                        }
                      })
                    }} 
                  
                  />
                </Form.Item> 
              
            </div>

            <Form.Item label="Store Billing Address" style={{'marginTop': '20px'}}>
              {customer?.billing_address_array?.filter((address) => address.isCredit === true).map((address, index) => (
                <Form.Item >
                  <div className='inside-box'>
                    <div className='inline_inputs'>
                      <Form.Item label="Billing Name">
                        <Input
                          value={address.billing_name}
                          onChange={(e) => {
                            setCustomer({
                              ...customer,
                              billing_address_array: [
                                ...customer?.billing_address_array?.filter((i) => i._id !== address._id),
                                { ...address, billing_name: e.target.value }
                              ]
                            })
                          }}
                        />
                      </Form.Item>
                      <Form.Item label={t('fields.edit_order.Company Type')}>
                        <Select
                        value={address.company_type}
                        onChange={(e) => {
                          setCustomer({
                            ...customer,
                            billing_address_array: [
                              ...customer?.billing_address_array?.filter((i) => i._id !== address._id),
                              { ...address, company_type: e }
                            ]
                          })
                        }}
                        >
                          <Option value="Individual">Individual</Option>
                          <Option value="Company">Company</Option>

                        </Select>
                    </Form.Item>
                    </div>
                    <div className='inline_inputs'>
                      <Form.Item label={t('fields.edit_order.VAT Number')}>
                        <Input
                          value={address.corporate_identity_number}
                          onChange={(e) => {
                            setCustomer({
                              ...customer,
                              billing_address_array: [
                                ...customer?.billing_address_array?.filter((i) => i._id !== address._id),
                                { ...address, corporate_identity_number: e.target.value }
                              ]
                            })
                          }}
                        />
                      </Form.Item>
                      <Form.Item label={t('fields.edit_order.Tax Office')}>
                        <Input
                          value={address.tax_office}
                          onChange={(e) => {
                            setCustomer({
                              ...customer,
                              billing_address_array: [
                                ...customer?.billing_address_array?.filter((i) => i._id !== address._id),
                                { ...address, tax_office: e.target.value }
                              ]
                            })
                          }}
                        />
                      </Form.Item>
                      
                    </div>
                    <div className='inline_inputs'>
                      <Form.Item label={t('fields.edit_order.Address')}>
                        <Input
                          value={address.address}
                          onChange={(e) => {
                            setCustomer({
                              ...customer,
                              billing_address_array: [
                                ...customer?.billing_address_array?.filter((i) => i._id !== address._id),
                                { ...address, address: e.target.value }
                              ]
                            })
                          }}
                        />
                      </Form.Item>
                      <Form.Item label={t('fields.edit_order.City')}>
                        <Input
                          value={address.city}
                          onChange={(e) => {
                            setCustomer({
                              ...customer,
                              billing_address_array: [
                                ...customer?.billing_address_array?.filter((i) => i._id !== address._id),
                                { ...address, city: e.target.value }
                              ]
                            })
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className='inline_inputs'>
                      <Form.Item label={t('fields.edit_order.Postal Code')}>
                        <Input
                          value={address.postal_code}
                          onChange={(e) => {
                            setCustomer({
                              ...customer,
                              billing_address_array: [
                                ...customer?.billing_address_array?.filter((i) => i._id !== address._id),
                                { ...address, postal_code: e.target.value }
                              ]
                            })
                          }}
                        />
                      </Form.Item>
                      <Form.Item label={t('fields.edit_order.Country')}>
                        <Select
                          showSearch
                          value={address.country}
                          optionFilterProp="children"
                          onChange={(e) => {
                            setCustomer({
                              ...customer,
                              billing_address_array: [
                                ...customer?.billing_address_array?.filter((i) => i._id !== address._id),
                                { ...address, country: e }
                              ]
                            })
                          }}>
                          {countryList?.map((country) =>
                              <Option value={country?.code}>{country?.code + " - " + country?.name}</Option>)}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </Form.Item>
              ))}
              {customer?.billing_address_array.filter((address) => address.isCredit === true).length === 0 && (
                <Button
                  type="primary"
                  className="button-margin calculate-button"
                  onClick={() => {
                    setCustomer({
                      ...customer,
                      billing_address_array: [...customer?.billing_address_array, { company_type: "", billing_name: "", address: "", postal_code: "", city: "", country: "", corporate_identity_number: "", tax_office: "", isCredit: true, isEditable: false } ],
                    })
                  }}
                >
                Add Store Billing Address
              </Button>
              )}
            </Form.Item>

          </div>
        </Form.Item>

      {customer?.customer_details ? (

        <Form.Item label="Business Details">
          <div className='details-container box'>
            <div className='detail-info-row' >
     
              {customer?.customer_details ?
                Object.keys(customer.customer_details)
                  .filter(key => detailMapping[key])
                  .filter(key => ["yearsInBusiness", "annualSales"].includes(key))
                  .map(key => (
                    <Form.Item
                      label={
                        (key === "annualSales" && customer.customer_details["yearsInBusiness"]?.option === "Opening soon") ? (
                          "Estimated Annual Sales"
                        ) : (
                          key.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); })
                        )
                      }

                      className='details-item'
                    >
                      <Select
                        defaultValue={customer.customer_details[key]._id}
                        onChange={(e) => {
                          const optionObject = registrationInputs?.find(({_id}) => _id === detailMapping[key])?.options?.find(r => r?._id?.toString() === e?.toString());
                          setCustomer({ ...customer, customer_details: { ...customer.customer_details, [key]: { ...optionObject, input: detailMapping[key] } } });
                        }}
                      >
                        {
                          optionssOrdered?.find(input => input._id === detailMapping[key])?.options?.map(({ _id, option }) => (
                            <Option key={_id} value={_id}>
                              {option}
                            </Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  )) : null
              }
            </div>
            <div className='detail-info-row' >
              {customer?.customer_details ?
                ["apparelBoutiqueCategory", "womenSubCategory", "storeCategory", "storeType", "otherCategory"]
                  .map(key => ({ key, snakeKey: detailMapping[key] }))
                  .map(({ key, snakeKey }) => (
                    <Form.Item
                      label={key.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/(^| )(.)| ([a-z])/g, (match, p1, p2, p3) => p1 ? p1 + p2.toUpperCase() : p2.toUpperCase() )}
                      className='details-item'
                    >
                      <Select
                        defaultValue={customer.customer_details?.[key]?._id}
                        onChange={(e) => {
                          const optionObject = registrationInputs?.find(({_id}) => _id === detailMapping[key])?.options?.find(r => r?._id?.toString() === e?.toString());
                          setCustomer({ ...customer, customer_details: { ...customer.customer_details, [key]: { ...optionObject, input: detailMapping[key] } } });
                        }}
                      >
                        {
                          optionssOrdered?.find(input => input._id === detailMapping[key])?.options?.map(({ _id, option }) => (
                            <Option key={_id} value={_id}>
                              {option}
                            </Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  )) : null
              }
            </div>
          </div>
        </Form.Item>
      ) : null}



      <Form.Item label="Conversion Fields">
        <div className='details-container box'>
          <div className="inline_three_inputs">
            <Form.Item label="Closed Lost & Unqualified">
              <Select
                value={customer?.closed_lost_unqualified}
                onChange={(e) => {
                  setCustomer({ ...customer, closed_lost_unqualified: e });
                }}
              >
                <Option value="">None</Option>
                <Option value="Closed Lost">Closed Lost</Option>
                <Option value="Unqualified">Unqualified</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Closed Lost & Unqualified Reason">
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={customer?.closed_lost_unqualified_reason}
                onChange={(e) => {
                  setCustomer({ ...customer, closed_lost_unqualified_reason: e });
                }}
              >
                {closedLostUnqualifiedReasons?.sort((a, b) => a?.localeCompare(b)).map(reason => (
                  <Option value={reason}>{reason || "None"}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Closed Lost&Unqualified Notes">
              <TextArea
                value={customer?.closed_lost_unqualified_notes}
                onChange={(e) => setCustomer({ ...customer, closed_lost_unqualified_notes: e.target.value })}
              />
            </Form.Item>
            <Form.Item label="Stage">
              <Select
                value={customer?.hubspot_stage}
                onChange={(e) => {
                  setCustomer({ ...customer, hubspot_stage: e });
                }}
              >
                <Option value="">None</Option>
                <Option value="Signed Up">Signed Up</Option>
                <Option value="Product Added to Cart">Product Added to Cart</Option>
                <Option value="Order Created">Order Created</Option>
                <Option value="Order Received">Order Received</Option>
                <Option value="Order Delivery">Order Delivery</Option>
                <Option value="Order Completed">Order Completed</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Sendpulse Status">
              <Select
                defaultValue={(typeof customer?.state?.message?.twilioStatus === 'undefined') ? true : customer?.state?.message?.twilioStatus}
                value={customer?.state?.message?.twilioStatus}
                onChange={(e) => { setCustomer({ ...customer, state: { ...customer?.state, message: { ...customer?.state?.message, twilioStatus: e } } }) }}
                options={[
                  {
                    value: true,
                    label: 'Send Messages',
                  },
                  {
                    value: false,
                    label: 'Do Not Send Messages',
                  }]}
              />
            </Form.Item>
            <Form.Item label="Priority">
              <Select
                defaultValue={customer?.priority || ""}
                value={customer?.priority}
                onChange={(e) => { setCustomer({ ...customer, priority: e }) }}
              >
                <Option value="">None</Option>
                <Option value="First">First</Option>
                <Option value="Second">Second</Option>
                <Option value="Third">Third</Option>
              </Select>
            </Form.Item>
          </div>
        </div>
      </Form.Item>

      <Form.Item label="Churn Fields">
        <div className='details-container box'>
          <div className="inline_three_inputs">
            <Form.Item label="Churn">
              <Select
                defaultValue={(typeof customer?.churn === 'undefined') ? false : customer?.churn}
                value={customer?.churn}
                onChange={(e) => { setCustomer({ ...customer, churn: e }) }}
                options={[
                  {
                    value: true,
                    label: 'True',
                  },
                  {
                    value: false,
                    label: 'False',
                  }]}
              />
            </Form.Item>
            <Form.Item label="Churn Reason">
              <Select
                value={customer?.churn_reason}
                onChange={(e) => {
                  setCustomer({ ...customer, churn_reason: e });
                }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {churnReasons?.sort((a, b) => a?.localeCompare(b)).map(reason => (
                  <Option value={reason}>{reason || "None"}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Churn Reason Notes">
              <TextArea
                value={customer?.churn_reason_notes}
                onChange={(e) => setCustomer({ ...customer, churn_reason_notes: e.target.value })}
              />
            </Form.Item>
          </div>
        </div>
      </Form.Item>

        <Form.Item label={t('fields.edit_order.Address')}>
          <div className='box'>

            <Button 
              style={{ marginRight: "10px" , alignItems: "flex-end"}}
              onClick={addNewAddressButton}
              >
              {!showNewAddressForm ?  "Add New Address" : "Cancel" }
            </Button>
            
            {customer?.address_array?.map((address, index) => (
              <Form.Item label={`Address ${index + 1}`}>
                <div className='inside-box'>
                  <div className='inline_inputs'>
                    <Form.Item label="Address">
                      <Input
                        value={address.address}
                        onChange={(e) => {
                          setCustomer({
                            ...customer,
                            address_array: [
                              ...customer?.address_array?.filter((i) => i._id !== address._id),
                              { ...address, address: e.target.value }
                            ]
                          })
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.Phone Number')}>
                      <Input
                        value={address.phone_number}
                        onChange={(e) => {
                          setCustomer({
                            ...customer,
                            address_array: [
                              ...customer?.address_array?.filter((i) => i._id !== address._id),
                              { ...address, phone_number: e.target.value }
                            ]
                          })
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className='inline_inputs'>
                    <Form.Item label={t('fields.edit_order.City')}>
                      <Input
                        value={address.city}
                        onChange={(e) => {
                          setCustomer({
                            ...customer,
                            address_array: [
                              ...customer?.address_array?.filter((i) => i._id !== address._id),
                              { ...address, city: e.target.value }
                            ]
                          })
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.State')}>
                      <Input
                        value={address.state_or_province}
                        onChange={(e) => {
                          setCustomer({
                            ...customer,
                            address_array: [
                              ...customer?.address_array?.filter((i) => i._id !== address._id),
                              { ...address, state_or_province: e.target.value }
                            ]
                          })
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className='inline_inputs'>
                    <Form.Item label={t('fields.edit_order.Postal Code')}>
                      <Input
                        value={address.postal_code}
                        onChange={(e) => {
                          setCustomer({
                            ...customer,
                            address_array: [
                              ...customer?.address_array?.filter((i) => i._id !== address._id),
                              { ...address, postal_code: e.target.value }
                            ]
                          })
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.Country')}>
                      <Select
                        showSearch
                        value={address.country}
                        optionFilterProp="children"
                        onChange={(e) => {
                          setCustomer({
                            ...customer,
                            address_array: [
                              ...customer?.address_array?.filter((i) => i._id !== address._id),
                              { ...address, country: e }
                            ]
                          })
                        }}>
                        {countryList?.map((country) =>
                            <Option value={country?.code}>{country?.code + " - " + country?.name}</Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </Form.Item>
            ))}

            {showNewAddressForm ? <div className='inside-box' style={{"marginTop" : '10px'}}>
              <Form.Item label="Add New Address">
                  <div className="inline-inputs">
                    <Form.Item label={t('fields.edit_order.Address')}>
                      <Input
                        
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.Phone Number')}>
                      <Input
                        
                      />
                    </Form.Item>
                  </div>
                  <div className="inline-inputs">
                    <Form.Item label={t('fields.edit_order.City')}>
                      <Input
                        
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.State')}>
                      <Input
                        
                      />
                    </Form.Item>
                  </div>
                  <div className="inline-inputs">
                    <Form.Item label={t('fields.edit_order.Postal Code')}>
                      <Input
                        
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.Country')}>
                      <Input
                        
                      />
                    </Form.Item>
                  </div>
              </Form.Item>
            </div> : null}
            
            
          </div>
        </Form.Item> 

        

        <Form.Item label="Billing Addresses">
          <div className='box'>

            <Button 
              style={{ marginRight: "10px" , alignItems: "flex-end"}}
              onClick={addNewBillingAddressButton}
            >
              {!showNewBillingAddressForm ?  "Add New Billing Address" : "Cancel" }
            </Button>
            {customer?.billing_address_array?.map((address, index) => (
              <Form.Item label={`Billing Address ${index + 1}`}>
                <div className='inside-box'>
                  <div className='inline_inputs'>
                    <Form.Item label={t('fields.edit_order.Billing Name')}>
                      <Input
                        value={address.billing_name}
                        onChange={(e) => {
                          setCustomer({
                            ...customer,
                            billing_address_array: [
                              ...customer?.billing_address_array?.filter((i) => i._id !== address._id),
                              { ...address, billing_name: e.target.value }
                            ]
                          })
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.Company Type')}>
                        <Select
                        value={address.company_type}
                        onChange={(e) => {
                          setCustomer({
                            ...customer,
                            billing_address_array: [
                              ...customer?.billing_address_array?.filter((i) => i._id !== address._id),
                              { ...address, company_type: e }
                            ]
                          })
                        }}
                        >
                          <Option value="Individual">Individual</Option>
                          <Option value="Company">Company</Option>

                        </Select>
                    </Form.Item>
                    
                  </div>
                  <div className='inline_inputs'>
                    <Form.Item label={t('fields.edit_order.VAT Number')}>
                      <Input
                        value={address.corporate_identity_number}
                        onChange={(e) => {
                          setCustomer({
                            ...customer,
                            billing_address_array: [
                              ...customer?.billing_address_array?.filter((i) => i._id !== address._id),
                              { ...address, corporate_identity_number: e.target.value }
                            ]
                          })
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.Tax Office')}>
                      <Input
                        value={address.tax_office}
                        onChange={(e) => {
                          setCustomer({
                            ...customer,
                            billing_address_array: [
                              ...customer?.billing_address_array?.filter((i) => i._id !== address._id),
                              { ...address, tax_office: e.target.value }
                            ]
                          })
                        }}
                      />
                    </Form.Item>
                    
                  </div>
                  <div className='inline_inputs'>
                    <Form.Item label={t('fields.edit_order.Address')}>
                      <Input
                        value={address.address}
                        onChange={(e) => {
                          setCustomer({
                            ...customer,
                            billing_address_array: [
                              ...customer?.billing_address_array?.filter((i) => i._id !== address._id),
                              { ...address, address: e.target.value }
                            ]
                          })
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.City')}>
                      <Input
                        value={address.city}
                        onChange={(e) => {
                          setCustomer({
                            ...customer,
                            billing_address_array: [
                              ...customer?.billing_address_array?.filter((i) => i._id !== address._id),
                              { ...address, city: e.target.value }
                            ]
                          })
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className='inline_inputs'>
                    <Form.Item label={t('fields.edit_order.Postal Code')}>
                      <Input
                        value={address.postal_code}
                        onChange={(e) => {
                          setCustomer({
                            ...customer,
                            billing_address_array: [
                              ...customer?.billing_address_array?.filter((i) => i._id !== address._id),
                              { ...address, postal_code: e.target.value }
                            ]
                          })
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.Country')}>
                      <Select
                        showSearch
                        value={address.country}
                        optionFilterProp="children"
                        onChange={(e) => {
                          setCustomer({
                            ...customer,
                            billing_address_array: [
                              ...customer?.billing_address_array?.filter((i) => i._id !== address._id),
                              { ...address, country: e }
                            ]
                          })
                        }}>
                        {countryList?.map((country) =>
                            <Option value={country?.code}>{country?.code + " - " + country?.name}</Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </Form.Item>
            ))}

            {showNewBillingAddressForm ? <div className='inside-box' style={{"marginTop" : '10px'}}>
              <Form.Item label="Add New Billing Address">
                  <div className="inline-inputs">
                    <Form.Item label={t('fields.edit_order.Billing Name')}>
                      <Input
                        
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.Company Type')}>
                      <Input
                        
                      />
                    </Form.Item>
                  </div>

                  <div className="inline_inputs">
                    <Form.Item label={t('fields.edit_order.VAT Number')}>
                      <Input
                        
                      />
                    </Form.Item>

                    <Form.Item label={t('fields.edit_order.Tax Office')}>
                      <Input
                        
                      />
                    </Form.Item>
                  </div>

                  <div className="inline-inputs">
                    <Form.Item label={t('fields.edit_order.Address')}>
                      <Input
                        
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.City')}>
                      <Input
                        
                      />
                    </Form.Item>
                  </div>
                  <div className="inline_inputs">
                    <Form.Item label={t('fields.edit_order.Postal Code')}>
                      <Input
                        
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.Country')}>
                      <Input
                        
                      />
                    </Form.Item>
                  </div>
              </Form.Item>
            </div> : null}


          </div>
        </Form.Item>

    </div>
  )
};

export default PersonalInfo 