import React, { useState, useEffect } from 'react';
import { Button, Select, Form } from 'antd';

import { Pagination, CreditApplication } from '../../components';

import { fetchCreditApplications } from '../../redux/creditApplicationsSlice';

import './CreditApplications.css';

function CreditApplications() {
  const { Option } = Select;
  const [form] = Form.useForm();

  const [filterLoading, setFilterLoading] = useState(false);
  const [sortLoading, setSortLoading] = useState(false);

  const [filter, setFilter] = useState({
    filter: {
      status: 'In Progress',
    },
    sort: { createdAt: -1 },
    page: 1,
    limit: 100
  });
  const [applications, setApplications] = useState([]);

  useEffect(() => {
    fetchCreditApplications(filter)
      .then((res) => {
        setApplications(res);
        setFilterLoading(false);
        setSortLoading(false);
      })
      .catch(() => {
        setFilterLoading(false);
        setSortLoading(false);
      });
  }, [filter]);

  return (
    <div className="list-customers-container">
      <div className="list-customers-nav">
        <h2>Credit Applications</h2>
        <div>
          <Button type="text">Export</Button>
          <Button type="text">Import</Button>
        </div>
      </div>
      <div className="list-application-filter">
        <Form
          className="application-filter-form"
          layout="inline"
          form={form}
          initialValues={{
            sort: filter?.sort?.createdAt,
            status_filter: filter?.filter?.status,
          }}
        >
          <Form.Item name="status_filter">
            <Select
              onChange={(e) => {
                setFilterLoading(true);
                setFilter({ ...filter, filter: { ...filter?.filter, status: e } });
              }}
              style={{ width: '150px' }}
              loading={filterLoading}
            >
              <Option value="Approved">Approved</Option>
              <Option value="Disapproved">Disapproved</Option>
              <Option value="In Progress">In Progress</Option>
              <Option value="On Hold">On Hold</Option>

            </Select>
          </Form.Item>

          <Form.Item name="sort">
            <Select
              onChange={(e) => {
                setSortLoading(true);
                setFilter({ ...filter, sort: { ...filter?.sort, createdAt: e } });
              }}
              loading={sortLoading}
            >
              <Option value={-1}>New to Old</Option>
              <Option value={1}>Old to New</Option>
            </Select>
          </Form.Item>
        </Form>
      </div>

      <div className="list-applications-table-head">
        <div className="list-applications-table-check">*</div>
        <div className="list-applications-table-first">First Name</div>
        <div className="list-applications-table-last">Last Name</div>
        <div className="list-applications-table-email">Email</div>
        <div className="list-applications-table-phone">Phone Number</div>
        <div className="list-applications-table-status">Application Status</div>
        <div className="list-applications-table-date">Application Date</div>
      </div>

      {applications.map((item) => (
        <CreditApplication key={item._id} item={item} />
      ))}

      <div>
        <Pagination filter={filter} setFilter={setFilter} />
      </div>
    </div>
  );
}

export default CreditApplications;
