import React, { useState } from "react";
import { useParams, useNavigate} from "react-router-dom";
import { useSelector } from "react-redux";
import { Form, Input, Spin, Button} from "antd";
import { useTranslation } from "react-i18next";
import { fetchSupplierInvoiceByUniqueId } from "../../redux/supplierInvoiceSlice";
import { FileViewer } from "../../components";
import { loncaFormatDate } from "../../helpers";
import "./EditSupplierInvoice.css";
import { useEffect } from "react";

const EditSupplierInvoice = () => {
  const { t } = useTranslation("translation");
  const { selectedLanguage } = useSelector((state) => state.user);
  const { handle, vendor_id } = useParams();
  const [loading, setLoading] = useState(false);

  const [invoiceCartItems, setInvoiceCartItems] = useState([]);
  const [code, setCode] = useState("");
  const [fileList, setFileList] = useState("");
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    if (handle !== "new") {
      setCode(handle);
      form.setFieldsValue({ code: handle });
    }
  }, [handle]);

  useEffect(() => {
    if (handle !== "new") {
      setLoading(true);
      fetchSupplierInvoiceByUniqueId(handle)
        .then((res) => {
          setInvoiceCartItems(res?.data?.invoice_item);
          setFileList(res?.data?.images);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [handle]);

  return (
    <div className="invoices-container">
          <div className="cancel-btn">
              <Button type="secondary" onClick={() => navigate(-1)}>
                Cancel
              </Button>
            </div>
      <Form form={form} className="invoice-form" layout="vertical">
        <Form.Item label={t(`fields.invoice.Code`)} name="code">
          <Input
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
            }}
            disabled
          ></Input>
        </Form.Item>
      </Form>
      <div className="inputs-invoice">
        {loading && <Spin tip={t(`placeholders.Loading Invoice...`)} />}
        <div className="invoice-image-container">
          <h3 className="invoice-title">{t("fields.invoice.Invoice Files")}</h3>
          <FileViewer fileList={fileList} />
        </div>
        <div className="all-invoice-items-container">
          <h3 className="invoice-title">{t("fields.invoice.Invoice Items")}</h3>
          {invoiceCartItems?.map((item, index) => {
            return (
              <div className="invoice-item-container" key={item._id + index}>
                <div className="invoice-item-image-container">
                  <img
                    className="invoice-item-image"
                    src={item?.main_image}
                    alt="product"
                  ></img>
                </div>
                <div className="invoice-product-info">
                  <div className="inline-info-three">
                    <div className="invoice-item-info-item product-id">
                      <span className="product-info-label">
                        {t(`fields.product.unique_id`)}
                      </span>
                      <input
                        className={`ant-input`}
                        value={item?.unique_order_id}
                        disabled
                      />
                    </div>
                    <div className="invoice-item-info-item invoice-product-name">
                      <span>
                        {t(`fields.product.Stock Code`)}
                        <input
                          className={`ant-input`}
                          placeholder="Stock Code"
                          value={item?.supplier_stock_code}
                          disabled
                        />
                      </span>
                    </div>

                    <div className="invoice-item-info-item invoice-product-name">
                      <span>
                        {t(`fields.product.Name Short`)}
                        <input
                          className={`ant-input`}
                          placeholder="Name"
                          value={selectedLanguage?.code === "tr" ? (item?.names?.tr || item?.name_tr) :  (item?.names?.en || item?.name)}
                          disabled
                        />
                      </span>
                    </div>
                    </div>
                    <div className="inline-info-three">
                      <div className="invoice-item-info-item product-quantity">
                        <span className="product-info-label">
                          {t(`fields.order_products.Quantity`)}
                        </span>
                        <input
                          className="ant-input"
                          placeholder="Quantity"
                          value={item?.quantity}
                          disabled
                        />
                      </div>
                      <div className="invoice-item-info-item product-count">
                        <span className="product-info-label">
                          {t(`fields.order_products.Item Count`)}
                        </span>
                        <input
                          className="ant-input"
                          placeholder="Item Count"
                          value={item?.item_count}
                          disabled
                        />
                      </div>
                      <div className="invoice-item-info-item product-payment">
                          <span>
                            {t(`fields.order_products.Payment At`)}
                            <input
                              className={`ant-input`}
                              placeholder="Order Date"
                              value={loncaFormatDate(item?.payment_at)}
                              disabled
                            />
                          </span>
                        </div>
                    </div>
              
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default EditSupplierInvoice;
