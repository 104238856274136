import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCoupon } from '../../redux/couponSlice';
import { fetchAllExchangeRates } from '../../redux/exchangeRatesSlice';

import './Coupons.css';

import { Button, Select, Form } from 'antd';
import { Coupon, LocalSearch, EditCoupon } from '../../components';

function Coupons() {
  const [filter, setFilter] = useState(() => {});
  const { Option } = Select;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { coupons } = useSelector((state) => state.coupon)

  const [coupon, setCoupon] = useState(null);
  const [newCoupon, setNewCoupon] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    if(!showForm) setAddNew(false);
  }, [showForm])

  useEffect(() => {
    dispatch(fetchCoupon(filter))
  }, [dispatch, filter]);

  useEffect(() => {
		fetchAllExchangeRates()
		  .then(res => {
			  setCurrencies(res?.data);
		  })
	}, [])

  if(!showForm) return (
    <div className="list-coupons-container">
      <div className="list-coupons-nav">
        <h2>Coupons</h2>
        <div>
          <Button type="text">Export</Button>
          <Button type="text">Import</Button>
          <Button type="text" onClick={() => {
            setAddNew(true);
            setShowForm(true);
          }}>Add New Coupon</Button>
        </div>
      </div>
      <div className="list-coupons-filter">
        <div className='search'>
          <LocalSearch items={coupons} type="coupon" setShowForm={setShowForm} setCoupon={setCoupon}/>
        </div>
        <Form
          form={form}
          className="coupon-filter-form"
          layout="inline"
          initialValues={{
            status: "all",
            type: "all",
          }}
        >
          <Form.Item name="status">
            <Select
              onChange={(e) => {
                form.setFieldsValue({
                  type: "all"
                })
                if (e === 'all') {
                  const newFilter = { ...filter };
                  delete newFilter.status;
                  setFilter(newFilter);
                  return;
                }
                setFilter((state) => ({ status: e }));
              }}
              style={{ width:"120px" }}
            >
              <Option value="all">All Status</Option>
              <Option value="Active">Active</Option>
              <Option value="Passive">Passive</Option>
              <Option value="Archived">Archived</Option>
            </Select>
          </Form.Item>
          <Form.Item name="type">
            <Select
              onChange={(e) => {
                form.setFieldsValue({
                  status: "all"
                })
                if (e === 'all') {
                  const newFilter = { ...filter };
                  delete newFilter.discount_type;
                  setFilter(newFilter);
                  return;
                }
                setFilter((state) => ({ discount_type: e }));
              }}
              style={{ width:"120px" }}
            >
              <Option value="all">All Type</Option>
              <Option value="Percentage">Percentage</Option>
              <Option value="Absolute">Absolute</Option>
            </Select>
          </Form.Item>
        </Form>
      </div>
      <div className="list-coupons-table-head">
        <div className="list-coupons-table-check">*</div>
        <div className="list-coupons-table-coupon">Coupon</div>
        <div className="list-coupons-table-status">Status</div>
        <div className="list-coupons-table-type">Type</div>
        <div className="list-coupons-table-amount">Amount</div>
        <div className="list-coupons-table-start">Start Date</div>
        <div className="list-coupons-table-end">End Date</div>
      </div>
      {coupons.map((item) => (
        <Coupon key={item._id} item={item} setShowForm={setShowForm} setCoupon={setCoupon} currencies={currencies}/>
      ))}
    </div>
  );

  if(showForm) return (
    <EditCoupon coupon={addNew ? newCoupon : coupon} setCoupon={addNew ? setNewCoupon : setCoupon} setShowForm={setShowForm} addNew={addNew}/>
  );
}

export default Coupons;
