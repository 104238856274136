import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUrlFromState } from './userSlice';
import store from './store'

export const listOrders = async ({ filter, mode, cancelToken = null }) => {
  const url = getUrlFromState(store)
  return axios.post(`${url}/order/list-for-admin?mode=${mode}`, { filter }, { cancelToken: cancelToken });
}

export const listOrdersToState = createAsyncThunk('order/listOrdersToState', async ({ filter, mode, cancelToken = null }) => {
  const { data } = await listOrders({ filter, mode, cancelToken });
  return data;
});

export const fetchOrderAggregate = ({ filter, mode = "", cancelToken = null }) => {
  const requestBody = {
    filter,
    mode,
  };
  const url = getUrlFromState(store)

  return axios.post(`${url}/order/aggregate-order-for-admin`, requestBody, {
    cancelToken: cancelToken,
  });
};

export const fetchCreate = (order) => {
  const url = getUrlFromState(store)
  return axios.post(`${url}/order/create`, { order });
}

export const fetchUpdate = async (id, update, changedCartItems, beforeUpdateItems, forceUpdate, conflictCheckOnly) => {
  const url = getUrlFromState(store)

  const { data } = await axios.patch(`${url}/order/one/${id}`, { update, changedCartItems, beforeUpdateItems, forceUpdate, conflictCheckOnly, isAdmin: true });
  return data;
}

export const fetchDelete = (id) => {
  const url = getUrlFromState(store)
  return axios.delete(`${url}/order/one/${id}`);
}

export const fetchCalculatePrices = (order, isPaymentChanged) => {
  const url = getUrlFromState(store)
  return axios.post(`${url}/order/one/calculate`, { order, isPaymentChanged });
}

export const fetchCalculateAndUpdate = async (changedItems, beforeUpdateOrders = null, forceUpdate = false, conflictCheckOnly = true) => {
  const url = getUrlFromState(store)
  const { data } = await axios.post(`${url}/order/calculate-update`, { changedItems, beforeUpdateOrders, forceUpdate, conflictCheckOnly, isAdmin: true });
  return data;
};

export const fetchOptimalShipmentChoice = (desi, cargoCompany, addressInformation, rateUsdBuying) => {
  const url = getUrlFromState(store)
  return axios.post(`${url}/order/one/choose-shipment`, { desi, cargoCompany, addressInformation, rateUsdBuying });
}

export const fetchCargoCosts = async (desi, cargoCompanies, addressInformation, rateUsdBuying, orderCargoCompany) => {
  const url = getUrlFromState(store)
  const { data } = await axios.post(`${url}/order/cargo-costs`, { desi, cargoCompanies, addressInformation, rateUsdBuying, orderCargoCompany });
  return data;
}

export const fetchCreateEnglishInvoice = ({ orders, mode }) => {
  const url = getUrlFromState(store)
  return axios.post(`${url}/order/create-english-invoice`, { orders, mode });
}

export const createParasutInvoice = ({ orders, mode }) => {
  const url = getUrlFromState(store)
  return axios.post(`${url}/order/create-parasut-invoice`, { orders, mode });
}

export const updateReadyBulkOrders = async ( { orderIds, shipmentDate, billOfLading, bulkCargoCompany, shipmentCost, shipmentCostLocal, shipmentDesi, currency, invoice } ) => {
  const url = getUrlFromState(store);
  const data = await axios.patch(`${url}/order/update-bulk-orders`, { orderIds: orderIds, shipmentDate: shipmentDate, billOfLading: billOfLading, bulkCargoCompany: bulkCargoCompany, shipmentCost, shipmentCostLocal, shipmentDesi, currency, invoice });

  return data;
}

export const createShipment = async (orderId) => {
  const url = getUrlFromState(store)
  const { data } = await axios.post(`${url}/order/create-shipment`, { orderId });
  return data;
}

export const createSalesAgreement = async (orderId) => {
  const url = getUrlFromState(store)
  const { data } = await axios.post(`${url}/order/sales-agreement-admin`, { orderId });
  return data;
}

export const fetchPendingUpdate = (orderId, status) => {
  const url = getUrlFromState(store)
  return axios.patch(`${url}/order/pending-update`, { orderId, status });
}

export const fetchVendorAutoOrder = (order) => {
  const url = getUrlFromState(store)
  return axios.post(`${url}/order/vendor-auto`, { order });
}  

export const updateOrderAddress = (address, orderId, type) => {
  const url = getUrlFromState(store)
  return axios.patch(`${url}/order/update-address-admin`, { address, orderId, type });
}

export const fetchProcessDelayedItems = () => {
  const url = getUrlFromState(store)
  return axios.patch(`${url}/order/process-delayed-items`);
}

const orderSlice = createSlice({
  name: 'order',
  initialState: {
    orders: [],
    count: 0,
    status: {
      listOrdersToState: 'idle',
    },
    error: null,
  },
  reducers: {
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
  },
  extraReducers: {
    [listOrdersToState.pending]: (state) => {
      state.status.listOrdersToState = 'pending';
    },
    [listOrdersToState.fulfilled]: (state, action) => {
      state.status.listOrdersToState = 'succeeded';
      state.orders = action.payload.data?.length > 0 ? action.payload.data : [];
      state.count = action.payload.data?.length > 0 ? action.payload.totalCount[0].count : 0;
    },
    [listOrdersToState.rejected]: (state, action) => {
      state.status.listOrdersToState = 'rejected';
      state.error = action.payload;
    },
  },
});

export const { setOrders } = orderSlice.actions;
export default orderSlice.reducer;
