import React, { useEffect, useState } from "react";
import "./AddExchange.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Form, Input, Select, notification } from "antd";
import { createOrderExchange, fetchReasons, fetchOrderProductsForRefund } from "../../redux/refundSlice";
import { ResponsiveImage } from "../../components";
import { handleAddToListHelper, handleRemoveExchangeProductHelper, showItemCountNotSeries } from "./functions/helper";

const AddExchange = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { order } = location.state || {};
    const [refundReasons, setRefundReasons] = useState([]);
    const [selectedExchangeProducts, setSelectedExchangeProducts] = useState([]);
    const [exchanges, setExchanges] = useState([]); 
    const [selectedExchangeProduct, setSelectedExchangeProduct] = useState({});
    const [refundableProducts, setRefundableProducts] = useState([]);
    const [itemCount, setItemCount] = useState(0);
    const [seriesCount, setSeriesCount] = useState(0);
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [orderForm] = Form.useForm();
    const orderFormInitialValues = {
        newproduct: undefined,
        refundreason: undefined,
        itemcount: undefined,
        seriesamount: undefined
    }
  
    useEffect(() => {
        fetchOrderProductsForRefund(order?._id)
        .then(res => {
          if(res?.data){
            setRefundableProducts(res?.data?.filter(i => i?.order_status !== "StockOut"));
          }
        }).catch((err) => {
          console.error(`Errror fetching products: ${err}`);
        });
    }, [order])

    useEffect(() => {
        fetchReasons().then(res => {
            setRefundReasons(res?.data); 
        }).catch(err => {
            console.error(err);
        });
    }, []);

    const handleProductChange = (e) => {
        const productId = e.split('*')[0];
        const series = e.split('*')[1];
        const selectedProduct = refundableProducts.find(item => item?.product === productId && item?.series === series);
        setSelectedExchangeProduct({
            ...selectedExchangeProduct, 
            product: productId, 
            series : selectedProduct?.series,
            product_image: selectedProduct?.product_image,
            product_name: selectedProduct?.product_name,
            is_misfire: selectedProduct?.is_misfire,
            refund_reason: null,
            item_count: null,
            series_amount: null,
            free_return_available: selectedProduct?.free_return_available
        });
        setItemCount(selectedProduct?.item_count)
        setSeriesCount(selectedProduct?.quantity)
        orderForm.setFieldsValue({
            refundreason: undefined,
            itemcount: undefined,
            seriesamount: undefined
        });
    }

    const handleAddToList = () => {
        const isAdded = exchanges?.some(exchange => exchange?.product_id === selectedExchangeProduct?.product && exchange?.series === selectedExchangeProduct?.series);
        const productExists = !!selectedExchangeProduct?.product;
        const refundReasonExists = !!selectedExchangeProduct?.refund_reason;
        const refundSeriesAmountOrItemCountExists = showItemCountNotSeries(selectedExchangeProduct) ? !!selectedExchangeProduct?.item_count : !!selectedExchangeProduct?.series_amount;

        if(!productExists) {
            return notification["error"]({
                message: "Select a Product!"
            });
        }

        if(!refundReasonExists) {
            return notification["error"]({
                message: "Select a Reason!"
            });
        }

        if(!refundSeriesAmountOrItemCountExists) {
            return notification["error"]({
                message: "Select a Series Amount/Item Count!"
            });
        }

        if(isAdded) {
            return notification["error"]({
                message: "This product is already added to the Product List!"
            });
        }

        try {
            setSelectedExchangeProducts([
                ...selectedExchangeProducts,
                selectedExchangeProduct
            ]);
            
            handleAddToListHelper(order, selectedExchangeProduct, exchanges, setExchanges, refundableProducts, setRefundableProducts)
            setSelectedExchangeProduct({})
    
            orderForm.setFieldsValue(orderFormInitialValues);
            notification["success"]({
                message: "Product successfully added to the List!"
            });
            
        } catch (error) {
            notification["error"]({
                message: "Error adding product to the List!"
            });
        }

    }

    const handleSubmit = () => {
        if(exchanges?.length === 0) {
            return notification["error"]({
                message: "Add one product at least to create!"
            });
        }
        setIsSaveLoading(true);
        const payload = {
            carts: exchanges,
            orderId: order?._id,
            isAdmin: true,
            isNewExchangeFromAdmin: true
        }
        createOrderExchange(payload).then(res => {
            notification["success"]({
                message: "Created Successfully!"
            })
            setTimeout(() => {
                navigate(`../orders/${order?._id}`);
                
            }, 1000);

        }).catch(error => {
            notification["error"]({
                message: "Error Creating Exchange!"
            })
            setIsSaveLoading(false);
        })
    }

    const handleCancel = () => {
        navigate(`../orders/${order?._id}`);
    }

    const handleRemoveExchangeProduct = (productId) => {
        handleRemoveExchangeProductHelper(productId, selectedExchangeProducts, setSelectedExchangeProducts, exchanges, setExchanges);
    }

    const handleIsInList = (item) => {
        !(selectedExchangeProducts?.some(exchangeProduct => exchangeProduct?.product === item?.product))

        if(item?.is_misfire) {
            return (selectedExchangeProducts?.some(exchangeProduct => exchangeProduct?.product === item?.product && exchangeProduct?.series === item?.series));
        } else {
            return (selectedExchangeProducts?.some(exchangeProduct => exchangeProduct?.product === item?.product));
        }
    }
    
    return (
        <div className="add-exchange-main-container">
            <div className="add-exchange-header-container">
                <h2>Create New</h2>
                <div className="add-exchange-header-button-container">
                    <Button
                        type="primary"
                        onClick={handleSubmit}
                        loading={isSaveLoading}
                    >
                        Save
                    </Button>

                    <Button
                        type="secondary"
                        onClick={handleCancel}
                        loading={isSaveLoading}
                    >
                        Cancel
                    </Button>
                </div>
            </div>

            <>
                <Form layout="vertical">
                    <div className="add-exchange-body-row-container">
                        <Form.Item label=" Unique Order Id">
                            <Input value={order?.unique_order_id} disabled />
                        </Form.Item>
                        <Form.Item label="Region">
                            <Input value={order?.region} disabled />
                        </Form.Item>
                        <Form.Item label="Customer Name">
                            <Input value={order?.customer_name} disabled />
                        </Form.Item>
                        <Form.Item label="Customer Email">
                            <Input value={order?.customer?.email} disabled />
                        </Form.Item>
                    </div>
                </Form>
            </>

            <>
                <Form
                    layout="vertical"
                    form={orderForm}
                    initialValues={orderFormInitialValues}
                >

                    <div className="add-exchange-add-product-container">
                        <div className="add-exchange-add-product-header">
                            <h3>Add Product</h3>
                            <Button
                                type="primary"
                                onClick={handleAddToList}
                            >
                                Add to List
                            </Button>
                        </div>
                        <div className="inline-inputs-refund-margin">

                            <Form.Item label="Product" name="newproduct">
                                <Select
                                    style={{ width: "100%" }}
                                    className="custom-product-selector"
                                    value={selectedExchangeProduct?.product}
                                    onChange={handleProductChange}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => {
                                        const children = option?.children?.props?.children;
                                        const textChild = children?.find(c => c?.props?.className === "add-product-selection-name");
                                        if (typeof textChild?.props?.children !== "string") return false;
                                        return (textChild?.props?.children || "")?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;
                                    }}
                                >
                                    {
                                        refundableProducts.sort((a, b) => a?.product_name?.localeCompare(b?.product_name)).map((item, index) => (
                                            !handleIsInList(item) && 
                                            <Option value={item?.product + "*" + item?.series}>
                                                <div className="add-product-selection">
                                                    <ResponsiveImage height={100} src={item?.product_image} alt="product"/>
                                                    <span className="add-product-selection-name">{item?.product_name}</span>
                                                </div>    
                                            </Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>

                            <div className="add-product-reasons">
                                <Form.Item label="Refund Reason" name="refundreason">
                                    <Select
                                        style={{
                                            width: "100%",
                                        }}
                                        value={selectedExchangeProduct?.refund_reason}
                                        onSelect={(e) => {
                                            setSelectedExchangeProduct({...selectedExchangeProduct, refund_reason: e})
                                        }}
                                        disabled={!selectedExchangeProduct?.product}
                                        placeholder={!selectedExchangeProduct?.product ? "Please Select Product" : ""}
                                    >
                                        {selectedExchangeProduct?.product && (
                                            selectedExchangeProduct?.free_return_available === false 
                                            ? refundReasons.filter(reason => !reason?.free_return_reason).sort((a, b) => a.name.localeCompare(b.name)).map(reason => (
                                                <Option key={reason?.name} value={reason?.name}>{reason?.name}</Option>
                                                ))
                                            : refundReasons.sort((a, b) => a.name.localeCompare(b.name)).map(reason => (
                                                <Option key={reason?.name} value={reason?.name}>{reason?.name}</Option>
                                                ))
                                        )}
                                    </Select>
                                </Form.Item>

                                {showItemCountNotSeries(selectedExchangeProduct) ?

                                    <Form.Item label="Item Count" name="itemcount">
                                        <Select
                                            style={{
                                                width: "100%",
                                            }}
                                            disabled={!showItemCountNotSeries(selectedExchangeProduct)}
                                            value={selectedExchangeProduct?.item_count}
                                            onChange={(e) => {
                                                setSelectedExchangeProduct({...selectedExchangeProduct, item_count: e});
                                            }}
                                        >
                                            {Array.from({length: itemCount * seriesCount}, (_, i) => i + 1).map(number => (
                                            <Option value={`${number}`}>{number}</Option>))}
                                        </Select>
                                    </Form.Item>
                                    :
                                    <Form.Item label="Series Amount" name="seriesamount">
                                        <Select
                                            style={{
                                                width: "100%",
                                            }}
                                            disabled={showItemCountNotSeries(selectedExchangeProduct)}
                                            value={selectedExchangeProduct?.series_amount}
                                            onChange={(e) => {
                                                setSelectedExchangeProduct({...selectedExchangeProduct, series_amount: e});
                                            }}
                                        >

                                        {Array.from({length: seriesCount}, (_, i) => i + 1).map(number => (
                                            <Option value={`${number}`}>{number}</Option>))}
                                        </Select>
                                    </Form.Item>
                                }
                            </div>   
                        </div> 
                    </div>
                </Form>
            </>

            <>
                <div className="add-exchange-added-products-main-container">
                    <h3>Product List</h3>
                    {selectedExchangeProducts?.length > 0 ? 
                        selectedExchangeProducts?.map((exchangeProduct) => (
                            <div className="add-exchange-added-products">
                                <ResponsiveImage height={200} src={exchangeProduct?.product_image}/>
                                <Form>
                                    <Form.Item label="Product Name">
                                        <Input value={exchangeProduct?.product_name} disabled />
                                    </Form.Item>
                                    <Form.Item label="Reason">
                                        <Input value={exchangeProduct?.refund_reason} disabled />
                                    </Form.Item>
                                    {
                                        showItemCountNotSeries(exchangeProduct) 
                                        ?
                                            <Form.Item label="Item Count">
                                                <Input value={exchangeProduct?.item_count} disabled />
                                            </Form.Item>
                                        :
                                            <Form.Item label="Series Amount">
                                                <Input value={exchangeProduct?.series_amount} disabled />
                                            </Form.Item>
                                    }
                                </Form>
                                <Button
                                    type="secondary"
                                    className="add-exchange-added-products-button"
                                    onClick={() => handleRemoveExchangeProduct(exchangeProduct?.product)}
                                >
                                    Remove
                                </Button>
                            </div>
                        ))
                    :
                        <span>No Products</span>
                    }
                </div>
            </>
        </div>
    )

}

export default AddExchange;