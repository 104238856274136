import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import store from './store'
import { getUrlFromState } from './userSlice';


export const fetchVendors = createAsyncThunk(
  'products/fetchVendors',
  async (filter) => {
    const url = getUrlFromState(store)
    const { data } = await axios.post(`${url}/vendor`, {
      filter,
    });

    return data;
  },
);

export const fetchCategories = createAsyncThunk(
  'categories/fetchCategories',
  async () => {
    const url = getUrlFromState(store)
    const { data } = await axios.get(`${url}/collection/category/all`);
    return data;
  },
);


export const fetchCreate = (vendor) => {
  const url = getUrlFromState(store)
  return axios.post(`${url}/vendor/create`, vendor);
}

export const fetchUpdateStatus = (id, status) => {
  const url = getUrlFromState(store)

  return axios.patch(`${url}/vendor/one/${id}`, {
  vendorUpdates: {
    status,
  },
  productsUpdates: {
    status,
  },
})};

export const fetchUpdate = (id, update, language) => {
  const url = getUrlFromState(store)

  return axios.patch(`${url}/vendor/basic-update/${id}`, {
  update, language
})};

export const fetchDelete = (id) => {
  const url = getUrlFromState(store)
  
  return axios.delete(`${url}/vendor/one/${id}`);
}

export const fetchShutDown = createAsyncThunk(
  'vendor/shutdown',
  async (id) => {
    try {
      const url = getUrlFromState(store)
      const response = await axios.patch(`${url}/vendor/shut-down/${id}`);
      return response.data;
    } catch (error) {
      console.log(error)
    }
  }
);

export const fetchOpenVendor = createAsyncThunk(
  'vendor/open',
  async (id) => {
    try {
      const url = getUrlFromState(store)
      const response = await axios.patch(`${url}/vendor/open/${id}`);
      return response.data;
    } catch (error) {
      console.log(error)
    }
  }
);



const vendorSlice = createSlice({
  name: 'vendor',
  initialState: {
    filter: {},
    vendors: [],
    categories: [],
    status: {
      fetchVendors: 'idle',
    },
    error: null,
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [fetchVendors.pending]: (state) => {
      state.status.fetchVendors = 'pending';
    },
    [fetchVendors.fulfilled]: (state, action) => {
      state.status.fetchVendors = 'succeeded';
      state.vendors = action.payload;
    },
    [fetchVendors.rejected]: (state, action) => {
      state.status.fetchVendors = 'rejected';
      state.error = action.payload;
    },
    [fetchCategories.fulfilled]: (state, action) => {
      state.categories = action.payload;
    }
  },
});

export const { setFilter, setVendors } = vendorSlice.actions;

export default vendorSlice.reducer;
