import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFavoriteFilter } from '../../redux/filterSlice';
import { fetchFavorite } from '../../redux/favoriteSlice';

import './Favorites.css';

import { Select, Form, Spin } from 'antd';
import { Favorite, FavoriteSearchBar, Pagination } from '../../components';

function Favorites() {
  const [favorites, setFavorites] = useState([]);
	const [loading, setLoading] = useState(true);

  const { Option } = Select;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { favoriteFilter: filter } = useSelector((state) => state.filter);

  const setFilter = useCallback((value) => {
    dispatch(setFavoriteFilter(value));
  }, [dispatch]);



  useEffect(() => {
    setLoading(true)
    fetchFavorite(filter)
      .then((res) => {
        setFavorites(res.data)
        setLoading(false)

      })
      .catch((err) => console.log(err));
  }, [filter]);

  return (
    <div className="list-favorites-container">
      <div className="list-favorites-nav">
        <h2>Favorites</h2>
      </div>
      <div className="list-favorites-filter">
        <div className='search'>
          <FavoriteSearchBar type = 'favorite'/>
        </div>
        <Form
          className='favorites-filter-form'
          layout="inline"
          form={form}
          initialValues={{
            sort: filter?.sort?.toString(),
          }}
        >
          <Form.Item name="sort">
            <Select
              onChange={(e) => {
                if (e === "all") {
                  setFilter({ ...filter, sort: {} })
                } else {
                  setFilter({ ...filter, sort:  e })
                }
              }}
              style={{ width: "150px" }}
            >
              <Option value="-1">Date: New to Old</Option>
              <Option value="1">Date: Old to New</Option>
            </Select>
          </Form.Item>
        </Form>
      </div>
      <div className="list-favorites-table-head">
        <div className="list-favorites-table-check">*</div>
        <div className="list-favorites-table-fname">First Name</div>
        <div className="list-favorites-table-lname">Last Name</div>
        <div className="list-favorites-table-items"># of Products</div>
        <div className="list-favorites-table-date">Updated Date</div>
        <div className="list-favorites-table-time">Updated Time</div>
      </div>
      {favorites.map((item) => (
        <Favorite key={item?._id} item={item} />
      ))}
      {loading &&
        <Spin />
      }
      <div>
        <Pagination filter={filter} setFilter={setFilter} />
      </div>
    </div>
  );
}

export default Favorites;
