import React from "react";
import { Input } from "antd";
import { loncaFormatDate } from "../../../helpers";
import { ExportOutlined } from '@ant-design/icons';
import "./Refund.scss";

const ExchangeOrderInfo = ({ uniqueOrderId, shipmentCountry, orderDeliveryDate, refundRequestDate, orderExchangeStatus = null, orderId }) => {

    return (

        <div className="inline-inputs-refund">
            <label>
                Order Number &nbsp;
                <ExportOutlined onClick={() => window.open(`../orders/${orderId}`)}/>
                <Input value={uniqueOrderId} disabled />
            </label>
            <label>
                Shipment Country
                <Input value={shipmentCountry} disabled />
            </label>
            <label>
                Order Delivery Date
                <Input value={loncaFormatDate(orderDeliveryDate)} disabled />
            </label>
            <label>
                Request Date
                <Input value={loncaFormatDate(refundRequestDate)} disabled />
            </label>

            { orderExchangeStatus &&
                <label>
                    Order Return Status
                    <Input value={orderExchangeStatus} disabled />
                </label>
            }
        </div>          
    );
};

export default ExchangeOrderInfo;
