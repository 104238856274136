import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { fetchDelete } from '../../redux/productSlice';
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage';
import { Select, Button, notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import './Product.css';
import { defaultImage } from '../../helpers';

function Product({ item }) {
  const [visible, setVisible] = useState(true);
  const [selected, setSelected] = useState(false);
  const navigate = useNavigate();

  const handleDelete = (e) => {
    e.stopPropagation();
    fetchDelete(item._id)
      .then((data) => {
        notification['success']({
          message: 'Deleting successful',
        });
        setVisible(false);
      })
      .catch((err) => {
        notification['error']({
          message: err.response.data.message,
        });
      });
  };

  const { Option } = Select;

  if (!visible || !item) return null;

  return (
    <div className="list-product">
      <div 
        className="list-product-item"
        onClick={(e) => {
          e.preventDefault();
          const newTab = window.open(`/product/${item?._id}`, '_blank');
          newTab.focus();
          }   
        }
      >
        <div className="list-product-item-check" onClick={(e) => e.stopPropagation()}>
          <input
            value={selected}
            onChange={() => setSelected((state) => !state)}
            type="checkbox"
          />
        </div>
        <div className="list-product-item-image" onClick={(e) => e.stopPropagation()}>
          <ResponsiveImage height={70} src={item.main_image || defaultImage} />
        </div>

        <div className="list-product-item-product">{item.names?.en}</div>
        <div className="list-product-item-status">{item.status}</div>
        <div className="list-product-item-type">{item.product_type?.names?.en}</div>
        <div className="list-product-item-tag">{item.tags[0]?.names?.en}</div>
        <div className="list-product-item-vendor">{item.vendor?.name}</div>
        <div className="list-product-item-price">${item.price}</div>
        <div 
          id="trash" 
          className={!selected ? 'visibility-hidden' : null}
          onClick={(e) => e.stopPropagation()}
        >
        </div>
      </div>
    </div>
  );
}

export default Product;
