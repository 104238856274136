import React, { useState } from 'react';
import { Form, Button, Spin, Select, notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllProductsHandle, fetchProductByHandleOrId } from "../../redux/productSlice";

const ProductGroupEditor = ({
  product,
  productGroup,
  setProductGroup,
  dispatch,
  t,
  setProductGroupDirty,
  setIsDirty,
  productGroupRemoved,
  setProductGroupRemoved
}) => {

    const [isFetchingAllProducts, setIsFetchingAllProducts] = useState(false);
    const { allProductsHandles } = useSelector((state) => state.products)
    const [productGroupInput, setProductGroupInput] = useState('');

    const handleRemoveFromGroup = (productHere) => {

        setIsDirty(true);
        setProductGroupDirty(true);
    
        if (productHere.handle === product.handle) {
          setProductGroupRemoved([...productGroup.products.map((item) => item._id)]);
          setProductGroup({ ...productGroup, products: [] });
        } else {
          setProductGroupRemoved([...productGroupRemoved, productHere._id]);
          setProductGroup({ ...productGroup, products: productGroup.products.filter((item) => item.handle !== productHere.handle) });
        }
      }

      const handleAddToGroup = (handle) => {

        fetchProductByHandleOrId({handle, mode : 'edit_product'}).then((res) => {
          if (res.data.product_group?._id) {
            notification['error']({
              message: 'Product already in another group',
            });
            return;
          }
          if (productGroup?.products?.find((item) => item.handle === res.data.handle)) {
            notification['error']({
              message: 'Product already in the group',
            });
            return;
          }
    
          setProductGroup({ ...productGroup, products: [...(productGroup?.products), res.data] })
          setIsDirty(true);
          setProductGroupDirty(true);
        }
        ).catch((err) => {
          console.log(err);
          if (err?.response?.status === 404) {
            notification['error']({
              message: 'Product for product group not found',
            });
          }
        })
      }  

    return (
        <Form.Item label='Product Group' className="product-group-box">
            <div className="product-group-container">
                {productGroup?.products
                ?.filter((item) => item?.handle !== product?.handle).sort((item) => item.handle.localeCompare(product.handle))
                ?.map((item) => (
                    <div className="product-group-item" key={item.handle} onClick={(e) => {
                    e.preventDefault();
                    window.open(`/product/${item?._id}`, "_blank")
                    }} >
                    <span className={`product-group-item-X ${item.handle === product.handle ? "important-x" : ""}`} onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        
                        if (item.handle === product.handle) {
                        if (window.confirm("This will delete the whole product group, do you want to continue?")) handleRemoveFromGroup(item);
                        } else {
                        handleRemoveFromGroup(item)
                        }
                    }}>X</span>
                    <span className="product-group-item-title">{item.names?.en}</span>
                    <span className="product-group-item-color">{item.color[0] ? item.color[0].color : 'NO COLOR MIGHT EXPLODE'}</span>
                    </div>
                ))}
            </div>
            <div className="product-group-add">
                { (!isFetchingAllProducts && allProductsHandles?.length === 0) && <Button
                onClick={() => {
                    if (isFetchingAllProducts) return;

                    setIsFetchingAllProducts(true);

                    dispatch(fetchAllProductsHandle())
                    .then(() => {
                        setIsFetchingAllProducts(false);
                    })
                }}
                >
                {t('fields.edit_order.Add new product')}
                </Button>}

                { isFetchingAllProducts && <Spin/> }

                { allProductsHandles?.length > 0 &&
                <div className="select-and-add">
                    <Select
                    onChange={(e) => { setProductGroupInput(e) }}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Add product by handle"
                    >
                    {allProductsHandles.map((product) => (
                        <Option key={product?.handle} value={product?.handle}>
                        {product?.names?.en}
                        </Option>
                    ))}
                    </Select>
                    <Button type="primary" onClick={() => handleAddToGroup(productGroupInput)}>
                    Add
                    </Button>
                </div>
                }
            </div>
        </Form.Item>
    );
};

export default ProductGroupEditor;
