import React from "react";
import { Switch } from "antd";

import "./TestModeSwitch.scss";
import { useDispatch, useSelector } from "react-redux";
import { toggleUrl } from "../../redux/userSlice";
  
const TestModeSwitch = () => {
const { environment } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const onChange = () => {
     dispatch(toggleUrl())
     window.location.reload()
    
  };

  return (
    <div className="test-mode-switch-container" onClick={() => onChange()}>
      <div className="test-mode-switch-label">Test Mode</div>
      <Switch checked={environment === 'test'} />
    </div>
  );
};

export default TestModeSwitch;
