import './LocalSearch.css';
import { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'underscore';
import { getDate } from '../../helpers';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

function LocalSearch({
  setRefresh,
  items, 
  type, 
  minDisplay = 3,
  setItems,
  setState,
  setItem,
  setShowForm
}) {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [display, setDisplay] = useState(false);
    const [searchItems, setSearchItems] = useState([]);
 
    const fields = useMemo(() => ({
      "collection": ["name"],
      "coupon": ["code"],
      "customer": ["firstName", "lastName"],
      "order": ["firstName", "lastName", "bill_of_lading", "email"],
      "cart": ["firstName", "lastName"],
      "service": ["firstName", "lastName"],
      "series": ["name"],
      "invoice": ["code"],
      "productTypes": ["name"],
      "colors": ["color"],
      "user": ["full_name"]
    }), []);

    useEffect(() => {      
      if(setRefresh && searchText === "") setRefresh(r => !r);
    }, [searchText, setRefresh]);

    useEffect(() => {
      setSearchResults([]);
      setSearchItems([]);
      if(searchText.length < minDisplay) {
        setDisplay(false);
        return;
      };
      setDisplay(false);
      let foundCount = 0;
      for (const item of items) {
        let itemWithCustomer = item;
        if(type === "order" || type === "cart" || type === "service") {
          if(!item.customer) continue
          itemWithCustomer = { _id: item._id, bill_of_lading: (item.bill_of_lading ?
                                                               item.bill_of_lading : "NaN"), ...(_.omit(item.customer,'_id')) }
        }
        let searchBase = "";
        if(!itemWithCustomer) continue;
        for(const field of fields[type]) {
          //handles empty value e.g. order without bill_of_lading
          searchBase += (itemWithCustomer[field] ? (itemWithCustomer[field].toLowerCase() + " ") : "");
        }
        if(searchBase.includes(searchText.toLowerCase())) {
          let newResult = "";
          for(const field of fields[type]) {
            newResult += itemWithCustomer[field] + " ";
          }
          if(type === "service" || type === "order") newResult += getDate(item.payment_at);
          if(foundCount < 3) setSearchResults(s => [...s, {_id: item._id, newResult}]);
          setSearchItems(s => [...s, item]);
          foundCount += 1;
        }
      }
      setDisplay(true);
    }, [searchText, items, type, fields, minDisplay]);

  return (
    <div className="search-wrapper">
      <div className="search-input">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setDisplay(false);
            setSearchText('');
          }}
        >
          <div className="search-area">
            <i className="bi bi-search search-icon"></i>
            <input
              className=""
              type="text"
              placeholder={t('placeholders.Search')}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                e.target.value.length > 0
                  ? setDisplay(true)
                  : setDisplay(false);
              }}
            />
            {display && searchResults.length > 0 ? (
              <i
                className="bi bi-x x-icon"
                onClick={() => {
                  setDisplay(false);
                  setSearchText('');
                }}
              ></i>
            ) : null}
          </div>
          {display && searchResults.length > 0 && (
            <div className="search-results">
              <ul className="search-result">
                {searchResults?.map((result) => {
                  return (
                  <li
                    key={result._id}
                    onClick={() => {
                      setDisplay(false);
                      setSearchText('');
                      if(["coupon", "invoice"].includes(type)) {
                        setItem(items.find((i) => i._id === result._id));
                        setShowForm(true);
                      } else {
                        navigate(`/${type}s/${result?._id}`)
                      }
                    }}
                  >
                    <span className="name">{result.newResult}</span>
                  </li>
                )})}
              </ul>
              <input
                className="w-100 button-primary list-all"
                type="submit"
                value={t('placeholders.List All Results')}
                onClick={() => {
                  if(setItems) setItems(searchItems);
                  if(setState) dispatch(setState(searchItems))
                  setDisplay(false);
                }}
              />
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default LocalSearch;
