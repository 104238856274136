import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCartFilter } from '../../redux/filterSlice';
import { fetchCart } from '../../redux/cartSlice';

import './Carts.css';

import { Button, Select, Form } from 'antd';
import { Cart, CartSearchBar, Pagination } from '../../components';

function Carts() {
  const [carts, setCarts] = useState([]);

  const [showForm, setShowForm] = useState(false);

  const { Option } = Select;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { cartFilter: filter } = useSelector((state) => state.filter);

  const setFilter = useCallback((value) => {
    dispatch(setCartFilter(value));
  }, [dispatch]);



  useEffect(() => {
    fetchCart(filter)
      .then((res) => {
        setCarts(res.data)
      })
      .catch((err) => console.log(err));
  }, [filter, showForm]);

  return (
    <div className="list-carts-container">
      <div className="list-carts-nav">
        <h2>Carts</h2>
        <div>
          <Button type="text">Export</Button>
          <Button type="text">Import</Button>
          <Button type="text">Add New Cart</Button>
        </div>
      </div>
      <div className="list-carts-filter">
        <div className='search'>
        <CartSearchBar type = 'cart'/>
        </div>
        <Form
          className='cart-filter-form'
          layout="inline"
          form={form}
          initialValues={{
            sort: filter?.sort?.toString(),
          }}
        >
          <Form.Item name="sort">
            <Select
              onChange={(e) => {
                if (e === "all") {
                  setFilter({ ...filter, sort: {} })
                } else {
                  setFilter({ ...filter, sort:  e })
                }
              }}
              style={{ width: "150px" }}
            >
              <Option value="-1">Date: New to Old</Option>
              <Option value="1">Date: Old to New</Option>
            </Select>
          </Form.Item>
        </Form>
      </div>
      <div className="list-carts-table-head">
        <div className="list-carts-table-check">*</div>
        <div className="list-carts-table-fname">First Name</div>
        <div className="list-carts-table-lname">Last Name</div>
        <div className="list-carts-table-cost">Total Cost</div>
        <div className="list-carts-table-items"># of cart items</div>
        <div className="list-carts-table-date">Updated Date</div>
        <div className="list-carts-table-time">Updated Time</div>
      </div>
      {carts.map((item) => (
        <Cart key={item?._id} item={item} setShowForm={setShowForm} />
      ))}
      <div>
        <Pagination filter={filter} setFilter={setFilter} />
      </div>
    </div>
  );
}

export default Carts;
