import { Form, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const SizeChartUploader = ({ chartFileList, setIsDirty, setChartFileList, handlePreview }) => {
    
  const handleChange = ({ file: updatedFile, fileList: newFileList }) => {
    setIsDirty(true);
    if (chartFileList.length < newFileList.length) {
      setChartFileList(currentList => [
        ...currentList,
        { ...updatedFile, status: 'done' },
      ]);
    } else if (chartFileList.length > newFileList.length) {
      setChartFileList(newFileList.filter((f) => f.status !== 'removed'));
    }
  };

  return (
    <Form.Item name="Size Chart" label="Size Chart">
      <Upload listType="picture-card" multiple={true} fileList={chartFileList} onPreview={handlePreview} onChange={handleChange}>
        {chartFileList?.length < 1 ? (
          <div>
            <PlusOutlined />
            <div className="plus-outlined-margin">Upload</div>
          </div>
        ) : null}
      </Upload>
    </Form.Item>
  );
};

export default SizeChartUploader;
