import React, { useState } from "react";
import "./BulkInvoicePopup.css";
import { Button, DatePicker, Form, Input, Select, notification } from "antd";
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import FormItem from "antd/lib/form/FormItem";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { loncaRound, getBase64, generateZip } from "../../../helpers";
import { fetchUploadUrl } from "../../../redux/productSlice";

const BulkInvoicePopup = ({ 
    handleBulkOperations, 
    setIsBulkInvoicePopupOpen, 
    shipmentDate, 
    setShipmentDate, 
    billOfLading, 
    setBillOfLading,
    bulkCargoCompany,
    bulkCargoCompanies,
    setBulkCargoCompany,
    numBulkOrders,
    shipmentCost,
    setShipmentCost,
    shipmentCostLocal,
    setShipmentCostLocal,
    currencies,
    currency,
    setCurrency,
    invoice,
    setInvoice,
    realInfo
}) => {

    const { t } = useTranslation("translation");
    const [buttonsLoading, setButtonsLoading] = useState(false);
    const [invoiceLink, setInvoiceLink] = useState(null);
	const [invoiceThumb, setInvoiceThumb] = useState(null);
	const [invoiceName, setInvoiceName] = useState(null);

    const handleSubmit = async () => {
        if(!shipmentDate || !billOfLading || !bulkCargoCompany || !shipmentCost || !shipmentCostLocal) {
            notification['error']({
                message: "Please provide a shipment date and/or a bill of lading!"
            });
            return;
        }

        setButtonsLoading(true);
        await handleBulkOperations("bulkShipment");
        setBillOfLading("");
        setShipmentDate(null);
        setBulkCargoCompany("");
        setIsBulkInvoicePopupOpen(false);
        setShipmentCost(0);
        setShipmentCostLocal(0);
        setInvoice(null);
    }

    const handleCancel = () => {
        setBillOfLading("");
        setShipmentDate(null);
        setBulkCargoCompany("");
        setIsBulkInvoicePopupOpen(false);
        setShipmentCost(0);
        setShipmentCostLocal(0);
        setInvoice(null);
    }

    const handleDownload = async () => {
		try {
			generateZip([invoice], invoiceName)
		} catch(err) {
			console.log(err)
		}
	}

    return (
        <Form onFinish={handleSubmit}>
            <div className="bulk-invoice-popup-container">
                <span className="bulk-invoice-popup-header">{t("fields.order_status.Create Bulk Shipment")}:</span>
                <div className="inline-three-inputs">
                    <FormItem label={t("fields.order_status.Shipment Date")} required>
                        <DatePicker
                            placeholder={t("fields.order_status.Shipment Date")}
                            showTime
                            value={shipmentDate ? moment(shipmentDate) : null}
                            onChange={(e) => {
                                setShipmentDate(e._d);
                            }}
                        />
                    </FormItem>
                    <FormItem label={t("fields.order_status.Bill of Lading (TR to NL)")} required>
                        <Input
                            placeholder={t("fields.order_status.Bill of Lading (TR to NL)")}
                            value={billOfLading}
                            onChange={(e) => setBillOfLading(e.target.value)}
                            required
                        />
                    </FormItem>
                    <FormItem label={t("fields.order_status.Real Cargo Company")} required>
                        <Select
                            onChange={setBulkCargoCompany}
                            placeholder={t("fields.order_status.Real Cargo Company")}
                        >
                            {
                                bulkCargoCompanies?.map((cargoCompany) => {
                                    return (
                                        <Option key={cargoCompany?._id} value={cargoCompany?._id}>
                                            {cargoCompany?.name}
                                        </Option>
                                    )
                                })
                            } 
                        </Select>
                    </FormItem>
                </div>
                
                <div className="inline-three-inputs">
                    <Form.Item label={t("fields.order_status.Shipment Cost") + ` (${currency?.symbol})`} required>
                        <Input
                            value={shipmentCostLocal}
                            onChange={(e) => {
                                setShipmentCost(loncaRound(e.target.value / currency?.rate_usd_buying));
                                setShipmentCostLocal(e.target.value);
                            }}
                            required
                        />
                    </Form.Item>
                    <FormItem label={t("fields.order_status.Shipment Cost") + ` ($)` } required>
                        <Input
                            placeholder={t("fields.order_status.Shipment Cost")}
                            value={shipmentCost}
                            required
                            disabled
                        />
                    </FormItem>
                    <Form.Item name="currency" label={t("fields.order_status.local_currency")}>
                        <></>
                        <Select
                            value={currency?.abbreviation}
                            onChange={(e) => {
                                const selectedCurrency = currencies?.find(c => c?.abbreviation === e);
                                setCurrency(selectedCurrency);
                            }}
                        >
                            {currencies?.map(currency => (
                                <Option value={currency?.abbreviation}>{currency?.abbreviation}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
                
                <div className="inline-three-inputs">
                    <Form.Item label={t("fields.edit_order.Rate Usd Selling") + ` (${currency?.symbol})`}>
                        <Input
                            value={`${currency?.rate_usd_buying}`}
                            disabled
                        />
                    </Form.Item>
                    <Form.Item label={t("fields.order_status.per_desi_cost") + ` ($)`}>
                        <Input
                            value={loncaRound(shipmentCost/realInfo?.desi) + "$ - " + loncaRound(shipmentCostLocal/realInfo?.desi) + `${currency?.symbol}`}
                            disabled
                        />
                    </Form.Item>
                    <Form.Item>
                        <label class="custom-file-upload">
                            <input type="file" onChange={async (e) => {
                                setInvoiceName(e.target.files[0].name)
                                setInvoiceLink(e.target.files[0]);

                                const thumb = await getBase64(e.target.files[0]);
                                setInvoiceThumb(thumb);

                                const extension = e.target.files[0]?.type === "application/pdf" ? "pdf" : "jpg";

                                fetchUploadUrl(`shipment-invoice/${e.target.files[0].name}-shipment.${extension}`, e.target.files[0]?.type)
                                    .then(async response => {
                                        await fetch(response.data, {
                                            method: "PUT",
                                            body: e.target.files[0],
                                        })
                                    
                                        let imageUrl = response.data.split('?')[0];

                                        setInvoice(imageUrl);
                                        notification['success']({
                                            message: 'Invoice uploaded to AWS successfully',
                                        });
                                    })

                            }}/>
                            <UploadOutlined />
                            {"  Upload Invoice"}
                        </label>
                        <div className="invoice-img-container">
                            <iframe title="invoice" style={{display: "none"}} id="invoice-embed" width={100} src={invoiceThumb ? invoiceThumb : `${invoice?.link}?${new Date().getTime()}`} />
                            <img alt="invoice" style={{display: "none"}} id="invoice-img" width={100} src={invoiceThumb ? invoiceThumb : `${invoice?.link}?${new Date().getTime()}`} />
                            <div>{invoiceLink ? `FILE: ${invoiceName}` : invoice?.link ? `FILE: ${invoice.link}` : ""}</div>
                        </div>

                        <Button onClick={() => handleDownload()}>
                            <DownloadOutlined />
                            Download Current Invoice
                        </Button>

                        
                    </Form.Item>
                </div>
                <div className="inline-two-inputs">
                    
                </div>
                
                
                <div className="bulk-invoice-popup-button-container">
                    <FormItem>
                        <Button type="primary" htmlType="submit" loading={buttonsLoading} disabled={numBulkOrders === 0}>
                            {t("fields.order_status.Submit")}
                        </Button>
                    </FormItem>
                    <FormItem>
                        <Button type="secondary" loading={buttonsLoading} onClick={handleCancel}>
                            {t("fields.order_status.Cancel")}
                        </Button>
                    </FormItem>
                </div>
            </div>
        </Form>
    );
}

export default BulkInvoicePopup;