import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const GenderSelector = ({product, setProduct, setIsDirty }) => {
    
    const { genders } = useSelector((state) => state.gender);

    const handleGenderChange = (e) => {
        const gender = genders.find((item) => item.handle === e);
        setProduct({ ...product, gender: [...product.gender, gender] });
        setIsDirty(true);
    };

    const deleteGender = (handle) => {
        setProduct({ ...product, gender: product.gender.filter((item) => item.handle !== handle) });
        setIsDirty(true);
    };

    return (
        <Form.Item name="gender" label="Genders">
            <Select
            onChange={handleGenderChange}
            showSearch
            optionFilterProp="children"
            placeholder="Add gender"
            >
            {genders
                ?.slice()
                .filter(
                (item1) => !product?.gender?.find((item2) => item1?.handle === item2?.handle)
                )
                .map((gender, index) => (
                <Select.Option key={gender.handle} value={gender.handle}>
                    {gender?.names?.en}
                </Select.Option>
                ))}
            </Select>
            <div className="multi-container">
            {product?.gender?.length > 0 &&
                product?.gender?.map((gender) => (
                <div className="multi-item" key={gender.handle}>
                    <CloseOutlined onClick={() => deleteGender(gender.handle)} />
                    {gender?.names?.en}
                </div>
                ))}
            </div>
        </Form.Item>
        
    );
};

export default GenderSelector;
