import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

import "./Tags.css";

import { Button } from "antd";
import { Tag, SearchBar } from "../../components";

function Tags() {
  const navigate = useNavigate()

  const { tags } = useSelector((state) => state.tags);
  const [searchText, setSearchText] = useState("");

  return (
    <div className="list-tags-container">
      <div className="list-tags-nav">
        <h2>Tags</h2>
        <div>
          <Button type="text">Export</Button>
          <Button type="text">Import</Button>
          <Button type="text" onClick={() => {navigate("/tag/new")}}>Add New Tag</Button>
        </div>
      </div>
      <div className="list-tags-filter">
        <SearchBar type="tag"/>
      </div>
      <div className="list-tags-table-head">
        <div className="list-tags-table-check">*</div>
        <div className="list-tags-table-tag">Tag</div>
        <div className="list-tags-table-tagtr">Tag TR</div>
        <div className="list-tags-table-type">Type</div>
        <div className="list-tags-table-status">Status</div>
      </div>
      {tags
        ?.filter((tag) => {
          return (
            tag.names?.en?.toLowerCase().includes(searchText.toLowerCase())
          );
        })
        ?.map((item) => (
          <Tag key={item._id} item={item} />
        ))}
    </div>
  );
}

export default Tags;
