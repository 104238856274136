import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getUrlFromState } from './userSlice';
import store from './store'

export const fetchRoles = createAsyncThunk(
  'refunds/fetchRoles',
  async () => {
    const url = getUrlFromState(store)
    const { data } = await axios.get(`${url}/role/`);

    return data;
  },
);

export const fetchRoleById =
    async (id) => {
      const url = getUrlFromState(store)
      const { data } = await axios.get(`${url}/role/${id}`);
      return data;
}

export const fetchDeleteRole =
    async (id) => {
      const url = getUrlFromState(store)
      const { data } = await axios.delete(`${url}/role/${id}`);
      return data;
}

export const fetchCreateRole =
    async (roleData) => {
      const url = getUrlFromState(store)
      const { data } = await axios.post(`${url}/role/create`, roleData);
      return data;
}

export const fetchUpdateRole =
    async (id, update) => {
      const url = getUrlFromState(store)
      const { data } = await axios.post(`${url}/role/update/${id}`, update);
  
      return data;
}
const roleSlice = createSlice({
  name: 'role',
  initialState: {
 
    roles: [],
    status: {
      fetchRefunds: 'idle',
    },
    error: null,
  },

  extraReducers: {

    [fetchRoles.pending]: (state) => {
      state.status.fetchRoles = 'pending';
    },
    [fetchRoles.fulfilled]: (state, action) => {
      state.status.fetchRoles = 'succeeded';
      state.roles = action.payload;
    },
    [fetchRoles.rejected]: (state, action) => {
      state.status.fetchRoles = 'rejected';
      state.error = action.payload;
    },

  },
});


export default roleSlice.reducer;
