import React from 'react';

const UpdateConfigEditor = ({ product, setProduct, setIsDirty }) => {
  const configAssignableFields = [
    'series',
    'status',
    'price',
    'fabric',
    'model_measurements',
    'product_measurements',
    'sample_size']
    ;

  const handleConfigChange = (field) => {
    const isFixed = product?.update_config?.[field] === 'fixed';
    setProduct({
      ...product,
      update_config: { ...product?.update_config, [field]: isFixed ? '' : 'fixed' }
    });
    setIsDirty(true);
  };

  return (
    <div>
        <p style={{ textAlign: 'left' }}>Update Configs</p>
      {configAssignableFields.map((field) => (
        <div className="checkbox-container" key={field}>
          <label>
            <input
              type="checkbox"
              onChange={() => handleConfigChange(field)}
              checked={product?.update_config?.[field] === 'fixed'}
            />
            {"Scraper not updating: " + field}
          </label>
        </div>
      ))}
    </div>
  );
};

export default UpdateConfigEditor;
