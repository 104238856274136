import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './CargoCompanies.css';

import { Button, Select } from 'antd';
import { CargoCompany } from '../../components';
import SimpleSearch from '../../components/SimpleSearch/SimpleSearch';
import { fetchCompanies } from '../../redux/cargoCompanySlice';

function CargoCompanies() {
  const { Option } = Select;
  const [cargoCompanies, setCargoCompanies] = useState([]);
  const [filter, setFilter] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = (searchText) => {
    const filteredCompanies = cargoCompanies.filter((company) => {
      return company.name.toLowerCase().includes(searchText.toLowerCase());
    });
    setSearchResults(filteredCompanies);
  };

  useEffect(() => {
    fetchCompanies({ filter: filter })
      .then(res => {
        setCargoCompanies(res)
      });
  }, [filter])
  const handleFilter = (value) => {

    if (value.express === "all") {
      // Handle the "express-all" case by removing the 'express' property
      const { express, ...rest } = filter;
      setFilter(rest);

    } else if (value.own_cargo === "all") {
      // Handle the "own_cargo-all" case by removing the 'own_cargo' property
      const { own_cargo, ...rest } = filter;
      setFilter(rest);
    } else if (value.status === "all") {
      // Handle the "status-all" case by removing the 'status' property
      const { status, ...rest } = filter;
      setFilter(rest);
    }

    else {
      setFilter({ ...filter, ...value });
    }
  }


  return (
    <div className="list-cargo-companies-container">
      <div className="list-cargo-companies-nav">
        <h2>Cargo Companies</h2>
        <div>
          <Button type="text" onClick={() => { navigate("/cargo-company/new") }}>Add New Cargo Company</Button>
        </div>
      </div>
      <div className="list-cargo-companies-filter">
        <div className='search'>
          <SimpleSearch onSearchFunction={handleSearch} dataToBeSearched={cargoCompanies} />
        </div>
        <Select
          onChange={(e) => {
            const value = JSON.parse(e);
            handleFilter(value);
          }}
          defaultValue='{"status": "all"}'
        >
          <Option value='{"status": "all"}'>Status</Option>
          <Option value='{"status": "Active"}'>Active</Option>
          <Option value='{"status": "Passive"}'>Passive</Option>
          <Option value='{"status": "Archived"}'>Archived</Option>
        </Select>
        <Select
          onChange={(value) => {

            const parsedValue = JSON.parse(value);
            handleFilter(parsedValue);
          }}
          defaultValue='{"express": "all"}'
        >
          <Option value='{"express": "all"}'>Express</Option>
          <Option value='{"express": true}'>True</Option>
          <Option value='{"express": false}'>False</Option>
        </Select>
        <Select
          onChange={(value) => {

            const parsedValue = JSON.parse(value);
            handleFilter(parsedValue);
          }}
          defaultValue='{"own_cargo": "all"}'
        >
          <Option value='{"own_cargo": "all"}'>Own Cargo</Option>
          <Option value='{"own_cargo": true}'>True</Option>
          <Option value='{"own_cargo": false}'>False</Option>
        </Select>

      </div>
      <div className="list-cargo-companies-table-head">
        <div className="list-cargo-companies-table-check">*</div>
        <div className="list-cargo-companies-table-cargo-company">Cargo Company</div>
        <div className="list-cargo-companies-table-status">Status</div>
        <div className="list-cargo-companies-table-handle">Express</div>
        <div className="list-cargo-companies-table-user">Own Cargo</div>
      </div>
      {searchResults.map((item) => (
        <CargoCompany key={item._id} item={item} />
      ))}
    </div>
  );

}

export default CargoCompanies;
