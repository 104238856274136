import axios from 'axios';
import store from './store';
import { getUrlFromState } from './userSlice';

const fetchEnums = async ({type}) => {
  const url = getUrlFromState(store)
  const {data} = await axios.post(`${url}/enums`, {type});
  return data
}

export default fetchEnums;
