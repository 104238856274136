import { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import './CreditStatuses.css';

import { Button, Select, Form, Input } from 'antd';
import { CreditStatus, Pagination } from '../../components';
import { listOrders } from '../../redux/orderSlice';
import { setCreditStatusFilter } from '../../redux/filterSlice';

function CreditStatuses() {

  const { Option } = Select;
  const [form] = Form.useForm();
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const { creditStatusFilter: filter } = useSelector((state) => state.filter);

  const [creditStatus, setCreditStatus] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchForm] = Form.useForm();

  const setFilter = useCallback((value) => {
    dispatch(setCreditStatusFilter(value));
  }, [dispatch]);

  useEffect(() => {
    listOrders({
      filter,
      mode: "admin_list"
    })
      .then(res => setCreditStatus(res?.data?.data))
      .catch(err => {
        setCreditStatus([])
        console.log(err)
      })
  }, [filter]);

  return (
    <div className="list-credit-status-container">
      <div className="list-credit-status-nav">
        <h2>Credit Status</h2>
        <div>
          <Button type="text">Export</Button>
          <Button type="text">Import</Button>
        </div>
      </div>
      <div className="list-credit-status-filter">
          <Form 
            form={searchForm} 
            onFinish={() => 
              setFilter({
                ...filter,
                search_key : searchText
              })
            }
            className="list-credit-status-search"
          >
            <Form.Item name="search">
              <Input value={searchText} placeholder={t('placeholders.Search')} onChange={(e) => setSearchText(e.target.value)} />
            </Form.Item>
          </Form>
        <Form
          className="credit-status-filter-form"
          layout="inline"
          form={form}
          initialValues={{
            filter: "allStatus"
          }}
        >
          <Form.Item name="filter">
            <Select
              onChange={(value) => {
                if (value === "allStatus") {
                  const { last_credit_payment_date, ...remainingFilter } = filter?.filter;
                  setFilter({
                    ...filter,
                    filter: {
                      ...remainingFilter,
                    },
                  });
                } else {
                  const endDate = new Date();
                  const startDate = new Date();

                  switch (value) {
                    case "moreThan14DaysPast":
                      startDate.setDate(startDate.getDate() - 14); // Start date is 14 days ago
                      setFilter({
                        ...filter,
                        filter: {
                          ...filter?.filter,
                          last_credit_payment_date: { $lte: startDate },
                        },
                      });
                      return;
                    case "between7And14DaysPast":
                      startDate.setDate(startDate.getDate() - 14);
                      endDate.setDate(endDate.getDate() - 7);
                      break;
                    case "between1And7DaysPast":
                      startDate.setDate(startDate.getDate() - 7);
                      endDate.setDate(endDate.getDate() - 1);
                      break;
                    case "1DayPast":
                      startDate.setDate(startDate.getDate() - 1);
                      break;
                    case "today":
                      endDate.setDate(endDate.getDate() + 1);
                      break;
                    case "next7Days":
                      startDate.setDate(startDate.getDate() + 1);
                      endDate.setDate(endDate.getDate() + 7);
                      break;
                    case "between7And14DaysNext":
                      startDate.setDate(startDate.getDate() + 7);
                      endDate.setDate(endDate.getDate() + 14);
                      break;
                    case "14DaysLater":
                      startDate.setDate(startDate.getDate() + 14);
                      setFilter({
                        ...filter,
                        filter: {
                          ...filter?.filter,
                          last_credit_payment_date: { $gte: startDate },
                        },
                      });
                      return;
                    default:
                      return;
                  }

                  setFilter({
                    ...filter,
                    filter: {
                      ...filter?.filter,
                      last_credit_payment_date: {
                        $gte: startDate,
                        $lte: endDate,
                      },
                    },
                  });

                }
              }}
              className='credit-status-filter-div'
              style={{ width: "150px" }}
            >
              <Option value="allStatus">All Times</Option>
              <Option value="moreThan14DaysPast">More than 14 Days Past</Option>
              <Option value="between7And14DaysPast">Between 7-14 Days Past</Option>
              <Option value="between1And7DaysPast">Between 1-7 Days Past</Option>
              <Option value="1DayPast">1 Day Past</Option>
              <Option value="today">In 24 Hours</Option>
              <Option value="next7Days">Next 1-7 Days</Option>
              <Option value="between7And14DaysNext">Next 7-14 Days</Option>
              <Option value="14DaysLater">14 Days Later</Option>

            </Select>
          </Form.Item>
        </Form>
      </div>
      <div className="list-credit-status-table-head">
        <div className="list-credit-status-table-check">*</div>
        <div className="list-credit-status-table-first">First Name</div>
        <div className="list-credit-status-table-last">Last Name</div>
        <div className="list-credit-status-table-total-credit">Total Credit</div>
        <div className="list-credit-status-table-amount-due">Credit Amount Due</div>
        <div className="list-credit-status-table-due-date">Credit Due Date</div>
      </div>
      {creditStatus?.map((item) => (
        <CreditStatus key={item._id} item={item} isCustomerPage={false}/>
      ))}
      <div>
        <Pagination filter={filter} setFilter={setFilter}/>
      </div>
    </div>
  );
}

export default CreditStatuses;
