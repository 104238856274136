import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import store from './store';
import { getUrlFromState } from './userSlice';

export const fetchExchangeRates = (filter) => {
  const url = getUrlFromState(store)
  return axios.post(
  `${url}/exchange-rate/list`,
  { filter },
)};

export const fetchAllExchangeRates = () => {
  const url = getUrlFromState(store)
  return axios.get(`${url}/exchange-rate/list`);
}

export const fetchAllExchangeRatesToState = createAsyncThunk(
  'rate/fetchAllExchangeRatesToState',
  async () => {
      const { data } =  await fetchAllExchangeRates()
      return data
  },
);


const rateSlice = createSlice({
  name: 'rate',
  initialState: {
    colors: [],
    status: {
      fetchAllExchangeRatesToState: 'idle',
    },
    error: null,
  },
  reducers: {

  },
  extraReducers: {
    [fetchAllExchangeRatesToState.pending]: (state) => {
      state.status.fetchAllExchangeRatesToState = 'pending';
    },
    [fetchAllExchangeRatesToState.fulfilled]: (state, action) => {
      state.status.fetchAllExchangeRatesToState = 'succeeded';
      state.rates = action.payload;
    },
    [fetchAllExchangeRatesToState.rejected]: (state, action) => {
      state.status.fetchAllExchangeRatesToState = 'rejected';
      state.error = action.payload;
    },
  },
});


export default rateSlice.reducer;