import React from 'react';
import './AdminRoles.scss';

import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { AdminRole } from '../../components';
import { useNavigate } from 'react-router-dom';

const AdminRoles = () => {

  const navigate = useNavigate();

  const { roles } = useSelector((s) => s.role);


  return (
    <div className="list-roles-container">
      <div className="list-roles-nav">
        <h2>Admin Roles</h2>
        <div>
          <Button onClick={() => navigate(`/roles/new`)} type="text">Add New Role</Button>
        </div>
      </div>
      <div className="list-roles-filter">
        <div className='search'>

        </div>
      </div>
      <div className="list-roles-table-head">
        <div className="list-roles-table-check">*</div>
        <div className="list-roles-table-name">Role Name</div>
        <div className="list-roles-table-tabs">Allowed Tabs</div>
      </div>
      {roles?.map((item) => (
        <AdminRole key={item._id} item={item} />
      ))}
      <div>
      </div>
    </div>
  );
}

export default AdminRoles;
