import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUrlFromState } from './userSlice';
import store from './store'

export const fetchTags = createAsyncThunk(
  'tags/fetchTags',
  async (filter) => {
    const url = getUrlFromState(store)
    const { data } = await axios.post(`${url}/tag`, {
      filter,
    });
    return data;
  },
);

export const fetchCategorizedTags = createAsyncThunk(
  'tags/fetchCategorizedTags',
  async () => {
    const url = getUrlFromState(store)
    const { data } = await axios.post(`${url}/tag/categorized-tags`);
    return data;
  },
);

export const fetchDelete = (tagId) => {
  const url = getUrlFromState(store)
  axios.delete(`${url}/tag/one/${tagId}`);
}

export const fetchUpdate = async (tagId, update) => {
  const url = getUrlFromState(store)
  const { data } = await axios.patch(`${url}/tag/one/${tagId}`, update);
  return data;
}

export const fetchCreate = async (tag) => {
  const url = getUrlFromState(store)
  const { data } = await axios.post(`${url}/tag/new`, tag);
  return data;
}

const tagSlice = createSlice({
  name: 'tag',
  initialState: {
    filter: {},
    tags: [],
    categorizedTags: {},
    allowedFilterTypeListOfCategorizedTags: {},
    parentSubtypes: {},
    status: {
      fetchTags: 'idle',
      fetchCategorizedTags: 'idle'
    },
    error: null,
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    addTag: (state, action) => {
      state.tags = [...state.tags, action.payload];
    },
    setTags: (state, action) => {
      state.tags = action.payload;
    }
  },
  extraReducers: {
    [fetchTags.pending]: (state) => {
      state.status.fetchTags = 'pending';
    },
    [fetchTags.fulfilled]: (state, action) => {
      state.status.fetchTags = 'succeeded';
      state.tags = action.payload;
    },
    [fetchTags.rejected]: (state, action) => {
      state.status.fetchTags = 'rejected';
      state.error = action.payload;
    },

    [fetchCategorizedTags.pending]: (state) => {
      state.status.fetchCategorizedTags = 'pending';
    },
    [fetchCategorizedTags.fulfilled]: (state, action) => {
      state.status.fetchCategorizedTags = 'succeeded';
      state.categorizedTags = action.payload?.categorizedTags;
      state.parentSubtypes = action.payload?.parentSubtypes;
      state.allowedFilterTypeListOfCategorizedTags = action.payload?.allowedFilterTypeListOfCategorizedTags;
    },
    [fetchCategorizedTags.rejected]: (state, action) => {
      state.status.fetchCategorizedTags = 'rejected';
      state.error = action.payload;
    },
  },
});

export const { setFilter, addTag, setTags } = tagSlice.actions;

export default tagSlice.reducer;
