import axios from "axios";

import { getUrlFromState } from "./userSlice";
import store from "./store";

export const fetchCustomers = async (query, mode) => {
  const url = getUrlFromState(store)
  return axios.post(`${url}/customer/`, { query, mode });
};

export const fetchCustomerById = (id) => {
  const url = getUrlFromState(store)
  return axios.get(`${url}/customer/one/${id}`);
};

export const fetchCustomerCartById = (id) => {
  const url = getUrlFromState(store)
  return axios.get(`${url}/customer/cart/${id}`);
};

export const fetchUpdate = (id, update) => {
  const url = getUrlFromState(store)

  return axios.patch(`${url}/customer/one/${id}`, {
    update,
  });
};
