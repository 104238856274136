import axios from 'axios';
import store from './store';
import { getUrlFromState } from './userSlice';


export const fetchUpdate = (id, update) => {
  const url = getUrlFromState(store)

  return axios.patch(`${url}/invoice/one/${id}`, {
  update,
});
}

export const fetchInvoices = (filter) => {
  const url = getUrlFromState(store)
  return axios.post(`${url}/invoice/admin`, filter);
}

export const fetchOrderInvoices = (customerId) => {
  const url = getUrlFromState(store)
  return axios.post(`${url}/invoice/admin-order`, { customerId });
}

export const fetchAllInvoices = () => {
  const url = getUrlFromState(store)
  return axios.post(`${url}/invoice/admin`);
}

export const fetchCreate = (invoice) => {
  const url = getUrlFromState(store)
  return axios.post(`${url}/invoice/`, { invoice });
}

export const fetchInvoiceByCode = (code) => {
  const url = getUrlFromState(store)
  return axios.get(`${url}/invoice/one/${code}`);
}

export const fetchDelete = (id) => {
  const url = getUrlFromState(store)
  return axios.delete(`${url}/invoice/one/${id}`);
}
