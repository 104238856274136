import React from 'react';

const CheckboxComponent = ({ type, checked, onChange }) => {
  return (
    <div className="checkbox-container">
      <label>
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
        />
        {type === 'hide_product' ? 'Hide Product in Collection Pages' : type === 'need_photo_shoot' ? 'Need Photo Shoot' : ''}
      </label>
    </div>
  );
};

export default CheckboxComponent;
