import { Form, Select } from 'antd';
import _ from 'underscore';
import { Pagination } from '../../components';
import './SupplierApplications.css';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSupplierApplications, setFilter } from '../../redux/supplierApplicationSlice';
import SupplierApplication from '../../components/SupplierApplications/SupplierApplication';

function SupplierApplications() {
  const { Option } = Select;
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const { filter, supplierApplications } = useSelector((state) => state.supplierApplications);

  const handleFilter = (payload) => {
    dispatch(setFilter(payload));
  };
  const handleSearch = (e) => {
    dispatch(setFilter({ ...filter, search: e.target.value }));
  };

  useEffect(() => {
    const updatedFilter = !filter.sort
      ? { ...filter, sort: { createdAt: -1 } }
      : filter;
  
    handleFilter(updatedFilter);
    dispatch(fetchSupplierApplications(updatedFilter));
  }, [filter, dispatch]);  

  return (
    <div className="list-applications-container">
      <div className="list-applications-nav">
        <h2>Applications</h2>
      </div>
      <div className="list-applications-filter">
        <input className="list-application-search" onChange={handleSearch} placeholder="Search" />
        <Form className='application-filter-form'
          form={form}
          layout="inline"
        >
          <Form.Item>
            <Select
              onChange={(value) => {
                if (value === 'all') {
                  handleFilter({ ...filter, filter: (_.omit(filter.filter, 'status')) });
                } else {
                  handleFilter({ ...filter, filter: { status: value } });
                }
              }}
              defaultValue='all'
            >
              <Option value='all'>Status Filter</Option>
              <Option value='Accepted'>Accepted</Option>
              <Option value='Rejected'>Rejected</Option>
              <Option value='In Progress'>In Progress</Option>
              <Option value='On Hold'>On Hold</Option>
              <Option value='Waitlist'>Waitlist</Option>
            </Select>
          </Form.Item>
        </Form>
      </div>
      <div className="list-application-table-head">
        <div className="list-application-table-applicant">Applicant</div>
        <div className="list-application-table-company">Company</div>
        <div className="list-application-table-date">Date</div>
        <div className="list-application-table-status">Status</div>
      </div>

      {supplierApplications?.map((item) => (
        <SupplierApplication key={item._id} item={item} />
      ))}
      <div>
        <Pagination filter={filter} setFilter={handleFilter} />
      </div>
    </div>
  );
}

export default SupplierApplications;
