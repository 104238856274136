import React, { useState, useEffect } from "react";
import { Form } from "antd";
import { fetchRefund } from "../../../redux/refundSlice";
import { calculateVendorCogs, getBase64, getCouponPercentage, loncaRound} from "../../../helpers";
import "../../../pages/EditRefund/EditRefund.css";
import { REASONS_FOR_ITEM_COUNT_NOT_SERIES } from "../../../constants";
import { fetchAllExchangeRates } from "../../../redux/exchangeRatesSlice";
import { ImageBoxForRefund, ExchangeGeneralInfo, ExchangeVendorInfo, ExchangeOperationInfo, ExchangeAccountInfo, ExchangeOrderInfo } from "../..";

const ProductForRefund = ({ id, updateRefundAmounts, updateChangedRefunds, refundReasons, currencies, page = "edit-exchange", changedRefunds, setCouponPercentage }) => {

  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [unitPrice, setUnitPrice] = useState(0);
  const [unitPriceLocal, setUnitPriceLocal] = useState(0);
  const [discountedUnitPrice, setDiscountedUnitPrice] = useState(0);
  const [discountedUnitPriceLocal, setDiscountedUnitPriceLocal] = useState(0);
  const [unitCogs, setUnitCogs] = useState(0);
  const [unitCogsTRY, setUnitCogsTRY] = useState(0);
  const [realUnitCogs, setRealUnitCogs] = useState(0);
  const [realUnitCogsTRY, setRealUnitCogsTRY] = useState(0);
  const [seriesQuantity, setSeriesQuantity] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const [refundItemCount, setRefundItemCount] = useState(0);
  const [refundAmount, setRefundAmount] = useState(0);
  const [refundAmountLocal, setRefundAmountLocal] = useState(0);
  const [discountedRefundAmount, setDiscountedRefundAmount] = useState(0);
  const [discountedRefundAmountLocal, setDiscountedRefundAmountLocal] = useState(0);
  const [vatMultiplier, setVatMultiplier] = useState(0);
  const [vat, setVat] = useState(0);
  const [rateUsdSelling, setRateUsdSelling] = useState(0);
  const [rateUsdBuying, setRateUsdBuying] = useState(0);
  const [rateUsdTrBuying, setRateUsdTrBuying] = useState(0);
  const [totalSupplierPayment, setTotalSupplierPayment] = useState('');
  const [realSupplierPayment, setRealSupplierPayment] = useState('');
  const [orderCurrencySymbol, setOrderCurrencySymbol] = useState("$");
  const [hasChanged, setHasChanged] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [refund, setRefund] = useState({});
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewFile, setPreviewFile] = useState({});
  const [exchangeBox, setExchangeBox] = useState();
  const [refundItemNotes, setRefundItemNotes] = useState([]);
  const [orderDeliveryDate, setOrderDeliveryDate] = useState("");
  const [refundRequestDate, setRefundRequestDate] = useState("");
  const [activeTab, setActiveTab] = useState('');
  const [initialRefundReason, setInitialRefundReason] = useState();
  const [couponPercentageProduct, setCouponPercentageProduct] = useState(0);

  const handlePreview = async (file) => {
    if (!file.url && !file) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewFile(file);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const calculateItemPriceWithCogs = ({ cogs, vat}) => {    
    return loncaRound(cogs * (1 + vat));
  };

  useEffect(() => {
    if(isDirty){
      handleChanges();
    }
  }, [hasChanged, fileList]);

  useEffect(() => {
    let imageLinks = [];

    imageLinks = [...(fileList?.filter(i => i?.existBeforeUpdate)?.map(image => image?.url) || []), ...(imageLinks || [])];

    setRefund({
        ...refund,
        images: imageLinks
    })
      
  }, [fileList]);

  const showItemCountNotSeries = () => {
    if (refund?.refund_reason && REASONS_FOR_ITEM_COUNT_NOT_SERIES?.includes(refund?.refund_reason)) {
      return true;
    }
  }

  useEffect(() => {
      if (id) {
        fetchRefund({ refund: id }).then((response) => {
        setRefund(response?.data);
        setInitialRefundReason(response?.data?.refund_reason);
        setFileList(response?.data?.images?.map((image, index) => {
          return {
            uid: index + 1,
            status: "done",
            url: image,
            name: `Image_${index + 1}`,
            existBeforeUpdate: true,
            key: `Image_${index + 1}`,
          }
        }));

        setExchangeBox(response?.data?.exchange_box);

        setOrderDeliveryDate(new Date(response?.data?.order?.delivery_date));
        setRefundRequestDate(new Date(response?.data?.createdAt));
                
        setRefundItemNotes(response?.data?.item_notes);

        const calculatedCouponPercentage = getCouponPercentage(response?.data?.order);
        setCouponPercentage(calculatedCouponPercentage);
        setCouponPercentageProduct(calculatedCouponPercentage);


        if (response?.data?.order?.currency){
          if(currencies?.length <= 0){
            fetchAllExchangeRates()
            .then(res => {
              const currency = res?.data?.find(c => (c?.abbreviation === response?.data?.order?.currency)) || res?.data?.find(c => (c?.abbreviation === "USD"));
              setOrderCurrencySymbol(currency?.symbol)
            })
          } else {
            const currency = currencies?.find(c => (c?.abbreviation === response?.data?.order?.currency)) || currencies?.find(c => (c?.abbreviation === "USD"));
            setOrderCurrencySymbol(currency?.symbol)
          }  
        }

        const item = response?.data?.order?.cart_item.find(cart_item => (cart_item?.product === response?.data?.product?._id && cart_item?.series === (response?.data?.series || response?.data?.product?.series?.name) ));

        setUnitCogs(item?.cogs)
        setUnitCogsTRY(loncaRound(calculateVendorCogs({
          payment_at: response?.data?.order?.payment_at,
          cogs: item?.cogs,
          rate_usd_tr_buying: response?.data?.order?.rate_usd_tr_buying,
          rate_usd_buying: response?.data?.order?.rate_usd_buying,
          rate_usd_selling: response?.data?.order?.rate_usd_selling
        })));

        setSeriesQuantity(item?.quantity);
        setRealUnitCogs(refund?.real_cogs_amount);

        setRealUnitCogsTRY(loncaRound(calculateVendorCogs({
          payment_at: response?.data?.order?.payment_at,
          cogs: refund?.real_cogs_amount,
          rate_usd_tr_buying: response?.data?.order?.rate_usd_tr_buying,
          rate_usd_buying: response?.data?.order?.rate_usd_buying,
          rate_usd_selling: response?.data?.order?.rate_usd_selling
        })));

        setItemCount(item?.item_count);
        setVat(item?.vat);

        if (REASONS_FOR_ITEM_COUNT_NOT_SERIES?.includes(response?.data?.refund_reason)) {
          setRefundItemCount(response?.data?.item_count);
        } 
        
        let constant = (1 + item?.vat); 
        setVatMultiplier(loncaRound(item?.price * constant));
        setUnitPrice(loncaRound(item?.price * constant))
        setUnitPriceLocal(loncaRound(item?.price * constant * response?.data?.order?.rate_usd_selling))

        setRateUsdSelling(response?.data?.order?.rate_usd_selling);
        setRateUsdBuying(response?.data?.order?.rate_usd_buying);
        setRateUsdTrBuying(response?.data?.order?.rate_usd_tr_buying);

        });
      }
  }, [id]);

  const setCogsForSupplierPayment = (unitCogs) => {
    const cogs = calculateVendorCogs({
      payment_at: refund?.order?.payment_at,
      cogs: unitCogs,
      rate_usd_tr_buying: rateUsdTrBuying,
      rate_usd_buying: rateUsdBuying,
      rate_usd_selling: rateUsdSelling})
    return cogs; 
  }

  useEffect(() => {

    let amount;

    if (showItemCountNotSeries()) {
      amount = (vatMultiplier * refundItemCount).toFixed(2)
      setTotalSupplierPayment(`${(!rateUsdBuying || !rateUsdTrBuying || !rateUsdSelling) ? "$" : "₺"}${loncaRound(calculateItemPriceWithCogs({
          cogs: setCogsForSupplierPayment(unitCogs),
          vat: vat
      }) * refundItemCount)}`);

      setRealSupplierPayment(`${(!rateUsdBuying || !rateUsdTrBuying || !rateUsdSelling) ? "$" : "₺"}${loncaRound(calculateItemPriceWithCogs({
        cogs: setCogsForSupplierPayment(realUnitCogs),
        vat: vat
    }) * refundItemCount)}`)
    }  else {
      amount = refund?.refund_status === "Disapproved" ? 0 : (vatMultiplier * itemCount * refund?.series_amount).toFixed(2)
      setTotalSupplierPayment(`${(!rateUsdBuying || !rateUsdTrBuying || !rateUsdSelling) ? "$" : "₺"}${loncaRound(calculateItemPriceWithCogs({
        cogs: setCogsForSupplierPayment(unitCogs),
        vat: vat
      }) * itemCount * refund?.series_amount)}`)

      setRealSupplierPayment(`${(!rateUsdBuying || !rateUsdTrBuying || !rateUsdSelling) ? "$" : "₺"}${loncaRound(calculateItemPriceWithCogs({
        cogs:setCogsForSupplierPayment(realUnitCogs),
        vat: vat
      }) * itemCount * refund?.series_amount)}`)
    }

    let net_amount = loncaRound(amount).toFixed(2);

    setRefundAmount(net_amount);
    setRefundAmountLocal(loncaRound(net_amount * rateUsdSelling).toFixed(2));

    setDiscountedRefundAmount(loncaRound(net_amount * (1 - couponPercentageProduct)));
    setDiscountedRefundAmountLocal(loncaRound(net_amount * (1 - couponPercentageProduct) * rateUsdSelling).toFixed(2));

    setDiscountedUnitPrice(loncaRound(unitPrice * (1 - couponPercentageProduct)))
    setDiscountedUnitPriceLocal(loncaRound(unitPriceLocal * (1 - couponPercentageProduct)))

    if(page === "edit-exchange"){
      if (refund?.refund_status === "Approved" || refund?.refund_status === "Completed"){
        updateRefundAmounts(id, net_amount, loncaRound(net_amount * rateUsdSelling).toFixed(2));
      }
    }

    form.setFieldsValue({
      customer_note: refund?.note,
      seriesinfo: refund?.series || refund?.product?.series?.name,
      taken_from_customer_note: refund?.operation_info?.taken_from_customer_note,
      send_to_vendor_note: refund?.operation_info?.send_to_vendor_note,
      taken_from_vendor_note: refund?.operation_info?.taken_from_vendor_note,
      send_to_customer_note: refund?.operation_info?.send_to_customer_note,
      accountmanagernote: refund?.account_manager_note,
      status: refund?.refund_status,
      vendor_status: refund?.vendor_status,
      refundInvoice: refund?.invoice_status,
      vendorname: refund?.product?.vendor?.name,
      productname: refund?.product?.names?.en,
      seriesamount: refund?.series_amount,
      supplierstockcode: refund?.product?.supplier_stock_code,
      vendor_note: refund?.vendor_note,
      unitprice : "$" + unitPrice + " - " + orderCurrencySymbol + unitPriceLocal,
      unitcogs : "$" + unitCogs + " - ₺" + unitCogsTRY,
      refundreason: refund?.refund_reason,
      itemcount: showItemCountNotSeries() ? refundItemCount : itemCount,
      exchangebox: exchangeBox ? `${exchangeBox?.unit}-${exchangeBox?.unit_number}` : "",
      refundamount : "$" + refundAmount + " - " + orderCurrencySymbol + refundAmountLocal,
      totalsupplierpayment: totalSupplierPayment,
      realunitcogs: realUnitCogs,
      realunitcogsTRY: realUnitCogsTRY,
      realsupplierpayment: realSupplierPayment,
      operationstockstatus: refund?.operation_stock_status,
      vendorstockstatus: refund?.vendor_stock_status,
      discountedrefundamount: "$" + discountedRefundAmount + " - " + orderCurrencySymbol + discountedRefundAmountLocal,
      discountedunitprice: "$" + discountedUnitPrice + " - " + orderCurrencySymbol + discountedUnitPriceLocal,
    });
  }, [refund, form, unitPrice, unitPriceLocal, unitCogs, unitCogsTRY, itemCount, refundItemCount, refundAmount, orderCurrencySymbol, refundAmountLocal, rateUsdSelling, rateUsdTrBuying, totalSupplierPayment, realUnitCogs, realUnitCogsTRY, realSupplierPayment, exchangeBox, discountedRefundAmount, discountedRefundAmountLocal, discountedUnitPrice, discountedUnitPriceLocal]);
  
  useEffect(() => {
    setRealUnitCogs(refund?.real_cogs_amount);

    setRealUnitCogsTRY(loncaRound(calculateVendorCogs({
      payment_at: refund?.order?.payment_at,
      cogs: refund?.real_cogs_amount,
      rate_usd_tr_buying: rateUsdTrBuying,
      rate_usd_buying: rateUsdBuying,
      rate_usd_selling: rateUsdSelling
    })));
  }, [refund?.real_cogs_amount, realUnitCogs, unitCogs, rateUsdBuying, rateUsdTrBuying, rateUsdTrBuying])

  const handleChanges = () => {
    const customer_name = refund?.order?.customer_name;
    const unique_order_id = refund?.order?.unique_order_id;
    let updatedRefund = { refund_status: refund?.refund_status, refund_reason: refund?.refund_reason, series_amount: refund?.series_amount, invoice_status: refund?.invoice_status, account_manager_note: refund?.account_manager_note};
    updatedRefund.vendor_status = refund?.vendor_status;
    updatedRefund.vendor_note = refund?.vendor_note;
    updatedRefund.orderId = refund?.order?._id;
    updatedRefund.images = refund?.images;
    updatedRefund.real_cogs_amount = refund?.real_cogs_amount;
    updatedRefund.operation_info = refund?.operation_info;
    updatedRefund.exchange_box = exchangeBox?._id || null;
    updatedRefund.item_notes = refund?.item_notes?.slice(0, refundItemCount);
    updatedRefund.operation_stock_status = refund?.operation_stock_status;
    updatedRefund.vendor_stock_status = refund?.vendor_stock_status;
    updatedRefund.series = refund?.series;

    if(updatedRefund?.refund_reason !== initialRefundReason && !refund?.initial_refund_reason){
      updatedRefund.initial_refund_reason = initialRefundReason;
    }
      
    if (showItemCountNotSeries()) {
      updatedRefund.item_count = refundItemCount;
    }

    updateChangedRefunds(id, updatedRefund, customer_name, unique_order_id);
    
  };

  const getOrderRefundStatus = () => {
    const existingChangedItem = changedRefunds.find((data) => data?.id?.toString() === id?.toString());
    return refund?.order?.refund_status;
  }

  return (
    <Form
      form={form}
      className="refund-form"
      layout="vertical"
    >
      <div className={`form-top-refund ${isDirty && 'changed'}`}>
        <div className="free-return-tag">
          {refund?.free_return_available ? <span>Free Return Available</span> : null}
        </div>

        <ImageBoxForRefund
          fileList={fileList}
          refund={refund}
          handlePreview={handlePreview}
          setFileList={setFileList}
          getBase64={getBase64}
          previewVisible={previewVisible}
          previewTitle={previewTitle}
          setPreviewVisible={setPreviewVisible}
          setHasChanged={setHasChanged}
          setIsDirty={setIsDirty}
          setRefund={setRefund}
          previewFile={previewFile}
        />

        <div>  

          { page === "exchange-products" &&

              <ExchangeOrderInfo 
                uniqueOrderId={refund?.order?.unique_order_id} 
                shipmentCountry={refund?.shipmentCountry} 
                orderDeliveryDate={orderDeliveryDate} 
                refundRequestDate={refundRequestDate} 
                orderExchangeStatus={getOrderRefundStatus()}
                orderId={refund?.order?._id}
              /> 

          }          

          <ExchangeGeneralInfo 
            form={form} 
            refund={refund} 
            setRefund={setRefund} 
            setHasChanged={setHasChanged} 
            setIsDirty={setIsDirty} 
            refundReasons={refundReasons}
            showItemCountNotSeries={showItemCountNotSeries} 
            seriesQuantity={seriesQuantity} 
            itemCount={itemCount}
            refundItemCount={refundItemCount}
            unitPrice={unitPrice}
            refundAmount={refundAmount} 
            exchangeBox={exchangeBox} 
            setExchangeBox={setExchangeBox}
            setRefundItemCount={setRefundItemCount}
            discountedRefundAmount={discountedRefundAmount}
            discountedUnitPrice={discountedUnitPrice}
            couponPercentageProduct={couponPercentageProduct}
          />

          <div className="accounting"></div> 

          {page === "edit-exchange" ?
            <div>
              <div className="accounting">
                <h3>Vendor</h3>

                <ExchangeVendorInfo 
                  form={form} 
                  refund={refund} 
                  setRefund={setRefund} 
                  setHasChanged={setHasChanged} 
                  setIsDirty={setIsDirty} 
                />
              </div>

              <div className="accounting">
                <h3>Operation</h3>

                <ExchangeOperationInfo 
                  form={form} 
                  refund={refund} 
                  setRefund={setRefund} 
                  setHasChanged={setHasChanged} 
                  setIsDirty={setIsDirty} 
                />
              </div>

              <div className="accounting">
                <h3>Accounting</h3>

                <ExchangeAccountInfo 
                  form={form} 
                  refund={refund} 
                  setRefund={setRefund} 
                  setHasChanged={setHasChanged} 
                  setIsDirty={setIsDirty}
                  unitCogs={unitCogs} 
                  totalSupplierPayment={totalSupplierPayment} 
                  realUnitCogs={realUnitCogs}
                  realUnitCogsTRY={realUnitCogsTRY} 
                  realSupplierPayment={realSupplierPayment} 
                />

              </div>
            </div>
            :
            <div className="tab-headers">
              <div className="tab-header-container">
                <h3
                  className={activeTab === 'Vendor' ? 'active' : ''}
                  onClick={() => setActiveTab('Vendor')}
                >
                    Vendor
                </h3>
                  
                <h3
                    className={activeTab === 'Operation' ? 'active' : ''}
                    onClick={() => setActiveTab('Operation')}
                >
                    Operation
                </h3>
                
                <h3
                    className={activeTab === 'Accounting' ? 'active' : ''}
                    onClick={() => setActiveTab('Accounting')}
                >
                    Accounting
                </h3>
              </div>
              {activeTab === "Vendor" &&
                <ExchangeVendorInfo 
                  form={form} 
                  refund={refund} 
                  setRefund={setRefund} 
                  setHasChanged={setHasChanged} 
                  setIsDirty={setIsDirty} 
                />
              }
              {activeTab === "Operation" && 
                <ExchangeOperationInfo 
                  form={form} 
                  refund={refund} 
                  setRefund={setRefund} 
                  setHasChanged={setHasChanged} 
                  setIsDirty={setIsDirty} 
                /> 
              }
              {activeTab === "Accounting" && 
                <ExchangeAccountInfo 
                  form={form} 
                  refund={refund} 
                  setRefund={setRefund} 
                  setHasChanged={setHasChanged} 
                  setIsDirty={setIsDirty}
                  unitCogs={unitCogs} 
                  totalSupplierPayment={totalSupplierPayment} 
                  realUnitCogs={realUnitCogs}
                  realUnitCogsTRY={realUnitCogsTRY} 
                  realSupplierPayment={realSupplierPayment} 
                />
              }
            </div>   
          }

        </div>
        
      </div>
    </Form>
  );
};

export default ProductForRefund;
