import React, { useEffect, useState } from 'react';
import { Form, Button, DatePicker, notification, Input } from 'antd';
import moment from 'moment';
import { t } from 'i18next';
import { fetchWarehouseBoxes } from '../../redux/warehouseBoxSlice';
import { initStockBox } from '../../helpers';

const InTransitInfoEditor = ({ inTransitInfo, setInTransitInfo, product, setIsDirty, user, stockBox, setStockBox, setProduct, changedStockBox, setChangedStockBox }) => {
    const [newWarehouseBox, setNewWarehouseBox] = useState(null);

    useEffect(() => {
        if (product?.in_transit_info?.length > 0) {
            setInTransitInfo(product?.in_transit_info)
        }
    }, [product?.in_transit_info]);

    const handleFetchWarehouseBoxes = async() => {
        try {
            const res = await fetchWarehouseBoxes({ type: "stock", is_disabled: { $ne: true }, is_full: { $ne: true } });
            let firstWarehouseBox = res.data[0];
            setNewWarehouseBox(firstWarehouseBox);
            return firstWarehouseBox;
          } catch (error) {

            console.error("An error occurred while fetching warehouse boxes:", error);
          }          
      }

    const handleAddInTransitInfo = () => {

        if (inTransitInfo?.length >= product?.in_transit_stock) {
            notification['error']({
                message: t('errors.In Transit Stocks already put in boxes. Cant add new box'),
            });
            return
        }

        setInTransitInfo(inTransitInfo => [...inTransitInfo, { date: new Date(), owner: user?.full_name }])
    }

    const handleRemoveInTransitInfo = (box, indexToRemove) => {
        const newInTransitInfo = inTransitInfo.filter((_, index) => index !== indexToRemove);
        setInTransitInfo(newInTransitInfo);
        if (newInTransitInfo?.length === 0) {
            setProduct(product => ({ ...product, last_in_transit_stock_date: box?.date }))
        }
    }

    const handleAddToStockBox = async(box, index) => {
        let warehouseBox = newWarehouseBox || await handleFetchWarehouseBoxes()
        let initializedStockBox = initStockBox({
            warehouseBoxUnit: warehouseBox?.unit,
            warehouseBoxUnitNumber: warehouseBox?.unit_number,
            warehouseBoxId: warehouseBox?._id,
            userFullName: user?.full_name})


        setStockBox(stockBox => [...stockBox, { ...initializedStockBox, in_transit_stock_date: box?.date }])
        const newInTransitInfo = inTransitInfo.filter((_, i) => i !== index);
        setInTransitInfo(newInTransitInfo);
        setChangedStockBox(stockBox => [...stockBox, { ...initializedStockBox, mode: 'add'}])
        
    }

    const handleInTransitInfoChange = (change, index) => {
        const newInTransitInfoEntry = {
            ...inTransitInfo[index],
            ...change,
        };

        const newInTransitInfo = [...inTransitInfo]; // Create a new array
        newInTransitInfo[index] = newInTransitInfoEntry;

        setInTransitInfo(newInTransitInfo);
    };

    return (
        <div className="in_transit_stock_box">
            <Form.Item name='in_transit_info' label='In Transit Stock Box Information' >
                {inTransitInfo?.length > 0 ? inTransitInfo?.map((box, index) => (

                    <div className="address-box">
                        <div className="inline-inputs-in_transit_stock-box">
                            <Form.Item label={`In Transit Stock Box ${index + 1}`} key={index + 1}>
                                <Form.Item label="Date">
                                    <DatePicker
                                        className="payment-date"
                                        value={moment(box?.date)}
                                        required={true}
                                        onChange={(date) => {
                                            handleInTransitInfoChange({ date: new Date(date) }, index)
                                            setIsDirty(true);
                                        }}
                                    />
                                </Form.Item>
                                {box?.owner ?
                                    <Form.Item>
                                        {t('Owner')}
                                        <Input
                                            value={box?.owner}
                                            disabled
                                        />
                                    </Form.Item>
                                    : null}
                            </Form.Item>
                            <div className='in-transition-two-buttons'>
                                <Button type="primary" onClick={() => handleRemoveInTransitInfo(box, index)}> Remove </Button>
                                <Button type="primary" onClick={() => handleAddToStockBox(box, index)}> Move to StockBox </Button>
                            </div>
                        </div>
                    </div>

                )) : null}
                <Button type="primary" className="add-transit-info-button" onClick={() => handleAddInTransitInfo()}> Add InTransit Stock Box</Button>
            </Form.Item>
        </div>
    );
};

export default InTransitInfoEditor;
