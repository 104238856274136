import './CartSearchBar.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import {
  fetchSearchBarResults,
  searchBarItemsSelector,
  searchTypeSelector,
  setSearchText,
  setSearchType,
  reset,
} from '../../redux/searchSlice';

import { setCartFilter } from '../../redux/filterSlice';

function CartSearchBar({ type }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchBarItems = useSelector(searchBarItemsSelector);
  const searchType = useSelector(searchTypeSelector);

  const { cartFilter: filter } = useSelector((state) => state.filter);

  const [localSearchText, setLocalSearchText] = useState('');
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    dispatch(reset());
    dispatch(setSearchType(type));
  }, [dispatch, type]);

  const debouncedFetchResults = useCallback(
    debounce((searchText) => {
      dispatch(fetchSearchBarResults({ searchText, searchType }));
    }, 200),
    [dispatch, searchType],
  );

  useEffect(() => {
    if (localSearchText.length === 0) return;
    debouncedFetchResults(localSearchText);
  }, [localSearchText, debouncedFetchResults]);

  const setFilter = useCallback((value) => {
    dispatch(setCartFilter(value));
  }, [dispatch]);

  return (
    <div className="search-wrapper">
      <div className="search-input">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setDisplay(false);
            if (localSearchText.length !== 0) {
              setFilter({
                ...filter,
                filter: { customer: { $in: searchBarItems.map((item) => (item._id)) } },
              });
              dispatch(setSearchText(localSearchText));
              setFilter({
                ...filter,
                filter: { customer: { $in: searchBarItems.map((item) => (item._id)) } },
              });
            } else {
              setFilter({ ...filter, filter: {} });
            }
          }}
        >
          <div className="search-area">
            <i className="bi bi-search search-icon" />
            <input
              type="text"
              placeholder="Search"
              value={localSearchText}
              onChange={(e) => {
                setLocalSearchText(e.target.value);
                if (e.target.value.length > 0) {
                  setDisplay(true);
                } else {
                  setDisplay(false);
                }
              }}
            />
          </div>
          {display && searchBarItems.length > 0 && (
            <div className="search-results">
              <ul className="search-result">
                {searchBarItems?.slice(0, 3).map((result) => (
                  <li
                    key={result._id}
                    onClick={() => {
                      setDisplay(false);
                      setLocalSearchText('');
                      navigate(`/carts/${result?.carts?._id}`);
                    }}
                    onKeyDown={() => {
                      setDisplay(false);
                      setLocalSearchText('');
                      navigate(`/carts/${result?.carts?._id}`);
                    }}
                    aria-hidden="true"
                  >
                    <span className="name">{result?.firstName}</span>
                    <span className="name">{result?.lastName}</span>
                    <span className="type text-muted">{result.type}</span>
                  </li>
                ))}
              </ul>
              <input
                className="w-100 button-primary list-all"
                type="submit"
                value="List All Results"
              />
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default CartSearchBar;
