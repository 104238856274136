import { useState } from "react";

import { useSelector } from 'react-redux';

import "./Colors.css";

import { Button } from "antd";
import { Color, LocalSearch , Pagination} from "../../components";

function Colors() {

    const [searchText, setSearchText] = useState("");
    const { colors } = useSelector((state) => state.colors);
    
    const [colorsDisplayed, setColorsDisplayed] = useState(colors.slice());

    const [filter, setFilter] = useState({
        page: 1,
        limit: 10,
        sort: 1,
    });


    return (
        <div className="list-tags-container">
            <div className="list-tags-nav">
                <h2>Colors</h2>
                <div>
                    <Button type="text">Export</Button>
                    <Button type="text">Import</Button>
                    <Button type="text">Add New Color</Button>
                </div>
            </div>
            <div className="list-tags-filter">
                <LocalSearch items={colors} setItems={setColorsDisplayed} type="colors" minDisplay={1} />
            </div>
            <div className="list-colors-table-head">
                <div className="list-tags-table-check">*</div>
                <div className="list-tags-table-tag">Color</div>
            </div>
            {colorsDisplayed
                .slice((filter.page-1)*filter.limit,filter.page*filter.limit)
                .map((item) => (
                    <Color key={item._id} item={item} />
                ))}
            <div>
                <Pagination filter={filter} setFilter={setFilter} />
            </div>
        </div>
    );
}

export default Colors;
