import { Link } from "react-router-dom";
import { getDate, getTime } from '../../helpers';

import './Favorite.css';

function Favorite({ item }) {

  return (
    <div className="list-favorites">
      <Link 
        className="list-favorites-item"
        to={`../favorites/${item?.customer_id?._id}`}
      >
        <div></div>
        <div className="list-favorites-item-fname">
          {item?.customer_id?.firstName || 'NO NAME'}
        </div>
        <div className="list-favorites-item-lname">
          {item?.customer_id?.lastName || 'NO LASTNAME'}
        </div>
        <div className="list-favorites-item-items">
          {item?.product?.length}
        </div>
        <div className="list-favorites-item-date">
          {getDate(item?.updatedAt)}
        </div>
        <div className="list-favorites-item-time">
          {getTime(item?.updatedAt)}
        </div>
      </Link>
    </div>
  );
}

export default Favorite;
