import axios from 'axios';
import store from './store'
import { getUrlFromState } from './userSlice';

export const fetchCountries = async () => {
  const url = getUrlFromState(store)
  const { data } = await axios.get(`${url}/country/`);
  return data;
};

export const updateMessageMarketingAvailability = async (countries) => {
  const url = getUrlFromState(store)
  const { data } = await axios.post(`${url}/country/message-marketing-availability`, { countries });
  return data;
};
