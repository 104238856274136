import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUrlFromState } from './userSlice';
import store from './store'

export const fetchSeries = createAsyncThunk(
  'series/fetchSeries',
  async (filter) => {
    const url = getUrlFromState(store)
    const { data } = await axios.get(`${url}/series`, {
      params: filter,
    });
    return data;
  },
);

export const fetchAllSeries = createAsyncThunk(
  'series/fetchAllSeries',
  async () => {
    const url = getUrlFromState(store)
    const { data } = await axios.get(`${url}/series/all`);
    return data;
  },
);

export const fetchDelete = (id) => {
  const url = getUrlFromState(store)
  return axios.delete(`${url}/series/one/${id}`);
}

export const fetchCreate = async (serie) => {
  const url = getUrlFromState(store)
  const { data } = await axios.post(`${url}/series`, { serie });
  return data;
};

const seriesSlice = createSlice({
  name: 'series',
  initialState: {
    filter: {},
    series: [],
    allSeries: [],
    status: {
      fetchSeries: 'idle',
      fetchAllSeries: 'idle',
    },
    error: null,
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    // series get query
    [fetchSeries.pending]: (state) => {
      state.status.fetchSeries = 'pending';
    },
    [fetchSeries.fulfilled]: (state, action) => {
      state.status.fetchSeries = 'succeeded';
      state.series = action.payload;
    },
    [fetchSeries.rejected]: (state, action) => {
      state.status.fetchSeries = 'rejected';
      state.error = action.payload;
    },

    // get all series
    [fetchAllSeries.pending]: (state) => {
      state.status.fetchAllSeries = 'pending';
    },
    [fetchAllSeries.fulfilled]: (state, action) => {
      state.status.fetchAllSeries = 'succeeded';
      state.allSeries = action.payload;
    },
    [fetchAllSeries.rejected]: (state, action) => {
      state.status.fetchAllSeries = 'rejected';
      state.error = action.payload;
    },
  },
});

export const { setFilter } = seriesSlice.actions;

export default seriesSlice.reducer;
