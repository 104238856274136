import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { fetchMe } from "../../redux/userSlice";

function Auth(props) {
  const dispatch = useDispatch();
  const { status } = useSelector((state) => state.user);

  useEffect(() => {
    const token = localStorage.getItem("lonca-admin-token");
    if (token && status.auth === "idle") {
      dispatch(fetchMe());
    }
  }, [dispatch, status.auth]);

  return <>{props.children}</>;
}

export default Auth;
