import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import store from './store';
import { getUrlFromState } from './userSlice';

export const fetchColors = createAsyncThunk(
  'colors/fetchColors',
  async () => {
    const url = getUrlFromState(store)
    const { data } = await axios.get(`${url}/color`);
    return data;
  },
);

export const fetchDelete = (tagId) => {
  const url = getUrlFromState(store)
  return axios.delete(`${url}/color/${tagId}`)
};

const colorSlice = createSlice({
  name: 'color',
  initialState: {
    colors: [],
    status: {
      fetchColors: 'idle',
    },
    error: null,
  },
  reducers: {

  },
  extraReducers: {
    [fetchColors.pending]: (state) => {
      state.status.fetchColors = 'pending';
    },
    [fetchColors.fulfilled]: (state, action) => {
      state.status.fetchColors = 'succeeded';
      state.colors = action.payload;
    },
    [fetchColors.rejected]: (state, action) => {
      state.status.fetchColors = 'rejected';
      state.error = action.payload;
    },
  },
});

// Delete This Line
export const { setFilter } = colorSlice.actions;

export default colorSlice.reducer;
