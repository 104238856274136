import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

import { loncaRound } from "../../helpers";

import { fetchCsvDiscountedProducts, removeCsvDiscountedProducts } from "../../redux/productSlice";
import { fetchAllExchangeRates } from "../../redux/exchangeRatesSlice";

import "./CSVDiscountedProducts.scss";

const CSVDiscountedProducts = () => {

    const { t } = useTranslation();

    const [discountedProducts, setDiscountedProducts] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currencies, setCurrencies] = useState([]);

    useEffect(() => {
        setLoading(true);

        fetchCsvDiscountedProducts()
            .then(res => {
                setLoading(false);
                setDiscountedProducts(res?.data);
            })
            .catch(err => {
                setLoading(false);
                console.log(err?.response?.data);
            })
    }, [refresh]);

    useEffect(() => {
		fetchAllExchangeRates()
		  .then(res => {
			  setCurrencies(res?.data);
		  })
	}, [])

    const handleRemoveSubmit = (ids) => {
        removeCsvDiscountedProducts(ids)
            .then(() => {
                setSelectedIds([]);
                setRefresh(r => !r);
            })
            .catch(() => {
                setSelectedIds([]);
            })
    }

    return (
        <div className="csv-discounted-products-container">
            <div className="csv-discounted-products-header">
                <div className="csv-discounted-products-header-title-spin">
                    <h2>{ t("fields.discounted_products.title") }</h2>
                    { loading && <LoadingOutlined className="csv-discounted-products-loading"/> }
                </div>

                <Button
                    disabled={selectedIds?.length <= 0}
                    className="csv-discounted-products-bulk-remove"
                    onClick={() => handleRemoveSubmit(selectedIds)}
                >
                    { t("fields.discounted_products.remove_discount") } ({selectedIds?.length})
                </Button>
            </div>

            <div className="csv-discounted-products-labels">
                <span>
                    <input
                        type="checkbox"
                        checked={discountedProducts?.length > 0 && selectedIds?.length === discountedProducts?.length}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setSelectedIds(discountedProducts?.map(p => p?._id?.toString()))
                            } else {
                                setSelectedIds([]);
                            }
                        }}
                    />
                </span>
                <span>{ t("fields.discounted_products.image") }</span>
                <span>{ t("fields.discounted_products.name") }</span>
                <span>{ t("home.vendor") }</span>
                <span>{ t("fields.discounted_products.stock_code") }</span>
                <span>{ t("fields.discounted_products.price") }</span>
                <span>{ t("fields.discounted_products.discounted_price") }</span>
                <span>{ t("fields.discounted_products.discount_percent") }</span>
                <span></span>
            </div>

            <div className="csv-discounted-products">
                {
                    discountedProducts?.map(discountedProduct => {

                        const priceUnit = discountedProduct?.vendor?.price_unit || "USD";
                        const currency = currencies?.find(c => c?.abbreviation === priceUnit);

                        const price = loncaRound(discountedProduct?.price * currency?.rate_usd_buying);
                        const discountedPrice = loncaRound(discountedProduct?.discounted_price * currency?.rate_usd_buying);
                        const discountPercent = loncaRound(((discountedProduct?.price - discountedProduct?.discounted_price) / discountedProduct?.price) * 100);

                        return (
                            <div className="csv-discounted-product">

                                <input 
                                    type="checkbox" 
                                    checked={selectedIds.includes(discountedProduct?._id?.toString())}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            if (!selectedIds.includes(discountedProduct?._id?.toString())) setSelectedIds(ids => [...ids, discountedProduct?._id?.toString()]);
                                        } else {
                                            setSelectedIds(ids => ids?.filter(id => id !== discountedProduct?._id?.toString()));
                                        }
                                    }}
                                />

                                <span className="csv-discounted-product-image-container">
                                    <img src={discountedProduct?.main_image} alt={discountedProduct?.name} className="csv-discounted-product-image" />
                                </span>

                                <span>{ discountedProduct?.names?.en }</span>

                                <span>{ discountedProduct?.vendor?.name }</span>

                                <span>{ discountedProduct?.supplier_stock_code }</span>

                                <span>{ price } { priceUnit }</span>

                                <span>{ discountedPrice } { priceUnit }</span>

                                <span>%{ discountPercent }</span>

                                <Button 
                                    className="csv-discounted-product-remove-button"
                                    onClick={() => handleRemoveSubmit([discountedProduct?._id])}
                                >
                                    { t("fields.discounted_products.remove_discount") }
                                </Button>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default CSVDiscountedProducts;