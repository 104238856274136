import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Collapse, Form, Input, Radio, Spin, Checkbox, notification } from "antd";

import { fetchFlowById, updateFlowById } from "../../redux/marketingFlowSlice";
import { fetchCountries } from "../../redux/countrySlice";

import "./MarketingFlows.scss";

const EditMarketingFlows = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [form] = Form.useForm();

    const { Panel } = Collapse;

    const [isLoading, setIsLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [flow, setFlow] = useState(false);
    const [activeKey, setActiveKey] = useState(-1);
    const [refresh, setRefresh] = useState(-1);

    useEffect(() => {
        if (!id) return;

        setIsLoading(true);

        fetchFlowById(id)
            .then((data) => {
                setIsLoading(false);
                setFlow(data);
                form.setFieldsValue({
                    name: data?.name,
                    description: data?.ndescriptioname,
                    channel: data?.channel,
                    type: data?.type,
                    event_name: data?.event_name,
                })
            })
            .catch(() => {
                setIsLoading(false);
            })
    }, [id, refresh]);

    useEffect(async () => {
        const countriesResponse = await fetchCountries();
        setCountries(groupCountriesByRegion(countriesResponse));
    }, []);

    const handleSubmit = () => {
        setSaveLoading(true);

        updateFlowById(id, flow)
            .then(() => {
                setSaveLoading(false);
                setRefresh(r => !r);
                notification["success"]({
                    message: "Updated succesfully"
                })
            })
            .catch(() => {
                setSaveLoading(false);
                notification["success"]({
                    message: "Couldn't be updated!"
                })
            })
    }

    const groupCountriesByRegion = (countriesArr) => {
        let result = countriesArr?.reduce((acc, crr) => {
            if (!acc[crr.region]) {
                acc[crr.region] = [];
            }

            acc[crr?.region].push(crr);
            return acc;
        }, {});

        for (let key in result) {
            if (Array.isArray(result[key])) {
                result[key].sort((a, b) => a?.name?.localeCompare(b?.name));
            }
        }

        return result;
    };

    const toggleSingleCountry = (country, value) => {
        if (value) {
            if (flow?.allowed_countries?.find(c => c?.name === country?.name)) return;

            setFlow(f => {
                return {
                    ...f,
                    allowed_countries: [
                        ...f?.allowed_countries,
                        { _id: country?._id, name: country?.name, code: country?.code }
                    ]
                }
            })
        } else {
            setFlow(f => {
                return {
                    ...f,
                    allowed_countries: f?.allowed_countries?.filter(ac => ac?.name !== country?.name)
                }
            });
        }
    };

    const toggleAllRegion = (region) => {

        const selectedAny = flow?.allowed_countries?.some(ac => countries?.[region]?.find(c => c?.name === ac?.name));
        const currentCountryList = (flow?.allowed_countries && flow?.allowed_countries?.length > 0) 
            ? flow?.allowed_countries 
            : Object.values(countries)?.reduce((acc, crr) => {
                acc = [...acc, ...crr];
                return acc;
            }, [])

        if (selectedAny) {
            setFlow(f => ({
                ...f,
                allowed_countries: currentCountryList?.filter(ac => !countries?.[region]?.find(c => c?.name === ac?.name))?.map(c => ({ _id: c?._id, name: c?.name, code: c?.code }))
            }));
        } else {
            setFlow(f => ({
                ...f,
                allowed_countries: [ ...f?.allowed_countries, ...countries?.[region]?.map(c => ({ _id: c?._id, name: c?.name, code: c?.code })) ]
            }));
        }
    };

    return (
        <div className="marketing-flows-container">
            <div className="marketing-flows-header">
                <div className="marketing-flows-header-left">
                    <h2>{flow?.name}</h2>
                    <Spin spinning={isLoading}/>
                </div>

                <div className="marketing-flows-header-right">
                    <Button onClick={() => navigate('../marketing-flows')}>Cancel</Button>
                    <Button type="primary" onClick={handleSubmit} loading={saveLoading}>Save</Button>
                </div>
            </div>

            <div className="marketing-flow-details">
                <Form
                    layout="vertical"
                    form={form}
                >
                    <div className="horizontal-two-inputs">
                        <Form.Item 
                            label="Name" 
                            style={{ width: "48%" }}
                            rules={[
                                { 
                                    required: true,
                                    message: 'Please enter a name!',
                                }
                            ]}
                        >
                            <Input value={flow?.name} onChange={(e) => setFlow(f => ({ ...f, name: e.target.value }))}/>
                        </Form.Item>

                        <Form.Item 
                            label="Event Code" 
                            style={{ width: "48%" }}
                            rules={[
                                { 
                                    required: true,
                                    message: 'Please enter an event!',
                                }
                            ]}
                        >
                            <Input value={flow?.event_name} disabled/>
                        </Form.Item>
                    </div>

                    <Form.Item label="Description" style={{ width: "100%" }}>
                        <Input value={flow?.description} onChange={(e) => setFlow(f => ({ ...f, description: e.target.value }))}/>
                    </Form.Item>

                    <div className="horizontal-two-inputs">
                        <Form.Item
                            name="channel"
                            label="Channel"
                            rules={[
                                { 
                                    required: true,
                                    message: 'Please pick a channel!',
                                }
                            ]}
                        >
                            <Radio.Group value={flow?.channel} disabled>
                                <Radio.Button value="Sendpulse">Sendpulse</Radio.Button>
                                <Radio.Button value="Brevo">Brevo</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                </Form>
            </div>

            { Object.keys(countries)?.length > 0 && 
                <div className="country-regions">
                    <Collapse 
                        activeKey={activeKey}
                        onChange={e => {
                            setActiveKey(parseInt(e[1]))
                        }}
                    >
                        { Object.keys(countries).sort().map((region, index) => {
                            return (
                                <Panel 
                                    header={region} 
                                    extra={(
                                        <Button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                toggleAllRegion(region);
                                            }}
                                        >
                                            Change All
                                        </Button>
                                    )}
                                >
                                    <div className="checkbox-countries">
                                        { countries?.[region]?.map(country => {
                                            return (
                                                <div className="checkbox-country">
                                                    <Checkbox 
                                                        checked={flow?.allowed_countries?.find(c => c?.name === country?.name)} 
                                                        onChange={(e) => toggleSingleCountry(country, e.target.checked)}
                                                    >
                                                        {country?.name}
                                                    </Checkbox>
                                                </div>
                                            )
                                        }) }
                                    </div>
                                </Panel>
                            );
                        })}
                    </Collapse>
                </div>
            }
        </div>
    );
};

export default EditMarketingFlows;