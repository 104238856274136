import React from 'react';
import { Table } from 'antd';
import 'antd/dist/antd.css';

function MailSentStatus({ mail_status }) {
    
  const renderStatus = (mail, type) => {
    let status = false
    if(type === "sent") {
      status = mail?.messageId ? true : false
    } else {
      status = mail?.is_sent ? true : false
    }
    return (status ? '✅' : '❌')
  }

  const renderSupplierStatus = (items, type) => {
    let status = false
    if(type === "sent") {
      status = items?.some((item) => item?.messageId);
    } else {
      status = items?.some((item) => item?.is_sent);
    }
    return (status ? '✅' : '❌')
  };


  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Sent',
      dataIndex: 'sent',
      key: 'sent',
    },
    {
      title: 'Delivered',
      dataIndex: 'delivered',
      key: 'delivered',
    },
  ];
    
  let statusData = []

  const groupedSuppliers = {};

  mail_status?.['Supplier Received']?.messageId?.forEach((item) => {
    if (!groupedSuppliers[item?.supplier]) {
      groupedSuppliers[item?.supplier] = [];
    }
    groupedSuppliers[item?.supplier].push(item);
  });

  if (mail_status) {
       const sendgridStatusKeys = Object.keys(mail_status);
       statusData = sendgridStatusKeys
         ?.filter((key) => key !== 'Supplier Received') // Exclude the key
         ?.map((key) => ({
           key: key,
           name: key,
           type: 'Customer',
           sent: renderStatus(mail_status[key], "sent"),
           delivered: renderStatus(mail_status[key], "delivered")
         }));
   
         
       statusData?.push(...Object.entries(groupedSuppliers)?.map(([supplier, items]) => ({
         key: `supplier-${supplier}`,
         name: supplier,
         type: 'Supplier',
         sent: renderSupplierStatus(items, "sent"),
         delivered: renderSupplierStatus(items, "delivered"),
         children: items?.map((item) => ({
           key: item.messageId,
           name: item?.email,
           type: 'Supplier',
           sent: renderStatus(item, "sent"),
           delivered: renderStatus(item, "delivered"),
         })),
       })))
  
  }
 
  return (
    <div>
      <Table
        columns={columns}
        pagination={false}
        dataSource={statusData}
        expandable={{
          rowExpandable: (record) => record.type === 'Supplier',
        }}
      />
    </div>
  );
}

export default MailSentStatus;
