import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

import { Button, notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import './SupplierInvoice.css';
import { fetchDelete} from "../../redux/supplierInvoiceSlice";
import { useNavigate } from "react-router-dom";
import { loncaFormatDate } from '../../helpers';

function SupplierInvoice({ item }) {
  const { t } = useTranslation('translation');
  const [visible, setVisible] = useState(true);
  const [selected, setSelected] = useState(false);
  const navigate = useNavigate();
  const { languagePath } = useSelector((state) => state.user);

  const handleDelete = (e) => {
    e.preventDefault();
    fetchDelete(item._id)
      .then(() => {
        notification['success']({
          message: t('errors.Deleting successful'),
        });
        setVisible(false);
      })
      .catch((err) => {
        notification['error']({
          message: err.response.data.message,
        });
      });
  };

  if (!visible) return null;

  return (
    <div className="list-invoice">
      <div 
        className="list-supplier-invoice-item"
        onClick={() => window.open(`${languagePath}/supplier-invoice/${item?.unique_supplier_invoice_id}/${item?.vendor?._id}`)}
      >
        <div className="list-supplier-invoice-item-check">
        <input
            value={selected}
            onChange={() => setSelected((state) => !state)}
            type="checkbox"
            onClick={(e) => e.stopPropagation()} 
          />
        </div>
        <div className="list-supplier-invoice-item-code">{item?.unique_supplier_invoice_id}</div>
        <div className="list-supplier-invoice-item-vendor">{item?.vendor?.name}</div>
        <div className="list-supplier-invoice-item-created-at">{loncaFormatDate(item?.createdAt)}</div>
        
        <div id="trash" className={!selected ? 'visibility-hidden' : null}>
          <Button onClick={(e) => {e.stopPropagation(); handleDelete(e); }} icon={<DeleteOutlined />} />
        </div>
      </div>
    </div>
  );
}

export default SupplierInvoice;
