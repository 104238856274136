import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importing translation files
import translationEglish from './locales/gb/translation.json';
import translationTurkish from './locales/tr/translation.json';

// Creating object with the variables of imported translation files
const resources = {
  gb: {
    translation: translationEglish,
  },
  tr: {
    translation: translationTurkish,
  },
};

// i18N Initialization

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'tr', // default language
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
