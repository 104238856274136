import React from "react";
import { useSelector } from "react-redux";
import { Form, Input, Checkbox, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import "./Refund.scss";
import { EXCHANGE_BOXES_INVALID_ORDER_REFUND_STATUSES } from "../../../constants";

const ExchangeGeneralInfo = ({ form, refund, setRefund, setHasChanged, setIsDirty, refundReasons, showItemCountNotSeries, seriesQuantity, itemCount, refundItemCount, unitPrice, refundAmount, exchangeBox, setExchangeBox, setRefundItemCount, discountedRefundAmount, discountedUnitPrice, couponPercentageProduct }) => {

    const { allSeries: seriesMain } = useSelector((state) => state.series);

    return (

        <div>
            <div className="inline-inputs-refund">
                <Form.Item label="Vendor Name" name="vendorname">
                    <Input value={refund?.product?.vendor?.name} disabled />
                </Form.Item>

                <Form.Item label="Product Name" name="productname">
                    <Input value={refund?.product?.names?.en} disabled />
                </Form.Item>

                <Form.Item label="Supplier Stock Code" name="supplierstockcode">
                    <Input value={refund?.product?.supplier_stock_code} disabled />
                </Form.Item>

                <Form.Item>
                    <div className="exchange-cart-item-info">
                        <div>
                            Is Counted
                            <br></br>
                            <Checkbox
                                checked={refund?.order?.cart_item?.find?.(item => item?.refund === refund?._id)?.is_counted}
                                disabled
                            />
                        </div>
                        <div>
                            Is Stock In
                            <br></br>
                            <Checkbox
                                checked={refund?.order?.cart_item?.find?.(item => item?.refund === refund?._id)?.is_stock_in}
                                disabled
                            />
                        </div>
                    </div>               
                </Form.Item>
            </div> 

            <div className="inline-inputs-refund margin">
                <Form.Item label="Status" name="status">
                    <Select 
                        defaultValue={refund?.refund_status} 
                        onChange={(e) => {
                            setRefund({...refund, refund_status: e});
                            setHasChanged(c => !c)
                            setIsDirty(true)
                        }}
                    >
                        <Option value='Submitted'>Submitted</Option>
                        <Option value='Disapproved'>Disapproved</Option>
                        <Option value="Approved">Approved</Option>
                        <Option value="On Hold">On Hold</Option>                    
                        <Option value="Completed">Completed</Option>
                    </Select>
                </Form.Item>

                <Form.Item label="Reason" name="refundreason">
                    <Select
                        style={{
                            width: "100%",
                        }}
                        value={refund?.refund_reason}
                        onChange={(e) => {
                            setRefund({...refund, refund_reason: e});
                            setHasChanged(c => !c)
                            setIsDirty(true)
                        }}
                    >
                        {refundReasons.sort((a, b) => a.name.localeCompare(b.name)).map(reason => (
                        <Option value={reason?.name}>{reason?.name}</Option>))}
                    </Select>
                </Form.Item>

                <Form.Item label="Customer Note" name="customer_note">
                    <TextArea
                        placeholder="Customer Note"
                        value={refund?.note}
                        disabled
                        rows={1}
                    />
                </Form.Item>

                <Form.Item label="Customer Manager Note" name="accountmanagernote">
                    <TextArea
                        placeholder="Customer Manager Note"
                        value={refund?.account_manager_note}
                        onChange={(e) => {
                            setRefund({...refund, account_manager_note: e.target.value});
                            setHasChanged(c => !c)
                            setIsDirty(true)
                        }}
                        rows={1}
                    />
                </Form.Item>
            </div>

            <div className="inline-inputs-refund margin">
                <Form.Item label="Series Info" name="seriesinfo">
                   <Select
                        value={refund?.series || refund?.product?.series?.name}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        className="select-input-width"
                        onChange={(e) => {
                            setRefund({...refund, series: e});
                            setHasChanged(c => !c)
                            setIsDirty(true)
                        }}
                    >
                        {seriesMain?.map((serie, index) => (
                        <Option key={'series-select' + index} value={serie?.name}>
                            {serie?.name}
                        </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Series Amount" name="seriesamount">
                    <Select
                        style={{
                            width: "100%",
                        }}
                        disabled={showItemCountNotSeries()}
                        value={refund?.series_amount}
                        onChange={(e) => {
                            setRefund({...refund, series_amount: e});
                            setHasChanged(c => !c)
                            setIsDirty(true)
                        }}
                    >
                        {Array.from({length: seriesQuantity}, (_, i) => i + 1).map(number => (
                            <Option value={`${number}`}>{number}</Option>))}
                    </Select>
                </Form.Item>
                
                <Form.Item label="Item Count" name="itemcount">
                    <Select
                        style={{
                            width: "100%",
                        }}
                        disabled={!showItemCountNotSeries()}
                        value={itemCount}
                        onChange={(e) => {
                            setRefundItemCount(e)
                            setHasChanged(c => !c)
                            setIsDirty(true)
                        }}
                    >
                        {Array.from({length: itemCount * seriesQuantity}, (_, i) => i + 1).map(number => (
                        <Option value={`${number}`}>{number}</Option>))}
                    </Select>
                </Form.Item>

                {showItemCountNotSeries() &&
                    Array.from({length: refundItemCount}, (_, i) => i+1).map(item_note_index => (
                        <Form.Item label={`Item Note ${item_note_index}`}>
                            <Input 
                                value={refund?.item_notes?.[item_note_index-1]}
                                onChange={(e) => {
                                    const oldItemNotes = refund?.item_notes || [];
                                    oldItemNotes[item_note_index-1] = e.target.value;
                                    setRefund({
                                        ...refund,
                                        item_notes: oldItemNotes
                                    });
                                    setHasChanged(c => !c)
                                    setIsDirty(true)
                                }}
                            />
                        </Form.Item>
                    ))
                }
            </div>

            <div className="inline-inputs-refund margin">
                <Form.Item label="Unit Price (VAT)" name="unitprice">
                    <Input value={`$${unitPrice}`} disabled />
                </Form.Item>

                <Form.Item label="Amount" name="refundamount">
                    <Input value={refundAmount} disabled />
                </Form.Item>

                {couponPercentageProduct > 0 &&
                    <>
                    <Form.Item label="Discounted Unit Price (VAT)" name="discountedunitprice">
                        <Input value={`$${discountedUnitPrice}`} disabled />
                    </Form.Item>

                    <Form.Item label="Discounted Amount" name="discountedrefundamount">
                        <Input value={discountedRefundAmount} disabled />
                    </Form.Item>
                    </>
                }

                {refund?.order?.region === "Turkey" &&

                    <Form.Item label="Box" name="exchangebox">
                        <Select 
                            onChange={(e) => {
                                setHasChanged(c => !c)
                                setIsDirty(true)
                                setExchangeBox(refund?.possibleExchangeBoxes?.find(possibleExchangeBox => possibleExchangeBox?._id === e))
                            }}
                            disabled={EXCHANGE_BOXES_INVALID_ORDER_REFUND_STATUSES.includes(refund?.order?.refund_status)}
                        >
                            <Option key={"none"} value={null}></Option>
                                {refund?.possibleExchangeBoxes
                                    ?.filter(exchange_box => exchange_box?._id !== exchangeBox?._id)
                                    ?.map((filtered_exchange_box) => {
                                    return (
                                        <Option key={filtered_exchange_box?._id} value={filtered_exchange_box?._id}>
                                            {filtered_exchange_box?.unit}-{filtered_exchange_box?.unit_number}
                                        </Option>
                                    )
                                })}
                        </Select>
                    </Form.Item>
                }
            </div>

        </div>
                 
    );
};

export default ExchangeGeneralInfo;
