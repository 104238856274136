import React, { useEffect, useState } from 'react';

function SimpleSearch({ onSearchFunction, dataToBeSearched }) {
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    onSearchFunction(searchText);
  }, [searchText, dataToBeSearched])

  return (
    <div className="search-bar">
      <input
        type="text"
        placeholder="Search by name..."
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
    </div>
  );
}

export default SimpleSearch;
