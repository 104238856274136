/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { Form, Button, notification, Spin } from 'antd';

import PersonalInfo from './PersonalInfo';
import CreditInfo from './CreditInfo';
import Coupons from './Coupons';
import CustomerOrders from './CustomerOrders';
import CustomerReturns from './CustomerReturns';

import "./EditCustomer.css"
import { fetchUpdate, fetchCustomerById, fetchCustomerCartById } from "../../redux/customerSlice";
import { fetchCustomerCreditPayments } from "../../redux/creditPaymentSlice";
import { fetchExchangeRates } from "../../redux/exchangeRatesSlice";
import { fetchCreditApplications, updateCreditApplications } from '../../redux/creditApplicationsSlice';
import { fetchUploadUrl } from "../../redux/productSlice";

const EditCustomer = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [form] = Form.useForm();
  const [customer, setCustomer] = useState(null);
  const [oldCreditApproved, setOldCreditApproved] = useState();
  const [creditPayments, setCreditPayments] = useState([]);
  const [TL_USD_SELLING, setTL_USD_SELLING] = useState();
  const [creditApplication, setCreditApplication] = useState();
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [refresh, setRefresh] = useState(false);

  const creditApplicationFields = {
		// "signature_circulars": "İmza Sirküleri",
		"tax_plate": "Vergi Levhaları",
		"authorized_identity": "Yetkili Kimlik",
		"current_provisional_declaration": "Güncel Son Dönem Geçiçi Beyanname",
		// "current_trade_registry": "Güncel Ticaret Sicil Gazetesi",
	}

  const [tab, setTab] = useState("info");
  const [cartId, setCartId] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTab(searchParams?.get("tab") || "info");
  }, []);

  useEffect(() => {
    fetchCustomerById(id)
      .then((res) => {
        setCustomer(res.data);
        setOldCreditApproved(res.data?.credit_approved || 0);
      })
      .catch((err) => console.log(err));
  }, [id, refresh]);

  useEffect(() => {
    fetchCreditApplications({ filter: { customer: id } })
      .then(res => setCreditApplication(res[0]));
  }, [id, refresh])

  useEffect(() => {
    fetchCustomerCreditPayments(id)
      .then(res => {
        setCreditPayments(res.data?.map(payment => ({ ...payment, saved: true })));
      })
      .catch((err) => console.log(err));
  }, [id, refresh])

  useEffect(() => {
    fetchExchangeRates({ abbreviation: "TRY" })
      .then(res => {
        setTL_USD_SELLING(res?.data?.rate_usd_selling)
      }) 
  }, [])

  const handleSubmit = async () => {
    let updateCustomer = customer;

    if(updateCustomer?.credit_approved !== oldCreditApproved) {
      updateCustomer.remaining_credit = (updateCustomer?.remaining_credit || 0) + (updateCustomer?.credit_approved - oldCreditApproved);
      setCustomer(customer => ({ ...customer, remaining_credit: updateCustomer.remaining_credit }));
      setOldCreditApproved(updateCustomer?.credit_approved);
    }

    let creditApplicationUpdate = {};
    let updateApplication = false;

    for (const field of Object.keys(creditApplicationFields)) {
      try {
        creditApplicationUpdate[field] = creditApplication[field] || [];

        let newUrls = [];

        if(uploadedFiles[field]) {
          for (const uf of uploadedFiles[field]) {
            const response = await fetchUploadUrl(`credit-application/${customer?.firstName}-${customer?.lastName}/${field}-${new Date().getTime()}`);
                  
            await fetch(response.data, {
              method: "PUT",
              body: uf?.originFileObj,
            });
        
            const imageUrl = response.data.split('?')[0];
            newUrls.push(imageUrl)
          }
        }

        creditApplicationUpdate[field] = [ ...creditApplication[field], ...newUrls ];
        if(creditApplicationUpdate[field]?.length) updateApplication = true;
      } catch (err) {
        continue;
      }
    }

    if(uploadedFiles?.risk_report) {
      const response = await fetchUploadUrl(`credit-application/${customer?.firstName}-${customer?.lastName}/risk_report`);
                      
      await fetch(response.data, {
				method: "PUT",
				body: uploadedFiles?.risk_report?.originFileObj,
				headers: { "Content-Type": "application/pdf" }
			});
	
			const imageUrl = response.data.split('?')[0];
      creditApplicationUpdate.risk_report = [imageUrl];
    }

    if(updateApplication) updateCreditApplications(creditApplication?._id, creditApplicationUpdate)
      .then(res => {
        setCreditApplication(res);

        let newFiles = uploadedFiles;
        delete newFiles?.risk_report;

        setUploadedFiles({});
      })

    fetchUpdate(updateCustomer._id, updateCustomer)
      .then((res) => {
        notification['success']({
          message: 'Customer saved successfully',
        });
        setRefresh(r => !r)
      })
      .catch((err) => {
        console.log(err)
        notification['error']({
          message: 'Customer couldn\'t saved successfully',
        });
        setRefresh(r => !r)
      });
  }

  const tabHeader = () => {
    return(
      <div className='tab-button-container'>
        <div className='tab-container'>
          <div 
            className={`tab ${tab === "info" ? "selected" : ""}`}
            onClick={() => setTab("info")}
          >
            PERSONAL INFO
          </div>
          <div 
            className={`tab ${tab === "credit" ? "selected" : ""}`}
            onClick={() => setTab("credit")}
          >
            CREDIT INFO
          </div>
          <div 
            className={`tab ${tab === "coupon" ? "selected" : ""}`}
            onClick={() => setTab("coupon")}
          >
            COUPONS
          </div>

          <div 
            className={`tab ${tab === "order" ? "selected" : ""}`}
            onClick={() => setTab("order")}
          >
            ORDERS
          </div>
          <div 
            className="tab"
            onClick={() => {
              if(cartId){
                window.open(`../carts/${cartId}`)
              }else {
                setLoading(true)
                fetchCustomerCartById(id)
                .then((res) => {
                  setCartId(res.data?._id);
                  window.open(`../carts/${res.data?._id}`)
                  setLoading(false)
                })
                .catch((err) => {
                  console.log(err)
                  setLoading(false)
                });
              }
            }}
          >
            CART &nbsp; {loading && <Spin />}
          </div>
          <div 
            className="tab"
            onClick={() => {
              window.open(`../favorites/${id}`)
            }}
          >
            FAVORITES
          </div>
          <div 
            className={`tab ${tab === "return" ? "selected" : ""}`}
            onClick={() => setTab("return")}
          >
            RETURNS
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">

      <div className="customer-details">
        <Form
          form={form}
          className="customer-form"
          onFinish={handleSubmit}
          layout="vertical"
        >
          <div className="header-save">
            <h1 className="customer-header">{customer?.firstName} {customer?.lastName}</h1>
            <div className='header-buttons'>
              <Button type="primary" style={{ marginRight: "10px" }} onClick={handleSubmit}>
                Save
              </Button>
              <Button type="secondary" onClick={() => navigate('../customers')}>
                Cancel
              </Button>
            </div>
          </div>

          

          {tabHeader()}

          {
            tab === "info" ? 
            <PersonalInfo 
              customer={customer}
              setCustomer={setCustomer}
              id={id}
            /> : tab === "credit" ?
            <CreditInfo 
              customer={customer}
              setCustomer={setCustomer}
              creditPayments={creditPayments}
              setCreditPayments={setCreditPayments}
              id={id}
              TL_USD_SELLING={TL_USD_SELLING}
              creditApplication={creditApplication}
              setCreditApplication={setCreditApplication}
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
              creditApplicationFields={creditApplicationFields}
            /> : tab === "coupon" ?  
            <Coupons 
              customer={customer}
            /> : tab === "order" ?  
              <CustomerOrders customer={customer} id={id}/>
            : tab === "return" ?  
              <CustomerReturns customer={customer} id={id}/>
              : null
          }

        </Form>
      </div >
    </div >
  )
};

export default EditCustomer;