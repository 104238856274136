import axios from 'axios';
import store from './store';
import { getUrlFromState } from './userSlice';

export const fetchFlows = async () => {
    const url = getUrlFromState(store)
    const { data } = await axios.get(`${url}/marketing-flows`);
    return data;
}

export const fetchFlowById = async (id) => {
    const url = getUrlFromState(store)
    const { data } = await axios.get(`${url}/marketing-flows/${id}`);
    return data;
}

export const updateFlowById = async (id, update) => {
    const url = getUrlFromState(store)
    const { data } = await axios.patch(`${url}/marketing-flows/${id}`, update);
    return data;
}

export const createFlow = (data) => {
    const url = getUrlFromState(store)
    return axios.post(`${url}/marketing-flows`, data);
}