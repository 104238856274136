import { createSlice } from "@reduxjs/toolkit";

const bulkInvoiceSlice = createSlice({
    name: 'bulkInvoice',
    initialState: {
      bulkOrders: [],
    },
    reducers: {
      selectAllOrders: (state, action) => {
        return {
          ...state,
          bulkOrders: action.payload,
        };
      },
      removeAllOrders: (state, action) => {
        return {
          ...state,
          bulkOrders: [],
        };
      },
      addOrder: (state, action) => {
        return {
          ...state,
          bulkOrders: [...state.bulkOrders, action.payload],
        };
      },
      removeOrder: (state, action) => {
        return {
          ...state,
          bulkOrders: state.bulkOrders.filter(order => order._id !== action.payload._id),
        };
      },
    },
  });

  
  export const { addOrder, removeOrder, selectAllOrders, removeAllOrders } = bulkInvoiceSlice.actions;
    export default bulkInvoiceSlice.reducer;