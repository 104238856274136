import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getUrlFromState } from './userSlice';
import store from './store'

export const fetchSearchBarResults = createAsyncThunk(
  'search/fetchSearchBarResults',
  async (searchInfo) => {
    const url = getUrlFromState(store)
    const { data } = await axios.post(`${url}/search/admin`, {
      query: searchInfo.searchText,
      type: searchInfo.searchType,
    });
    return data;
  },
);

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    searchText: '',
    searchType: '',
    searchBarItems: [],
    loading: false,
    error: null,
  },
  reducers: {
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    setSearchType: (state, action) => {
      state.searchType = action.payload;
    },
    reset: (state) => {
      state.searchType = '';
      state.searchText = '';
      state.searchBarItems = [];
    },
    setSearchBarItems: (state, action) => {
      state.searchBarItems = action.payload;
    },
  },
  extraReducers: {
    // searchBar
    [fetchSearchBarResults.pending]: (state) => {
      state.loading = true;
    },
    [fetchSearchBarResults.fulfilled]: (state, action) => {
      state.searchBarItems = action.payload;
      state.loading = false;
    },
    [fetchSearchBarResults.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
  },
});

export const searchTextSelector = (state) => state.search.searchText;
export const searchTypeSelector = (state) => state.search.searchType;
export const searchBarItemsSelector = (state) => state.search.searchBarItems;
export const searchPageItemsSelector = (state) => state.search.searchPageItems;
export const searchLoadingSelector = (state) => state.search.loading;
export const searchErrorSelector = (state) => state.search.error;

export const {
  setSearchText, setSearchType, reset, setSearchBarItems,
} = searchSlice.actions;
export default searchSlice.reducer;
