import React, { useEffect, useState } from "react";
import { Form, Input, DatePicker, Button, notification, Upload, Modal, Checkbox, Select } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import moment from "moment";
import { loncaRound, getBase64, generateZip } from '../../helpers';

import { fetchDeleteCreditPayment, fetchSaveCreditPayment } from "../../redux/creditPaymentSlice";
import { approveCreditApplications } from "../../redux/creditApplicationsSlice";

import { CreditStatus } from "../../components";
import { listOrders } from "../../redux/orderSlice";

import "./EditCustomer.css";

const ObjectID = require("bson-objectid");

const CreditInfo = ({ 
	customer, 
	setCustomer, 
	creditPayments, 
	setCreditPayments, 
	id, 
	TL_USD_SELLING, 
	creditApplication,
	setCreditApplication,
	uploadedFiles, 
	setUploadedFiles,
	creditApplicationFields
}) => {

	const [fileMap, setFileMap] = useState({});
	const [previewVisible, setPreviewVisible] = useState(false);
	const [previewImage, setPreviewImage] = useState('');
	const [previewTitle, setPreviewTitle] = useState('');
	const [loadedFindexReport, setLoadedFindexReport] = useState("");

	const [creditStatus, setCreditStatus] = useState([]);

	useEffect(() => {

		const creditStatusFilter = {
			page: 1,
			limit: 100,
			sort: { last_credit_payment_date: 1 },
			filter: {
			  payment_type: 'Credit', status: { $nin: ['Cancelled', 'Created', 'Credit Pending'] }, credit_paid: false, customer : id
			},
		};

		listOrders({
		  filter: creditStatusFilter,
		  mode: "admin_list"
		})
		  .then(res => setCreditStatus(res?.data?.data))
		  .catch(err => {
			setCreditStatus([])
			console.log(err)
		  })
	  }, [customer, id]);

	
	const handleApprove = (status) => {
		approveCreditApplications(creditApplication?._id, status)
			.then((res) => {
				setCreditApplication(a => ({ ...a, status: res?.status }))
			})
	}

	useEffect(() => {
		if(creditApplication) {
			const asyncfunc = async () => {
				let fileMap = {};

				for (const field of Object.keys(creditApplicationFields)) {
					fileMap[field] = [];

					if(!creditApplication[field]) {
						continue;
					}

					const fieldArr = Array.isArray(creditApplication[field]) ? creditApplication[field] : [creditApplication[field]];

					for (const f of fieldArr) {
						try {
						const data = await fetch(f).then((response) => response.blob()).catch(error => console.log('error', error));
						const blob = new Blob([data]);
						const url = window.URL.createObjectURL(blob);

							fileMap[field].push({
								thumbUrl: url, 
								url, 
								status: "done",
								name: creditApplicationFields[field],
								original_url: f
							})
						} catch(err) {
							fileMap[field].push({
								thumbUrl: f, 
								url: f, 
								status: "done",
								name: creditApplicationFields[field],
								original_url: f
							})
						}
					}
				}

				setFileMap(fileMap)
			};
			asyncfunc();
		}
	}, [creditApplication])

	const handleDownload = async () => {
		let filesArray = []
		for(const key of Object.keys(creditApplicationFields)) {
			if(Array.isArray(creditApplication[key])){
				filesArray= filesArray.concat(creditApplication[key]) 
			}else if (creditApplication[key])
			{ filesArray =filesArray.concat([creditApplication[key]])}
		}
		generateZip(filesArray,`${customer?.firstName}_${customer?.lastName}_credit_application`)
	}

    const deleteCreditPayment = async (paymentId) => {
        const payment = creditPayments.find(cp => cp?._id?.toString() === paymentId?.toString());
        setCreditPayments(creditPayments => creditPayments.filter(cp => cp?._id?.toString() !== paymentId?.toString()));
      
        if(payment?.saved) fetchDeleteCreditPayment(paymentId)
            .then((res) => {
                notification['success']({
                    message: 'Credit payment is deleted successfully',
                });
                setCustomer(customer => ({ ...customer, remaining_credit: res.data?.remaining_credit }))
            })
            .catch((err) => {
                console.log(err)
                notification['error']({
                    message: 'Credit payment couldn\'t deleted successfully',
                });
            });
        } 
    
    const saveCreditPayment = async (paymentId) => {
        const payment = creditPayments.find(cp => cp?._id?.toString() === paymentId?.toString());
        let updatedPayment = payment ? { ...payment } : {};
        delete updatedPayment.saved;
    
        fetchSaveCreditPayment(updatedPayment)
            .then((res) => {
                notification['success']({
                    message: 'Credit payment is saved successfully',
                });
    
                setCustomer(customer => ({ ...customer, remaining_credit: res.data?.remaining_credit }))
                setCreditPayments(creditPayments.map(creditPayment => {
                    if(creditPayment?._id?.toString() === paymentId?.toString()) {
                        return {
                            ...creditPayment,
                            saved: true
                        }
                    }
                    
                    return creditPayment;
                }))
            })
            .catch((err) => {
                console.log(err)
                notification['error']({
                message: 'Credit payment couldn\'t saved successfully',
                });
            });
        } 

	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}
	
		setPreviewImage(file.url || file.preview);
		setPreviewVisible(true);
		setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
	};

	const handleReportUpload = async (e, type) => {
		// Check if a file was selected
        if (e.target.files && e.target.files.length > 0) {
			const url = await getBase64(e.target.files[0]);
			if(type === "risk_report") {
				setUploadedFiles(files => ({ ...files, [type]: { originFileObj: e.target.files[0], url } }));
			} else {
				setUploadedFiles(files => ({ 
					...files, 
					[type]: [
						...(uploadedFiles[type] || []),
						{ 
							originFileObj: e.target.files[0], 
							url, 
							uploaded: true,
							type,
							uid: new Date().getTime()
						} 
					]
				}));
			}
        }
	}

    return (
        <Form.Item>
			<Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={() => setPreviewVisible(false)}>
				<img
					alt="example"
					className="input-width"
					src={previewImage}
				/>
			</Modal>

            <div className="credit-info-container">
				<div className="credit-info">
					{ creditApplication && 
						<div className="application">
							<div className="label left-align">Credit Application</div>
							<div className="border">
								<div className="inline-inputs">
									<Form.Item label="Status">
										<Input
											value={creditApplication?.status}
											disabled
										/>
									</Form.Item>
									<Form.Item label="Application Date">
										<DatePicker
											className="payment-date"
											value={moment(creditApplication?.createdAt)}
											disabled
										/>
									</Form.Item>
								</div>

								<div className="papers">
									{ Object.keys(creditApplicationFields)?.map(field => 
										{
											let uploadedList = [];

											if(uploadedFiles[field]) {
												for (const uploaded of uploadedFiles[field]) {
													uploadedList.push({
														thumbUrl: uploaded?.url, 
														url: uploaded?.url, 
														status: "done",
														name: creditApplicationFields[field],
														...uploaded
													});
												}
											}

											const fileList = [
												...(fileMap[field] || []),
												...uploadedList
											];

											return (
												<div className="paper">
													<div className="paper-name">{creditApplicationFields[field]}</div>

													{ fileMap[field] ? 
														<Upload 
															onPreview={handlePreview}
															listType="picture-card"
															fileList={fileList}
															onChange={({ file: updatedFile }) => {
																if(updatedFile?.status === "removed") {
																	if(updatedFile?.uploaded) {
																		setUploadedFiles(files => ({
																			...files,
																			[field]: uploadedFiles[field]?.filter(uf => uf?.uid !== updatedFile?.uid)
																		}))
																	} else {
																		setCreditApplication({
																			...creditApplication,
																			[field]: creditApplication[field]?.filter(uf => uf !== updatedFile?.original_url)
																		})
																	}
																}
															}}
														/> : 
														<LoadingOutlined />
													}

													<Button
														type="secondary"
														onClick={() => {
															const importFile = document.getElementById(`${field}-upload`);
															// Reset the file input and open the file input dialog
															importFile.value = "";
															importFile.click();
														}}		
													>
														<PlusOutlined />
														<input 
															type="file" 
															id={`${field}-upload`}
															style={{ display: "none" }} 
															onChange={(e) => handleReportUpload(e, field)}
														/> 
													</Button>
												</div>
											)
										}) 
									}
								</div>

								<div className="credit-application-buttons">
									{ ["In Progress", "On Hold"].includes(creditApplication?.status) &&
										<>
											<Button
												type="primary"
												onClick={() => handleApprove("Approved")}
												className="approve"
											>
												Approve
											</Button>
											<Button
												type="primary"
												onClick={() => handleApprove("Disapproved")}
												className="disapprove"
											>
												Disapprove
											</Button>
											<Button
												type="secondary"
												onClick={() => handleApprove("On Hold")}
												className="onhold"
											>
												On Hold
											</Button>
										</>
									}
									<Button
										type="primary"	
										onClick={handleDownload}
									>
										Download Documents
									</Button>
								</div>
							</div>
						</div>
					}

					<div className="label left-align">Findex Report</div>
					<div className="border">
						<div className="findex-status left-align">
							<span className="findex-status-label">Report Status:</span>
							<span>
								{ 
									(Array.isArray(creditApplication?.risk_report) && creditApplication?.risk_report?.length) || 
									(!Array.isArray(creditApplication?.risk_report) && creditApplication?.risk_report) ?
									"Uploaded" : "Not uploaded"
								}
							</span>
						</div>

						{ uploadedFiles?.risk_report &&
							<div className="findex-warning">
								<span>* You've uplaoded a new report, you should save your changes before leaving the page!</span>
							</div>
						}

						<div className="findex-buttons">
							<Button
								disabled={
									!(Array.isArray(creditApplication?.risk_report) && creditApplication?.risk_report?.length) && 
									!(!Array.isArray(creditApplication?.risk_report) && creditApplication?.risk_report) &&
									!uploadedFiles?.risk_report
								}
								type="primary"
								className="approve"
							>
								<a target="_blank" rel="noreferrer noopener" href={uploadedFiles?.risk_report ? window.URL.createObjectURL(uploadedFiles?.risk_report?.originFileObj) : creditApplication?.risk_report}>Preview Report</a>
							</Button>
							<Button
								disabled={!(Array.isArray(creditApplication?.risk_report) && creditApplication?.risk_report?.length) && 
									!(!Array.isArray(creditApplication?.risk_report) && creditApplication?.risk_report)}
								type="primary"
								onClick={async () => {
									const key = Array.isArray(creditApplication?.risk_report) ? creditApplication?.risk_report[0] : creditApplication?.risk_report

									let url = loadedFindexReport;
									
									if(!loadedFindexReport) {
										const data = await fetch(key).then((response) => response.blob()).catch(error => console.log('error', error));
										const blob = new Blob([data]);
										url = window.URL.createObjectURL(blob);
										setLoadedFindexReport(window.URL.createObjectURL(blob));
									}

									const a = document.createElement('a');
									a.style.display = 'none';
									a.href = url;
									// the filename you want
									a.download = `${customer?.firstName}_${customer?.lastName}_findex_report.pdf`;
									document.body.appendChild(a);

									a.click();
									a.parentNode.removeChild(a);
								}}
								className="approve"
							>
								Download Report
							</Button>

							<Button
								type="primary"
								onClick={() => {
									const importJsonFile = document.getElementById("importReportFile");
									// Reset the file input and open the file input dialog
									importJsonFile.value = "";
									importJsonFile.click();
								}}		
							>
								Upload Report
								<input 
									type="file" 
									id="importReportFile" 
									style={{ display: "none" }} 
									onChange={(e) => handleReportUpload(e, "risk_report")}
								/> 
							</Button>
						</div>
					</div>

					<div className="label left-align">Credit Amounts</div>
					<div className="inline_inputs border">
						<Form.Item label="Approved Credit Amount">
							<Input
								prefix="$"
								value={customer?.credit_approved || ''}
								onChange={(e) => setCustomer({ ...customer, credit_approved: e.target.value })}
							/>
						</Form.Item>
						<Form.Item label="Remaining Credit Amount">
							<Input
								prefix="$"
								disabled
								value={customer?.remaining_credit || 0}
							/>
						</Form.Item>

						<Form.Item label="Seized Status">
							<Select
								value={customer?.customer_details?.seized_status ? customer?.customer_details?.seized_status : ""}
								onChange={(value) => {
								setCustomer({ 
									...customer, 
									customer_details: {
									...customer.customer_details,
									...(customer.customer_details && customer.customer_details.seized_status
										? { seized_status:  value}
										: { ...customer.customer_details, seized_status: value}
									)
									}
								})
								}}
							>
								<Option value=""></Option>
								<Option value="Will be Seized">Will be Seized</Option>
								<Option value="Seized">Seized</Option>
							</Select>
							</Form.Item>

						<Form.Item label="Blacklisted">
							<Select value={customer?.blacklisted} 
								onChange={(value) => setCustomer({ ...customer, blacklisted: value })}>
								<Option value={null}></Option>
								<Option value={true}>Yes</Option>
								<Option value={false}>No</Option>
							</Select>
						</Form.Item>

					</div>
				</div>

				<div className="right">
					<h3 className="label">Credit Payments</h3>
					<div>
						<div className="payment-list">
							{ creditPayments?.map((creditPayment, index) => (
								<>
									<Form.Item>
										<div className="payment">
											<div className="inline_three_inputs_seized">
												<Form.Item label="USD Amount">
													<Input
														prefix="$"
														value={ creditPayment?.usd_amount }
														onChange={(e) => {
															const updatedCreditPayments = creditPayments?.map((payment => {
																if(payment?._id?.toString() === creditPayment?._id?.toString()) {
																	return {
																		...payment,
																		usd_amount: e.target.value,
																		try_amount: loncaRound(parseFloat(e.target.value) * payment?.usd_rate)
																	}
																}
																return payment;
															}))
															setCreditPayments(updatedCreditPayments)
														}}
													/>
												</Form.Item>

												<Form.Item label="TRY Amount">
													<Input
														prefix="₺"
														value={ creditPayment?.try_amount }
														onChange={(e) => {
															const updatedCreditPayments = creditPayments?.map((payment => {
																if(payment?._id?.toString() === creditPayment?._id?.toString()) {
																	return {
																	...payment,
																	try_amount: e.target.value,
																	usd_rate: parseFloat(payment?.usd_amount) !== 0 ? loncaRound(parseFloat(e.target.value) / payment?.usd_amount) : payment?.usd_rate,
																	}
																}
																return payment;
															}))
															setCreditPayments(updatedCreditPayments)
														}}
													/>
												</Form.Item>
												<Form.Item label="Seized">
													<Checkbox
														checked={creditPayment?.isSeized}
														onChange={(e) => {
															const updatedCreditPayments = creditPayments?.map((payment => {
																if(payment?._id?.toString() === creditPayment?._id?.toString()) {
																	return {
																		...payment,
																		isSeized: e.target.checked
																	}
																}
																return payment;
															}))
															setCreditPayments(updatedCreditPayments);
														}}
													/>
												</Form.Item>
											</div>

											<div className="inline-inputs">
												<Form.Item label="USD-TRY Rate">
													<Input
														value={ creditPayment?.usd_rate }
														onChange={(e) => {
															const updatedCreditPayments = creditPayments?.map((payment => {
																if(payment?._id?.toString() === creditPayment?._id?.toString()) {
																	return {
																	...payment,
																	usd_rate: e.target.value,
																	usd_amount: parseFloat(e.target.value) !== 0 ? loncaRound(parseFloat(payment?.try_amount) / parseFloat(e.target.value)) : payment?.usd_amount
																	}
																}
																return payment;
															}))
															setCreditPayments(updatedCreditPayments)
														}}
													/>
												</Form.Item>

												<Form.Item label="Payment Date">
													<DatePicker
														className="payment-date"
														showTime
														value={creditPayment?.payment_date ? moment(creditPayment?.payment_date) : null}
														onChange={(e) => {
															const updatedCreditPayments = creditPayments?.map((payment => {
																if(payment?._id?.toString() === creditPayment?._id?.toString()) {
																	return {
																	...payment,
																	payment_date: new Date(e._d)
																	}
																}
																return payment;
															}))
															setCreditPayments(updatedCreditPayments)
														}}
													/>
												</Form.Item>
											</div>

											<Button 
												type="primary" 
												className="button-margin calculate-button"
												onClick={() => saveCreditPayment(creditPayment?._id)}
											>
												Save
											</Button>

											<Button 
												type="primary" 
												className="button-margin calculate-button"
												onClick={() => deleteCreditPayment(creditPayment?._id)}
											>
												Delete
											</Button>

										</div>
									</Form.Item>
								</>
							)) }

							<Button 
								type="primary" 
								className="button-margin calculate-button"
								onClick={() => {
									setCreditPayments(creditPayments => 
										[
											...creditPayments, 
											{ 
												_id: ObjectID(),
												customer: id,
												usd_amount: 0, 
												try_amount: 0, 
												usd_rate: TL_USD_SELLING, 
												payment_date: 0, 
												saved: false
											}
										]
									)
								}}
							>
								Add Credit Payment
							</Button>

						</div>
					</div>
				</div>
            </div>

			<div className="non-paid-orders">
				<div className="label">Non Paid Credit Orders</div>
				<div className="list-credit-status-table-head">
					<div className="list-credit-status-table-check">*</div>
					<div className="list-credit-status-table-first">First Name</div>
					<div className="list-credit-status-table-last">Last Name</div>
					<div className="list-credit-status-table-total-credit">Total Credit</div>
					<div className="list-credit-status-table-amount-due">Credit Amount Due</div>
					<div className="list-credit-status-table-due-date">Credit Due Date</div>
				</div>
				{creditStatus?.map((item) => (
					<CreditStatus key={item._id} item={item} isCustomerPage={true}/>
				))}
			</div>

			
        </Form.Item>
    )
};

export default CreditInfo;