import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Form, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { getBase64 } from '../../helpers';
import { separateAndCapitalize } from '../../helpers';
import { ImageBox } from '..';

import { fetchCategorizedTags } from '../../redux/tagSlice';

import './TagSelector.scss';

const TagSelector = ({ product, setProduct, setIsDirty, fileList, setFileList }) => {

    const { status: tagStatus, tags, categorizedTags } = useSelector((state) => state.tags);
    const dispatch = useDispatch();

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewTitle, setPreviewTitle] = useState('');
    const [previewImage, setPreviewImage] = useState('');

    useEffect(() => {
        if (tagStatus.fetchCategorizedTags === "idle") dispatch(fetchCategorizedTags());
    }, [tagStatus]);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
    
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleTagChange = (e) => {
        if (!product.tags.find((tag) => tag._id === e)) {
            setProduct({ ...product, tags: [...product.tags, tags.find((a) => a?._id === e)] });
        }
        setIsDirty(true);
    };

    const deleteTag = (tag) => {
        setProduct({ ...product, tags: product.tags.filter((item) => item._id !== tag._id) });
        setIsDirty(true);
    };

    const sortBasedOnType = (a, b) => a?.type?.localeCompare(b?.type);

    const seasonalTagExists = (type, tags) => {
        const seasonalTagExists = tags?.some(t => t?.type === type) && type === "season"
        return seasonalTagExists;
    }

    return (
        <div className='tag-selector-container'>
            <div className='tag-selector'>
                <div className="multi-container-tag-selector">
                    {product?.tags?.sort(sortBasedOnType).map((tag, index, array) => (
                        <div className={`multi-item-tag-selector ${index > 0 && array[index - 1]?.type !== tag?.type && "grouped"}`} key={index}>
                            { (tag?.type !== 'tag' && tag?.type !== 'price') && <CloseOutlined onClick={() => deleteTag(tag)} /> }
                            { tag?.names?.en }
                            <br />
                            <span className='multi-item-tag-type'>{separateAndCapitalize(tag?.type, '_')}</span>
                        </div>
                    ))}
                    {product?.tags?.length === 0 && <div>No tag</div>}

                </div>
                <Form.Item name="tags" className='multiple-tag-selection-container'>
                    <div className='multiple-tag-selection'>
                        {product?.product_type && categorizedTags?.[product?.product_type?.handle] && Object.keys(categorizedTags?.[product?.product_type?.handle])?.sort()?.map((type) => (
                            <div className={`tag-selector-select-container`}>
                                <label>{separateAndCapitalize(type, '_')}</label>
                                <Select
                                    key={'select-' + type}
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={handleTagChange}
                                    placeholder={`Add ${separateAndCapitalize(type, '_')} Tag`}
                                    className={`tag-selector-select ${!seasonalTagExists(type, product?.tags) ? "error-display" : ""}`}
                                    disabled={type === 'tag' || type === "price"}
                                >
                                    {categorizedTags?.[product?.product_type?.handle]?.[type]
                                        ?.slice()
                                        .sort((a, b) => a?.names?.en?.localeCompare(b?.names?.en))
                                        .map((tag, index) => (
                                            <Select.Option key={'tag-select' + index} value={tag?._id}>
                                                {tag?.names?.en}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </div>
                        ))}
                    </div>
                    {!product?.product_type && <Alert message={"First you need to specify Product Type to select Tags"} type="info" />}
                </Form.Item>
            </div>

            <ImageBox
                fileList={fileList}
                product={product}
                handlePreview={handlePreview}
                setFileList={setFileList}
                getBase64={getBase64}
                previewVisible={previewVisible}
                previewTitle={previewTitle}
                previewImage={previewImage}
                setPreviewVisible={setPreviewVisible}
                isEditable={false}
            />
        </div>
    );
};

export default TagSelector;
