import React, { useEffect, useState } from "react";
import { Spin } from 'antd';
import { useTranslation } from "react-i18next";
import "./EditCustomer.css";

import { listOrders } from "../../redux/orderSlice";
import { Order } from "../../components";

const CustomerOrders = ({ 
	customer, 
	id, 
}) => {

    const { t } = useTranslation('translation');
    const [orders, setOrders] = useState([])
    const [count, setCount] = useState(0)
    const [loading, setLoading] = useState(false)

    useEffect(() => {

        setLoading(true);

		const customerOrdersFilter = {
			page: 1,
			limit: 100,
			sort: { checkout_date: -1 },
			filter: {
			  customer : id
			},
		};

		listOrders({
		  filter: customerOrdersFilter,
		  mode: "admin_list"
		})
		  .then(res => {
            setOrders(res?.data?.data);
            setCount(res?.data.totalCount[0]?.count);
            setLoading(false);
          })
		  .catch(err => {
			setOrders([]);
            setCount(0);
            setLoading(false);
			console.log(err)
		  })
	}, [customer, id]);

    return (
        <div>
            <div className="list-orders-header">
                <div className='list-orders-number'>
                    <span style={{fontStyle: 'italic'}}>Number of orders:</span>{' ' + count}
                </div>
                <div className="list-orders-table-head">
                    <div className="list-orders-table-check">*</div>
                    <div className="list-orders-table-fname">{t(`fields.orders.First Name`)}</div>
                    <div className="list-orders-table-lname">{t(`fields.orders.Last Name`)}</div>
                    <div className="list-orders-table-status">{t(`fields.orders.Status`)}</div>
                    <div className="list-orders-table-cost">{t(`fields.orders.Total Cost`)}</div>
                    <div className="list-orders-table-invoice">{t(`fields.orders.Customer Pending`)}</div>
                    <div className="list-orders-table-cargo-company">{t(`fields.orders.Region`)}</div>
                    <div className="list-orders-table-checkout">{t(`fields.orders.Checkout Date`)}</div>
                    <div className="list-orders-table-payment">{t(`fields.orders.Payment Date`)}</div>
                    <div className="list-orders-table-completed">{t(`fields.orders.Completed Date`)}</div>
                </div>
            </div>
            {loading && <Spin style={{ "marginTop": "20px" }} tip={"Loading Orders..."} />}
            {orders.map((item) => {
                return (
                    <Order key={item._id} item={item} />
                )
            })}
        </div>
    )
};

export default CustomerOrders;