import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
import './BarChart.scss';
Chart.register(CategoryScale);

const BarChart = ({ data }) => {
    const total = data.datasets[0].data.reduce((acc, value) => acc + value, 0);

    const options = {
        maintainAspectRatio: true,
        cutoutPercentage: 50,
        plugins: {
            legend: {
                display: false,
                position: 'top', // Change position to 'bottom'
                align: 'center',
                labels: {
                    fontColor: 'black',
                    boxWidth: 12, // Adjust the legend box width
                    padding: 10, // Add padding between legend items
                },
            },
            datalabels: {
                display: true,
                color: 'white',
                font: {
                    size: '16',
                },
                formatter: (value, context) => {
                    return `${value}`;
                },
            },
            tooltips: {
                enabled: true,
                mode: 'index',
                intersect: false,
                backgroundColor: 'rgba(0,0,0,0.8)',
                titleFontColor: '#fff',
                bodyFontColor: '#fff',
                footerFontColor: '#fff',
                callbacks: {
                    label: (tooltipItem, data) => {
                        const dataset = data.datasets[tooltipItem.datasetIndex];
                        const currentValue = dataset.data[tooltipItem.index];
                        const percentage = ((currentValue / total) * 100).toFixed(2);
                        return `${dataset.label}: ${currentValue} (${percentage}%)`;
                    },
                },
            },
        },
    };

    return (
        <div className='bar-chart-container'>
            <Bar data={data} options={options} />
            <ul className='bar-chart-data-values'>
                {data?.datasets?.[0]?.data?.map((label) => <li>{label}</li>)}
            </ul>
            <p className='bar-chart-total'>Total: {total}</p>
        </div>
    );
};

export default BarChart;
