import axios from 'axios';
import { getUrlFromState } from './userSlice';
import store from './store'

export const fetchSupplierInvoices = (filter) => {
    const url = getUrlFromState(store)
    return axios.post(`${url}/supplier-invoice/filter`, { query: filter });
}

export const fetchSupplierInvoiceByUniqueId = (uniqueId) => {
    const url = getUrlFromState(store)
    return axios.get(`${url}/supplier-invoice/one/${uniqueId}`);
}

export const fetchDelete = (id) => {
    const url = getUrlFromState(store)
    return axios.delete(`${url}/supplier-invoice/one/${id}`);
}
