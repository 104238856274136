import React, { useEffect, useState } from "react";
import "./AddNewBoxPopup.scss";
import Form from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import {Button, Input, InputNumber, Select, notification } from "antd";
import { addExchangeBox } from "../../../redux/warehouseBoxSlice";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const AddNewBoxPopup = ({ setIsAddNewBoxPopupOpen, exchangeOrders }) => {
    const { t } = useTranslation('translation');
    const [buttonLoading, setButtonLoading] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState();
    const { Option } = Select;
    
    const handleBoxSubmit = async () => {
        if(!selectedOrder) {
            notification['error']({
                message: t("shelves.Please Select an Order")
            });
            return;
        }
        setButtonLoading(true);
        addExchangeBox({ order: selectedOrder, type: "exchange"}).then((response) => {
            notification['success']({
                message: t("shelves.Box Created Successfully")
            });
        }).catch((err) => {
            console.log(err);
            notification['error']({
                message: t("shelves.Error Creating Box")
            });
        });
        setTimeout(() => {
            setButtonLoading(false);
            setIsAddNewBoxPopupOpen(false);
            window.location.reload();
        }, 500);
    }

    const handleBoxCancel = async () => {
        setIsAddNewBoxPopupOpen(false);
    }

    return (
        <Form onFinish={handleBoxSubmit}>
            <div className="add-new-box-popup-container">
                <span className="add-new-box-popup-header">{t('shelves.Add New Exchange Box')}</span>
                <FormItem label={t('shelves.Order')}>
                    <Select
                        onChange={(value) => {
                            setSelectedOrder(
                              exchangeOrders.find((order) => order.unique_order_id === value)
                            );
                          }}
                        showSearch
                    >
                        {exchangeOrders?.map((order) => (
                            <Option key={order?.unique_order_id} value={order?.unique_order_id}>
                                <div>
                                <div>
                                    {order?.unique_order_id} - {order?.customer_name}
                                </div>
                                <div>
                                    {t("shelves.Status")}: {order?.refund_status}
                                </div>
                                <div>
                                    {t("shelves.Payment Date")}:{" "}
                                    {new Date(order?.payment_at).toLocaleDateString()}
                                </div>
                                </div>
                            </Option>
                        ))}
                    </Select>
                </FormItem>
                <div className="add-new-box-popup-button-container">
                    <FormItem>
                        <Button type="primary" htmlType="submit" loading={buttonLoading}>
                            {t('shelves.Submit')}
                        </Button>
                    </FormItem>
                    <FormItem>
                        <Button type="secondary" onClick={handleBoxCancel} loading={buttonLoading}>
                            {t('shelves.Cancel')}
                        </Button>
                    </FormItem>
                </div>
            </div>
        </Form>
    );
}

export default AddNewBoxPopup;