import axios from 'axios';
import store from './store';
import { getUrlFromState } from './userSlice';

export const fetchFirstMileShipments = (filter) => {
  const url = getUrlFromState(store)
  return axios.get(`${url}/first-mile-shipments/list`, {
    params: filter,
  })
};


