import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import store from './store';
import { getUrlFromState } from './userSlice';

export const fetchFilterTypes = createAsyncThunk('tag/fetchFilterTypes', async () => {
    const url = getUrlFromState(store)
    const { data } = await axios.get(`${url}/filters`);
    return data;
});

export const fetchUpdate = async (filterId, update) => {
    const url = getUrlFromState(store)
    const { data } = await axios.patch(`${url}/filters/one/${filterId}`, update);
    return data;
}

export const fetchCreate = async (filter) => {
    const url = getUrlFromState(store)
    const { data } = await axios.post(`${url}/filters/new`, filter);
    return data;
}

const filterTypeSlice = createSlice({
    name: 'filter',
    initialState: {
        filterTypes: [],
        status: {
            fetchFilterTypes: 'idle'
        },
        error: null,
    },

    reducers: {
        addFilter: (state, action) => {
            state.filterTypes = [...state.filterTypes, action.payload];
        },
    },
  
    extraReducers: {
        // filter types
        [fetchFilterTypes.pending]: (state, action) => {
            state.status.fetchFilterTypes = 'pending';
        },
        [fetchFilterTypes.fulfilled]: (state, action) => {
            state.filterTypes = action.payload;
            state.status.fetchFilterTypes = 'fulfilled';
        },
        [fetchFilterTypes.rejected]: (state, action) => {
            state.status.fetchFilterTypes = 'rejected';
        },
    }
});

export const { addFilter } = filterTypeSlice.actions;

export default filterTypeSlice.reducer;