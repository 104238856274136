import React, { useEffect, useState } from "react";
import { Spin } from 'antd';
import "./EditCustomer.css";

import { fetchCustomerRefunds } from "../../redux/refundSlice";
import { Refund } from "../../components";

const CustomerReturns = ({ 
	customer, 
	id, 
}) => {

    const [count, setCount] = useState(0)
    const [returns, setReturns] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {

        setLoading(true);

		const filter = {
			page: 1,
			limit: 100,
			filter: {
			  customer : id
			},
		};

		fetchCustomerRefunds(filter)
		  .then(res => {
            setReturns(res);
            setCount(res?.length || 0);
            setLoading(false);
          })
		  .catch(err => {
			setReturns([]);
            setCount(0);
            setLoading(false);
			console.log(err)
		  })
	}, [customer, id]);

    return (
        <div>
            <div className='list-orders-number'>
                <span style={{fontStyle: 'italic'}}>Number of returns:</span>{' ' + count}
            </div>
            <div className="list-refund-table-head">
                <div className="list-refund-table-order">Order</div>
                <div className="list-refund-table-customer">Customer</div>
                <div className="list-refund-table-count">Return Count</div>
                <div className="list-refund-table-status">Status</div> 
                <div className="list-refund-table-status">Order Delivery Date</div> 
                <div className="list-refund-table-status">Request Date</div> 
            </div>
            {loading && <Spin style={{ "marginTop": "20px" }} tip={"Loading Refunds..."} />}

            {!loading && returns?.map((item) => (
                <Refund key={item._id} item={item} />
            ))}
        </div>
    )
};

export default CustomerReturns;