import React from 'react';
import { Buffer } from 'buffer';
import { pure } from 'recompose';

const cloudFrontURL = 'https://db5nh1xljxisr.cloudfront.net';
const bucket = 'loncapazar';

const responsiveImageConfig = {
  srcSetWidths: [150, 300, 450, 640, 768, 1024, 1280, 1366, 1600, 1920, 3200],
  defaultQuality: 100,
  circle: false,
  format: 'webp',
};

function createSrcSetItem(imagePath, width, height, quality, circle, format, isMain) {
  const imagePathDecoded = decodeURIComponent(imagePath);
  const transition = {
    bucket,
    key: imagePathDecoded,
    edits: {
      resize: {
        fit: 'contain',
      },
      rotate: null,
      roundCrop: circle,
      format,
      webp: {
        quality,
      },
    },
  };

  if (width) {
    transition.edits.resize.width = width * 1.3;
  } else if (height) {
    transition.edits.resize.height = height * 1.3;
  }

  return isMain ? `${cloudFrontURL}/${Buffer.from((JSON.stringify(transition))).toString('base64')}` : `${cloudFrontURL}/${Buffer.from((JSON.stringify(transition))).toString('base64')} ${width}w`;
}

function ResponsiveImage({
  src, alt, className, maxWidth, minWidth, height, width, loading = 'lazy', style, quality = responsiveImageConfig.defaultQuality, circle = responsiveImageConfig.circle, format = responsiveImageConfig.format,
}) {
  const imagePath = src?.split('/').slice(3).join('/');

  if (height) {
    return (
      <img
        loading={loading}
        src={createSrcSetItem(imagePath, null, height, quality, circle, format, true)}
        alt={alt}
        className={className}
        height={height}
        width={width}
      />
    );
  }
  if (maxWidth) {
    responsiveImageConfig.srcSetWidths = responsiveImageConfig.srcSetWidths.filter(
      (widthValue) => widthValue <= maxWidth,
    );
  }

  if (minWidth) {
    responsiveImageConfig.srcSetWidths = responsiveImageConfig.srcSetWidths.filter(
      (widthValue) => widthValue >= minWidth,
    );
  }

  const srcSet = responsiveImageConfig.srcSetWidths.map(
    (widthValue) => createSrcSetItem(imagePath, widthValue, null, quality, circle, format, false),
  );
  const srcMain = createSrcSetItem(imagePath, maxWidth, null, 100, circle, format, true);

  return (
    <div>
      <picture>
        {srcSet.map((srcItem, index) => (
          <source key={`source-${responsiveImageConfig.srcSetWidths[index]}`} alt={alt} media={`(max-width: ${responsiveImageConfig.srcSetWidths[index]}px)`} srcSet={srcItem} />
        ))}
        {maxWidth ? <source key="max" alt={alt} media={`(min-width: ${maxWidth}px)`} srcSet={srcMain} loading={loading} /> : null}
        <img className={className} src={srcMain} alt={alt} loading={loading} style={style} />
      </picture>
    </div>
  );
}

export default pure(ResponsiveImage);
