import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getUrlFromState } from './userSlice';
import store from './store'

export const fetchRefunds = createAsyncThunk(
  'refunds/fetchRefunds',
  async ({ filter, signal = null }, { rejectWithValue }) => {
    try {
      const url = getUrlFromState(store);
      const { data } = await axios.post(`${url}/refund/list`, filter, { signal });
      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const fetchCustomerRefunds = async (filter) => {
  const url = getUrlFromState(store)
  const { data } = await axios.post(`${url}/refund/list`, filter);
  return data;
}

export const fetchRefund = async (payload) => {
  const url = getUrlFromState(store)
  const res = await axios.post(`${url}/refund/index-one`, payload);
  return res;
};

export const approveRefund = async (id, status) => {
  const url = getUrlFromState(store)
  const { data } = await axios.patch(`${url}/refund?id=${id}`, { status });
  return data;
};

export const fetchUpdate = (id, update, additionalRefundUpdate) => {
  const url = getUrlFromState(store)
  return axios.patch(`${url}/refund/one/${id}`, {
  update,
  additionalRefundUpdate
})};

export const fetchReasons = () => {
  const url = getUrlFromState(store)
  return axios.post(`${url}/refund-reason/list`);
};

export const fetchOrderProductsForRefund = (orderId) => {
  const url = getUrlFromState(store)
  return axios.post(`${url}/refund/get`, {orderId});
};

export const createRefund= (data) => {
  const url = getUrlFromState(store)
  return axios.post(`${url}/refund/create`, {data});
};

export const fetchRefundProducts = createAsyncThunk(
  'refunds/fetchRefundProducts',
  async ({ filter, signal = null}, { rejectWithValue }) => {
    try {
      const url = getUrlFromState(store);
      const { data } = await axios.post(`${url}/refund/list-products`, filter, { signal });
      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const createOrderExchange = async (payload) => {
  const url = getUrlFromState(store);
  return axios.post(`${url}/refund/order-admin`, payload)
}

const refundSlice = createSlice({
  name: 'refund',
  initialState: {
    filter: {
      page: 1,
      limit: 30,
    },
    refunds: [],
    refundProducts: [],
    status: {
      fetchRefunds: 'idle',
      fetchRefundProducts: 'idle'
    },
    error: null,
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    resetRefundFilter: (state) => {
      state.filter = {
        page: 1,
        limit: 30,
      };
    },
  },
  extraReducers: {
    // normal filter results
    [fetchRefunds.pending]: (state) => {
      state.status.fetchRefunds = 'pending';
    },
    [fetchRefunds.fulfilled]: (state, action) => {
      state.status.fetchRefunds = 'succeeded';
      state.refunds = action.payload;
    },
    [fetchRefunds.rejected]: (state, action) => {
      state.status.fetchRefunds = 'rejected';
      state.error = action.payload;
    },

    [fetchRefundProducts.pending]: (state) => {
      state.status.fetchRefundProducts = 'pending';
    },
    [fetchRefundProducts.fulfilled]: (state, action) => {
      state.status.fetchRefundProducts = 'succeeded';
      state.refundProducts = action.payload;
    },
    [fetchRefundProducts.rejected]: (state, action) => {
      state.status.fetchRefundProducts = 'rejected';
      state.error = action.payload;
    },

  },
});

export const { setFilter, resetRefundFilter } = refundSlice.actions;

export default refundSlice.reducer;
