import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { loncaFormatDate } from '../../helpers';

import './CreditApplication.css';

function Customer({ item }) {
  const [selected, setSelected] = useState(false);

  return (
    <div className="list-customer">
      <Link
        className="list-application-item"
        to={`../customers/${item?.customer?._id}?tab=credit`}
      >
        <div
          role="button"
          tabIndex={0}
          className="list-customer-item-check"
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <input
            value={selected}
            onChange={() => setSelected((state) => !state)}
            type="checkbox"
          />
        </div>

        <div className="list-customer-item-first">{item?.customer?.firstName}</div>
        <div className="list-customer-item-last">{item?.customer?.lastName}</div>
        <div className="list-customer-item-email">{item?.customer?.email}</div>
        <div className="list-customer-item-phone">{item?.customer?.phone_number}</div>
        <div className="list-customer-item-inst">{item.status}</div>
        <div className="list-customer-item-date">{loncaFormatDate(item.createdAt)}</div>

      </Link>
    </div>
  );
}

export default Customer;
