import { useState } from 'react';
import { fetchDelete } from '../../redux/colorSlice';

import './Color.css';

import { Button, notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

function Color({ item }) {
  const [visible, setVisible] = useState(true);
  const [selected, setSelected] = useState(false);

  const handleDelete = () => {
    fetchDelete(item._id)
      .then((data) => {
        notification['success']({
          message: 'Deleting successful',
        });
        setVisible(false);
      })
      .catch((err) => {
        notification['error']({
          message: err.response.data.message,
        });
      });
  };
  if (visible) {
    return (
      <div className="list-tag">
        <div className="list-tag-item">
          <div className="list-tag-item-check">
            <input
              value={selected}
              onChange={() => setSelected((state) => !state)}
              type="checkbox"
            />
          </div>
          <div className="list-tag-item-tag">{item.color}</div>

          <div id="trash" className={!selected ? 'visibility-hidden' : null}>
            <Button onClick={handleDelete} icon={<DeleteOutlined />} />
          </div>
        </div>
      </div>
    );
  } else return null;
}

export default Color;
