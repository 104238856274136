import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUrlFromState } from './userSlice';
import axios from 'axios';
import store from './store'

const initialState = {
    vendors: [],
    configs: [],
    status: {
        fetchVendors: "idle",
        fetchConfigs: "idle"
    },
    errors: {
        fetchVendors: null,
        fetchConfigs: null
    }
}

export const fetchVendorsForSupplierAuthority = createAsyncThunk('supplierAuthority/fetchVendorsForSupplierAuthority', async () => {
    const url = getUrlFromState(store)
    const { data } = await axios.get(`${url}/supplier-authority/get-all-vendors`);
    return data;
});

export const fetchSupplierAuthorityConfigs = createAsyncThunk('supplierAuthority/fetchSupplierAuthorityConfigs', async () => {
    const url = getUrlFromState(store)
    const { data } = await axios.get(`${url}/supplier-authority/get-configs`);
    return data;
})

export const updateVendorsForSupplierAuthority = ({ changedVendors, changedConfigs }) => {
    const url = getUrlFromState(store);
    return axios.patch(`${url}/supplier-authority/update-vendors`, { changedVendors, changedConfigs });
};

const supplierAuthoritySlice = createSlice({
    name: "supplierAuthority",
    initialState: initialState,
    reducers: {
        setVendors: (state, action) => {
            state.vendors = action.payload;
        },
        setConfigs: (state, action) => {
            state.configs = action.payload
        }
    },
    extraReducers: {
        /** Vendors */
        [fetchVendorsForSupplierAuthority.pending]: (state) => {
            state.status.fetchVendors = 'pending';
        },
        [fetchVendorsForSupplierAuthority.fulfilled]: (state, action) => {
            state.status.fetchVendors = 'fulfilled';
            state.vendors = action.payload;
        },
        [fetchVendorsForSupplierAuthority.rejected]: (state, action) => {
            state.status.fetchVendors = 'rejected';
            state.errors.fetchVendors = action.error.message;
        },
        
        /** Configs */
        [fetchSupplierAuthorityConfigs.pending]: (state) => {
            state.status.fetchConfigs = 'pending';
        },
        [fetchSupplierAuthorityConfigs.fulfilled]: (state, action) => {
            state.status.fetchConfigs = 'fulfilled';
            state.configs = action.payload;
        },
        [fetchSupplierAuthorityConfigs.rejected]: (state, action) => {
            state.status.fetchConfigs = 'rejected';
            state.errors.fetchConfigs = action.error.message;
        },
    }
});


export const { setVendors, setConfigs } = supplierAuthoritySlice.actions;
export default supplierAuthoritySlice.reducer;

