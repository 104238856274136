import React from 'react';
import { Form, Input } from 'antd';
import { DESI_CONSTANT } from "../../constants";


const MeasurementsEditor = ({ measurement, product, setProduct, setMeasurement, setIsDirty }) => {

    const handleDesiChange = (change) => {

        let newMeasurement = {
          ...measurement,
          ...change,
        }
    
        newMeasurement.desi = Math.max(
          newMeasurement.weight,
          newMeasurement.height *
          newMeasurement.length *
          newMeasurement.width /
          DESI_CONSTANT
        )
        setMeasurement(newMeasurement);
        setProduct({
          ...product,
          measurement: {
            weight: parseFloat(newMeasurement.weight),
            height: parseFloat(newMeasurement.height),
            width: parseFloat(newMeasurement.width),
            length: parseFloat(newMeasurement.length),
            desi: parseFloat(newMeasurement.desi),
          }
        })
    }

    return (
        <div className="measurements-box">  
            <Form.Item label='Measurements' >
                <div className="inline-inputs">
                    <Form.Item label="Length">
                        <Input
                            prefix="cm"
                            value={measurement?.length}
                            onChange={(e) => {
                                handleDesiChange({ length: e.target.value });

                                setIsDirty(true);
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Height">
                        <Input
                            prefix="cm"
                            value={measurement?.height}
                            onChange={(e) => {
                                handleDesiChange({ height: e.target.value });
                                setIsDirty(true);
                            }}
                        />
                    </Form.Item>
                </div>
                <div className="inline-inputs">
                    <Form.Item label="Width">
                        <Input
                            prefix="cm"
                            value={measurement?.width}
                            onChange={(e) => {
                                handleDesiChange({ width: e.target.value });
                                setIsDirty(true);
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Weight">
                        <Input
                            prefix="kg"
                            value={measurement?.weight}
                            onChange={(e) => {
                                handleDesiChange({ weight: e.target.value });
                                setIsDirty(true);
                            }}
                        />
                    </Form.Item>
                </div>
                <div className="inline-inputs">
                    <Form.Item label="Desi">
                        <Input
                            prefix=""
                            value={measurement?.desi}
                            disabled
                        />
                    </Form.Item>

                </div>
            </Form.Item>
        </div>
    );
};

export default MeasurementsEditor;
