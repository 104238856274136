import './SearchBar.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchSearchBarResults,
  searchBarItemsSelector,
  searchTypeSelector,
  setSearchText,
  setSearchType,
  reset
} from '../../redux/searchSlice';
import { useTranslation } from "react-i18next";

import { setOrderFilter } from '../../redux/filterSlice';
import { setFilter } from '../../redux/newProductsSlice'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react';

function SearchBar({ type }) {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchBarItems = useSelector(searchBarItemsSelector);
  const searchType = useSelector(searchTypeSelector);

  const { orderFilter : filter } = useSelector((state) => state.filter);

  const [localSearchText, setLocalSearchText] = useState('');
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    dispatch(reset());
    dispatch(setSearchType(type));
  }, [dispatch, type])

  const debouncedFetchResults = useCallback(
    debounce((searchText) => {
      dispatch(fetchSearchBarResults({ searchText, searchType }));
    }, 300),
    [dispatch, searchType]
  );

  useEffect(() => {
    if (localSearchText.length === 0) return;
    debouncedFetchResults(localSearchText);
  }, [localSearchText, debouncedFetchResults]);

  const setFilterOrder = useCallback((value) => {
    dispatch(setOrderFilter(value));
  }, [dispatch]);

  return (
    <div className="search-wrapper">
      <div className="search-input">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setDisplay(false);
            if(localSearchText.length !== 0){
              dispatch(setSearchText(localSearchText));
              if(type === "order")setFilterOrder({ ...filter, filter: { customer: {$in : searchBarItems.map((item) => (item._id))}}})
              if(type === "new-products")dispatch(setFilter({ ...filter, filter: { stock_code: {$in : searchBarItems.map((item) => (item.stock_code))}}}))

            }else{
              if(type === "order")setFilterOrder({ ...filter, filter : {}})
              if(type === "new-products")dispatch(setFilter({ ...filter, filter : {}}))
            }
          }}
        >
          <div className="order-search-area">
            <i className="bi bi-search search-icon"></i>
            <input
              type="text"
              placeholder={t('placeholders.Search')}
              value={localSearchText}
              onChange={(e) => {
                setLocalSearchText(e.target.value);
                e.target.value.length > 0
                  ? setDisplay(true)
                  : setDisplay(false);
              }}
            />
          </div>
          {display && searchBarItems.length > 0 && (
            <div className="order-search-results">
              <ul className="order-search-result" key={[searchBarItems.length, localSearchText.length]}>
                {searchBarItems?.slice(0, 3).map((result) => (
                  <li
                    key={result._id}
                    onClick={() => {
                      setDisplay(false);
                      setLocalSearchText('');

                      if(result.type === "order")navigate(`/orders/${result?.orders?._id}`)
                      if(result.type === "new-products"){navigate(`/new-product/${result?._id}`)}
                    }}
                  > 
                  {(result.type === "order") ? <React.Fragment> 
                                                  <span className="name">{result?.firstName}</span>
                                                  <span className="name">{result?.lastName}</span>
                                                  <span className="name">{result?.orders?.payment_at?.slice(0,10) || result?.orders?.checkout_date?.slice(0,10) }</span>
                                                  <span className="type text-muted">{result?.type}</span> 
                                                  </React.Fragment>: null}    
                  {(result.type === "new-products") ? <React.Fragment> 
                                                  <span className="name">{result?.stock_code}</span>
                                                  <span className="name">{result?.vendor}</span>
                                                  <span className="name">{result?.name?.slice(0,10)}</span>
                                                  <span className="type text-muted">{result?.type}</span> 
                                                  </React.Fragment>: null}                     
                  </li>
                ))}
              </ul>
            {
              <input
                className="w-100 button-primary list-all"
                type="submit"
                value={t('placeholders.List All Results')}
              />
              }
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default SearchBar;
