import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, notification, Select } from 'antd';
import ButtonGroup from "antd/lib/button/button-group";
import { DeleteOutlined } from '@ant-design/icons';

import { fetchCreate, fetchUpdate, fetchFilterTypes } from "../../redux/filterTypeSlice";
import { fetchCategories } from "../../redux/vendorSlice";

import "./EditFilter.scss";

const EditFilter = () => {
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const { id } = useParams();
    const isNew = id === "new";

    const { categories } = useSelector((state) => state.vendors);
    const { filterTypes } = useSelector((state) => state.filterType);

    const [hoverText, setHoverText] = useState("");
    const [showHoverText, setShowHoverText] = useState(false);
    const [filterType, setFilterType] = useState({});
    const [addNewCategory, setAddNewCategory] = useState(false);
    const [draftCategoryId, setDraftCategoryId] = useState("");

    useEffect(() => {
        dispatch(fetchCategories());
    }, []);

    useEffect(() => {
        if(!isNew) {
            const id = location.pathname.split('/').pop();
            setFilterType(filterTypes.find(filterType => filterType?._id?.toString() === id?.toString()));
        }
    }, [filterTypes]);

    useEffect(() => {
        form.setFieldsValue({
            name: filterType?.name,
            handle: filterType?.handle,
            type: filterType?.type,
            status: filterType?.status,
            ranking: filterType?.ranking,
            ui_order: filterType?.ui_order,
            category_naming: filterType?.category_naming,
            display_on_vendor_page: filterType?.display_on_vendor_page
        });

        Object.keys((filterType?.names || {}))?.forEach(key => form.setFieldsValue(`name_${key}`, filterType?.names?.[key]))
    }, [form, filterType]);

    const handleSubmit = async () => {
        if(isNew) {
            fetchCreate(filterType)
                .then(() => {
                    notification['success']({
                        message: "Filter created successfully"
                    });

                    dispatch(fetchFilterTypes());
                    navigate("/filters")
                })
                .catch((err) => {
                    notification['error']({
                        message: err.response.data.error
                    })
                    .catch((err) => {
                        notification['error']({
                            message: "Filter couldn't created successfully"
                        });
                    })
                })
        } else {
            fetchUpdate(filterType?._id, filterType)
                .then(response => {
                    notification['success']({
                        message: "Filter modified successfully"
                    });

                    setFilterType(response);
                    dispatch(fetchFilterTypes());
                }).catch((err) => {
                    notification['error']({
                        message: err?.response?.data?.error
                    })
                    .catch((err) => {
                        notification['error']({
                            message: "Filter couldn't modified successfully"
                        });
                    })
                })
        }
    }

    const setInfo = ({ parentField, field, value }) => {
        if (parentField) {
            setFilterType({
                ...filterType,
                [parentField]: {
                    ...filterType[parentField],
                    [field]: value
                }
            })
        } else {
            setFilterType({
                ...filterType,
                [field]: value
            })
        }
    }

    const handleMouseEnter = (category) => {
        const hierarchyText = [...category?.hierarchy?.slice().sort((a, b) => a.category_ranking - b.category_ranking).map((x) => x.name)]
        setHoverText(`Hovered Category: ${hierarchyText.join(' > ')} > ${category.name}`);
        setShowHoverText(true);
    };
    
    const handleMouseLeave = () => {
        setShowHoverText(false);
    };

    const handleAddNewCategory = () => {
        const toBeAddedCategory = `${draftCategoryId}`;
        setDraftCategoryId({});

        if (!toBeAddedCategory) {
            setAddNewCategory(false);
            return;
        }

        if ( filterType?.active_categories?.find(c => c?.toString() === draftCategoryId?.toString()) ) {
            setAddNewCategory(false);
            return;
        }

        setFilterType({
            ...filterType,
            active_categories: [
                ...(filterType?.active_categories || []),
                toBeAddedCategory
            ]
        });

        setAddNewCategory(false);
    }

    const handleCategoryDelete = (category) => {
        setFilterType({
            ...filterType,
            active_categories: filterType?.active_categories?.filter(p => p?._id?.toString() !== category?._id?.toString())
        })
    }

    return (
        <div className="edit-filter-container">
            <Form
                form={form}
                className="edit-filter-form"
                onFinish={handleSubmit}
                layout="vertical"
            >
                <div className="edit-filter-header">
                    <div className="filter-header">
                        <h2>{ filterType?.name }</h2>
                    </div>

                    <div>
                        <Button type="primary" htmlType="submit" style={{ marginRight: "10px" }}>
                            Save
                        </Button>
                        <Button type="secondary" onClick={() => {navigate("/filters")}}>
                            Cancel
                        </Button>
                    </div>
                </div>

                <div className="info-container">
                    <div className="info-header">
                        <h3>General Info</h3>
                    </div>

                    <div className="info">
                        <div className="inline-inputs-four">
                            <Form.Item label="Name"  name="name">
                                <Input
                                    value={filterType?.name}
                                    placeholder="Name"
                                    onChange={(e) => setInfo({ field: "name", value: e.target.value}) }>
                                </Input>
                            </Form.Item>

                            <Form.Item label="Type"  name="type">
                                <Input
                                    disabled={!isNew}
                                    value={filterType?.type}
                                    placeholder="Type"
                                    onChange={(e) => setInfo({ field: "type", value: e.target.value}) }>
                                </Input>
                            </Form.Item>

                            <Form.Item label="Status"  name="status">
                                <Select
                                    value={filterType?.status}
                                    onChange={(e) => setInfo({ field: "status", value: e }) }
                                >
                                    <Option value="Active">Active</Option>
                                    <Option value="Passive">Passive</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item label="Display On Vendor Page"  name="display_on_vendor_page">
                                <Select
                                    value={filterType?.display_on_vendor_page}
                                    onChange={(e) => setInfo({ field: "display_on_vendor_page", value: e }) }
                                >
                                    <Option value={true}>Yes</Option>
                                    <Option value={false}>No</Option>
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="inline-inputs-four">
                            <Form.Item label="Url Extension"  name="handle">
                                <Input
                                    value={filterType?.handle}
                                    placeholder="Url Extension"
                                    onChange={(e) => setInfo({ field: "handle", value: e.target.value}) }>
                                </Input>
                            </Form.Item>

                            <Form.Item label="Url Extension Order"  name="ranking">
                                <Input
                                    type = "number"
                                    min={0}
                                    value={filterType?.ranking}
                                    placeholder="Url Extension Order"
                                    onChange={(e) => setInfo({ field: "ranking", value: e.target.value }) }>
                                </Input>
                            </Form.Item>

                            <Form.Item label="FilterBar UI Order"  name="ui_order">
                                <Input
                                    type = "number"
                                    min={0}
                                    value={filterType?.ui_order}
                                    placeholder="FilterBar UI Order"
                                    onChange={(e) => setInfo({ field: "ui_order", value: e.target.value }) }>
                                </Input>
                            </Form.Item>

                            <Form.Item label="Category Naming"  name="category_naming">
                                <Input
                                    value={filterType?.category_naming}
                                    placeholder="Category Naming"
                                    onChange={(e) => setInfo({ field: "category_naming", value: e.target.value}) }>
                                </Input>
                            </Form.Item>
                        </div>
                    </div>
                </div>

                { Object.keys((filterType?.names || {}))?.length > 0 && <div className="info-container">
                    <div className="info-header">
                        <h3>Translations</h3>
                    </div>

                    <div className="info">
                        <div className="translations-container">
                            { Object.keys((filterType?.names || {}))
                                ?.sort((a, b) => a - b)
                                ?.map(language => (
                                    <Form.Item label={`Name ${language?.toUpperCase()}`}  name={`name_${language}`}>
                                        <div></div>
                                        <Input
                                            value={filterType?.names?.[language]}
                                            placeholder={`Name ${language?.toUpperCase()}`}
                                            onChange={(e) => setInfo({ parentField: "names", field: language, value: e.target.value}) }>
                                        </Input>
                                    </Form.Item>
                                )) 
                            }
                        </div>
                    </div>
                </div>}

                <div className="info-container">
                    <h3 className="section-title">Allowed Categories</h3>

                    <div className="section">

                        <div>
                            { !addNewCategory && <Button type="secondary" onClick={() => setAddNewCategory(true)}>Add New Category</Button> }

                            { addNewCategory && <div>
                                <div className="inline-inputs-three-to-one center-column">
                                    <Form.Item 
                                        label="Category"
                                        name="category"
                                    >
                                        <Select
                                            showSearch
                                            onMouseLeave={handleMouseLeave}
                                            onChange={(e) => setDraftCategoryId(e.split('-')[0]) }
                                        >
                                            { categories && categories
                                                .slice()
                                                .sort((a, b) => a.category_ranking - b.category_ranking)
                                                .map((category) => (
                                                    <Option key={category?._id}  value={category?._id + '-' + category?.name}   onMouseEnter={() => handleMouseEnter(category)}>
                                                        {category?.hierarchy[0]?.name && category?.hierarchy?.slice().sort((a, b) => a.category_ranking - b.category_ranking)[category?.hierarchy.length -1]?.name}  {category.name} {category.category_ranking}
                                                    </Option>
                                                ))
                                            }
                                        </Select>

                                        {showHoverText && <div className="hovered-category">{ hoverText}</div>}
                                    </Form.Item>

                                    <div>
                                        <ButtonGroup>
                                            <Button type="primary" className="add-person-button" onClick={handleAddNewCategory}>Save New Category</Button>
                                            <Button type="secondary" className="add-person-button" onClick={() => setAddNewCategory(false)}>Cancel</Button>
                                        </ButtonGroup>
                                    </div>
                                </div>
                            </div> }

                        </div>

                        <div className="seperator"></div>

                        <div className="info">

                            <div className="category-list">
                                <h4>Allowed Category List</h4>

                                { (filterType?.active_categories || [])?.map(category => {

                                    const populatedCategory = categories?.find(c => c?._id?.toString() === category?._id?.toString());

                                    return (
                                        <div className="inline-inputs-three-to-one">
                                            <Form.Item label="">
                                                <Input
                                                    value={`${populatedCategory?.hierarchy?.[0]?.name && populatedCategory?.hierarchy?.slice().sort((a, b) => a.category_ranking - b.category_ranking)[populatedCategory?.hierarchy.length -1]?.name} ${populatedCategory?.name} ${populatedCategory?.category_ranking}`}
                                                    disabled
                                                >
                                                </Input>
                                            </Form.Item>

                                            <div 
                                                className="category-trash"
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                <Button onClick={() => handleCategoryDelete(category)} icon={<DeleteOutlined />} />
                                            </div>
                                        </div>
                                    )
                                }) }
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
};

export default EditFilter;