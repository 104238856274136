import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getUrlFromState } from './userSlice';
import store from './store'

export const fetchSupplierApplications = createAsyncThunk(
  'supplierApplications/fetchSupplierApplications',
  async (filter) => {
    const url = getUrlFromState(store)
    const { data } = await axios.post(`${url}/supplier-application/list`, filter);

    return data;
  },
);

export const fetchSupplierApplication = async (payload) => {
  const url = getUrlFromState(store)
  const res = await axios.post(`${url}/supplier-application/index-one`, payload);
  return res;
};

export const fetchUpdate = (id, update) => {
  const url = getUrlFromState(store)
  return axios.patch(`${url}/supplier-application/one/${id}`, {
  update,
});
}

const supplierApplicationSlice = createSlice({
  name: 'supplierApplication',
  initialState: {
    filter: {
      page: 1,
      limit: 100,
      search: '',
    },
    supplierApplications: [],
    status: {
      fetchSupplierApplications: 'idle',

    },
    error: null,
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    // normal filter results
    [fetchSupplierApplications.pending]: (state) => {
      state.status.fetchSupplierApplications = 'pending';
    },
    [fetchSupplierApplications.fulfilled]: (state, action) => {
      state.status.fetchSupplierApplications = 'succeeded';
      state.supplierApplications = action.payload;
    },
    [fetchSupplierApplications.rejected]: (state, action) => {
      state.status.fetchSupplierApplications = 'rejected';
      state.error = action.payload;
    },

  },
});

export const { setFilter } = supplierApplicationSlice.actions;

export default supplierApplicationSlice.reducer;
