import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { fetchDeleteNewProduct, fetchUpdateNewProducts } from '../../redux/newProductsSlice';

import { Image, Button, notification } from 'antd';
import { DeleteOutlined, CloseCircleOutlined } from '@ant-design/icons';

import './NewProduct.css';
import { defaultImage, loncaRound } from '../../helpers';

function NewProduct({ item }) {
    const [selected, setSelected] = useState(false);
    const [visible, setVisible] = useState(true);
    const navigate = useNavigate();

    const handleDelete = (e) => {
        e.stopPropagation();
        fetchUpdateNewProducts({ id: item._id, product: { ...item, blacklist: true } })
            .then((data) => {
                notification['success']({
                    message: 'Deleting successful',
                });
                setVisible(false);
            })
            .catch((err) => {
                notification['error']({
                    message: 'Deleting failed',
                });
            });
    };

    const handleRemove = (e) => {
        e.stopPropagation();
        fetchDeleteNewProduct({ id: item._id }).then((data) => {
            notification['success']({
                message: 'Removing successful',
            });
            setVisible(false);
        })
            .catch((err) => {
                notification['error']({
                    message: 'Removing failed',
                });
            });
    }

    if (!visible || !item) return null;

    return (
        <div className="list-product">
            <div
                className="list-new-product-item"
                onClick={() => {
                    navigate(`/new-product/${item?._id}`);
                }}
            >
                <div className="list-new-product-item-check" onClick={(e) => e.stopPropagation()}>
                    <input
                        value={selected}
                        onChange={() => setSelected((state) => !state)}
                        type="checkbox"
                    />
                </div>
                <div className="list-new-product-item-image" 
                    onClick={(e) => {
                        if(item.images) {
                            window.open(item.images[0], '_blank');
                        }
                        e.stopPropagation();
                    }}
                >
                    <Image height={60} src={item.images ? item.images[0] : defaultImage} />
                </div>
                <div className="list-new-product-item-product">{item?.name}</div>
                <div className="list-new-product-item-status">{item?.status}</div>
                <div className="list-new-product-item-tag">{item?.stock_code}</div>
                <div className="list-new-product-item-vendor">{item?.vendor}</div>
                <div className="list-new-product-item-price">{item?.price_unit ? `${loncaRound(item?.price)}${item?.price_unit}` : ""}</div>
                <div
                    id="trash"
                    className={!selected ? 'visibility-hidden list-new-product-item-blacklist' : "list-new-product-item-blacklist"}
                >
                    <Button style={{ marginTop: '15px' }} onClick={(e) => handleDelete(e)} icon={<CloseCircleOutlined />} />
                    <p>Blacklist</p>
                </div>
                <div
                    id="trash"
                    className={!selected ? 'visibility-hidden list-new-product-item-trash' : "list-new-product-item-blacklist"}
                >
                    <Button style={{ marginTop: '15px' }} onClick={(e) => handleRemove(e)} icon={<DeleteOutlined />} />
                    <p>Delete</p>
                </div>
            </div>
        </div >
    );
}

export default NewProduct;