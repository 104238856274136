import axios from 'axios';
import store from './store'
import { getUrlFromState } from './userSlice';

export const fetchWarehouseBoxes = (filter) => {
    const url = getUrlFromState(store)    
    return axios.post(`${url}/warehouse-box`, { filter });
}

export const fetchUnusedWarehouseBox = (type) => {
    const url = getUrlFromState(store)    
    return axios.post(`${url}/warehouse-box/get-unused-box`, { type });
    }


export const fetchUpdateWarehouseBox = async ({boxId, update, isOrderPackagable}) =>{
    const url = getUrlFromState(store)    
    const { data } = await axios.post(`${url}/warehouse-box/update`, { boxId, update, isOrderPackagable })
    return data
} ;

export const addExchangeBox = async ({ order, type }) =>{
    const url = getUrlFromState(store)    
    const { data } = await axios.post(`${url}/warehouse-box/add-exchange-box`, { order, type })
    return data
} ;

export const updateExchangeBox = async ({ order, exchangeBoxData }) =>{
    const url = getUrlFromState(store)    
    const { data } = await axios.patch(`${url}/warehouse-box/update-exchange-box`, { order, exchangeBoxData })
    return data
} ;

