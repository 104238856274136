import { configureStore } from '@reduxjs/toolkit';
import productSlice from './productSlice';
import vendorSlice from './vendorSlice';
import filterSlice from './filterSlice';
import userSlice from './userSlice';
import searchSlice from './searchSlice';
import tagSlice from './tagSlice';
import seriesSlice from './seriesSlice';
import paymentTypesSlice from './paymentTypesSlice';
import productTypesSlice from './productTypesSlice';
import colorSlice from './colorSlice';
import registrationInputSlice from './registrationInputSlice';
import newProductsSlice from './newProductsSlice';
import genderSlice from './genderSlice';
import orderSlice from './orderSlice';
import bulkInvoiceSlice from './bulkInvoiceSlice';
import couponSlice from './couponSlice';
import fieldDictsSlice from './fieldDictsSlice';
import refundSlice from './refundSlice';
import supplierApplicationSlice from './supplierApplicationSlice';
import roleSlice from './roleSlice';
import filterTypeSlice from './filterTypeSlice';
import exchangeRatesSlice from './exchangeRatesSlice';
import supplierAuthoritySlice from './supplierAuthoritySlice';
import configurationsSlice from './configurationsSlice';

const store = configureStore({
  reducer: {
    products: productSlice,
    vendors: vendorSlice,
    filter: filterSlice,
    user: userSlice,
    search: searchSlice,
    tags: tagSlice,
    series: seriesSlice,
    paymentTypes: paymentTypesSlice,
    productTypes: productTypesSlice,
    colors: colorSlice,
    registrationInput: registrationInputSlice,
    newProducts: newProductsSlice,
    gender: genderSlice,
    order: orderSlice,
    bulkInvoice: bulkInvoiceSlice,
    coupon: couponSlice,
    fieldDicts: fieldDictsSlice,
    refunds: refundSlice,
    supplierApplications: supplierApplicationSlice,
    role: roleSlice,
    filterType: filterTypeSlice,
    exchangeRates: exchangeRatesSlice,
    supplierAuthority: supplierAuthoritySlice,
    configurations: configurationsSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  }),
});

export default store;
