import axios from 'axios';
import { getUrlFromState } from './userSlice';
import store from './store'

export const fetchUsers = (filter) => {
  const url = getUrlFromState(store)
  return axios.post(
  `${url}/user/filter`,
  filter,
);
}

export const fetchDelete = (id) => {
  const url = getUrlFromState(store)
  return axios.delete(`${url}/user/one/${id}`);
}

export const fetchUpdate = (id, update, passwordChanged) => {
  const url = getUrlFromState(store)
  return axios.patch(`${url}/user/one/${id}`, {
  update,
  passwordChanged
});
}

export const fetchCreate = (user) => {
  const url = getUrlFromState(store)
  return axios.post(`${url}/user/`, user);
}

export const fetchUserById = (id) => {
  const url = getUrlFromState(store)
  return axios.get(
  `${url}/user/one/${id}`,
)};
