import React from 'react';
import { Form, InputNumber } from 'antd';

const StockInformation = ({ product, setProduct, setIsDirty, isAllowed }) => {

  return (
    <div className="stock-box">
        <Form.Item label='Stock Information' >
            <div className="inline-inputs">
                <Form.Item name="stock" label="Stock">
                    <InputNumber
                    value={product?.stock}
                    min={0}
                    onChange={(e) => {
                        const stock = parseInt(e)
                        setProduct({ ...product, stock });
                        setIsDirty(true);
                    }}
                    className="input-width"
                    />
                </Form.Item>
                {isAllowed && <Form.Item name="supplier_stock" label="Supplier Stock">
                    <InputNumber
                    value={product?.supplier_stock}
                    min={0}
                    onChange={(e) => {
                        const supplier_stock = parseInt(e)
                        setProduct({ ...product, supplier_stock });
                        setIsDirty(true);
                    }}
                    className="input-width"
                    />
                </Form.Item>}
            </div>
            <div className="inline-inputs">
                <Form.Item name="in_transit_stock" label="In Transit Stock">
                    <InputNumber
                    value={product?.in_transit_stock}
                    min={0}
                    onChange={(e) => {
                        const in_transit_stock = parseInt(e)
                        setProduct({ ...product, in_transit_stock });
                        setIsDirty(true);
                    }}
                    className="input-width"
                    />
                </Form.Item>
                <Form.Item name="reserved_stock" label="Reserved Stock">
                    <InputNumber
                    value={product?.reserved_stock}
                    min={0}
                    onChange={(e) => {
                        const reserved_stock = parseInt(e)
                        setProduct({ ...product, reserved_stock });
                        setIsDirty(true);
                    }}
                    className="input-width"
                    />
                </Form.Item>
            </div>
        </Form.Item>
    </div>
  );
};

export default StockInformation;
