import { Link } from "react-router-dom";
import { getDate, getTime } from '../../helpers';

import './Cart.css';

function Cart({ item, setShowForm, setCart }) {

  return (
    <div className="list-cart">
      <Link 
        className="list-cart-item"
        to={`../carts/${item?._id}`}
      >
        <div></div>
        <div className="list-cart-item-fname">
          {item?.customer?.firstName || 'NO NAME'}
        </div>
        <div className="list-cart-item-lname">
          {item?.customer?.lastName || 'NO LASTNAME'}
        </div>
        <div className="list-cart-item-cost">{item?.total_cost}</div>
        <div className="list-cart-item-items">
          {item?.cart_item?.length}
        </div>
        <div className="list-cart-item-date">
          {getDate(item?.updatedAt)}
        </div>
        <div className="list-cart-item-time">
          {getTime(item?.updatedAt)}
        </div>
      </Link>
    </div>
  );
}

export default Cart;
